import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { t } from '@lingui/macro';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CockpitGoobieInformation from './components/GoobiesAdministration/CockpitGoobieInformations';
import { ReturnButton } from '../../components/molecules';
import { getTrackerDataByImei } from '../../api/trackers/Trackers';
import { useUserContext } from '../../contexts/UserContext/UserContext';

const CockpitGoobie = () => {
  const params = useParams();
  const { id } = params;
  const [goobieData, setGoobieData] = useState(null);

  const fetchData = async (id) => {
    const result = await getTrackerDataByImei(id);
    // const reports = await getGoobieReports(result.id);
    setGoobieData(result);
    // setReports(reports);
  };

  const user = useUserContext();

  useEffect(() => {
    if (user.codeRegion !== '01') {
      window.location.replace('/');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchData(id);
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ paddingBottom: '15px' }}
        >
          <ReturnButton label={t`Retour`} />
        </Stack>
      </Stack>
      <Typography variant="h3">Goobie n° {id}</Typography>
      {goobieData ? (
        <CockpitGoobieInformation
          goobie={goobieData}
          setGoobieData={setGoobieData}
        />
      ) : null}
    </Box>
  );
};

export default CockpitGoobie;
