import { StyleSheet } from '@react-pdf/renderer';
import colors from '../../../../theme/_colors.scss';

export const ReportPDFStyles = StyleSheet.create({
  page: {
    backgroundColor: colors.imperialRed,
    color: 'white',
  },
  loxamLogo: {
    width: 100,
    margin: '20px 0',
    alignSelf: 'center',
  },
  titleSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    alignSelf: 'center',
    padding: 10,
  },
  reportIcon: {
    width: 24,
    marginRight: 5,
  },
  periodText: {
    fontSize: 14,
  },
  linkSection: {
    alignSelf: 'center',
    margin: '0px 50px 0px 50px',
    fontSize: 12,
  },
  linkText: {
    color: 'white',
  },
});
