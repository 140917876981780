import { getRequest } from '../utils/axios.utils';

export const getAgenciesByEmail = (email) => {
  return getRequest(`agency-email/${email}`);
};
export const getContractsByEmail = (email) => {
  return getRequest(`contract-email/${email}`);
};
export const getJobSitesByEmail = (email) => {
  return getRequest(`job-site-email/${email}`);
};
export const getMachinesByEmail = (email) => {
  return getRequest(`machines-email/${email}`);
};
