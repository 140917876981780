import { t } from '@lingui/macro';

// Create an object used as links on the app
export const createEntry = ({
  icon = '',
  iconColor = null,
  title = '',
  titleInternal = title,
  description = '',
  descriptionInternal = description,
  href = '',
  value = '',
  hide = false,
  disabled = false,
  isInternal = false,
  rolesAuthorized = [],
  regionsAuthorized = [],
  showNotifPanel = false,
}) => {
  return {
    icon,
    iconColor,
    title,
    titleInternal,
    description,
    descriptionInternal,
    href,
    value,
    hide,
    disabled,
    isInternal,
    rolesAuthorized,
    regionsAuthorized,
    showNotificationsPanel: showNotifPanel,
  };
};

// Array containing the Main Navbar Mobile menu items
export const homeMobileEntries = [
  createEntry({
    icon: 'location_on',
    title: t`Parc LOXAM`,
    description: t`Localisation des machines équipées de trackunit, OEM et Goobies`,
    href: '/parcLoxam/map',
    value: 'parcLoxam',
    rolesAuthorized: ['DR', 'SG'],
    regionsAuthorized: ['01'],
    isInternal: true,
  }),
  createEntry({
    icon: 'query_stats',
    title: t`Flotte`,
    titleInternal: t`Flotte Agence`,
    description: t`Ensemble des machines connectées en cours de location et des chantiers.`,
    descriptionInternal: t`Détails des données par machine, agence et chantier.`,
    href: '/equipments/map',
    value: 'equipments',
  }),
  createEntry({
    icon: 'verified_user',
    title: t`Sécurité`,
    description: t`Ensemble des alertes sur les machines connectées.`,
    // notifications: 0,
    // alerts:0,
    // showNotificationsPanel: true,
    disabled: true,
    // href: '/security',
    value: 'security',
  }),
  createEntry({
    icon: 'spa',
    title: t`Environnement`,
    description: t`Empreinte environnementale de vos machines connectées et chantiers.`,
    disabled: true,
    value: 'environnement',
  }),
  createEntry({
    icon: 'vpn_key',
    title: t`Accès`,
    description: t`Gestion des accès à vos machines connectées.`,
    disabled: true,
    // value: 'access',
  }),
  createEntry({
    icon: 'description',
    title: t`Rapport`,
    description: t`Vos rapports d'activité.`,
    disabled: true,
    // href: '/report',
    value: 'report',
  }),
  createEntry({
    icon: 'notification_important',
    title: t`Notifications`,
    description: t`Vos notifications d'activité.`,
    // href: '/notifications',
    // value: 'notifications',
    disabled: true,
  }),
  createEntry({
    icon: 'insert_chart',
    title: t`Cockpit`,
    description: t`Supervision de l'infrastructure et des données`,
    href: '/cockpit',
    value: 'cockpit',
    disabled: false,
    isInternal: true,
    regionsAuthorized: '01',
  }),
  createEntry({
    icon: 'settings_applications',
    title: t`Paramètres`,
    description: t`Vos paramètres.`,
    // href: '/settings',
    // value: 'settings',
    disabled: true,
  }),
  createEntry({
    icon: 'track_changes',
    title: t`Goobie Pairing`,
    description: t`Goobie Pairing`,
    // href: '/scan',
    value: 'scan',
    disabled: true,
    isInternal: true,
  }),
];

// Array containing the Mobile menu items
export const mainNavbarMobileEntries = [
  createEntry({
    icon: 'home',
    title: t`Accueil`,
    description: t`Page d'accueil de l'application`,
    href: '/',
    value: '/',
  }),
  createEntry({
    icon: 'location_on',
    title: t`Parc LOXAM`,
    description: t`Localisation des machines équipées de trackunit, OEM et Goobies`,
    href: '/parcLoxam/map',
    value: 'parcLoxam',
    rolesAuthorized: ['DR', 'SG'],
    regionsAuthorized: ['01'],
    isInternal: true,
  }),
  createEntry({
    icon: 'query_stats',
    title: t`Flotte`,
    titleInternal: t`Flotte Agence`,
    description: t`Ensemble des machines connectées en cours de location et des chantiers.`,
    descriptionInternal: t`Détails des données par machine, agence et chantier.`,
    href: '/equipments/map',
    value: 'equipments',
  }),
  createEntry({
    icon: 'verified_user',
    title: t`Sécurité`,
    description: t`Ensemble des alertes sur les machines connectées.`,
    // notifications: 0,
    // alerts:0,
    // showNotificationsPanel: true,
    disabled: true,
    // href: '/security',
    value: 'security',
  }),
  createEntry({
    icon: 'spa',
    title: t`Environnement`,
    description: t`Empreinte environnementale de vos machines connectées et chantiers.`,
    disabled: true,
    value: 'environnement',
  }),
  createEntry({
    icon: 'vpn_key',
    title: t`Accès`,
    description: t`Gestion des accès à vos machines connectées.`,
    disabled: true,
    // value: 'access',
  }),
  createEntry({
    icon: 'description',
    title: t`Rapport`,
    description: t`Vos rapports d'activité.`,
    disabled: true,
    // href: '/report',
    value: 'report',
  }),
  createEntry({
    icon: 'notification_important',
    title: t`Notifications`,
    description: t`Vos notifications d'activité.`,
    // href: '/notifications',
    // value: 'notifications',
    disabled: true,
  }),
  createEntry({
    icon: 'insert_chart',
    title: t`Cockpit`,
    description: t`Supervision de l'infrastructure et des données`,
    href: '/cockpit',
    value: 'cockpit',
    disabled: false,
    isInternal: true,
    regionsAuthorized: '01',
  }),
  createEntry({
    icon: 'settings_applications',
    title: t`Paramètres`,
    description: t`Vos paramètres.`,
    // href: '/settings',
    // value: 'settings',
    disabled: true,
  }),
  createEntry({
    icon: 'track_changes',
    title: t`Goobie Pairing`,
    description: t`Goobie Pairing`,
    // href: '/scan',
    value: 'scan',
    disabled: true,
    isInternal: true,
  }),
];

// Array containing the Desktop menu items
export const homeDesktopEntries = [
  createEntry({
    icon: 'location_on',
    title: t`Parc LOXAM`,
    description: t`Localisation des machines équipées de trackunit, OEM et Goobies`,
    href: '/parcLoxam/map',
    value: 'parcLoxam',
    rolesAuthorized: ['DR', 'SG'],
    regionsAuthorized: ['01'],
    isInternal: true,
  }),
  createEntry({
    icon: 'query_stats',
    title: t`Flotte`,
    titleInternal: t`Flotte Agence`,
    description: t`Ensemble des machines connectées en cours de location et des chantiers.`,
    descriptionInternal: t`Détails des données par machine, agence et chantier.`,
    href: '/equipments/map',
    value: 'equipments',
  }),
  createEntry({
    icon: 'verified_user',
    title: t`Sécurité`,
    description: t`Ensemble des alertes sur les machines connectées.`,
    // notifications: 0,
    // alerts:0,
    // showNotificationsPanel: true,
    disabled: true,
    // href: '/security',
    value: 'security',
  }),
  createEntry({
    icon: 'spa',
    title: t`Environnement`,
    description: t`Empreinte environnementale de vos machines connectées et chantiers.`,
    disabled: true,
    value: 'environnement',
  }),
  createEntry({
    icon: 'vpn_key',
    title: t`Accès`,
    description: t`Gestion des accès à vos machines connectées.`,
    disabled: true,
    // value: 'access',
  }),
  createEntry({
    icon: 'description',
    title: t`Rapport`,
    description: t`Vos rapports d'activité.`,
    disabled: true,
    // href: '/report',
    value: 'report',
  }),
  createEntry({
    icon: 'notification_important',
    title: t`Notifications`,
    description: t`Vos notifications d'activité.`,
    // href: '/notifications',
    // value: 'notifications',
    disabled: true,
  }),
  createEntry({
    icon: 'insert_chart',
    title: t`Cockpit`,
    description: t`Supervision de l'infrastructure et des données`,
    href: '/cockpit',
    value: 'cockpit',
    disabled: false,
    isInternal: true,
    regionsAuthorized: '01',
  }),
  createEntry({
    icon: 'settings_applications',
    title: t`Paramètres`,
    description: t`Vos paramètres.`,
    // href: '/settings',
    // value: 'settings',
    disabled: true,
  }),
];

// Array containing the Main Navbar Desktop menu items
export const mainNavbarDesktopEntries = [
  createEntry({
    icon: 'home',
    title: t`Accueil`,
    description: t`Page d'accueil de l'application`,
    href: '/',
    value: '/',
  }),
  createEntry({
    icon: 'location_on',
    title: t`Parc LOXAM`,
    description: t`Localisation des machines équipées de trackunit, OEM et Goobies`,
    href: '/parcLoxam/map',
    value: 'parcLoxam',
    rolesAuthorized: ['DR', 'SG'],
    regionsAuthorized: ['01'],
    isInternal: true,
  }),
  createEntry({
    icon: 'query_stats',
    title: t`Flotte`,
    titleInternal: t`Flotte Agence`,
    description: t`Ensemble des machines connectées en cours de location et des chantiers.`,
    descriptionInternal: t`Détails des données par machine, agence et chantier.`,
    href: '/equipments/map',
    value: 'equipments',
  }),
  createEntry({
    icon: 'verified_user',
    title: t`Sécurité`,
    description: t`Ensemble des alertes sur les machines connectées.`,
    // notifications: 0,
    // alerts:0,
    // showNotificationsPanel: true,
    disabled: true,
    // href: '/security',
    value: 'security',
  }),
  createEntry({
    icon: 'spa',
    title: t`Environnement`,
    description: t`Empreinte environnementale de vos machines connectées et chantiers.`,
    disabled: true,
    value: 'environnement',
  }),
  createEntry({
    icon: 'vpn_key',
    title: t`Accès`,
    description: t`Gestion des accès à vos machines connectées.`,
    disabled: true,
    // value: 'access',
  }),
  createEntry({
    icon: 'description',
    title: t`Rapport`,
    description: t`Vos rapports d'activité.`,
    disabled: true,
    // href: '/report',
    value: 'report',
  }),
  createEntry({
    icon: 'notification_important',
    title: t`Notifications`,
    description: t`Vos notifications d'activité.`,
    // href: '/notifications',
    // value: 'notifications',
    disabled: true,
  }),
  createEntry({
    icon: 'insert_chart',
    title: t`Cockpit`,
    description: t`Supervision de l'infrastructure et des données`,
    href: '/cockpit',
    value: 'cockpit',
    disabled: false,
    isInternal: true,
    regionsAuthorized: '01',
  }),
  createEntry({
    icon: 'settings_applications',
    title: t`Paramètres`,
    description: t`Vos paramètres.`,
    // href: '/settings',
    // value: 'settings',
    disabled: true,
  }),
];
