import Typography from '@mui/material/Typography';

const MachinePurchaseOrder = ({
  purchaseOrderNumber,
}: {
  purchaseOrderNumber: string;
}) => {
  return <Typography>{purchaseOrderNumber || '-'}</Typography>;
};

export default MachinePurchaseOrder;
