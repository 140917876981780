import { t } from '@lingui/macro';
import { Paper, styled, SvgIcon, MenuItem } from '@mui/material';

// Object used to populate the search filter on the header of the app
export const SearchCategoriesOptions = [
  {
    label: t`Toutes catégories`,
    value: 'Toutes catégories',
  },
  {
    label: t`Agences`,
    value: 'Agences',
  },
  // {
  //   label: t`Chantiers`,
  //   value: 'Chantiers',
  // },
  {
    label: t`Machines`,
    value: 'Machines',
  },
];

export const UserProfileIcon = (
  <SvgIcon>
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0L0 9.95944L6 19.9189H18L24 9.95944L18 0H6ZM2.73962 9.95944L7.36981 2.2783H16.6302L21.2604 9.95944L16.6302 17.6406H7.36981L2.73962 9.95944Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5319 7.72475C15.5319 8.39422 15.3248 9.04866 14.9367 9.6053C14.5486 10.1619 13.9969 10.5958 13.3515 10.852C12.7061 11.1082 11.9959 11.1752 11.3108 11.0446C10.6256 10.914 9.99627 10.5916 9.5023 10.1182C9.00833 9.64485 8.67193 9.04172 8.53564 8.38511C8.39936 7.72851 8.4693 7.04791 8.73664 6.4294C9.00397 5.81089 9.45669 5.28224 10.0375 4.9103C10.6184 4.53836 11.3013 4.33984 11.9999 4.33984C12.9366 4.33984 13.835 4.69647 14.4974 5.33126C15.1598 5.96605 15.5319 6.82702 15.5319 7.72475Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.60382 15.8398H15.6567L17.0265 14.1365C16.3898 13.4653 15.6138 12.929 14.7482 12.562C13.8826 12.195 12.9466 12.0054 12.0001 12.0054C11.0535 12.0054 10.1175 12.195 9.25186 12.562C8.38627 12.929 7.61027 13.4653 6.97363 14.1365L8.60382 15.8398Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

export const StyledPaper = styled(Paper)(({ theme }) => {
  const themePalette: any = theme.palette;

  return {
    width: '100%',
    padding: '16px',
    background: `linear-gradient(180deg, ${
      themePalette.navbar.color1 ?? 'rgba(228,1,21,1)'
    } 0%, ${themePalette.navbar.color2 ?? 'rgba(173,1,0,1)'} 100%)`,
  };
});
