import { Trans } from '@lingui/macro';
import Logout from '@mui/icons-material/Logout';
import {
  Button,
  CircularProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import CustomSelect from '../../../../components/CustomSelect/CustomSelect';
import { SelectedAllOptions } from '../../../../components/JobSiteSelector/JobSiteSelector.interface';
import { logout } from '../../../api/auth/customAuth';
import { removeAuthorizationToken } from '../../../api/utils/axios';
import { useAgenciesContext } from '../../../contexts/AgenciesContext';
import { useContractsContext } from '../../../contexts/ContractsContext';
import { useJobSitesContext } from '../../../contexts/JobSitesContext';
import { useMachinesContext } from '../../../contexts/MachinesContext';
import { useUserContext } from '../../../contexts/UserContext/UserContext';
import { isAllContextLoaded } from '../../../contexts/utils';
import IsMobile from '../../../utils/IsMobile.ts';
import { useAppModeContext } from '../../../utils/hooks';
import {
  AGENCY_FIELDS,
  CONTRACT_FIELDS,
  JOBSITE_FIELDS,
  MACHINE_FIELDS,
  createSearchQuery,
  extractSearcMatchedData,
  generateLunrIndex,
} from '../../../utils/utils';
import SearchInput from '../SearchInput/SearchInput';
import {
  SearchCategoriesOptions,
  StyledPaper,
  UserProfileIcon,
} from './PlainAppNavBar.constants';
import LanguageSelector from './components/LanguageSelector/LanguageSelector';
import LinksMenu from './components/LinksMenu';

import './PlainAppNavBar.scss';

/**
 * This component renders the main app header
 * @param {string} pathName - URL Pathname
 * @param {Array} mobileLinks - Array for the mobile version
 * @param {Array} desktopLinks - Array for the desktop version
 */
const PlainAppNavBar = ({ pathName, mobileLinks, desktopLinks }) => {
  const okta = useOktaAuth();
  const user = useUserContext();

  const { getMachinesLoadStatus, getMachines } = useMachinesContext();
  const { getJobSitesLoadStatus, getJobSites } = useJobSitesContext();
  const { getAgenciesLoadStatus, getAgencies } = useAgenciesContext();
  const { getContractsLoadStatus, getContractsByClient } =
    useContractsContext();

  const { isInternalAppMode } = useAppModeContext();
  const [searchResult, setSearchResult] = useState(null);

  const [machinesLunrIdx, setMachinesLunrIdx] = useState(
    generateLunrIndex(MACHINE_FIELDS, [])
  );
  const [jobSitesLunrIdx, setJobSitesLunrIdx] = useState(
    generateLunrIndex(JOBSITE_FIELDS, [])
  );
  const [agenciesLunrIdx, setAgenciesLunrIdx] = useState(
    generateLunrIndex(AGENCY_FIELDS, [])
  );
  const [contractsLunrIdx, setContractsLunrIdx] = useState(
    generateLunrIndex(CONTRACT_FIELDS, [])
  );
  const [allData, setAllData] = useState({
    machines: [],
    jobSites: [],
    agencies: [],
    contracts: [],
  });

  const [isLoading, setLoading] = useState(true);

  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const [optionSelect, setOptionSelect] = useState('Toutes catégories');

  const openMenu = Boolean(anchorMenuEl);
  const isMobile = IsMobile();

  useEffect(() => {
    if (
      isAllContextLoaded([
        getMachinesLoadStatus(),
        getJobSitesLoadStatus(),
        getAgenciesLoadStatus(),
        getContractsLoadStatus(),
      ])
    ) {
      setLoading(false);
    }
  }, [
    getAgenciesLoadStatus,
    getContractsLoadStatus,
    getJobSitesLoadStatus,
    getMachinesLoadStatus,
  ]);

  useEffect(() => {
    const machines = getMachines();
    setAllData((data) => ({ ...data, machines }));

    setMachinesLunrIdx(generateLunrIndex(MACHINE_FIELDS, machines));
  }, [getMachines]);

  useEffect(() => {
    const jobSites = getJobSites();
    setAllData((data) => ({ ...data, jobSites }));

    setJobSitesLunrIdx(generateLunrIndex(JOBSITE_FIELDS, jobSites));
  }, [getJobSites]);

  useEffect(() => {
    const agencies = getAgencies();
    setAllData((data) => ({ ...data, agencies }));

    setAgenciesLunrIdx(generateLunrIndex(AGENCY_FIELDS, agencies));
  }, [getAgencies]);

  useEffect(() => {
    if (user) {
      const contracts = getContractsByClient(user.clientId);

      setAllData((data) => ({ ...data, contracts }));

      setContractsLunrIdx(generateLunrIndex(CONTRACT_FIELDS, contracts));
    }
  }, [getContractsByClient, user]);

  const handleMenuOpenClick = (event) => {
    setAnchorMenuEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorMenuEl(null);
  };

  const handleLogout = () => {
    // add clear cookies after use of cookies
    removeAuthorizationToken();
    if (isInternalAppMode) {
      okta.oktaAuth.signOut('/');
    } else {
      logout().then(() => {
        window.location.reload();
      });
    }
  };

  const performSearch = (key) => {
    if (key.search.length > 2) {
      const machineIdxResult = machinesLunrIdx.search(
        createSearchQuery(key.search)
      );
      const jobsiteIdxResult = jobSitesLunrIdx.search(
        createSearchQuery(key.search)
      );
      const agencyIdxResult = agenciesLunrIdx.search(
        createSearchQuery(key.search)
      );
      const contractIdxResult = contractsLunrIdx.search(
        createSearchQuery(key.search)
      );
      const machines = extractSearcMatchedData(
        allData.machines,
        machineIdxResult
      );
      const jobSites = extractSearcMatchedData(
        allData.jobSites,
        jobsiteIdxResult
      );
      const agencies = extractSearcMatchedData(
        allData.agencies,
        agencyIdxResult
      );
      const contracts = extractSearcMatchedData(
        allData.contracts,
        contractIdxResult
      );

      setSearchResult({
        machinesResults: machines,
        jobSitesResults: jobSites,
        agenciesResults: agencies,
        contractsResults: contracts,
      });
    } else {
      setSearchResult({
        machinesResults: [],
        jobSitesResults: [],
        agenciesResults: [],
        contractsResults: [],
      });
    }
    return key;
  };

  return (
    <Stack className="plain-app-nav-bar">
      <StyledPaper>
        <Stack
          direction="row"
          spacing={isMobile ? 0 : 3}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Stack
            className={classNames('plain-app-nav-bar__search-bar', {
              'plain-app-nav-bar__search-bar--grid-temp-col-none': isMobile,
            })}
          >
            {isLoading ? (
              <CircularProgress
                classes={{ circle: 'plain-app-nav-bar__loading-icon' }}
              />
            ) : (
              <>
                {!isMobile && (
                  <CustomSelect
                    value={optionSelect}
                    handleChange={setOptionSelect}
                    options={SearchCategoriesOptions}
                    style={{
                      backgroundColor: 'white',
                      height: '56px',
                      borderRadius: '4px 0px 0px 4px',
                    }}
                  />
                )}

                <SearchInput
                  id="header-search-input"
                  isLoading={false}
                  performSearch={performSearch}
                  searchResult={searchResult}
                  whiteBackground
                  filterValue={
                    optionSelect !== 'Toutes catégories'
                      ? optionSelect
                      : SelectedAllOptions
                  }
                />
              </>
            )}
          </Stack>

          <Button onClick={handleMenuOpenClick}>
            <Stack
              direction="row"
              alignContent="end"
              justifyContent="right"
              spacing={1}
            >
              {UserProfileIcon}

              {!isMobile && (
                <Typography color="white">
                  {`${user.firstName} ${user.lastName}`}
                </Typography>
              )}
            </Stack>
          </Button>

          <Menu
            className="plain-app-nav-bar__account-menu"
            anchorEl={anchorMenuEl}
            id="account-menu"
            open={openMenu}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={{
              elevation: 0,
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              <Trans>Déconnexion</Trans>
            </MenuItem>
          </Menu>

          <LanguageSelector />
        </Stack>
      </StyledPaper>

      <Paper className="plain-app-nav-bar__bottom-section">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            className="plain-app-nav-bar__logo"
          >
            <Typography
              className="notranslate"
              variant="h3"
              color="primary.main"
              display="inline-block"
            >
              {isInternalAppMode ? 'Agence' : 'Lox'}
            </Typography>
            <Typography
              className="notranslate"
              variant="h3"
              color="secondary.main"
              display="inline-block"
            >
              Connect
            </Typography>
          </Stack>

          <LinksMenu
            pathName={pathName}
            mobileLinks={mobileLinks}
            desktopLinks={desktopLinks}
          />
        </Stack>
      </Paper>
    </Stack>
  );
};

export default PlainAppNavBar;

PlainAppNavBar.propTypes = {
  pathName: PropTypes.string.isRequired,
  mobileLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string,
      icon: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  desktopLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string,
      icon: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
};
