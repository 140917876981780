import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import MapIcon from '@mui/icons-material/Map';
import ListIcon from '@mui/icons-material/List';

import './MapAndListButtons.scss';

type Props = {
  current: string;
  firstPath: string;
  secondPath: string;
};

const MapAndListButtons: React.FC<Props> = ({
  current,
  firstPath,
  secondPath,
}) => (
  <Stack className="map-and-list-buttons">
    <IconButton
      className="map-and-list-buttons__btn"
      component={Link}
      to={firstPath}
      color="primary"
      disabled={current === firstPath}
      sx={{
        '&:hover': {
          color: 'primary.dark',
        },
      }}
      aria-label="Map view"
    >
      <MapIcon className="map-and-list-buttons__icon" />
    </IconButton>
    <IconButton
      className="map-and-list-buttons__btn"
      component={Link}
      to={secondPath}
      color="primary"
      disabled={current === secondPath}
      sx={{
        '&:hover': {
          color: 'primary.dark',
        },
      }}
      aria-label="List view"
    >
      <ListIcon className="map-and-list-buttons__icon" />
    </IconButton>
  </Stack>
);

export default MapAndListButtons;
