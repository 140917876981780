import { t } from '@lingui/macro';
import { Box, Card, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Period } from '../../../components/EquipmentSecurity/EquipmentSecurity.interface';
import JobSiteSelector from '../../../components/JobSiteSelector/JobSiteSelector';
import { SelectedAllOptions } from '../../../components/JobSiteSelector/JobSiteSelector.interface';
import MachinesByJobSiteSelector from '../../../components/MachinesByJobSiteSelector/MachinesByJobSiteSelector';
import PDFDisplay from '../../../components/PDFDisplay/PDFDisplay';
import { SecurityAlertProps } from '../../../components/SecurityAlert/SecurityAlert';
import Loading from '../../../lib/apptheme/partials/Loading';
import { RequestStatus } from '../../api/api.interface';
import {
  getAllJobSitesSecurityByAgence,
  getAllJobSitesSecurityByClient,
  getJobSiteSecurity,
} from '../../api/jobsites/JobSites';
import DateRangeForm from '../../components/DateRangeForm';
import { useUserContext } from '../../contexts/UserContext/UserContext';
import { getFormatedDateRequest } from '../../utils/date/format';
import { useAppModeContext } from '../../utils/hooks';
import { formatSecurityAlerts } from '../../utils/security/security.utils';
import ReportPDF from './components/ReportPDF/ReportPDF';

import './Report.scss';

const currentDate = new Date();

const defaultPeriod = {
  start: new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, 1),
  endDate: currentDate,
};

const Report = () => {
  const user: any = useUserContext();
  const { isInternalAppMode } = useAppModeContext();

  const [selectedJobSite, setSelectedJobSite] = useState(SelectedAllOptions);
  const [selectedMachine, setSelectedMachine] = useState(SelectedAllOptions);
  const [period, setPeriod] = useState<Period>({
    start: getFormatedDateRequest(defaultPeriod.start),
    end: getFormatedDateRequest(defaultPeriod.endDate),
  });

  const [loadStatus, setLoadStatus] = useState<RequestStatus>(
    RequestStatus.LOADING
  );

  const [alerts, setAlerts] = useState<SecurityAlertProps[]>([]);

  useEffect(() => {
    getReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobSite, selectedMachine, period]);

  const fetchForAllJobSites = () => {
    const agencyId = user?.agencyId;

    if (isInternalAppMode) {
      if (agencyId) {
        getAllJobSitesSecurityByAgence(agencyId, period?.start, period?.end)
          .then((resp) => {
            setAlerts(formatSecurityAlerts(resp.jobSiteMainSummary ?? []));
            setLoadStatus(RequestStatus.SUCCESS);
          })
          .catch((error) => {
            setLoadStatus(RequestStatus.ERROR);
          });
      } else {
        console.error("The user doesn't have an associated agency.");
        setLoadStatus(RequestStatus.ERROR);
      }
    } else {
      getAllJobSitesSecurityByClient(period?.start, period?.end)
        .then((resp) => {
          setAlerts(formatSecurityAlerts(resp.jobSiteMainSummary ?? []));
          setLoadStatus(RequestStatus.SUCCESS);
        })
        .catch((error) => {
          setLoadStatus(RequestStatus.ERROR);
        });
    }
  };

  const getReportData = () => {
    if (selectedJobSite === SelectedAllOptions) {
      fetchForAllJobSites();
    } else {
      getJobSiteSecurity({
        jobSiteId: selectedJobSite,
        machineId:
          selectedMachine === SelectedAllOptions ? null : selectedMachine,
        startDate: period?.start,
        endDate: period?.end,
      })
        .then((resp) => {
          setAlerts(formatSecurityAlerts(resp.jobSiteMainSummary ?? []));
          setLoadStatus(RequestStatus.SUCCESS);
        })
        .catch((error) => {
          setLoadStatus(RequestStatus.ERROR);
        });
    }
  };

  return (
    <Box className="report">
      <Typography className="report__title" variant="h1">
        {t`Rapport`}
      </Typography>
      <Card className="security__card">
        <Stack padding={2} spacing={3}>
          {loadStatus === RequestStatus.LOADING && <Loading />}

          {loadStatus === RequestStatus.ERROR && (
            <Typography variant="h6" textAlign="center">
              {t`Aucune information disponible`}
            </Typography>
          )}

          {loadStatus === RequestStatus.SUCCESS && (
            <>
              <JobSiteSelector handleChange={setSelectedJobSite} />

              {selectedJobSite !== SelectedAllOptions && (
                <MachinesByJobSiteSelector
                  jobSiteId={selectedJobSite}
                  handleChange={setSelectedMachine}
                />
              )}

              <DateRangeForm
                handleSubmit={(period: any) => {
                  setPeriod({
                    start: getFormatedDateRequest(period.start),
                    end: getFormatedDateRequest(period.end),
                  });
                }}
                initialValues={{
                  dateRange: {
                    start: moment(defaultPeriod.start),
                    end: moment(defaultPeriod.endDate),
                  },
                }}
              />

              <PDFDisplay
                document={<ReportPDF alerts={alerts} period={period} />}
                documentName={`report-${Date.now()}.pdf`}
              />
            </>
          )}
        </Stack>
      </Card>
    </Box>
  );
};

export default Report;
