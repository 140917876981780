import { t, Trans } from '@lingui/macro';
import { Box, Card, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import ChartBarAndAreaGraph from '../../../components/EquipmentSecurity/components/ChartBarAndAreaGraph/ChartBarAndAreatGraph';
import SecurityAlertsList from '../../../components/EquipmentSecurity/components/SecurityAlertsList/SecurityAlertsList';
import SecurityTable from '../../../components/EquipmentSecurity/components/SecurityTable/SecurityTable';
import { CellsInterface } from '../../../components/EquipmentSecurity/components/SecurityTable/SecurityTable.interface';
import {
  GraphUsageDataset,
  MachineUsageProps,
  Period,
} from '../../../components/EquipmentSecurity/EquipmentSecurity.interface';
import {
  getAlertsGraphFilters,
  getAlertsGraphNumbers,
} from '../../../components/EquipmentSecurity/EquipmentSecurity.utils';
import { SelectedAllOptions } from '../../../components/JobSiteSelector/JobSiteSelector.interface';
import { SecurityAlertProps } from '../../../components/SecurityAlert/SecurityAlert';
import Loading from '../../../lib/apptheme/partials/Loading';
import { RequestStatus } from '../../api/api.interface';
import {
  getAllJobSitesAlertsSecurityByClient,
  getAllJobSitesSecurityByAgence,
  getAllJobSitesSecurityByClient,
  getJobSiteAlertsSecurity,
  getJobSiteSecurity,
} from '../../api/jobsites/JobSites';
import { StyledTableCell } from '../../components/molecules/TableWithPagination/UsedComponentsAndImports';
import GraphLegend from '../../components/organisms/MuiCustomCalendar/GraphLegend/GraphLegend';
import MuiCustomCalendar from '../../components/organisms/MuiCustomCalendar/MuiCustomCalendar';
import { calendarTypeOptions } from '../../components/organisms/MuiCustomCalendar/utils';
import { useUserContext } from '../../contexts/UserContext/UserContext';
import { formatDuration } from '../../utils/date/calcul';
import { getFormatedDateRequest } from '../../utils/date/format';
import { useAppModeContext } from '../../utils/hooks';
import { checkSecurityGraphDisplayInMinutes } from '../../utils/security/security.utils';
import { mets } from '../../utils/utils';
import {
  asyncDataCallAndLogic,
  getChartArray,
  getGraphLabels,
} from '../equipments/EquipmentDetail/EquipmentDetail.utils';
import JobSiteSecurityHeader from './components/JobSiteSecurityHeader/JobSiteSecurityHeader';
import SecurityGraphTotalTitle from './components/SecurityGraphTotalTitle/SecurityGraphTotalTitle';
import { setAlertsSecurityData, setSecurityData } from './Security.utils';

import colors from '../../theme/_colors.scss';
import './Security.scss';

const Security = () => {
  const user: any = useUserContext();
  const { isInternalAppMode } = useAppModeContext();

  const [loadStatus, setLoadStatus] = useState<RequestStatus>(
    RequestStatus.LOADING
  );

  const [jobSiteSelected, setJobSiteSelected] = useState(SelectedAllOptions);
  const [machineSelected, setMachineSelected] = useState(SelectedAllOptions);
  const [periodType, setPeriodType] = useState(0);

  const [alerts, setAlerts] = useState<SecurityAlertProps[]>();
  const [calendarData, setCalendarData] = useState<any[]>();
  const [period, setPeriod] = useState<Period | null>(null);

  const [dataTable, setDataTable] = useState<CellInterface[]>([]);
  const allDataTable = useRef<CellInterface[]>([]);

  const [machineUsage, setMachineUsage] = useState([]);
  const [mchUsgGraphData, setMchUsgGraphData] =
    useState<MachineUsageProps | null>(null);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [graphDisplayInMinutes, setGraphDisplayInMinutes] = useState(true);

  const hasGraphData =
    mchUsgGraphData?.alertsNumbers.find((i: number) => i !== 0) ||
    mchUsgGraphData?.alertsDuration.find((i: number) => i !== 0);

  const hasMachineSelected = machineSelected !== SelectedAllOptions;

  const selectedCalendarFilter = calendarTypeOptions(() => {})[periodType]
    ?.value;

  const maxYValue = graphDisplayInMinutes
    ? 60
    : selectedCalendarFilter === 'month'
    ? 40
    : 8;

  useEffect(() => {
    setMchUsgGraphData(null);
  }, [period]);

  useEffect(() => {
    // machine utilisation usage request
    // eslint-disable-next-line no-unused-expressions
    period &&
      hasMachineSelected &&
      asyncDataCallAndLogic({
        id: machineSelected,
        customerNumber: user.id,
        startDate: period.start,
        endDate: period.end,
        setMachineUsage,
      });
  }, [jobSiteSelected, period, user, machineSelected, hasMachineSelected]);

  useEffect(() => {
    setLoadStatus(RequestStatus.LOADING);

    fetchSecurityData(period?.start, period?.end)
      .then((resp) => {
        setSecurityData(
          resp,
          setAlerts,
          setCalendarData,
          !period ? setPeriod : undefined,
          filterAlertsTable
        );
        setLoadStatus(RequestStatus.SUCCESS);
      })
      .catch((error) => {
        setLoadStatus(RequestStatus.ERROR);
      });

    fetchTableAlerts(period?.start, period?.end);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobSiteSelected, period, machineSelected]);

  useEffect(() => {
    if (selectedDate) {
      const begin = moment(selectedDate).startOf(selectedCalendarFilter);
      const end = moment(selectedDate).endOf(selectedCalendarFilter);

      updateGraphData(begin, end);

      fetchSecurityData(
        getFormatedDateRequest(begin),
        getFormatedDateRequest(end)
      ).then((resp) => {
        setAlertsSecurityData(resp, setAlerts, filterAlertsTable);
      });

      fetchTableAlerts(
        getFormatedDateRequest(begin),
        getFormatedDateRequest(end)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineUsage, selectedCalendarFilter, selectedDate]);

  const fetchSecurityData = (
    startDate: string | undefined,
    endDate: string | undefined
  ) => {
    if (jobSiteSelected === SelectedAllOptions) {
      const agencyId = user?.agencyId;

      if (isInternalAppMode) {
        if (agencyId) {
          return getAllJobSitesSecurityByAgence(agencyId, startDate, endDate);
        }

        return Promise.reject(
          new Error("The user doesn't have an associated agency.")
        );
      }

      return getAllJobSitesSecurityByClient(startDate, endDate);
    }

    return getJobSiteSecurity({
      jobSiteId: jobSiteSelected,
      machineId:
        machineSelected === SelectedAllOptions ? null : machineSelected,
      startDate,
      endDate,
    });
  };

  const fetchTableAlerts = (
    startDate: string | undefined,
    endDate: string | undefined
  ) => {
    if (jobSiteSelected === SelectedAllOptions) {
      getAllJobSitesAlertsSecurityByClient({
        startDate,
        endDate,
        page: 0,
        size: 99999,
      }).then((resp) => {
        setDataTable(resp);
        allDataTable.current = resp;
      });
    } else {
      getJobSiteAlertsSecurity({
        jobSiteId: jobSiteSelected,
        machineId:
          machineSelected === SelectedAllOptions ? null : machineSelected,
        startDate,
        endDate,
        page: 0,
        size: 99999,
      }).then((resp) => {
        setDataTable(resp);
        allDataTable.current = resp;
      });
    }
  };

  const updateGraphData = (begin: moment.Moment, end: moment.Moment) => {
    const usage: GraphUsageDataset[] = [];

    const alertsFilters = getAlertsGraphFilters(calendarData, begin, end);
    const labelToUse = getGraphLabels(selectedCalendarFilter, end);
    const arrAlertsNumbers = getAlertsGraphNumbers(
      alertsFilters,
      labelToUse,
      selectedCalendarFilter
    );

    const isAlertsUnderOneHours = checkSecurityGraphDisplayInMinutes(
      alertsFilters,
      selectedCalendarFilter
    );

    setGraphDisplayInMinutes(isAlertsUnderOneHours);

    const arrAlertsDuration = getChartArray(
      labelToUse,
      alertsFilters,
      selectedCalendarFilter,
      true,
      isAlertsUnderOneHours,
      true
    );

    setMchUsgGraphData({
      labels: labelToUse.map((m: any) => m.value),
      datasets: usage,
      alertsNumbers: arrAlertsNumbers,
      alertsDuration: arrAlertsDuration.map((x: any) =>
        x.length > 0 ? x[1] : 0
      ),
    });
  };

  const filterAlertsTable = (alertType: string) => {
    setDataTable(
      alertType === 'Total Alert'
        ? allDataTable.current
        : allDataTable.current.filter((alert) => alert.type === alertType)
    );

    setAlerts((prevAlerts) =>
      prevAlerts?.map((alert) => ({
        ...alert,
        isSelected: alert.title === alertType,
      }))
    );
  };

  const renderComponents = () => {
    return (
      <>
        {period && (
          <>
            <Typography variant="h6">{t`Calendrier`}</Typography>

            <MuiCustomCalendar
              startDate={new Date(period.start)}
              endDate={new Date(period.end)}
              data={calendarData ?? []}
              isSecurity
              setSelectedDate={setSelectedDate}
              periodType={periodType}
              handlePeriodType={setPeriodType}
            />

            <GraphLegend
              legends={[
                {
                  color: colors.americanOrange,
                  description: t`Présence d’alerte`,
                },
                {
                  color: colors.brightGray,
                  description: t`Durée de location`,
                },
              ]}
            />

            {hasGraphData && (
              <Stack sx={{ width: '80%', alignSelf: 'center' }}>
                <SecurityGraphTotalTitle
                  alerts={mchUsgGraphData?.alertsDuration}
                  graphDisplayInMinutes={graphDisplayInMinutes}
                />
                <ChartBarAndAreaGraph
                  labels={mchUsgGraphData?.labels}
                  maxYValue={maxYValue}
                  numbersOfAlerts={mchUsgGraphData?.alertsNumbers}
                  alertsDuration={mchUsgGraphData?.alertsDuration}
                />
                {hasMachineSelected && (
                  <GraphLegend
                    legends={[
                      {
                        color: colors.americanOrange,
                        description: t`Alerte`,
                      },
                    ]}
                  />
                )}
              </Stack>
            )}
          </>
        )}

        <SecurityAlertsList alerts={alerts ?? []} />

        {dataTable && (
          <SecurityTable
            data={dataTable}
            headers={[t`Type`, t`Machine`, t`Contrat`, t`Date`, t`Durée`]}
            GenerateCells={generateCells}
          />
        )}
      </>
    );
  };

  return (
    <Box className="security">
      <Typography className="security__title" variant="h1">
        {t`Sécurité`}
      </Typography>

      <Card className="security__card">
        <Stack padding={2} spacing={3}>
          {period && (
            <JobSiteSecurityHeader
              period={period}
              handlePeriod={setPeriod}
              handlePeriodType={setPeriodType}
              handleJobSiteChange={setJobSiteSelected}
              handleMachineChange={setMachineSelected}
            />
          )}

          {loadStatus === RequestStatus.LOADING && <Loading />}

          {loadStatus === RequestStatus.ERROR && (
            <Typography variant="h6" textAlign="center">
              {t`Aucune information disponible`}
            </Typography>
          )}

          {loadStatus === RequestStatus.SUCCESS && renderComponents()}
        </Stack>
      </Card>
    </Box>
  );
};

export default Security;

interface CellInterface extends CellsInterface {
  machine: string;
  contract: string;
  dateDescription: string;
}

const generateCells = (row: CellInterface) => {
  // eslint-disable-next-line prettier/prettier
  const str = row.type as string;
  return (
    <>
      <StyledTableCell>
        <Trans id={`${mets[str as keyof typeof mets] ?? row.type}`} />
      </StyledTableCell>
      <StyledTableCell>{row.machine}</StyledTableCell>
      <StyledTableCell>{row.contract}</StyledTableCell>
      <StyledTableCell>{t`${row.dateDescription}`}</StyledTableCell>
      <StyledTableCell>{formatDuration(row.duration)}</StyledTableCell>
    </>
  );
};
