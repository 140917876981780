const ErrorMessage = ({ message }) => (
  <p
    data-testid="machine-history-error-message"
    className="error-message text-primary"
  >
    {message}
  </p>
);

export default ErrorMessage;
