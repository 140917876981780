import { createContext, useContext, useEffect, useState } from 'react';
import { getJobSiteMachines } from '../api/jobsites/JobSites';
import { Machine } from '../api/machines/Machine.interface';
import {
  getAgencyMachines,
  getAllMachines,
  getMachineById,
} from '../api/machines/Machines';
import { useAppModeContext } from '../utils/hooks';
import ContextLoadStatus from './utils';

const MachinesContext = createContext(null);

const MachinesProvider = ({ children, machines: machinesInit }: any) => {
  const { isInternalAppMode } = useAppModeContext();

  const [loadStatus, setLoadStatus] = useState<ContextLoadStatus>(
    machinesInit ? ContextLoadStatus.LOADED : ContextLoadStatus.NOTLOADED
  );

  const [machines, setMachines] = useState<Machine[]>(machinesInit || []);

  const [isLoadingMachine, setIsLoadingMachine] = useState<boolean>(false);

  useEffect(() => {
    setMachines(machinesInit);
    setLoadStatus(
      machinesInit ? ContextLoadStatus.LOADED : ContextLoadStatus.NOTLOADED
    );
  }, [machinesInit]);

  const loadMachines = async () => {
    if (!isInternalAppMode) {
      setLoadStatus(ContextLoadStatus.LOADING);

      getAllMachines().then(
        (result) => {
          setMachines(result.machines);
          setLoadStatus(ContextLoadStatus.LOADED);
        },
        (error) => {
          setLoadStatus(ContextLoadStatus.ERROR);
        }
      );
    }
  };

  const loadMachine = async (id: string) => {
    if (!isInternalAppMode) {
      setIsLoadingMachine(true);

      getMachineById(id).then((result) => {
        setMachines((prevMachines) => [
          ...prevMachines.filter((m) => m.id !== result.id),
          result,
        ]);

        setIsLoadingMachine(false);
      });
    }
  };

  const getMachinesLoadStatus = (): ContextLoadStatus => {
    return loadStatus;
  };

  const getMachines = (): any[] => {
    if (loadStatus === ContextLoadStatus.NOTLOADED) {
      loadMachines();

      return [];
    }

    return machines;
  };

  const getMachine = (id: string): Machine | undefined => {
    const machinesFiltered: Machine | undefined = machines.find(
      (m) => m.id === id
    );

    if (!machinesFiltered && !isLoadingMachine) {
      loadMachine(id);

      return undefined;
    }
    return machinesFiltered;
  };

  const getMachinesByJobSite = (
    jobSiteId: string
  ): Machine[] | Promise<Machine[]> => {
    if (loadStatus === ContextLoadStatus.NOTLOADED) {
      return getJobSiteMachines(jobSiteId).then((resp) => {
        return resp.machines;
      });
    }

    return machines.filter((m) => m.jobSiteId === jobSiteId);
  };

  const getMachinesByAgency = (
    agencyId: string
  ): Machine[] | Promise<Machine[]> => {
    if (loadStatus === ContextLoadStatus.NOTLOADED) {
      return getAgencyMachines(agencyId).then((resp) => {
        return resp.machines;
      });
    }

    return machines.filter((m) => m.agencyId === agencyId);
  };

  const machinesData: any = {
    getMachinesLoadStatus,
    loadMachines,
    loadMachine,
    getMachines,
    getMachine,
    getMachinesByJobSite,
    getMachinesByAgency,
  };

  return (
    <MachinesContext.Provider value={machinesData}>
      {children}
    </MachinesContext.Provider>
  );
};

const useMachinesContext = () => {
  return useContext(MachinesContext);
};

export { MachinesProvider, useMachinesContext };
