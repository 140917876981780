import { t } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Loading } from '../../../../../../lib/apptheme';
import { getMeteo } from '../../../../../api/weather/weather';
import { SelectOptions } from '../../../../../components/molecules';
import {
  calendarCategories,
  calendarTypeOptions,
} from '../../../../../components/organisms/MuiCustomCalendar/utils';
import useAsyncFunction from '../../../../../hooks/AsyncFetch/useAsyncFetch';
import IsMobile from '../../../../../utils/IsMobile';
import { checkGraphDisplayInMinutes } from '../../../../../utils/equipment/machine/machineUtilisation.utils';
import { useAppModeContext } from '../../../../../utils/hooks';
import {
  asyncDataCallAndLogic,
  getChartArray,
  getGraphLabels,
  handleMachineUsageAlerts,
} from '../../EquipmentDetail.utils';
import CalendarAndMachUsageGraph from '../CalendarAndMachUsageGraph/CalendarAndMachUsageGraph';
import GlobalMachineUsage from '../GlobalMachineUsage/GlobalMachineUsage';
import TableMachineUsageTime from '../TableMachineUsageTime/TableMachineUsageTime';

import colors from '../../../../../theme/_colors.scss';
import './EquipmentUtilisation.scss';

const EquipmentUtilisation = ({
  id,
  machine,
  contract,
  machineClientsOptions,
  setMachine,
}) => {
  // state for page header dropdowns
  const [selectedClientIndex, setSelectedClientIndex] = useState(0);
  const [periodType, setPeriodType] = useState(0);

  const [machineUsage, setMachineUsage] = useState();
  const [globalMachineUsage, setGlobalMachineUsage] = useState();
  const [machineUsageTotalDuration, setMachineUsageTotalDuration] = useState(0);
  const [chartBarData, setChartBarData] = useState([]);
  const [usageAlerts, setUsageAlerts] = useState([]);
  const [isMachineUsagesOutOfContract, setIsMachineUsagesOutOfContract] =
    useState(false);
  const [selectedDate, setSelectedDate] = useState([]);

  const [usageAlertsSize, setUsageAlertsSize] = useState(100);
  const [usageAlertsPage, setUsageAlertsPage] = useState(0);
  const [displayOutOfContract, setDisplayOutOfContract] = useState(true);

  const [period, setPeriod] = useState(null);

  const [graphDisplayInMinutes, setGraphDisplayInMinutes] = useState(true);

  const { isInternalAppMode } = useAppModeContext();
  const isMobile = IsMobile();

  const selectedCalendarFilter = calendarTypeOptions(() => {})[periodType]
    .value;

  const customerNumber =
    machineClientsOptions[selectedClientIndex]?.customerNumber;

  const hasMachineUsage = machineUsage?.length > 0;

  useEffect(() => {
    asyncDataCallAndLogic({
      id,
      customerNumber,
      setMachineUsage,
      setPeriod,
      setMachineUsageTotalDuration,
      setGlobalMachineUsage,
      machine,
      setMachine,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedClientIndex]);

  useEffect(() => {
    if (hasMachineUsage) {
      calendarCategories.forEach((c) => {
        // eslint-disable-next-line no-param-reassign
        c.value = machineUsage.filter((el) =>
          c.func(el.usageOnContract / 60, el.usageOutOfContract / 60)
        ).length;
      });
    }
  }, [hasMachineUsage, machineUsage]);

  useEffect(() => {
    const begin = moment(selectedDate).startOf(selectedCalendarFilter);
    const end = moment(selectedDate).endOf(selectedCalendarFilter);

    const machineUsageFilter = machineUsage?.filter(
      (f) =>
        moment(f.day) >= begin &&
        moment(f.day) <= end &&
        f.sessionsByHourList &&
        f.sessionsByHourList.length > 0
    );

    if (machineUsageFilter && machineUsageFilter.length > 0) {
      let arrUsageOutOfTime = [];
      let arrUsageInTime = [];

      const isUsageOutOfContract = machineUsageFilter.some(
        (e) => e.usageOutOfContract > 0
      );
      setIsMachineUsagesOutOfContract(isUsageOutOfContract);

      const labelToUse = getGraphLabels(selectedCalendarFilter, end);

      const isMachineUsageUnderOneHours = checkGraphDisplayInMinutes(
        machineUsageFilter,
        displayOutOfContract,
        selectedCalendarFilter
      );

      setGraphDisplayInMinutes(isMachineUsageUnderOneHours);

      arrUsageInTime = getChartArray(
        labelToUse,
        machineUsageFilter,
        selectedCalendarFilter,
        false,
        isMachineUsageUnderOneHours
      );

      arrUsageOutOfTime = getChartArray(
        labelToUse,
        machineUsageFilter,
        selectedCalendarFilter,
        true,
        isMachineUsageUnderOneHours
      );

      const totalDuration = machineUsageFilter.reduce((acc, obj) => {
        const duration = displayOutOfContract
          ? obj.usageOnContract + obj.usageOutOfContract
          : obj.usageOnContract;
        return acc + duration;
      }, 0);
      setMachineUsageTotalDuration(totalDuration);

      const usageOutOfContract = {
        label: t`Utilisation hors contrat`,
        data: arrUsageOutOfTime,
        backgroundColor: colors.imperialRed,
      };

      const usageInContract = {
        label: t`Utilisation horaires contrats`,
        data: arrUsageInTime,
        backgroundColor: colors.blueJeans,
      };

      const usage = isUsageOutOfContract
        ? [usageInContract, usageOutOfContract]
        : [usageInContract];

      const data = {
        labels: labelToUse.map((m) => m.value),
        datasets: displayOutOfContract ? usage : [usageInContract],
      };
      setChartBarData(data);
    } else {
      setChartBarData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    machineUsage,
    selectedDate,
    selectedCalendarFilter,
    displayOutOfContract,
  ]);

  useEffect(() => {
    handleMachineUsageAlerts(
      id,
      customerNumber,
      usageAlertsPage,
      usageAlertsSize,
      setUsageAlerts
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedClientIndex, usageAlertsPage, usageAlertsSize]);

  const { data: meteo } = useAsyncFunction({
    asyncFunction: getMeteo,
    params: { lat: machine.lat, lon: machine.lon },
  });

  if (!machineUsage) {
    return <Loading />;
  }

  if (!(contract && hasMachineUsage)) {
    return (
      <>
        <Typography sx={{ color: 'primary.main' }} variant="h3">
          {t`Utilisation`}
        </Typography>

        <Stack className="equipment-detail__no-data">
          <Typography variant="h5">
            {t`La machine n'a pas d'informations d'utilisation`}
          </Typography>
        </Stack>
      </>
    );
  }

  return (
    <>
      <Stack direction="row" className="equipment-utilisation__title">
        <Typography variant="h3" sx={{ color: 'primary.main' }}>
          {t`Utilisation`}
        </Typography>

        <Stack direction="row" spacing={2}>
          {/* Clients Selector - Agence Connect */}
          {isInternalAppMode && machineClientsOptions?.length > 1 && (
            <SelectOptions
              defaultIndex={selectedClientIndex}
              updateParentIndex={setSelectedClientIndex}
              options={machineClientsOptions}
            />
          )}

          <SelectOptions
            defaultIndex={periodType}
            options={calendarTypeOptions(setPeriodType)}
          />
        </Stack>
      </Stack>

      {/* Client Description - Agence Connect */}
      {hasMachineUsage && (
        <Stack>
          <Typography variant="h4">
            {machineClientsOptions[selectedClientIndex]?.label}
          </Typography>
          <Stack
            direction={!isMobile ? 'row' : 'column'}
            sx={{ gap: !isMobile ? '5px' : 'normal' }}
          >
            <Typography variant="body1">
              <b>{t`Date de location`} : </b>{' '}
              {`${t`Du`} ${moment(period?.start).format('L')} ${t`au`} ${moment(
                period?.end
              ).format('L')}`}
            </Typography>
            {!isMobile && <Typography variant="body1">|</Typography>}
            <Typography variant="body1">
              <b>{t`Chantier`}: </b> {contract?.constructionSiteName}
            </Typography>
          </Stack>
        </Stack>
      )}

      {/* Horizontal Bar Graphic */}
      {globalMachineUsage && period && (
        <GlobalMachineUsage
          contractDaysNumber={moment(period?.end).diff(
            moment(period?.start),
            'days'
          )}
          globalMachineUsage={globalMachineUsage}
        />
      )}

      {/* Calendar and Meteo */}
      {hasMachineUsage && period && (
        <CalendarAndMachUsageGraph
          period={period}
          machineUsage={machineUsage}
          machineUsageTotalDuration={machineUsageTotalDuration}
          setSelectedDate={setSelectedDate}
          meteo={meteo}
          chartBarData={chartBarData}
          displayOutOfContract={displayOutOfContract}
          setDisplayOutOfContract={setDisplayOutOfContract}
          isUsageOutOfContract={isMachineUsagesOutOfContract}
          periodType={periodType}
          handlePeriodType={setPeriodType}
          graphDisplayInMinutes={graphDisplayInMinutes}
          selectedCalendarFilter={selectedCalendarFilter}
        />
      )}

      {/* Alerts List */}
      {usageAlerts && usageAlerts.length > 0 && (
        <TableMachineUsageTime
          data={usageAlerts}
          usageAlertsPage={usageAlertsPage}
          setUsageAlertsPage={setUsageAlertsPage}
          setUsageAlertsSize={setUsageAlertsSize}
        />
      )}
    </>
  );
};

export default EquipmentUtilisation;
