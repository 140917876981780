import { Button, Stack } from '@mui/material';
import { CURRENT, ANTITHEFT } from './ButtonPositionsView.constants';

import './ButtonPositionsView.scss';

const ButtonPositionsView = ({ isCurrentView, onChange }) => {
  const handleChangeView = (view) => {
    onChange(view);
  };

  return (
    <Stack className="button-positions-view">
      <Button
        className="button-positions-view__btn"
        variant={isCurrentView ? 'contained' : 'outlined'}
        onClick={() => !isCurrentView && handleChangeView(CURRENT)}
      >
        Trackunit & OEM
      </Button>
      <Button
        className="button-positions-view__btn"
        variant={!isCurrentView ? 'contained' : 'outlined'}
        onClick={() => isCurrentView && handleChangeView(ANTITHEFT)}
      >
        Goobies
      </Button>
    </Stack>
  );
};

export default ButtonPositionsView;
