/* eslint-disable no-undef */

// polygonCoords format [[lat, lon], ...]
export const drawPolygon = (map, polygonCoords, style) => {
  const polygonPath = polygonCoords.map(
    (coords) => new f4.map.LatLng(coords[1], coords[0])
  );

  const polygon = new f4.map.Polygon({
    paths: polygonPath,
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    ...style,
  });

  polygon.setMap(map);

  return polygon;
};

const renderEquipmentZone = (
  equipment,
  map,
  equipmentCoords,
  polygons,
  circles,
  radius = 250
) => {
  switch (equipment.state) {
    case 'Chantiers': {
      if (!equipment.zones || equipment.zones.length === 0) {
        break;
      }

      const zonePointsLatLng = equipment.zones.map((z) => {
        return new f4.map.LatLng(z.lat, z.lon);
      });

      const zonePointsArr = new f4.map.Polygon({
        paths: zonePointsLatLng,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
      });

      polygons.push(zonePointsArr);

      zonePointsArr.setMap(map);

      break;
    }
    case 'Agences': {
      // eslint-disable-next-line no-unused-vars
      const cityCircle = new f4.map.Circle({
        strokeColor: '#5E19B0',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#5E19B0',
        fillOpacity: 0.35,
        map,
        center: equipmentCoords,
        radius,
      });

      circles.push(cityCircle);

      break;
    }
    case 'Machines': {
      // eslint-disable-next-line no-unused-vars
      const cityCircle = new f4.map.Circle({
        strokeColor: '#5E19B0',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#5E19B0',
        fillOpacity: 0.35,
        map,
        center: equipmentCoords,
        radius,
      });

      circles.push(cityCircle);

      break;
    }

    default: {
      // eslint-disable-next-line no-unused-vars
      const cityCircle = new f4.map.Circle({
        strokeColor: '#5E19B0',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#5E19B0',
        fillOpacity: 0.35,
        map,
        center: equipmentCoords,
        radius,
      });

      circles.push(cityCircle);
      break;
    }
  }
};

export default renderEquipmentZone;
