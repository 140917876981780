import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import IsMobile from '../../../../utils/IsMobile';

import './GraphLegend.scss';

const GraphLegend = ({ legends }) => {
  const isMobile = IsMobile();

  return (
    <div
      className={classNames('mui-custom-calendar-legend', {
        'mui-custom-calendar-legend-view--mobile': isMobile,
      })}
    >
      {legends.map((c, index) => {
        return (
          <div
            className={classNames('mui-custom-calendar-legend__category', {
              'mui-custom-calendar-legend__category--mobile-view': isMobile,
            })}
            key={index}
          >
            <div
              className={classNames(
                'mui-custom-calendar-legend__category-grid',
                {
                  'mui-custom-calendar-legend__category-grid--mobile-view':
                    isMobile,
                }
              )}
            >
              <div
                className={classNames('mui-custom-calendar-legend__color', {
                  'mui-custom-calendar-legend__color--mobile-view': isMobile,
                })}
                style={{
                  backgroundColor: c.color,
                }}
              />
              <span
                className={classNames(
                  'mui-custom-calendar-legend__description',
                  {
                    'mui-custom-calendar-legend__description--mobile-view':
                      isMobile,
                  }
                )}
              >
                <Trans id={c.description} />
              </span>
            </div>
            {c.value != null && c.timeInfo != null && (
              <span
                className={classNames('mui-custom-calendar-legend__time-info', {
                  'mui-custom-calendar-legend__time-info--mobile-view':
                    isMobile,
                })}
              >
                {`${c.value} ${c.timeInfo}`}
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default GraphLegend;
