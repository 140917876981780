import { t } from '@lingui/macro';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const PairTrackerButton = () => {
  const history = useHistory();

  const [nfcEnable, setnfcEnable] = useState(false);

  useEffect(() => {
    if ('NDEFReader' in window) {
      setnfcEnable(true);
    }
  }, []);

  if (nfcEnable) {
    return (
      <Button
        variant="contained"
        onClick={() => {
          history.push('/scan');
        }}
      >
        {t`Associer un tracker`}
      </Button>
    );
  }
  return <></>;
};
export default PairTrackerButton;
