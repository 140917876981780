import { getUserByEmail } from '../../api/users/Users';
import { addAuthorizationToken } from '../../api/utils/axios';
import { login, getUserLogged } from '../../api/auth/customAuth';

// only external user can have cookies
export const getUserAlreadyAuthen = ({ isInternalAppMode }) => {
  return !isInternalAppMode
    ? getUserLogged().then((usr) => {
        addAuthorizationToken(usr.token);
        return usr;
      })
    : Promise.resolve({});
};

export const getUserInfoByOkta = ({ oktaAuth }) => {
  addAuthorizationToken(oktaAuth.getAccessToken());
  return oktaAuth.getUser().then(({ email }) => {
    return getUserByEmail(email);
  });
};

export const getUserInfoByEmail = ({ email, password }) => {
  return login(email, password).then((usr) => {
    addAuthorizationToken(usr.token);
    return usr;
  });
};
