import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import './CardTable.scss';

const CardTable = ({ header, rows, maxHeight, onClick }) => (
  <TableContainer className="card-table" sx={{ maxHeight }}>
    <Table className="card-table__table" aria-label="simple table" stickyHeader>
      <TableHead>
        <TableRow>
          {header.map((cell) => (
            <TableCell size="small" sx={{ bgcolor: 'tableheader.bgcolor' }}>
              <Typography variant="body1Bold" color="tableheader.color">
                {cell}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow
            className="card-table__body-row"
            component={Paper}
            onClick={() => onClick(row.id)}
          >
            {row.cells.map((cell) => (
              <TableCell align="left">{cell}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

CardTable.propTypes = {
  /**
   * Header Data
   */
  header: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * Table Data
   */
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      cells: PropTypes.arrayOf(PropTypes.element).isRequired,
    })
  ).isRequired,
  /**
   * Row click event
   */
  onClick: PropTypes.func,
  /**
   * Max Height
   */
  maxHeight: PropTypes.string,
};

CardTable.defaultProps = {
  onClick: null,
  maxHeight: null,
};

export default CardTable;
