import { FETCH_ALL_PAGE_SIZE } from '../../utils/utils';
import { getRequest, postRequest, putRequest } from '../utils/axios.utils';

export const getJobSiteAgencies = (id) => {
  return getRequest(`agencies/jobsite/${id}`);
};

export const getAllAgencies = () => {
  return postRequest(`agencies`, { size: FETCH_ALL_PAGE_SIZE });
};

export const getAgencyById = (id) => {
  return getRequest(`agencies/${id}`);
};

export const updateAgencyPosition = (id, lat, lon) => {
  return putRequest(`agencies/${id}/position`, { lat, lon });
};

export const updateAgencyGeoZones = (id, zonesId) => {
  return putRequest(`agencies/${id}/geozone`, { zonesId });
};

export const getAgenciesByContractId = (id) => {
  return getRequest(`agencies/contract/${id}`);
};

export const getAgencyTracker = () => {
  return getRequest(`machines/agency/tracker`);
};

export const getTrackerByRegion = (region) => {
  return getRequest(`machines/agency/tracker/${region}`);
};

export const getTrackersByAgency = (agency) => {
  return getRequest(`machines/agency/${agency}/tracker`);
};

export const getGoobieReports = (id) => {
  return getRequest(`trackers/report/${id}`);
};

export const getAgenciesByRegionList = (region) => {
  return getRequest(`agencies/regions/${region}`);
};

export const getAgenciesList = () => {
  return getRequest(`agencies/rentalmanId`);
};

export const getRegionsList = () => {
  return getRequest(`agencies/regions`);
};

export const updateImeiData = (data) => {
  return postRequest(`trackers/pair/validation`, data);
};
