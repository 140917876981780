import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import SiteContext from './SiteContext';
import PeriodContext from './PeriodContext';
import BreadCrumbsContext from './BreadcrumbsContext';
import AppModeContext from './context/AppModeContext';

export const useModal = () => {
  const [modal, setModal] = useState(true);
  const history = useHistory();

  const toggle = () => {
    setModal(false);

    setTimeout(() => {
      history.goBack();
    }, 300);
  };

  return { modal, setModal, toggle };
};

export const useSiteContext = () => {
  const { site, setSite } = useContext(SiteContext);

  return [site, setSite];
};

export const usePeriodContext = () => {
  const { period, setPeriod } = useContext(PeriodContext);
  return [period, setPeriod];
};

export const useBreadcrumbsContext = () => {
  const { breadcrumbsList, setBreadcrumbsList } =
    useContext(BreadCrumbsContext);
  return [breadcrumbsList, setBreadcrumbsList];
};

export const useAppModeContext = () => {
  const { isInternalAppMode } = useContext(AppModeContext);
  return { isInternalAppMode, isExternalAppMode: !isInternalAppMode };
};
