import { t } from '@lingui/macro';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { getReport } from '../../api/trackers/Trackers';
import { ReturnButton, SpecificationList } from '../../components/molecules';

/**
 * Page to display report's information
 * url = /reports/{reportUUID}
 * @returns {JSX.Element}
 * @constructor
 */
export const ReportDetail = () => {
  const { reportId } = useParams();

  const [report, setreport] = useState(undefined);

  useEffect(() => {
    const fetchReport = async () => {
      const response = await getReport(reportId);
      setreport(response);
    };

    fetchReport().catch((error) => console.log(error));
  }, [reportId]);

  return (
    <>
      <ReturnButton label={t`Retour`} sx={{ m: 2 }} />
      <Typography
        variant="h1"
        sx={{ mb: 1 }}
      >{t`Tracker rapport details`}</Typography>
      {report !== undefined && (
        <Grid
          className="report-detail-container"
          container
          spacing={1}
          alignItems="flex-start"
        >
          <Grid item xs={12} lg={6}>
            <ReportInfo report={report} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <GPSInfo report={report} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TrackerInfo report={report} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

/**
 * List of information for a tracker
 * @param report
 * @returns {JSX.Element}
 * @constructor
 */
const TrackerInfo = ({ report }) => {
  return (
    <>
      <Paper sx={{ p: 2 }}>
        <SpecificationList
          title={t`Tracker Informations`}
          specifications={[
            {
              data: [
                {
                  name: t`imei`,
                  component: (
                    <Box sx={{ display: 'flex' }}>
                      <Typography
                        className="specification-list__name"
                        variant="body1Bold"
                      >
                        {t`imei`}
                      </Typography>
                      <Typography>
                        <Link to={`/trackers/${report.trackerId}`}>
                          {report.imei}
                        </Link>
                      </Typography>
                    </Box>
                  ),
                },
                {
                  name: t`Mode`,
                  value: report.mode,
                },
                {
                  name: t`Tag`,
                  value: report.tag,
                },
              ],
            },
          ]}
        />
      </Paper>
    </>
  );
};

const ReportInfo = ({ report }) => {
  return (
    <>
      <Paper sx={{ p: 2 }}>
        <SpecificationList
          title={t`Mesures globales`}
          specifications={[
            {
              data: [
                {
                  name: t`Date`,
                  value: new Date(report.created_at)
                    .toISOString()
                    .substring(0, 10),
                },
                {
                  name: t`Operator`,
                  value: report.operator,
                },
                {
                  name: t`Température`,
                  value: report.temperature,
                },
                {
                  name: t`Voltage mv`,
                  value: report.voltage,
                },
                {
                  name: t`"GPS" time counters`,
                  value: report.counterGps,
                },
                {
                  name: t`Accelerometer time counter`,
                  value: report.counterAccelerometer,
                },
                {
                  name: t`"GSM" time counters`,
                  value: report.counterGsm,
                },
                {
                  name: t`"iot networks" time counters`,
                  value: report.counterIot,
                },
                {
                  name: t`"Firmware update" time counters`,
                  value: report.counterUpdateFirmware,
                },
                {
                  name: t`Errors`,
                  value: report.error,
                },
              ],
            },
          ]}
        />
      </Paper>
    </>
  );
};

const GPSInfo = ({ report }) => {
  return (
    <>
      <Paper sx={{ p: 2 }}>
        <SpecificationList
          title={t`Mesures GPS`}
          specifications={[
            {
              data: [
                {
                  name: t`Latitude`,
                  value: report.latitude,
                },
                {
                  name: t`Longitude`,
                  value: report.longitude,
                },
                {
                  name: t`Longitude`,
                  value: report.altitude,
                },
                {
                  name: t`La composante X du vecteur gravitationnel`,
                  value: report.gravitationX,
                },
                {
                  name: t`La composante Y du vecteur gravitationnel`,
                  value: report.gravitationY,
                },
                {
                  name: t`La composante Z du vecteur gravitationnel`,
                  value: report.gravitationZ,
                },
              ],
            },
          ]}
        />
      </Paper>
    </>
  );
};
