import classNames from 'classnames';
import { Stack, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import './PercentageContent.scss';

interface PercentageContentProps {
  percentage: number;
  isPercentageUp?: boolean;
}

const PercentageContent = ({
  percentage,
  isPercentageUp,
}: PercentageContentProps) => {
  const hasIsPercentageUp = isPercentageUp != null;

  return (
    <Stack
      direction="row"
      className={classNames('percentage-content', {
        'percentage-content--up': hasIsPercentageUp && isPercentageUp,
        'percentage-content--down': hasIsPercentageUp && !isPercentageUp,
      })}
      alignItems="inherit"
    >
      {hasIsPercentageUp &&
        (isPercentageUp ? (
          <ArrowUpwardIcon className="percentage-content__icon" />
        ) : (
          <ArrowDownwardIcon className="percentage-content__icon" />
        ))}
      <Typography>{percentage}%</Typography>
    </Stack>
  );
};

export default PercentageContent;
