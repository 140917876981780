import { i18n } from '@lingui/core';
import { da, de, en, fr, nl } from 'make-plural/plurals';

export const locales = {
  en: 'English',
  fr: 'Français',
  // nl: 'Dutch',
  // de: 'German',
  // da: 'Danish',
};

export const defaultLocale = localStorage.getItem(
  'loxam-user-selected-language'
)
  ? localStorage.getItem('loxam-user-selected-language')
  : navigator.language.split('-')[0];

i18n.loadLocaleData({
  en: { plurals: en },
  fr: { plurals: fr },
  // nl: { plurals: nl },
  // de: { plurals: de },
  // da: { plurals: da },
});

export async function dynamicActivate(locale) {
  try {
    const { messages } = await import(`../locales/${locale}/messages`);
    i18n.load(locale, messages);
    i18n.activate(locale);
  } catch (error) {
    const { messages } = await import(`../locales/en/messages`);
    i18n.load('en', messages);
    i18n.activate('en');
  }
  localStorage.setItem('loxam-user-selected-language', locale);
}
