import { useMemo } from 'react';
import { Trans, t } from '@lingui/macro';
import { Download } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { TableWithPagination } from '../../../../../../components/molecules';
import { StyledTableCell } from '../../../../../../components/molecules/TableWithPagination/UsedComponentsAndImports';
import { getDateWithGMTInfo } from '../../../../../../utils/date/format';

export const HEADER = [
  t`Position et adresse de départ`,
  t`Date de départ`,
  t`Position et adresse d’arrivée`,
  t`Date d’arrivée`,
];

// Headers for Geolocation CSV
export const GEOLOCATION_HEADERS = [
  { label: t`Date`, key: 'date' },
  { label: t`Geolocation`, key: 'geolocation' },
  { label: t`Address`, key: 'address' },
];

// Headers for Journeys CSV
export const JOURNEY_HEADERS = [
  { label: t`Begin Date`, key: 'beginDate' },
  { label: t`End Date`, key: 'endDate' },
  { label: t`Initial GPS position`, key: 'initialCoordinates' },
  { label: t`Final GPS position`, key: 'finalCoordinates' },
  { label: t`Addresse de départ`, key: 'initialAddress' },
  { label: t`Adresse d'arrivée`, key: 'finalAddress' },
];

// Line break on template strings wasn't working.....
const lineBreakCell = (firstLine = '', secondLine = '') => {
  return (
    <>
      <p>{firstLine}</p>
      <p>{secondLine}</p>
    </>
  );
};

const generateCells = (row, handleClick, selectedRow) => {
  const data = [
    lineBreakCell(row?.initialAddress, row?.initialCoordinates),
    row?.beginDate || '-',
    lineBreakCell(row?.finalAddress, row?.finalCoordinates),
    row?.endDate || '-',
  ];
  return (
    <>
      {data.map((x) => (
        <StyledTableCell
          sx={{ cursor: 'pointer', fontWeight: selectedRow === row && 'bold' }}
          onClick={() => handleClick(row)}
        >
          {x}
        </StyledTableCell>
      ))}
    </>
  );
};

const EquipmentHistoryTable = ({
  data,
  handleRowClick,
  selectedRow,
  geolocationCsv,
  journeysCsv,
  id,
  rentalmanId,
  start,
  end,
}) => {
  const journeysFormatedWithTimeZone = useMemo(() => {
    return journeysCsv.map((journey) => ({
      ...journey,
      beginDate: getDateWithGMTInfo(journey.beginDate),
      endDate: getDateWithGMTInfo(journey.endDate),
    }));
  }, [journeysCsv]);

  const geolocationsFormatedWithTimeZone = useMemo(() => {
    return geolocationCsv.map((geoloc) => ({
      ...geoloc,
      date: getDateWithGMTInfo(geoloc.date),
    }));
  }, [geolocationCsv]);

  const formatedData = useMemo(() => {
    return data.map((itemRow) => ({
      ...itemRow,
      beginDate: getDateWithGMTInfo(itemRow.beginDate),
      endDate: getDateWithGMTInfo(itemRow.endDate),
    }));
  }, [data]);

  const getFileNameCSV = (fileType) => {
    return `History_${fileType}_${rentalmanId || id}_${start}_${end}`.replace(
      /\([^)]*\)/g,
      ''
    );
  };

  const locationsCSVFileName = getFileNameCSV('locations');
  const journeysCSVFileName = getFileNameCSV('journeys');

  return (
    <Stack sx={{ width: '100%', marginTop: '20px' }}>
      <Stack direction="row" justifyContent="flex-end">
        <Stack direction="row">
          {geolocationCsv.length >= 1 && (
            <CSVLink
              headers={GEOLOCATION_HEADERS}
              separator=";"
              filename={locationsCSVFileName}
              data={geolocationsFormatedWithTimeZone}
            >
              <Typography variant="body2Bold" fontSize="14px">
                <Download />
                <Trans>Exporter positions</Trans>
              </Typography>
            </CSVLink>
          )}

          {journeysCsv.length >= 1 && (
            <>
              <CSVLink
                headers={JOURNEY_HEADERS}
                separator=";"
                filename={journeysCSVFileName}
                data={journeysFormatedWithTimeZone}
              >
                <Typography
                  variant="body2Bold"
                  fontSize="14px"
                  sx={{ marginLeft: 2 }}
                >
                  <Download />
                  <Trans>Exporter trajets</Trans>
                </Typography>
              </CSVLink>
            </>
          )}
        </Stack>
      </Stack>

      {journeysCsv.length >= 1 && (
        <>
          <Typography variant="h3">
            <Trans>Historique des trajets</Trans>
          </Typography>

          <TableWithPagination
            headers={HEADER}
            rows={formatedData}
            cells={(row) => generateCells(row, handleRowClick, selectedRow)}
            optionsValue={[5, 10, 15]}
            alternateBg
            enableFiltersOptions
            checkboxesStatesResp={undefined}
            dataDescription={undefined}
            parentFilters={undefined}
            hideColumns={undefined}
          />
        </>
      )}
    </Stack>
  );
};

export default EquipmentHistoryTable;
