import axios from 'axios';
import { string } from 'prop-types';

interface weatherstack {
  lat: string;
  lon: string;
}

export const getMeteo = ({ lat, lon }: weatherstack) => {
  if (!lat || !lon) {
    return Promise.reject(new Error('Lat and lon is necessary'));
  }
  return axios
    .get(
      `https://api.weatherstack.com/current?access_key=${process.env.REACT_APP_WEATHER_API_KEY}&query=${lat},${lon}`
    )
    .then(({ data }: any) => {
      if (!data.current && !data.success) {
        throw data.type || 'Error api weather';
      }
      return data.current;
    });
};
