import { MachineIcon } from '../../../../../../components/MachineIcon/MachineIcon';

const MachineImage = ({ pim }: { pim: any }) => {
  return (
    <MachineIcon
      pim={pim}
      pimKey="product"
      style={{ height: '80px', width: '80px', maxWidth: '80px' }}
    />
  );
};

export default MachineImage;
