import { t } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import { Machine } from '../../../../../api/machines/Machine.interface';
import IsMobile from '../../../../../utils/IsMobile';
import { cellsWrap } from '../../../../../utils/table/table';
import MachineDetailsButton from '../MachineRow/components/MachineDetailsButton';
import MachineImage from '../MachineRow/components/MachineImage';
import MachineLocation from '../MachineRow/components/MachineLocation';
import MachineName from '../MachineRow/components/MachineName';

const SimpleMachineRow = ({
  machine,
  handleHistoryPush,
}: {
  machine: Machine;
  handleHistoryPush: () => void;
}) => {
  const isMobile = IsMobile();

  const cells = isMobile
    ? [
        <Stack onClick={handleHistoryPush}>
          <Stack direction="row" spacing={2}>
            <MachineImage pim={machine.pim} />
            <MachineName machine={machine} />
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography fontWeight="500">{t`Location`}:</Typography>
            <MachineLocation machine={machine} />
          </Stack>
        </Stack>,
      ]
    : [
        <MachineImage pim={machine.pim} />,
        <MachineName machine={machine} />,
        <MachineLocation machine={machine} />,
        <MachineDetailsButton handleHistoryPush={handleHistoryPush} />,
      ];

  return cellsWrap(cells);
};

export default SimpleMachineRow;
