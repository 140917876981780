export const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Credentials': true,
  'Access-Control-Allow-Methods': '*',
  'X-Loxam-Header': 'tazvJWTw$C4i1$mc&qhSXVmR!',
  Timeout: 10000,
};

export const headers2 = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Credentials': true,
  'Access-Control-Allow-Methods': '*',
  'X-Loxam-Header': 'tazvJWTw$C4i1$mc&qhSXVmR!',
};
