import Typography from '@mui/material/Typography';
import { capitalize } from 'lodash';
import { i18n } from '@lingui/core';

const MachineStatusLib = ({
  equipmentStatusLib,
}: {
  equipmentStatusLib: string;
}) => {
  const translatedStatus = i18n._(capitalize(equipmentStatusLib));

  return <Typography>{translatedStatus || '-'}</Typography>;
};

export default MachineStatusLib;
