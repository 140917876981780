import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { Typography, Tooltip, IconButton } from '@mui/material';

import moment from 'moment';

import ErrorIcon from '@mui/icons-material/Error';

import { getFullDate } from '../../../../utils/date/format';
import { isDateBellow24h } from '../../../../utils/date/calcul';

const InfoBull = ({ isVisible }) => {
  return isVisible ? (
    <Tooltip title={t`Dernière mise à jour + 24h`} placement="right">
      <IconButton>
        <ErrorIcon sx={{ color: 'primary.main' }} />
      </IconButton>
    </Tooltip>
  ) : null;
};

const TelemacticLastContactRecieved = ({ lastSyncTime }) => {
  let value;
  let isBellow24h = false;

  const isLastSyncTimeValid = moment(lastSyncTime).isValid();
  const label = `${t`Dernier contact Trackunit/OEM reçu`} :`;

  if (isLastSyncTimeValid) {
    value = getFullDate(lastSyncTime);
    isBellow24h = isDateBellow24h(lastSyncTime);
  } else {
    value = `(${t`Pas d'information reçue`})`;
  }

  return (
    <>
      <Typography className="specification-list__name" variant="body1Bold">
        {label}
      </Typography>
      <Typography className="specification-list__value" variant="body1">
        {value}
        <InfoBull isVisible={!isBellow24h && isLastSyncTimeValid} />
      </Typography>
    </>
  );
};

export default TelemacticLastContactRecieved;

TelemacticLastContactRecieved.propTypes = {
  lastSyncTime: PropTypes.any,
};
