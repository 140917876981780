import { useToasts } from 'react-toast-notifications';
import { Form } from '../components';

const FormSet = (props) => {
  const { isLoading, data, children, hideSubmitButton, handleSubmit } = props;
  const { addToast } = useToasts();

  const handleSubmitFormSet = async (values, actions) => {
    try {
      await handleSubmit(values, actions);
      addToast("Succès de l'enregistrement", { appearance: 'success' });
    } catch (error) {
      if (error.message) {
        actions.setStatus(error.message);
      } else {
        actions.setStatus('Erreur lors de la capture');
      }

      addToast("Echec de l'enregistrement", { appearance: 'error' });
    }
    actions.setSubmitting(false);
  };

  return isLoading ? (
    <p>Chargement en cours...</p>
  ) : (
    <Form
      onSubmit={handleSubmitFormSet}
      initialValues={data}
      hideSubmitButton={hideSubmitButton}
    >
      {children}
    </Form>
  );
};

FormSet.defaultProps = {
  isLoading: false,
};

export default FormSet;
