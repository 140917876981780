import { useState, lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { Button, DropdownItem } from 'reactstrap';
import moment from 'moment';
import { t, Trans } from '@lingui/macro';
import { useUserContext } from '../../../contexts/UserContext/UserContext';
import PeriodContext from '../../../utils/PeriodContext';
import SiteContext from '../../../utils/SiteContext';
import { HeaderHelpPages } from '../../../components';
import { HowToUse, MenuIndex, QuestionAnswer } from '../../../pages/HelpPages';
import { prepareEntries } from './PlainApp.service';
import { useAppModeContext } from '../../../utils/hooks';
import { MainContainer } from '../../../components/Layouts';
import BreadCrumbsContext from '../../../utils/BreadcrumbsContext';
import PrivateRoute from '../../../utils/PrivateRoute';
import { Loading } from '../../../../lib/apptheme';

import '../../../assets/scss/styles.scss';
import { Trackers } from '../../../pages/trackers/Trackers';
import ScanNFC from '../../../../components/Scan/ScanNFC';
import ScanEquipmentList from '../../../../components/Scan/ScanEquipmentList';
import TrackersDashboard from '../../../pages/trackers/TrackerDashboard';
import TrackersMap from '../../../pages/trackers/TrackerMap';
import { TrackerDetail } from '../../../pages/trackers/TrackerDetail';
import ReportGoobieList from '../../../pages/trackers/ReportGoobieList';
import { ReportDetail } from '../../../pages/trackers/RaportGoobieDetails';
import CockpitInfrasctructure from '../../../pages/cockpit/CockpitInfrasctructure';
import CockpitGoobiesAdministration from '../../../pages/cockpit/CockpitGoobiesAdministration';
import CockpitGoobie from '../../../pages/cockpit/CockpitGoobie';
import {
  homeDesktopEntries,
  homeMobileEntries,
  mainNavbarDesktopEntries,
  mainNavbarMobileEntries,
} from './PlainApp.constant';

const HomePage = lazy(() =>
  import('../../../components/pages/HomePage/HomePage')
);
const Security = lazy(() => import('../../../pages/Security/Security'));
const Report = lazy(() => import('../../../pages/report/Report'));
const Error = lazy(() => import('../../../pages/error/Error'));
const Settings = lazy(() => import('../../../pages/settings/Settings'));
const OnBoarding = lazy(() => import('../../../pages/HelpPages/OnBoarding'));
const Equipment = lazy(() =>
  import('../../../pages/equipments/Equipment/Equipment')
);
const EquipmentParcLoxam = lazy(() =>
  import('../../../pages/equipments/EquipmentParcLoxam/EquipmentParcLoxam')
);
const Cockpit = lazy(() => import('../../../pages/cockpit/Cockpit'));

const helpAsidemenu = [
  {
    id: 'dashboard',
    type: 'link',
    text: t`Tableau de bord`,
    link: '/',
  },
  {
    id: 'howtouse',
    type: 'link',
    text: t`Premiers pas`,
    link: '/help-how-to-use',
  },
  {
    id: 'qa',
    type: 'link',
    text: t`Utiliser LoxSafe`,
    link: '/help-index',
  },
];

const DropDownItems = () => {
  const history = useHistory();
  const handleLogout = () => {
    history.push('/login');
  };

  return (
    <>
      <DropdownItem onClick={() => history.push('/settings')}>
        <span className="dropdown-icon oi oi-cog">
          <Trans>Paramètres</Trans>
        </span>
      </DropdownItem>
      <Button type="button" onClick={handleLogout} color="secondary">
        <Trans>Se déconnecter</Trans>
      </Button>
    </>
  );
};

const PlainApp = () => {
  const user = useUserContext();
  const { isInternalAppMode } = useAppModeContext();

  const [site, setSite] = useState(null);
  const [period, setPeriod] = useState({
    start: moment().startOf('day'),
    end: moment().endOf('day'),
  });
  const [breadcrumbsList, setBreadcrumbs] = useState([]);

  const filteredHomeMobileEntries = prepareEntries(
    user,
    isInternalAppMode,
    homeMobileEntries
  );

  const filteredNavBarMobileEntries = prepareEntries(
    user,
    isInternalAppMode,
    mainNavbarMobileEntries
  );

  const filteredHomeDesktopEntries = prepareEntries(
    user,
    isInternalAppMode,
    homeDesktopEntries
  );

  const filteredNavBarDesktopEntries = prepareEntries(
    user,
    isInternalAppMode,
    mainNavbarDesktopEntries
  );

  const mobileHomeEntries = [...filteredHomeMobileEntries];
  const mobileNavBarEntries = [...filteredNavBarMobileEntries];
  const desktopHomeEntries = [...filteredHomeDesktopEntries];
  const desktopNavBarEntries = [...filteredNavBarDesktopEntries];

  if (
    filteredHomeMobileEntries.length > 0 &&
    filteredHomeMobileEntries[0].value === '/'
  ) {
    filteredHomeMobileEntries.shift();
  }

  if (
    filteredNavBarMobileEntries.length > 0 &&
    filteredNavBarMobileEntries[0].value === '/'
  ) {
    filteredNavBarMobileEntries.shift();
  }

  if (
    filteredHomeDesktopEntries.length > 0 &&
    filteredHomeDesktopEntries[0].value === '/'
  ) {
    filteredHomeDesktopEntries.shift();
  }

  if (
    filteredNavBarDesktopEntries.length > 0 &&
    filteredNavBarDesktopEntries[0].value === '/'
  ) {
    filteredNavBarDesktopEntries.shift();
  }

  return (
    <BrowserRouter>
      <BreadCrumbsContext.Provider
        value={{
          breadcrumbsList,
          setBreadcrumbsList: (breadcrumbsList) =>
            setBreadcrumbs(breadcrumbsList),
        }}
      >
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/error">
              <Error />
            </Route>
            <PrivateRoute exact path="/onboarding" isAuthenticated={!!user}>
              <OnBoarding />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/help-how-to-use"
              isAuthenticated={!!user}
            >
              <HowToUse
                asideMenu={helpAsidemenu}
                userDropdownItems={<DropDownItems />}
                headerComponent={HeaderHelpPages}
              />
            </PrivateRoute>
            <PrivateRoute exact path="/help-qa" isAuthenticated={!!user}>
              <QuestionAnswer
                asideMenu={helpAsidemenu}
                userDropdownItems={<DropDownItems />}
                headerComponent={HeaderHelpPages}
              />
            </PrivateRoute>
            <PrivateRoute exact path="/help-index" isAuthenticated={!!user}>
              <MenuIndex
                asideMenu={helpAsidemenu}
                userDropdownItems={<DropDownItems />}
                headerComponent={HeaderHelpPages}
              />
            </PrivateRoute>
            <PrivateRoute path="/" isAuthenticated={!!user}>
              <ToastProvider placement="bottom-right" autoDismiss>
                <SiteContext.Provider
                  value={{
                    site,
                    setSite: (site) => setSite(site),
                  }}
                >
                  <PeriodContext.Provider
                    value={{
                      period,
                      setPeriod: (period) => setPeriod(period),
                    }}
                  >
                    <MainContainer
                      mobileLinks={mobileNavBarEntries}
                      desktopLinks={desktopNavBarEntries}
                    >
                      <Switch>
                        <Route
                          exact
                          path="/"
                          render={() => (
                            <HomePage
                              title={t`Mes machines connectées`}
                              mobileLinks={mobileHomeEntries}
                              desktopLinks={desktopHomeEntries}
                            />
                          )}
                        />
                        <Route path="/equipments" component={Equipment} />
                        <Route
                          path="/parcLoxam"
                          component={EquipmentParcLoxam}
                        />
                        <Route exact path="/security" component={Security} />
                        <Route path="/settings" component={Settings} />
                        <Route path="/report" component={Report} />
                        <Route
                          exact
                          path="/trackers"
                          component={TrackersDashboard}
                        />
                        <Route
                          exact
                          path="/trackers/list"
                          component={Trackers}
                        />
                        <Route
                          exact
                          path="/trackers/map"
                          component={TrackersMap}
                        />
                        <Route exact path="/scan" component={ScanNFC} />
                        <Route
                          exact
                          path="/scan-machines"
                          component={ScanEquipmentList}
                        />
                        <Route exact path="/trackers/:trackerId">
                          <TrackerDetail />
                        </Route>
                        <Route
                          exact
                          path="/tracker-reports"
                          component={ReportGoobieList}
                        />
                        <Route exact path="/tracker-reports/:reportId">
                          <ReportDetail />
                        </Route>
                        <Route path="/cockpit" exact component={Cockpit} />
                        <Route
                          path="/cockpit/infrastructure"
                          exact
                          render={() => <CockpitInfrasctructure />}
                        />
                        <Route
                          path="/cockpit/goobies/administration"
                          exact
                          render={() => <CockpitGoobiesAdministration />}
                        />
                        <Route
                          path="/cockpit/goobies/administration/:id"
                          exact
                          render={() => <CockpitGoobie />}
                        />
                      </Switch>
                    </MainContainer>
                  </PeriodContext.Provider>
                </SiteContext.Provider>
              </ToastProvider>
            </PrivateRoute>
          </Switch>
        </Suspense>
      </BreadCrumbsContext.Provider>
    </BrowserRouter>
  );
};

export default PlainApp;
