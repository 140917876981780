import { t } from '@lingui/macro';
import { Button, Typography } from '@mui/material';

const MachineDetailsButton = ({
  handleHistoryPush,
}: {
  handleHistoryPush: () => void;
}) => {
  return (
    <Button variant="outlined" size="large" onClick={handleHistoryPush}>
      <Typography fontSize={12} fontWeight={600}>
        {t`Voir Détails`}
      </Typography>
    </Button>
  );
};

export default MachineDetailsButton;
