import { t, Trans } from '@lingui/macro';
import Typography from '@mui/material/Typography';
import { Machine } from '../../../../../../api/machines/Machine.interface';
import { MACHINE_WORKING_STATES } from '../../../../../../utils/utils';
import { isDateBellow24h } from '../../../../../../utils/date/calcul';

const engineRunningColor: { [key: string]: string } = {
  '': 'primary.main',
  true: 'success.main',
  false: 'secondary.main',
};

const machineStatusKeyValues: { [key: string]: string } = {
  true: MACHINE_WORKING_STATES.working,
  false: MACHINE_WORKING_STATES.stopped,
  '': MACHINE_WORKING_STATES.undefined,
};

export const getMachineStatus = (
  engineRunning: string,
  isEngineRunningDatetimeBellow24h = false
) => {
  if (!isEngineRunningDatetimeBellow24h) {
    return t`Indéterminé`;
  }

  return machineStatusKeyValues[engineRunning?.toString() ?? '']
    ? machineStatusKeyValues[engineRunning?.toString() ?? '']
    : t`Indéterminé`;
};

const MachineStatus = ({ machine }: { machine: Machine }) => {
  const { engineRunning, engineRunningDatetime } = machine;

  const isEngineRunningDatetimeBellow24h = isDateBellow24h(
    engineRunningDatetime
  );

  const isRunning =
    (engineRunning ?? false) && isEngineRunningDatetimeBellow24h;

  const machineStatus = getMachineStatus(
    engineRunning?.toString() ?? '',
    isEngineRunningDatetimeBellow24h
  );

  const machineStatusColor = engineRunningColor[isRunning.toString()];

  return (
    <Typography color={machineStatusColor}>
      <Trans id={machineStatus} />
    </Typography>
  );
};

export default MachineStatus;
