import { t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  getInactiveTrackers,
  getOverActiveTrackers,
} from '../../api/trackers/Trackers';
import { PaginatedTable } from '../../components/molecules/PaginatedTable/PaginatedTable';

/**
 * List of trackers
 * @param hideDetails show a simple list of tracker
 * @returns {JSX.Element}
 * @constructor
 */
export const TrackerGoobieWarningList = ({ overActiveOnly, inactiveOnly }) => {
  const [trackers, setTrackers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageNumber, setPage] = useState(0);
  const [totalTrackers, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [sortFilter, setSort] = useState(null);

  useEffect(() => {
    const fetchTrackers = async () => {
      setLoading(true);
      let response;
      if (overActiveOnly) {
        response = await getOverActiveTrackers({
          page: pageNumber,
          size: rowsPerPage,
          sort: sortFilter,
        });
      } else if (inactiveOnly) {
        response = await getInactiveTrackers({
          page: pageNumber,
          size: rowsPerPage,
          sort: sortFilter,
        });
      }

      setTrackers(response.trackers);
      setTotal(response.totalElements);
      setLoading(false);
    };

    fetchTrackers();
  }, [pageNumber, rowsPerPage, sortFilter, inactiveOnly, overActiveOnly]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSortChange = (sortModel) => {
    // the multi sorting is not available for free plan, https://mui.com/x/react-data-grid/sorting/#single-and-multi-sorting
    setSort(sortModel.length === 0 ? null : sortModel[0]);
  };

  let columns = [];
  if (overActiveOnly) {
    columns = [
      {
        field: 'mode',
        headerName: t`Mode`,
      },
      {
        field: 'tag',
        headerName: t`Tag`,
      },
      {
        field: 'imei',
        headerName: t`IMEI`,
        flex: 1,
        minWidth: 150,
        renderCell: (params) => (
          <Link to={`/trackers/${params.row.id}`}>{params.value}</Link>
        ),
      },
      {
        field: 'count',
        minWidth: 150,
        headerName: t`Nombre d'émission ces dernières 24h`,
      },
    ];
  } else {
    columns = [
      {
        field: 'mode',
        headerName: t`Mode`,
      },
      {
        field: 'tag',
        headerName: t`Tag`,
      },
      {
        field: 'imei',
        headerName: t`IMEI`,
        flex: 1,
        minWidth: 200,
        renderCell: (params) => (
          <Link to={`/trackers/${params.row.id}`}>{params.value}</Link>
        ),
      },
    ];
  }

  return (
    <>
      {overActiveOnly && (
        <>
          <Typography variant="h1">{t`Trackers anormalement actifs`}</Typography>
          <Typography variant="body1">{t`Ces trackers ont émis plus d'un rapport dans les dernières 24 heures`}</Typography>
        </>
      )}
      {inactiveOnly && (
        <>
          <Typography variant="h1">{t`Trackers inactifs`}</Typography>
          <Typography variant="body1">{t`Ces trackers n'ont plus émis de rapports depuis plus de 15 jours.`}</Typography>
        </>
      )}

      <PaginatedTable
        searchLabel={`${t`Imei`} / ${t`Equipement`}`}
        onSearchChange={() => {}}
        onPageChange={handleChangePage}
        onRowChange={handleChangeRowsPerPage}
        onSortChange={handleSortChange}
        data={trackers}
        columns={columns}
        page={pageNumber}
        rowsPerPage={rowsPerPage}
        totalElement={totalTrackers}
        searchEnable={false}
        isLoading={isLoading}
      />
    </>
  );
};
