import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import { t, Trans } from '@lingui/macro';
import { StyledTableCell } from '../../../../../components/molecules/TableWithPagination/UsedComponentsAndImports';
import TableWithPagination from '../../../../../components/molecules/TableWithPagination/TableWithPagination';

import colors from '../../../../../theme/_colors.scss';
import './TableMachineUsageTime.scss';

interface MachineUsageAlertsProps {
  duration: number;
  endDuration: string;
  outOfContract: boolean;
  startDuration: string;
}

const generateCells = (row: MachineUsageAlertsProps) => (
  <>
    <StyledTableCell
      sx={{ color: row.outOfContract ? colors.imperialRed : '' }}
    >
      {row.startDuration
        ? moment(row.startDuration).local().format('DD/MM/YYYY à HH:mm')
        : '-'}
    </StyledTableCell>
    <StyledTableCell
      sx={{ color: row.outOfContract ? colors.imperialRed : '' }}
    >
      {row.endDuration
        ? moment(row.endDuration).local().format('DD/MM/YYYY à HH:mm')
        : '-'}
    </StyledTableCell>
    <StyledTableCell
      sx={{ color: row.outOfContract ? colors.imperialRed : '' }}
    >
      {row.duration}
    </StyledTableCell>
  </>
);

const TableMachineUsageTime = ({ data }: any) => {
  return (
    <Stack className="table-machine-usage-time">
      <Typography variant="h4">
        <Trans>Consommation sur la durée totale de location</Trans>
      </Typography>
      <TableWithPagination
        headers={[t`Du`, t`Au`, t`Durée`]}
        rows={data}
        cells={generateCells}
        alternateBg
        checkboxesStatesResp={undefined}
        columnsObject={undefined}
        dataDescription={undefined}
        parentFilters={undefined}
        hideColumns={undefined}
        equipment={undefined}
      />
    </Stack>
  );
};

export default TableMachineUsageTime;
