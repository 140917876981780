import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useUserContext } from '../../../app/contexts/UserContext/UserContext';

const UserDropdown = ({ isOpen, toggle, children }) => {
  const user = useUserContext();
  return (
    <Dropdown
      tag="div"
      className="d-flex"
      isOpen={isOpen}
      toggle={toggle}
      inNavbar
      nav
      direction="down"
    >
      <DropdownToggle
        className="btn-account d-none d-md-flex"
        tag="button"
        caret
      >
        <span className="account-summary pr-lg-4 d-none d-lg-block">
          <span className="account-name">{user.firstName}</span>
          {user.accountDescription && (
            <span className="account-description">
              {user.accountDescription}
            </span>
          )}
        </span>
      </DropdownToggle>
      <DropdownMenu id="user-dropdown">
        <div className="dropdown-arrow" />
        {children}
      </DropdownMenu>
    </Dropdown>
  );
};

export default UserDropdown;
