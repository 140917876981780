import { t } from '@lingui/macro';
import * as yup from 'yup';

import Field from './Field';
import ReactStrapComponent from './ReactStrapComponent';

const validationSchema = yup
  .number(t`Le téléphone n'est pas valide.`)
  .max(99999999999, t`Le téléphone n'est pas valide.`)
  .positive(t`Le téléphone n'est pas valide.`)
  .integer(t`Le téléphone n'est pas valide.`)
  .typeError(t`Le téléphone n'est pas valide.`);

const PhoneField = (props) => (
  <Field
    type="phone"
    component={ReactStrapComponent}
    validationSchema={validationSchema}
    {...props}
  />
);

export default PhoneField;
