import { getRequest } from '../utils/axios.utils';

export const getContractByMachineIdRequest = (id) => {
  return getRequest(`contracts/${id}`);
};

export const getContractByClientId = (id) => {
  return getRequest(`contracts/client/${id}`);
};

export const getContractsByJobSiteId = (id) => {
  return getRequest(`contracts/contract/jobSite/${id}`);
};

export const getContractsByAgencyId = (id) => {
  return getRequest(`contracts/contract/agency/${id}`);
};
