/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */

import Aos from 'aos';
import 'aos/dist/aos.css';
import { createElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { Header, Page, PageSection } from '../../../lib/apptheme';
import { AsideNoExtend } from '../../components';

import dashboard from '../../assets/images/dashboard3.png';
import welcomeAlert from '../../assets/images/helpcenter/welcome-alerts.jpg';

const HowToUse = ({
  user,
  userDropdownItems,
  headerNavs,
  logo,
  asideMenu,
  headerComponent,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleNavbar = () => setCollapsed(!collapsed);
  const history = useHistory();

  useEffect(() => {
    Aos.init();
  });

  return (
    <div
      className="app"
      data-aos-easing="ease"
      data-aos-duration="1000"
      data-aos-delay="0"
    >
      {headerComponent ? (
        createElement(headerComponent, {
          toggle: toggleNavbar,
          user,
          headerNavs,
          userDropdownItems,
          logo,
        })
      ) : (
        <Header
          toggle={toggleNavbar}
          user={user}
          headerNavs={headerNavs}
          userDropdownItems={userDropdownItems}
          logo={logo}
        />
      )}
      <AsideNoExtend
        asideMenu={asideMenu}
        show={collapsed}
        user={user}
        userDropdownItems={userDropdownItems}
        toggleAside={toggleNavbar}
      />
      <main className="app-main">
        <div className="wrapper">
          <Page>
            <PageSection>
              <section className="py-5">
                <Container fluid="xl">
                  <Row className="align-items-center">
                    <Col
                      xs="12"
                      md={{ size: 7, order: 2 }}
                      className="text-center"
                      data-aos="fade-left"
                    >
                      <img
                        className="img-fluid img-float-md-6 mb-5 mb-md-0"
                        src={dashboard}
                        alt="placeholder"
                        width="100%"
                      />
                    </Col>
                    <Col xs="12" md={{ size: 5, order: 1 }} data-aos="fade-in">
                      <div className="col-fix pl-xl-3 ml-auto text-center text-sm-left">
                        <h1 className="display-4 enable-responsive-font-size mb-4">
                          <strong>Bienvenue !</strong>
                          <br /> Nous sommes ravis de vous accueillir ici.
                        </h1>
                        <p className="lead text-muted mb-5">
                          {' '}
                          Commençons sans attendre.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
              {
                // <section className="py-5">
                //   <Container>
                //     <Row className="text-center text-md-left">
                //       <Col xd="6" md="3" py="4" className="py-4" data-aos="fade-up" data-aos-delay="0">
                //         <img className="mb-4" src={lab} alt="" height="72" />
                //         <h2 className="lead"> Simple and Rich </h2>
                //       </Col>
                //       <Col xd="6" md="3" py="4" className="py-4" data-aos="fade-up" data-aos-delay="100">
                //         <img className="mb-4" src={easyconfig} alt="" height="72" />
                //         <h2 className="lead"> Easy Cuztomizable </h2>
                //       </Col>
                //       <Col xd="6" md="3" py="4" className="py-4" data-aos="fade-up" data-aos-delay="200">
                //         <img className="mb-4" src={scale} alt="" height="72" />
                //         <h2 className="lead"> Fast and scalable </h2>
                //       </Col>
                //       <Col xd="6" md="3" py="4" className="py-4" data-aos="fade-up" data-aos-delay="300">
                //         <img className="mb-4" src={support} alt="" height="72" />
                //         <h2 className="lead"> Excellent Support </h2>
                //       </Col>
                //     </Row>
                //   </Container>
                // </section>
              }
              <section className="py-5">
                <Container>
                  <Row>
                    <Col
                      xs="12"
                      md={{ size: 8, offset: 2 }}
                      className="text-center"
                    >
                      <h2> La sécurité pilotée par l&apos;IoT </h2>
                      <p className="lead text-muted">
                        L&apos;application LoxSafe propose des fonctionnalitées
                        permettant de suivre le niveau de risque sur les
                        chantiers et d&apos;agir pour l&apos;améliorer.
                      </p>
                    </Col>
                  </Row>
                </Container>
              </section>
              <section className="py-5">
                <Container>
                  <Row className="row align-items-center">
                    <Col
                      xs="12"
                      md="6"
                      className="text-center"
                      data-aos="fade-left"
                    >
                      <img
                        className="img-fluid mb-4 mb-md-0"
                        src={welcomeAlert}
                        alt="placeholdr"
                      />
                    </Col>
                    <Col
                      xs="12"
                      md={{ size: 6, order: 1 }}
                      class="text-sm-left"
                    >
                      <h3 className="mb-4">
                        {' '}
                        Suivre l&apos;évolution du comportement{' '}
                      </h3>
                      <p className="text-muted font-size-lg mb-4">
                        Les alertes remontées par les machines sont accécibles
                        facilement afin d&apos;analyser les comportements.
                      </p>
                    </Col>
                  </Row>
                </Container>
              </section>
              <section className="py-5">
                <Container>
                  <Row className="row align-items-center">
                    <Col
                      xs="12"
                      md={{ size: 6, order: 2 }}
                      className="text-center"
                      data-aos="fade-left"
                    >
                      <img
                        className="img-fluid mb-4 mb-md-0"
                        src={welcomeAlert}
                        alt="placeholdr"
                      />
                    </Col>
                    <Col xs="12" md="6" class="text-sm-left">
                      <h3 className="mb-4"> Increase your productivity </h3>
                      <p className="text-muted font-size-lg mb-4">
                        {' '}
                        Create stunning UI with tons of our components. Our
                        build system allows you to save a lot of time as well.{' '}
                      </p>
                    </Col>
                  </Row>
                </Container>
              </section>
              <section className="py-5">
                <Container>
                  <Row>
                    <Col
                      xs="12"
                      md={{ size: 8, offset: 2 }}
                      className="text-center"
                      data-eos="fade-left"
                    >
                      <h2> Si vous avez d&apos;autres questions... </h2>
                      <p className="lead text-muted">
                        {' '}
                        Cliquez sur le lien suivant pour y répondre{' '}
                      </p>
                      <Button
                        color="primary"
                        onClick={() => {
                          history.push('/help-qa');
                        }}
                      >
                        Questions et réponses
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </section>
            </PageSection>
          </Page>
        </div>
      </main>
      <div
        className={`aside-backdrop ${collapsed && 'show'}`}
        onClick={() => setCollapsed(!collapsed)}
        role="button"
        tabIndex={0}
      />
    </div>
  );
};

export default HowToUse;
