import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { t } from '@lingui/macro';
import { useBreadcrumbsContext } from '../../utils/hooks';
import CockpitRecence from './CockpitRecence';
import CockpitEquipment from './CockpitEquipment';
import { ReturnButton } from '../../components/molecules';
import { useUserContext } from '../../contexts/UserContext/UserContext';

const CockpitInfrasctructure = () => {
  const [, setBreadcrumbs] = useBreadcrumbsContext();
  const [showRecence, setShowRecence] = useState(true);

  const user = useUserContext();

  // useEffect(() => {
  //   if (user.codeRegion !== '01') {
  //     window.location.replace('/');
  //   }
  // }, []);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: 'Cockpit',
        to: '/cockpit',
      },
      {
        label: 'Infrastructure',
        to: '/cockpit/infrastructure',
      },
    ]);
    // TODO - fix eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ReturnButton label={t`Retour`} />
      <Typography variant="h2">{t`Infrastructure`}</Typography>
      {/* <Box className="cockpit-recence-buttons-box">
        <Button
          className="cockpit-recence-buttons-box__button"
          disabled={showRecence}
          onClick={() => setShowRecence(!showRecence)}
        >
          {t`Récence`}
        </Button>
        <Button
          className="cockpit-recence-buttons-box__button"
          disabled={!showRecence}
          onClick={() => setShowRecence(!showRecence)}
        >
          {t`Équipement`}
        </Button>
      </Box> */}
      <Box sx={{ paddingTop: '15px' }}>
        {showRecence ? <CockpitRecence /> : <CockpitEquipment />}
      </Box>
    </>
  );
};

export default CockpitInfrasctructure;
