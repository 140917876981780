import { FunctionComponent, memo } from 'react';
import { findEngineStatusCard } from './StatusEngine.utils';

import './StatusEngine.scss';

export interface StatusEngineProps {
  machineStatus?: string | null;
}

const StatusEngine: FunctionComponent<StatusEngineProps> = ({
  machineStatus,
}) => {
  return (
    <div className="status-engine">
      {machineStatus && findEngineStatusCard(machineStatus)}
    </div>
  );
};

export default memo(StatusEngine);
