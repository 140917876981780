import { useState, useEffect } from 'react';
import { t } from '@lingui/macro';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  TableCell,
  Typography,
} from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import { getAllSites } from '../../../api/jobsites/JobSites';
import { TableWithPagination } from '../../../components/molecules';
import { Loading } from '../../../../lib/apptheme';

import './MachineGroupList.scss';

const MachineGroupList = ({ onAdd, onItemClick, updateList }) => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const sites = await getAllSites();
      setData(sites);
      setLoading(false);
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateList]);

  const headers = [t`Nom du groupement de machines`, t`Description`, ''];

  const getCells = (el) => {
    return [
      <Typography>{el.name}</Typography>,
      <Typography>{el.description}</Typography>,
      <Stack className="machine-group-list__actions" direction="row">
        <Button
          className="machine-group-list__btn"
          variant="body1Bold"
          sx={{
            color: 'primary.main',
          }}
          onClick={() => onItemClick(el.id, 'read')}
        >
          {t`Voir détails`}
        </Button>
        <IconButton
          sx={{
            color: 'primary.main',
          }}
          onClick={() => onItemClick(el.id, 'update')}
        >
          <Edit />
        </IconButton>
        <IconButton
          sx={{
            color: 'primary.main',
          }}
          onClick={() => onItemClick(el.id, 'delete', el)}
        >
          <Delete />
        </IconButton>
      </Stack>,
    ];
  };

  const generateCells = (machine) => {
    const cells = getCells(machine);
    return cells.map((cell) => <TableCell align="left">{cell}</TableCell>);
  };

  return (
    <Box className="machine-group-list">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          className="machine-group-list__title"
          variant="h3"
          color="primary.main"
        >
          {t`Groupements de machines`}
        </Typography>

        <Button
          className="machine-group-list__btn machine-group-list__create-btn"
          startIcon={<AddCircleIcon />}
          onClick={() => {
            onAdd();
          }}
        >
          {t`Créer un groupement de machines`}
        </Button>
      </Stack>

      {isLoading ? (
        <Box className="machine-group-list__loading">
          <Loading />
        </Box>
      ) : (
        <TableWithPagination
          color="primary.main"
          headers={headers}
          rows={data}
          cells={generateCells}
          simpleTable
          alternateBg
        />
      )}
    </Box>
  );
};

export default MachineGroupList;
