import { t } from '@lingui/macro';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { JobSite } from '../../../../../api/jobsites/JobSite.interface';
import { getFormatedDate } from '../../../../../utils/date/format';
import { cellsWrap } from '../../../../../utils/table/table';
import { capitalizeOrDefault } from '../../../../../utils/utils';

import './JobSiteRow.scss';

type JobSiteAddressProps = {
  city: JobSite['city'];
  streetAdress: JobSite['streetAdress'];
  streetNumber: JobSite['streetNumber'];
};
type JobSiteDateProps = {
  startDate?: JobSite['startDate'];
  endDate?: JobSite['endDate'];
};

const JobSiteAddress: React.FC<JobSiteAddressProps> = ({
  city,
  streetAdress,
  streetNumber,
}) => (
  <Typography>
    {`
        ${capitalizeOrDefault(city)},
        ${capitalizeOrDefault(streetAdress)},
        ${capitalizeOrDefault(streetNumber)}
      `}
  </Typography>
);

const JobSiteDate: React.FC<JobSiteDateProps> = ({ startDate, endDate }) => (
  <Typography>
    {startDate && endDate
      ? `${t`Du`} ${getFormatedDate(startDate)} ${t`au`} ${getFormatedDate(
          endDate
        )}`
      : '-'}
  </Typography>
);

const JobSiteRow = ({
  jobSite,
  handleHistoryPush,
  isMobile,
}: {
  jobSite: JobSite;
  handleHistoryPush: () => void;
  isMobile: boolean;
}) => {
  const cells = isMobile
    ? [
        <Stack
          key="jobsite-row"
          className="jobsite-row"
          spacing={1}
          onClick={handleHistoryPush}
        >
          <Typography className="jobsite-row__name">
            {jobSite.name || '-'}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Typography fontWeight="500">{t`Adresse`}:</Typography>
            <JobSiteAddress
              key="jobsite-address"
              city={jobSite.city}
              streetAdress={jobSite.streetAdress}
              streetNumber={jobSite.streetNumber}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography fontWeight="500">{t`Date`}:</Typography>
            <JobSiteDate
              key="jobsite-date"
              startDate={jobSite.startDate}
              endDate={jobSite.endDate}
            />
          </Stack>
        </Stack>,
      ]
    : [
        <Typography key="jobsite-name">{jobSite.name || '-'}</Typography>,
        <JobSiteAddress
          key="jobsite-address"
          city={jobSite.city}
          streetAdress={jobSite.streetAdress}
          streetNumber={jobSite.streetNumber}
        />,
        <JobSiteDate
          key="jobsite-date"
          startDate={jobSite.startDate}
          endDate={jobSite.endDate}
        />,
        <Button
          key="jobsite-details-button"
          variant="outlined"
          size="large"
          onClick={handleHistoryPush}
        >
          <Typography fontSize={12} fontWeight={600}>
            {t`Voir Détails`}
          </Typography>
        </Button>,
      ];

  return cellsWrap(cells);
};

export default JobSiteRow;
