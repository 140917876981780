export const createMarkerWithLatLon = (map, lat, lon, icon) => {
  return createMarker(map, new f4.map.LatLng(lat, lon), icon); // eslint-disable-line no-undef
};

export const createMarker = (map, position, icon, title = '', config = {}) => {
  // eslint-disable-next-line no-undef
  return new f4.map.Marker({
    position,
    title,
    map,
    icon,
    ...config,
  });
};
