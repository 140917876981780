import { Trans } from '@lingui/macro';
import { Icon, Paper, Stack, Typography } from '@mui/material';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PercentageContent from '../PercentageContent/PercentageContent';
import colors from '../../app/theme/_colors.scss';
import { mets } from '../../app/utils/utils';

import './SecurityAlert.scss';

interface AlertInfo {
  total: string;
  percentage?: number;
  isPercentageUp?: boolean;
}

export interface SecurityAlertProps {
  title: string;
  notification: AlertInfo;
  machine: AlertInfo;
  time: AlertInfo;
  hideMachine?: boolean;
  isSelected?: boolean;
  handleClick?: () => void;
}

const SecurityAlertInfo = ({
  alert,
  icon,
}: {
  alert: AlertInfo;
  icon: JSX.Element;
}) => {
  return (
    <Stack alignItems="center" spacing={1}>
      <Typography variant="h3">{alert.total}</Typography>
      {icon}

      {alert.percentage !== undefined && (
        <PercentageContent
          percentage={alert.percentage}
          isPercentageUp={alert.isPercentageUp}
        />
      )}
    </Stack>
  );
};

const SecurityAlert = ({
  title,
  notification,
  machine,
  time,
  hideMachine = false,
  isSelected = false,
  handleClick,
}: SecurityAlertProps) => {
  // eslint-disable-next-line prettier/prettier
  const str = title as string;

  return (
    <Stack className="security-alert-container" onClick={handleClick}>
      <Paper
        className="security-alert"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sx={{
          bgcolor: isSelected ? colors.starCommandBlue : 'cardLink.bgcolor',
          color: isSelected && colors.white,
          '&:hover': !!handleClick && {
            bgcolor: colors.starCommandBlue,
            color: colors.white,
            cursor: 'pointer',
          },
        }}
        square
      >
        <Typography
          variant="h6"
          textAlign="center"
          fontWeight="bold"
          className="security-alert__title"
        >
          <Trans id={mets[str as keyof typeof mets] ?? title} />
        </Typography>

        <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={1}
        >
          <SecurityAlertInfo
            alert={notification}
            icon={<Icon fontSize="large">notifications</Icon>}
          />

          {!hideMachine && (
            <SecurityAlertInfo
              alert={machine}
              icon={<PrecisionManufacturingIcon fontSize="large" />}
            />
          )}

          <SecurityAlertInfo
            alert={time}
            icon={<AccessTimeIcon fontSize="large" />}
          />
        </Stack>
      </Paper>
    </Stack>
  );
};

export default SecurityAlert;
