/* eslint-disable no-undef */
import useTheme from '@mui/system/useTheme';
import moment from 'moment';
import { useEffect, useMemo, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import { useLocation } from 'react-router-dom';
import iconEllipse from '../../../../assets/images/Ellipse.svg';
import useMap from '../../../../hooks/useMap/useMap';
import colors from '../../../../theme/_colors.scss';
import { getBound } from '../../../../utils/f4map/bound.utils';
import { smoothZoom } from '../../../../utils/f4map/utils';
import { useAppModeContext } from '../../../../utils/hooks';
import NoCoordsWarning from '../FleetMapAll/components/NoCoordsWarning';
import { getDateWithGMTInfo } from '../../../../utils/date/format';
import {
  MARKER_POPUP_CLOSE_DELAY,
  MARKER_POPUP_OPEN_DELAY,
} from '../Map.constants';
import './HistoryMap.scss';

const HistoryMap = (props) => {
  const {
    locations,
    mapClassName = '',
    locationsToHighlight,
    handleMarkerDrag = (coords) => {},
  } = props;
  const themePalette = useTheme();
  const { isInternalAppMode } = useAppModeContext();
  const { search } = useLocation();
  const zoomOn = useMemo(() => new URLSearchParams(search), [search]).get(
    'zoomOn'
  );

  const mapOptions = {
    mapTypeId: 'satellite',
  };

  const { map, MapCanvas, setMapElements, resetMapElements } = useMap({
    mapClassName,
    mapOptions,
  });

  let click = false;
  const openMarkerTimeout = useRef(null);

  // Magic number to determine the points rendered on the map.
  // Above this number it's not rendering any markers, only the lines.
  const MAX_MARKERS = 7;

  useEffect(() => {
    const markers = [];
    const polylines = [];
    const coords = [];

    if (!map) return;

    resetMapElements();

    if (!locations || locations.length === 0) return;

    try {
      const areAllLocationsSamePoint = (locations) => {
        const distinctPoints = new Set(
          locations.flatMap((location) => [location.lat, location.lon])
        );

        return distinctPoints.size === 2;
      };

      const markersArr = new Set();
      locations.forEach((location) => {
        if (!isInternalAppMode && location.state === 'goobie') return;

        const equipLat = location.lat || location.gbLat;
        const equipLon = location.lon || location.gbLong;

        if (!!equipLat && !!equipLon) {
          const myLatlng2 = new f4.map.LatLng(equipLat, equipLon);
          const title = getDateWithGMTInfo(location.gpsFixTime);

          const contentString = renderToString(
            <div className="marker-card">{title}</div>
          );

          const infowindow = new f4.map.InfoWindow({
            content: contentString,
            maxWidth: 180,
          });

          markersArr.add({
            lat: equipLat,
            lon: equipLon,
          });

          coords.push(new f4.map.LatLng(equipLat, equipLon));

          f4.map.event.addListener(map, 'zoom_changed', () => {
            const zoomLevel = map.getZoom();
            if (zoomLevel !== 18) {
              // eslint-disable-next-line
              click = false;
            }
          });

          // Only render markers if the number os locations is below the limit
          if (locations.length <= MAX_MARKERS) {
            const iconDefinition =
              locations?.length === 1 || areAllLocationsSamePoint(locations)
                ? {
                    url: iconEllipse,
                    scaledSize: new f4.map.Size(15, 15),
                  }
                : {};

            const marker = new f4.map.Marker({
              position: myLatlng2,
              map,
              title,
              icon: iconDefinition,
              draggable: !!location.isDraggable,
            });
            markers.push(marker);

            if (location.isDraggable) {
              f4.map.event.addListener(marker, 'dragend', () => {
                const coords = marker.getPosition();

                // eslint-disable-next-line no-underscore-dangle
                handleMarkerDrag({ lat: coords._lat, lon: coords._lng });
                map.setCenter(coords);
              });
            }

            if (location.id === zoomOn) {
              map.setZoom(18);
              map.setCenter(marker.getPosition());
            }

            f4.map.event.addListener(marker, 'click', () => {
              smoothZoom(map, 18);

              map.setCenter(marker.getPosition());
              // eslint-disable-next-line react-hooks/exhaustive-deps
              click = true;
              // infowindow.open(map, marker);
            });

            // TODO : delete this if not needed
            /* f4.map.event.addListener(marker, 'mouseover', () => {
              console.log('mouseover');
              if (!click) {
                openMarkerTimeout.current = setTimeout(
                  () => infowindow.open(map, marker),
                  MARKER_POPUP_OPEN_DELAY
                );
              }
            }); */

            f4.map.event.addListener(marker, 'mouseout', () => {
              clearTimeout(openMarkerTimeout.current);

              try {
                const popUp = document.querySelector(
                  `.infowindow-${location.state}-${location.id}`
                );

                let isMouseOverPopUp = false;

                if (popUp) {
                  popUp.addEventListener('mouseover', () => {
                    isMouseOverPopUp = true;
                  });
                  popUp.addEventListener('mouseout', () => {
                    isMouseOverPopUp = false;
                  });
                }

                // doesn't close the popup after marker click
                if ((!click && !isMouseOverPopUp) || !popUp)
                  setTimeout(
                    () => infowindow.close(map, marker),
                    MARKER_POPUP_CLOSE_DELAY
                  );
              } catch (e) {
                console.error(e);
              }
            });
          }
        }
      });

      // Render lines connecting Markers if there's more then one point
      if (locations.length > 1) {
        const lines = new f4.map.Polyline({
          path: coords,
          geodesic: true,
          strokeColor: themePalette.palette.primary.main,
          strokeOpacity: locationsToHighlight?.length > 0 ? 0.3 : 1.0,
          strokeWeight: 4,
        });
        polylines.push(lines);

        lines.setMap(map);

        if (locationsToHighlight?.length > 0) {
          const formatedLocationsToHighlight = [
            locationsToHighlight.slice(0, 2),
            locationsToHighlight.slice(2, 4),
          ].map((coord) => new f4.map.LatLng(coord[0], coord[1]));

          const linesToHighlight = new f4.map.Polyline({
            path: formatedLocationsToHighlight,
            geodesic: true,
            strokeColor: colors.strawberry,
            strokeOpacity: 1.0,
            strokeWeight: 5,
          });

          polylines.push(linesToHighlight);

          const centerMap = new f4.map.Marker({
            position: formatedLocationsToHighlight[1],
          }).getPosition();
          map.setCenter(centerMap);
          linesToHighlight.setMap(map);
          smoothZoom(map, 18);
        } else {
          map.panToBounds(getBound(Array.from(markersArr)));
        }
      }
      if (!zoomOn && locations.length === 1) {
        map.panToBounds(getBound(Array.from(markersArr)));
      }
    } catch (e) {
      console.error(e);
    }
    setMapElements({ markers, polylines });
  }, [map, zoomOn, locations, locationsToHighlight]);

  return (
    <>
      <NoCoordsWarning
        hasWarning={locations.filter((l) => !l.lat || !l.lon).length > 0}
      />
      {MapCanvas}
    </>
  );
};

export default HistoryMap;
