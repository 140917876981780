export const chunksArray = (array, size) =>
  Array.from({ length: Math.ceil(array.length / size) }, (value, index) =>
    array.slice(index * size, index * size + size)
  );

export const concatArraysWithoutDuplicates = (arrayA, arrayB) => {
  const arrayWithoutDuplicates = [...arrayA];

  arrayWithoutDuplicates.push(
    ...arrayB.filter((item) => !arrayA.includes(item))
  );

  return arrayWithoutDuplicates;
};
