import { t } from '@lingui/macro';
import { Text, View } from '@react-pdf/renderer';
import { SecurityAlertProps } from '../../../../../components/SecurityAlert/SecurityAlert';
import { AlertPDFStyles } from './AlertPDF.constants';

interface AlertPDFProps {
  alert: SecurityAlertProps;
}

const AlertPDF = ({ alert }: AlertPDFProps) => {
  const { title, notification, machine, time } = alert;

  return (
    <View style={AlertPDFStyles.alert}>
      <Text style={AlertPDFStyles.alertTitle}>{title}</Text>

      <View style={AlertPDFStyles.alertRow}>
        <View style={AlertPDFStyles.alertCell}>
          <Text style={AlertPDFStyles.alertCount}>{notification.total}</Text>
          <Text style={AlertPDFStyles.alertName}>{t`Alertes`}</Text>
          {notification.percentage && (
            <Text style={AlertPDFStyles.alertPercentage}>
              {notification.percentage}%
            </Text>
          )}
        </View>

        <View
          style={{
            ...AlertPDFStyles.alertCell,
            borderLeft: '1px solid grey',
          }}
        >
          <Text style={AlertPDFStyles.alertCount}>{time.total}</Text>
          <Text style={AlertPDFStyles.alertName}>{t`Minutes`}</Text>
          {time.percentage && (
            <Text style={AlertPDFStyles.alertPercentage}>
              {time.percentage}%
            </Text>
          )}
        </View>

        <View
          style={{
            ...AlertPDFStyles.alertCell,
            borderLeft: '1px solid grey',
          }}
        >
          <Text style={AlertPDFStyles.alertCount}>{machine.total}</Text>
          <Text style={AlertPDFStyles.alertName}>{t`Notifications`}</Text>
          {machine.percentage && (
            <Text style={AlertPDFStyles.alertPercentage}>
              {machine.percentage}%
            </Text>
          )}
        </View>
      </View>
    </View>
  );
};

export default AlertPDF;
