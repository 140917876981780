import { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  ForgotPassword,
  Login,
  ResetPassword,
} from '../../../../../lib/apptheme';
import {
  resetPassword,
  sendPasswordResetEmail,
} from '../../../../api/auth/customAuth';
import logo from '../../../../assets/images/loxam-logo-white-default3.svg';
import UserContext from '../../../../contexts/UserContext/UserContext';
import PrivateRoute from '../../../../utils/PrivateRoute';
import { getUserInfoByEmail } from '../../Main.service';

const MainExt = ({ user, children }) => {
  const [userLogging, setUser] = useState(user);

  const handleLogin = (email, password) => {
    return getUserInfoByEmail({ email, password }).then(setUser);
  };
  const handleForgotPassword = (email) => sendPasswordResetEmail(email);
  const handleResetPassword = (token, password) => {
    return resetPassword(token, password);
  };

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login">
          <Login
            handleLogin={handleLogin}
            logo={logo}
            isAuthenticated={!!userLogging}
          />
        </Route>
        <Route exact path="/forgot-password">
          <ForgotPassword handleForgotPassword={handleForgotPassword} />
        </Route>
        <Route exact path="/reset-password">
          <ResetPassword handleResetPassword={handleResetPassword} />
        </Route>
        <Route exact path="/confirm-account">
          <ResetPassword handleResetPassword={handleResetPassword} />
        </Route>
        <PrivateRoute path="/" isAuthenticated={!!userLogging}>
          <UserContext.Provider value={userLogging}>
            {children}
          </UserContext.Provider>
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  );
};

export default MainExt;
