/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { createElement, useEffect, useState } from 'react';

import Aos from 'aos';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { Header, Page, PageSection } from '../../../lib/apptheme';
import { AsideNoExtend } from '../../components';

const MenuIndex = ({
  user,
  userDropdownItems,
  headerNavs,
  logo,
  asideMenu,
  headerComponent,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleNavbar = () => setCollapsed(!collapsed);
  const history = useHistory();

  useEffect(() => {
    Aos.init();
  });

  return (
    <div className="app">
      {headerComponent ? (
        createElement(headerComponent, {
          toggle: toggleNavbar,
          user,
          headerNavs,
          userDropdownItems,
          logo,
        })
      ) : (
        <Header
          toggle={toggleNavbar}
          user={user}
          headerNavs={headerNavs}
          userDropdownItems={userDropdownItems}
          logo={logo}
        />
      )}
      <AsideNoExtend
        asideMenu={asideMenu}
        show={collapsed}
        user={user}
        userDropdownItems={userDropdownItems}
        toggleAside={toggleNavbar}
      />
      <main className="app-main">
        <div className="wrapper">
          <Page>
            <PageSection>
              <Row>
                <Col sm="12" md={{ size: 8, offset: 1 }} data-eos="fade-in">
                  <h1 className="display-4 enable-responsive-font-size mb-4">
                    Utiliser <strong>LoxSafe</strong>
                  </h1>
                  <p className="lead text-muted mb-5" id="la-barre-laterale">
                    Découvrer le fonctionnement de LoxSafe jusque dans ses
                    moindres détails.
                  </p>
                </Col>
                <Col sm="12" md={{ size: 8, offset: 1 }} data-eos="fade-in">
                  <section className="py-0">
                    <Container>
                      <h3>Présentation de Slack</h3>
                      <ul className="article-list">
                        <li>
                          <a
                            href="#"
                            onClick={() => {
                              history.push('/help-qa');
                            }}
                          >
                            Rechercher et démarrer des
                            <b className="highlight"> conversations </b>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            Collaborer efficacement dans les
                            <b className="highlight"> canaux</b>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            Trouver les informations dont vous avez besoin
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            Organisez votre travail grâce aux
                            <b className="highlight"> canaux</b>
                          </a>
                        </li>
                        <li>
                          <a href="#">Se concentrer sur ce qui importe</a>
                        </li>
                      </ul>
                      <hr className="my-2" style={{ paddingBottom: '20px' }} />
                    </Container>
                    <Container>
                      <h3>Conseils et astuces</h3>
                      <ul className="article-list">
                        <li>
                          <a href="#">Tutoriels vidéos Slack</a>
                        </li>
                        <li>
                          <a href="#">Réduire les perturbations sur Slack</a>
                        </li>
                        <li>
                          <a href="#">
                            Configurer Slack selon vos horaires de travail
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            Configurer Slack lorsque vous partez en congés
                          </a>
                        </li>
                        <li>
                          <a href="#">Listes de choses à faire sur Slack</a>
                        </li>
                        <li>
                          <a href="#">Associer vos outils à Slack</a>
                        </li>
                        <li>
                          <a href="#">
                            <b className="highlight">Créer</b> des directives
                            pour les
                            <b className="highlight"> noms</b> des
                            <b className="highlight"> canaux</b>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            Présentation de
                            <b className="highlight"> Slackbot</b>
                          </a>
                        </li>
                      </ul>
                      <hr className="my-2" style={{ paddingBottom: '20px' }} />
                    </Container>
                    <Container>
                      <h3>Stratégies efficaces</h3>
                      <ul className="article-list">
                        <li>
                          <a href="#">
                            <b className="highlight">Slack pour les équipes </b>
                            - Un guide pour la réussite
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            Améliorer la
                            <b className="highlight">
                              {' '}
                              culture d’entreprise{' '}
                            </b>{' '}
                            grâce à Slack
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            Organiser une journée de formation Slack
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <b className="highlight">Conseils</b> de sécurité
                            pour protéger votre espace de travail
                          </a>
                        </li>
                        <li>
                          <a href="#">Passer de l’e-mail à Slack</a>
                        </li>
                        <li>
                          <a href="#">
                            Organiser rapidement les tâches et définir des
                            priorités grâce aux
                            <b className="highlight"> canaux</b> de triage
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            Recommandations de sécurité pour l’approbation
                            d’applications
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            Guide des
                            <b className="highlight"> Discovery API </b> de
                            Slack
                          </a>
                        </li>
                      </ul>
                    </Container>
                  </section>
                </Col>
              </Row>
            </PageSection>
          </Page>
        </div>
      </main>
      <div
        className={`aside-backdrop ${collapsed && 'show'}`}
        onClick={() => setCollapsed(!collapsed)}
        role="button"
        tabIndex={0}
      />
    </div>
  );
};

export default MenuIndex;
