import { AxiosRequestConfig } from 'axios';

export enum ApiRequestMethod {
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
}

export type ApiRequestHeaders = Record<string, string>;

export type ApiRequestParams = AxiosRequestConfig['params'];

export type ApiRequestSignal = AxiosRequestConfig['signal'];
