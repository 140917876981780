import { TableCell } from '@mui/material';

export const cellsWrap = (
  cells: JSX.Element[],
  size = '100px'
): JSX.Element[] =>
  cells.map((cell) => (
    <TableCell
      key={cell.key}
      align="left"
      sx={{
        height: `${size} !important`,
        maxHeight: `${size} !important`,
        minHeight: `${size} !important`,
        width: `${size} !important`,
        maxWidth: `${size} !important`,
      }}
    >
      {cell}
    </TableCell>
  ));
