import { useState, useEffect, useRef, useCallback } from 'react';
import { t } from '@lingui/macro';
import { Stack, Typography, Paper } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import { useBreadcrumbsContext, useAppModeContext } from '../../../utils/hooks';
import { Loading } from '../../../../lib/apptheme';
import agencyDefaultImage from '../../../assets/images/agencyDefaultImage.png';
import {
  MACHINE_FIELDS_TABLE,
  JOBSITE_FIELDS_TABLE,
  getEquipmentDescription,
} from '../../../utils/utils';
import { getAgencyJobSites } from '../../../api/jobsites/JobSites';
import { updateAgencyPosition } from '../../../api/agencies/Agencies';
import {
  TabButton,
  TableWithPagination,
  EquipmentContentDetails,
} from '../../../components/molecules';
import AgencyCarte from './components/AgencyCarte/AgencyCarte';
import AgencyGeozone from './components/AgencyGeozone/AgencyGeozone';
import PositionModifier from '../../../../components/PositionModifier/PositionModifier';
import { useAgenciesContext } from '../../../contexts/AgenciesContext';
import { useMachinesContext } from '../../../contexts/MachinesContext';
import IsMobile from '../../../utils/IsMobile';
import SimpleMachineRow from '../EquipmentRow/components/SimpleMachineRow/SimpleMachineRow';
import JobSiteRow from '../EquipmentRow/components/JobSiteRow/JobSiteRow';
import AgencyInformation from './components/AgencyInformation/AgencyInformation';
import AgencyInformationContact from './components/AgencyInformationContact/AgencyInformationContact';

import './EquipmentAgency.scss';

const AgencyContent = (content, description) => (
  <EquipmentContentDetails
    details={{
      image: agencyDefaultImage,
      description,
    }}
    content={content}
  />
);

const EquipmentAgency = ({ equipment }) => {
  const history = useHistory();
  const isMobile = IsMobile();
  const { id } = useParams();
  const [multipleLocation, setMultipleLocation] = useState({
    oneChecked: false,
    jobsites: false,
    machines: false,
  });
  const [agency, setAgency] = useState();
  const [agencyRow, setAgencyRow] = useState();
  const [machinesRow, setMachinesRow] = useState();
  const [jobSitesRow, setJobSitesRow] = useState();
  const [isLoading, setLoading] = useState(true);
  const [displayZone, setDisplayZone] = useState(false);

  const newPosition = useRef({ lat: 0, lon: 0 });

  // for agency geo zone
  const [displayRegions, setDisplayRegions] = useState(false);
  const [displayDepartments, setDisplayDepartments] = useState(false);
  const [geoZonesId, setGeoZonesId] = useState([]);

  const [, setBreadcrumbsList] = useBreadcrumbsContext();
  const { getAgency } = useAgenciesContext();
  const { getMachinesByAgency } = useMachinesContext();

  const { isInternalAppMode } = useAppModeContext();

  const options = [5, 10, 20, 50];

  useEffect(() => {
    if (agencyRow && machinesRow && jobSitesRow) {
      setLoading(false);
    }
  }, [agencyRow, machinesRow, jobSitesRow]);

  useEffect(() => {
    const agency = getAgency(id);

    if (agency) {
      agency.state = 'Agences';

      newPosition.current = { lat: agency.lat, lon: agency.lon };
      setAgency(agency);
      setAgencyRow([agency]);
    }
  }, [getAgency, id]);

  useEffect(() => {
    const fetchData = async () => {
      const [machines, jobsitesResp] = await Promise.all([
        getMachinesByAgency(id),
        getAgencyJobSites(id),
      ]);

      machines.forEach((el) => {
        // eslint-disable-next-line no-param-reassign
        el.state = 'Machines';
      });
      setMachinesRow(machines);

      jobsitesResp.forEach((el) => {
        // eslint-disable-next-line no-param-reassign
        el.state = 'Chantiers';
      });
      setJobSitesRow(jobsitesResp);
    };

    fetchData();

    return () => {
      setMachinesRow([]);
      setJobSitesRow([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const breadCrumbsData = [
      {
        label: t`Tableau de bord`,
        to: '/',
      },
      {
        label: t`Agences`,
        to: '/equipments/list',
      },
      {
        label: t`Details`,
        to: '/equipments/details',
      },
    ];
    setBreadcrumbsList(breadCrumbsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAgencyRow((prevRow) =>
      (prevRow ?? []).map((item) => (item.id === id ? agency : item))
    );
  }, [id, agency]);

  const handleHistoryPush = (path, id) => {
    history.push(`/equipments/${path}/${id}`);
  };

  const openingTimes = {
    morning: {
      opening: '06h30',
      openingDate: new Date().setHours('06', '30'),
      closing: '12h00',
      closingDate: new Date().setHours('12', '00'),
    },
    afternoon: {
      opening: '13h30',
      openingDate: new Date().setHours('13', '30'),
      closing: '18h00',
      closingDate: new Date().setHours('18', '00'),
    },
    open: t`Ouvert jusqu'à`,
    close: t`Fermé jusqu'à`,
  };

  const handleEquipmentFilter = (toggleValue, type) => {
    const isJobSite = type === 'jobsite';

    if (toggleValue) {
      setMultipleLocation((state) => ({
        ...state,
        oneChecked: true,
        jobsites: isJobSite ? true : state.jobsites,
        machine: !isJobSite ? true : state.machine,
      }));

      setAgencyRow(
        (isJobSite ? [agencyRow, jobSitesRow] : [agencyRow, machinesRow]).flat()
      );
    } else {
      setMultipleLocation((state) => ({
        ...state,
        oneChecked: isJobSite
          ? multipleLocation.machines
          : multipleLocation.jobsites,
        jobsites: isJobSite ? false : state.jobsites,
        machines: !isJobSite ? false : state.machines,
      }));

      if (isJobSite) {
        if (multipleLocation.machines) {
          setAgencyRow([agency, machinesRow].flat());
        } else {
          setAgencyRow([agency].flat());
        }
      } else if (multipleLocation.jobsites) {
        setAgencyRow([agency, jobSitesRow].flat());
      } else {
        setAgencyRow([agency].flat());
      }
    }
  };

  const updatePosition = () => {
    const { lat, lon } = newPosition.current;

    updateAgencyPosition(id, lat, lon).then((resp) => {
      setAgency((prevAgency) => ({
        ...prevAgency,
        lat: resp.lat,
        lon: resp.lon,
      }));
    });
  };

  const handleGeoZoneClicked = (id) => {
    setGeoZonesId((zonesId) => {
      return zonesId.includes(id)
        ? zonesId.filter((item) => item !== id)
        : [...zonesId, id];
    });
  };

  const machineRowCells = useCallback(
    (machine) => (
      <SimpleMachineRow
        machine={machine}
        handleHistoryPush={() => handleHistoryPush('detail', machine.id)}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const jobsiteRowCells = useCallback(
    (jobsite) => (
      <JobSiteRow
        jobSite={jobsite}
        handleHistoryPush={() => handleHistoryPush('jobsite', jobsite.id)}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return isLoading || !agency ? (
    <Loading />
  ) : (
    <Stack className="equipment-agency" spacing={2}>
      <Typography variant="h1">
        {getEquipmentDescription(agency, agency?.name || 'unknow')}
      </Typography>
      <TabButton
        tab={[
          {
            content: AgencyContent(
              <AgencyInformation agency={agency} openingTimes={openingTimes} />,
              [<AgencyInformationContact />]
            ),
            label: t`Informations`,
          },
          {
            content: AgencyContent(
              <AgencyCarte
                agency={agency}
                multipleLocation={multipleLocation}
                agencyRow={agencyRow}
                machinesRow={machinesRow}
                jobSitesRow={jobSitesRow}
                displayZone={displayZone}
                setAgencyRow={setAgencyRow}
                setMultipleLocation={setMultipleLocation}
                setDisplayZone={setDisplayZone}
                handleEquipmentFilter={handleEquipmentFilter}
                handleMarkerDrag={(newCoords) => {
                  newPosition.current = newCoords;
                }}
                geoZone={{
                  displayRegions,
                  displayDepartments,
                  geoZoneIds: geoZonesId,
                  handleGeoZoneClicked,
                }}
              />,
              isInternalAppMode
                ? [
                    <AgencyGeozone
                      agencyId={id}
                      geoZonesId={geoZonesId}
                      displayRegions={displayRegions}
                      displayDepartments={displayDepartments}
                      setDisplayRegions={(v) => {
                        setDisplayRegions(v);
                        setGeoZonesId([]);
                      }}
                      setDisplayDepartments={(v) => {
                        setDisplayDepartments(v);
                        setGeoZonesId([]);
                      }}
                    />,
                    <PositionModifier
                      handleIsModifying={(isDraggable) =>
                        setAgency((prevAgency) => ({
                          ...prevAgency,
                          isDraggable,
                        }))
                      }
                      handleSave={updatePosition}
                    />,
                  ]
                : []
            ),
            label: t`Carte`,
          },
          {
            content: AgencyContent(
              <Paper className="equipment-agency__map-tab" spacing={2}>
                <TableWithPagination
                  title={t`Machines`}
                  description={t`Nombre de machines`}
                  color="primary.main"
                  headers={[t`Illustration`, t`Machine`, t`Location`, '']}
                  rows={machinesRow}
                  cells={machineRowCells}
                  optionsValue={options}
                  searchFields={!isMobile ? MACHINE_FIELDS_TABLE : null}
                  checkboxHeader={
                    equipment.headersCheckbox.filter((e) => !e.checkboxes) ||
                    null
                  }
                  columnsObject={equipment.columnsObject}
                />
              </Paper>
            ),
            label: t`Machines`,
          },
          {
            content: AgencyContent(
              <Paper className="equipment-agency__map-tab" spacing={2}>
                <TableWithPagination
                  title={t`Chantiers`}
                  description={t`Nombre de chantiers`}
                  color="primary.main"
                  headers={[t`Nom`, t`Adresse`, t`Date`, '']}
                  rows={jobSitesRow}
                  cells={jobsiteRowCells}
                  optionsValue={options}
                  searchFields={!isMobile ? JOBSITE_FIELDS_TABLE : null}
                />
              </Paper>
            ),
            label: t`Chantiers`,
          },
        ]}
      />
    </Stack>
  );
};

export default EquipmentAgency;
