/* eslint-disable no-param-reassign */
import { equipmentsOptions } from '../../Equipment.service';
import { keysName } from '../../../constants';
import * as iconMachineOn from '../../../../../assets/images/Icon_Machine_On.svg';
import * as iconMachineOff from '../../../../../assets/images/Icon_Machine_Off.svg';
import * as iconAgencyOpen from '../../../../../assets/images/Icon_Agency_Open.svg';
import * as iconAgencyClosed from '../../../../../assets/images/Icon_Agency_Closed.svg';
import * as iconJobSite from '../../../../../assets/images/Icon_JobSite.svg';
import * as iconGoobie from '../../../../../assets/images/goobie/Pins_Machine_Antivol.svg';
import getMachineStatusCount from '../../../../../utils/equipment/machine/getMachineStatusCount';
import { isDateBellow24h } from '../../../../../utils/date/calcul';

export const handleMachines = (
  machines: any[],
  jobSites: any[],
  agencies: any[]
) => {
  const machinesState = getMachineStatusCount(machines);

  machines.forEach((el) => {
    el.state = keysName.Machines;
    el.page = equipmentsOptions.find(
      (el) => el.value === keysName.Machines
    )?.page;
    el.f4Icon = {
      on: iconMachineOn,
      off: iconMachineOff,
    };

    if (el.engineRunning === null && !isDateBellow24h(el.lastSyncTime)) {
      el.engineRunning = null;
    }

    addAgencyAndJobSiteLocation(el, agencies, jobSites);
  });

  const goobies = handleGoobies(machines);

  return {
    machines: {
      data: machines,
      ...machinesState,
    },
    goobies: {
      data: goobies,
    },
    // Save all machines because a filter will be apply on machines list for the machines group
    allMachines: [...machines, ...goobies],
  };
};

export const handleJobSites = (jobSites: any[]) => {
  jobSites.forEach((el) => {
    el.state = keysName.JobSites;
    el.page = equipmentsOptions.find(
      (el) => el.value === keysName.JobSites
    )?.page;
    el.f4Icon = {
      open: iconAgencyOpen,
      closed: iconAgencyClosed,
    };
  });

  return {
    jobSites,
  };
};

export const handleAgencies = (agencies: any[]) => {
  agencies.forEach((el) => {
    el.state = keysName.Agencies;
    el.page = equipmentsOptions.find(
      (el) => el.value === keysName.Agencies
    )?.page;
    el.f4Icon = iconJobSite;
  });

  return {
    agencies,
  };
};

export const handleGoobies = (machines: any[]) => {
  return machines
    .filter((el) => el.gbLong && el.gbLat)
    .map((el) => ({
      ...el,
      lat: el.gbLat,
      lon: el.gbLong,
      f4Icon: iconGoobie,
      state: 'goobie',
    }));
};

const addAgencyAndJobSiteLocation = (
  machine: any,
  agencies: any[],
  jobSites: any[]
) => {
  const agency = agencies.find((e: any) => e.id === machine.agencyId);
  const jobSite = jobSites.find((e: any) => e.id === machine.jobSiteId);

  machine.agencyLocation = agency ? `${agency.lat},${agency.lon}` : '';
  machine.jobSiteLocation = jobSite ? `${jobSite.lat},${jobSite.lon}` : '';

  return machine;
};
