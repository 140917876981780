import { t } from '@lingui/macro';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { Agency } from '../../../../../api/agencies/Agency.interface';
import { cellsWrap } from '../../../../../utils/table/table';
import {
  capitalizeOrDefault,
  getEquipmentDescription,
} from '../../../../../utils/utils';

import './AgencyRow.scss';

type AgencyAddressProps = {
  city: Agency['city'];
  streetAdress: Agency['streetAdress'];
  streetNumber: Agency['streetNumber'];
};
type AgencyEmailProps = { email: Agency['email'] };
type AgencyNumberProps = { rentalmanId: Agency['rentalmanId'] };
type AgencyRowProps = {
  agency: Agency;
  handleHistoryPush: () => void;
  isMobile: boolean;
};

const AgencyAddress: React.FC<AgencyAddressProps> = ({
  city,
  streetAdress,
  streetNumber,
}) => (
  <Typography>
    {`
  ${capitalizeOrDefault(city)},
  ${capitalizeOrDefault(streetAdress)},
  ${capitalizeOrDefault(streetNumber)}
`}
  </Typography>
);

const AgencyEmail: React.FC<AgencyEmailProps> = ({ email }) => (
  <Typography>{email || '-'}</Typography>
);

const AgencyNumber: React.FC<AgencyNumberProps> = ({ rentalmanId }) => (
  <Typography>{rentalmanId || '-'}</Typography>
);

const AgencyRow = ({ agency, handleHistoryPush, isMobile }: AgencyRowProps) => {
  const cells = isMobile
    ? [
        <Stack
          key="agency-row"
          className="agency-row"
          spacing={1}
          onClick={handleHistoryPush}
        >
          <Typography className="agency-row__name">
            {getEquipmentDescription(agency, agency.name) || '-'}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Typography fontWeight="500">{t`Adresse`}:</Typography>
            <AgencyAddress
              key="agency-address"
              city={agency.city}
              streetAdress={agency.streetAdress}
              streetNumber={agency.streetNumber}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography fontWeight="500">{t`Email`}:</Typography>
            <AgencyEmail key="agency-email" email={agency.email} />
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography fontWeight="500">{t`Numéro d'agence`}:</Typography>
            <AgencyNumber
              key="agency-number"
              rentalmanId={agency.rentalmanId}
            />
          </Stack>
        </Stack>,
      ]
    : [
        <Typography key="agency-equipment-description">
          {getEquipmentDescription(agency, agency.name) || '-'}
        </Typography>,
        <AgencyAddress
          key="agency-address"
          city={agency.city}
          streetAdress={agency.streetAdress}
          streetNumber={agency.streetNumber}
        />,
        <AgencyEmail key="agency-email" email={agency.email} />,
        <AgencyNumber key="agency-number" rentalmanId={agency.rentalmanId} />,
        <Stack
          key="agency-access-time"
          direction="row"
          alignItems="center"
          gap={1}
        >
          <AccessTimeIcon />
          <Typography>-</Typography>
        </Stack>,
        <Button
          key="agency-details-button"
          variant="outlined"
          size="large"
          onClick={handleHistoryPush}
        >
          <Typography fontSize={12} fontWeight={600}>
            {t`Voir Détails`}
          </Typography>
        </Button>,
      ];

  return cellsWrap(cells);
};

export default AgencyRow;
