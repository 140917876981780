import { postRequest, getRequest } from '../utils/axios.utils';

export const login = (email, password) => {
  return postRequest(`login`, {
    email,
    password,
  });
};

export const logout = () => {
  return getRequest(`logout`);
};

export const getUserLogged = () => {
  return getRequest(`rememberme`);
};

export const resetPassword = (token, password) => {
  return postRequest(`pwd/process/reset`, {
    token,
    password,
  });
};

export const sendPasswordResetEmail = (email) => {
  return postRequest(`pwd/ask/reset/${email}`);
};
