/* eslint-disable no-undef */
import { getArrayMaxOrMinValue } from '../utils';

const LAT_KEY = 'lat';
const LON_KEY = 'lon';

export const getBound = (equipments) => {
  const minLon = getArrayMaxOrMinValue(equipments, LON_KEY, 'min');
  const minLat = getArrayMaxOrMinValue(equipments, LAT_KEY, 'min');
  const maxLon = getArrayMaxOrMinValue(equipments, LON_KEY, 'max');
  const maxLat = getArrayMaxOrMinValue(equipments, LAT_KEY, 'max');

  const sw = new f4.map.LatLng(minLat, minLon);
  const ne = new f4.map.LatLng(maxLat, maxLon);
  return new f4.map.LatLngBounds(sw, ne);
};
