import { Button } from '@mui/material';

const SimpleButton = ({ text, onClick, className, type = 'contained' }) => {
  return (
    <Button className={className} variant={type} onClick={onClick}>
      {text}
    </Button>
  );
};

export default SimpleButton;
