import { t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  getAllMachinesGpsGoobiesGeoJson,
  getTrackerFromMachineId,
} from '../../api/machines/Machines';
import goobieStandardPin from '../../assets/images/goobie/Pins_Machine_Antivol_Disconnected.png';
import { MapAndListButtons } from '../equipments';
import MapGeoJSONData from './components/MapGeoJSONData';

export const TrackersMap = () => {
  // fetch machine paired to trackers and get gps pos
  const [machines, setMachines] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const fetchMachines = async () => {
      const response = await getAllMachinesGpsGoobiesGeoJson();
      setMachines({
        type: 'FeatureCollection',
        features: response,
      });
    };

    fetchMachines();
  }, []);
  const handleTrackerClick = (trackerId) => {
    history.push(`/trackers/${trackerId}`);
  };
  const OpenDetailsPopup = async (id, map, coordinates) => {
    /*  const trackerDetails = await getTrackerFromMachineId(id);
     new mapboxgl.Popup()
      .setLngLat(coordinates)
      .setHTML(
        renderToString(<MachineTrackerInfoPopup item={trackerDetails} />)
      )
      .addTo(map); */
    const detailsLink = document.querySelectorAll(`.linkToDetailsTracker`);
    detailsLink.forEach((el) => {
      el.addEventListener('click', (e) => {
        handleTrackerClick(e.target.id);
      });
    });
  };
  return (
    <>
      <Typography variant="h1">{t`Machines liées à un tracker`}</Typography>
      <MapAndListButtons
        current="/trackers/map"
        firstPath="/trackers/map"
        secondPath="/trackers/list"
      />
      <MapGeoJSONData
        dataGeoJSON={machines}
        pinImage={goobieStandardPin}
        openDetailsComponent={OpenDetailsPopup}
      />
    </>
  );
};

export default TrackersMap;
