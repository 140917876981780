import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Checkbox,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import './CardTableDetails.scss';

const CardTableDetails = ({
  header,
  rows,
  maxHeight,
  checkboxType,
  checkboxState,
  alternateBg,
}) => {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const { all, setAll, checkedStates, onCheckboxClick } = checkboxState;

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor:
        alternateBg &&
        (theme.palette.light ? theme.palette.light.bgcolor : '#F0EDEE'),
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <TableContainer className="card-table-details" sx={{ maxHeight }}>
      <Table
        className="card-table-details__table"
        sx={{
          borderSpacing: !checkboxType && '0 8px',
          borderCollapse: !checkboxType && 'separate',
        }}
        aria-label="simple table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            {checkboxType && (
              <TableCell size="small" sx={{ bgcolor: 'tableheader.bgcolor' }}>
                <Checkbox
                  {...label}
                  size="large"
                  sx={{ color: 'background.light' }}
                  checked={all}
                  onChange={setAll}
                />
              </TableCell>
            )}
            {header.map((cell) => (
              <TableCell size="small" sx={{ bgcolor: 'tableheader.bgcolor' }}>
                <Typography variant="body1Bold" color="tableheader.color">
                  {cell}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow
              className="card-table-details__body-row"
              component={Paper}
            >
              {checkboxType && checkedStates && (
                <TableCell align="left">
                  <Checkbox
                    {...label}
                    size="large"
                    sx={{ color: 'primary.main' }}
                    checked={checkedStates[index]?.checked}
                    onChange={() => onCheckboxClick(checkedStates[index])}
                  />
                </TableCell>
              )}
              {row.cells.map((cell) => (
                <TableCell align="left">{cell}</TableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CardTableDetails.propTypes = {
  /**
   * Header Data
   */
  header: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * Table Data
   */
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      cells: PropTypes.arrayOf(PropTypes.element).isRequired,
    })
  ).isRequired,
  /**
   * Max Height
   */
  maxHeight: PropTypes.string,
  /**
   * Checkbox Type
   */
  checkboxType: PropTypes.bool,
  /**
   * Checkbox State
   */
  checkboxState: PropTypes.shape({
    all: PropTypes.bool.isRequired,
    setAll: PropTypes.func.isRequired,
    checkedStates: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        checked: PropTypes.bool.isRequired,
      })
    ).isRequired,
    onCheckboxClick: PropTypes.func.isRequired,
  }),
  /**
   * alternateBg
   */
  alternateBg: PropTypes.bool,
};

CardTableDetails.defaultProps = {
  maxHeight: null,
  checkboxType: false,
  checkboxState: {
    all: false,
    setAll: null,
    checkedStates: null,
    onCheckboxClick: null,
  },
  alternateBg: false,
};

export default CardTableDetails;
