import { Children, cloneElement, useEffect, useState } from 'react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { useHistory } from 'react-router-dom';
import { Table as TableStrap } from 'reactstrap';

const Table = (props) => {
  const {
    hover,
    children,
    data,
    isLoading,
    siteFilter,
    initialData,
    catFilter,
  } = props;

  const siteData = [];
  const typeData = [];

  for (let i = 0; i < initialData.length; i += 1) {
    if (!siteData.includes(initialData[i].site)) {
      siteData.push(initialData[i].site);
    }
    if (!typeData.includes(initialData[i].categorie)) {
      typeData.push(initialData[i].categorie);
    }
  }
  const newSiteData = siteData.map((index) => ({ value: index, label: index }));
  const newTypeData = typeData.map((index) => ({ value: index, label: index }));

  const history = useHistory();

  const handleRowClick = (id) => {
    if (hover) {
      history.push(`${hover.path}/${id}`);
    }
  };

  const hoverr = !!hover;

  const [selectedOptionsSite, setSelectedOptionsSite] = useState([]);
  const [selectedOptionsCat, setSelectedOptionsCat] = useState([]);

  useEffect(() => {
    setSelectedOptionsSite([{ label: 'Tous', value: '*' }, ...newSiteData]);
    setSelectedOptionsCat([{ label: 'Tous', value: '*' }, ...newTypeData]);
    // TODO - fix eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === '*')) {
      /* eslint-disable-next-line */
      return <b>{placeholderButtonLabel}: Tous</b>
    }
    return `${placeholderButtonLabel}: ${value.length} ${
      value.length > 1 ? 'selectionnés' : 'selectionné'
    }`;
  }

  function onChangeConstructionSite(value, event) {
    let newValue = [];
    if (event.action === 'select-option' && event.option.value === '*') {
      newValue = [{ label: 'Tous', value: '*' }, ...newSiteData];
    } else if (
      event.action === 'deselect-option' &&
      event.option.value === '*'
    ) {
      newValue = [];
    } else if (event.action === 'deselect-option') {
      newValue = value.filter((o) => o.value !== '*');
    } else if (value.length === newSiteData.length) {
      newValue = [{ label: 'Tous', value: '*' }, ...newSiteData];
    } else {
      newValue = value;
    }
    setSelectedOptionsSite(newValue);
    siteFilter(newValue, selectedOptionsCat);
  }

  function onChangeMachineCat(value, event) {
    let newValue = [];
    if (event.action === 'select-option' && event.option.value === '*') {
      newValue = [{ label: 'Tous', value: '*' }, ...newTypeData];
    } else if (
      event.action === 'deselect-option' &&
      event.option.value === '*'
    ) {
      newValue = [];
    } else if (event.action === 'deselect-option') {
      newValue = value.filter((o) => o.value !== '*');
    } else if (value.length === newTypeData.length) {
      newValue = [{ label: 'Tous', value: '*' }, ...newTypeData];
    } else {
      newValue = value;
    }
    setSelectedOptionsCat(newValue);
    catFilter(newValue, selectedOptionsSite);
  }

  const minHeight = `${(initialData.length + 1) * 40}px`;
  return (
    <div className="table-responsive" style={{ minHeight }}>
      <TableStrap hover={hoverr}>
        <thead>
          <tr className="flat-label">
            {Children.map(children, (child) => {
              if (child.props.label === 'Catégorie de la machine') {
                return (
                  <th
                    style={
                      child.props.action && {
                        width: '100px',
                        minWidth: '100px',
                      }
                    }
                  >
                    <ReactMultiSelectCheckboxes
                      options={[{ label: 'Tous', value: '*' }, ...newTypeData]}
                      placeholderButtonLabel={child.props.label}
                      value={selectedOptionsCat}
                      getDropdownButtonLabel={getDropdownButtonLabel}
                      onChange={onChangeMachineCat}
                      setState={setSelectedOptionsCat}
                      hideSearch
                    />
                  </th>
                );
              }
              if (child.props.label === 'Chantier') {
                return (
                  <th
                    style={
                      child.props.action && {
                        width: '100px',
                        minWidth: '100px',
                      }
                    }
                  >
                    <ReactMultiSelectCheckboxes
                      options={[{ label: 'Tous', value: '*' }, ...newSiteData]}
                      placeholderButtonLabel={child.props.label}
                      value={selectedOptionsSite}
                      getDropdownButtonLabel={getDropdownButtonLabel}
                      onChange={onChangeConstructionSite}
                      setState={setSelectedOptionsSite}
                      hideSearch
                    />
                  </th>
                );
              }
              return (
                <th
                  style={
                    child.props.action && { width: '100px', minWidth: '100px' }
                  }
                >
                  {child.props.label ? child.props.label : ' '}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {!isLoading ? (
            data.length > 0 ? (
              data.map((item, index) =>
                hover ? (
                  <tr key={index} onClick={() => handleRowClick(item.id)}>
                    {Children.map(children, (child, index) => (
                      <td
                        key={index}
                        className={`align-middle ${
                          child.props.action && 'text-right'
                        }`}
                      >
                        {cloneElement(child, {
                          data: child.props.name
                            ? item[child.props.name]
                            : item,
                        })}
                      </td>
                    ))}
                  </tr>
                ) : (
                  <tr key={index}>
                    {Children.map(children, (child, index) => (
                      <td
                        key={index}
                        className={`align-middle ${
                          child.props.action && 'text-right'
                        }`}
                      >
                        {cloneElement(child, {
                          data: child.props.name
                            ? item[child.props.name]
                            : item,
                        })}
                      </td>
                    ))}
                  </tr>
                )
              )
            ) : (
              <tr>
                <td colSpan={Children.count(children)}>
                  Pas encore d&apos;éléments
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan={Children.count(children)}>Chargement en cours...</td>
            </tr>
          )}
        </tbody>
      </TableStrap>
    </div>
  );
};

Table.defaultProps = {
  isLoading: false,
};

export default Table;
