import { t } from '@lingui/macro';
import { Box, Stack, Typography } from '@mui/material';
import CustomizedSwitches from '../../../../../../components/CustomizedSwitches/CustomizedSwitches';
import IconInfoBar from '../../../../../components/molecules/IconInfoBar/IconInfoBar';
import MuiCustomCalendar from '../../../../../components/organisms/MuiCustomCalendar/MuiCustomCalendar';
import { calendarCategories } from '../../../../../components/organisms/MuiCustomCalendar/utils';
import ChartBarMachineUsage from '../ChartBarMachineUsage/ChartBarMachineUsage';
import GraphLegend from '../../../../../components/organisms/MuiCustomCalendar/GraphLegend/GraphLegend';

import './CalendarAndMachUsageGraph.scss';

const MeteoBar = ({ meteo }: any) => {
  return (
    <Stack direction="row" className="calendar-mach-usage-graph__meteo-bar">
      {meteo && meteo.weather_icons && (
        <IconInfoBar
          icon={
            <img
              className="calendar-mach-usage-graph__meteo-bar-image"
              alt="weather_icons"
              src={meteo.weather_icons[0]}
            />
          }
          content={
            <Typography className="calendar-mach-usage-graph__meteo-bar-desc">
              {t`Température`} : {meteo.temperature}° | {t`Vent`} :{' '}
              {meteo.wind_speed} km/h
            </Typography>
          }
        />
      )}
    </Stack>
  );
};

const CalendarAndMachUsageGraph = ({
  period,
  machineUsage,
  machineUsageTotalDuration,
  setSelectedDate,
  meteo,
  chartBarData,
  displayOutOfContract,
  setDisplayOutOfContract,
  isUsageOutOfContract,
  periodType,
  handlePeriodType,
  graphDisplayInMinutes,
  selectedCalendarFilter,
}: any) => {
  return (
    <Box className="calendar-mach-usage-graph">
      <MuiCustomCalendar
        title="Utilisation détaillée de la machine"
        startDate={new Date(period.start)}
        endDate={new Date(period.end)}
        data={machineUsage}
        setSelectedDate={setSelectedDate}
        periodType={periodType}
        handlePeriodType={handlePeriodType}
      />

      <Stack alignSelf="center">
        <GraphLegend legends={calendarCategories} />
      </Stack>

      <MeteoBar meteo={meteo} />
      {chartBarData?.datasets?.length > 0 && (
        <>
          <Stack
            direction="row"
            className="calendar-mach-usage-graph__usage-switch"
          >
            <CustomizedSwitches
              text={
                <Typography variant="h6">{t`Afficher les utilisations hors horaires contrat`}</Typography>
              }
              onToggle={(value: boolean) => setDisplayOutOfContract(value)}
              value={displayOutOfContract}
              disabled={!isUsageOutOfContract}
            />
          </Stack>
          <ChartBarMachineUsage
            chartBarData={chartBarData}
            totalValue={machineUsageTotalDuration}
            graphDisplayInMinutes={graphDisplayInMinutes}
            selectedCalendarFilter={selectedCalendarFilter}
          />
        </>
      )}
    </Box>
  );
};

export default CalendarAndMachUsageGraph;
