import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import moment from 'moment';
import { t } from '@lingui/macro';
import PeriodContext from '../../utils/PeriodContext';
import PrivateRoute from '../../utils/PrivateRoute';
import SiteContext from '../../utils/SiteContext';
import { getSessionsSummary } from '../../api/sessions/Sessions';
import { Loading } from '../../../lib/apptheme';
import { HeaderHelpPages } from '../../components';
import {
  Equipment,
  EquipmentAgency,
  EquipmentDetail,
  EquipmentJobSite,
  EquipmentListAndMap,
  OnBoarding,
  Settings,
} from '../../pages';
import { HowToUse, MenuIndex, QuestionAnswer } from '../../pages/HelpPages';
import { HomePage } from '../../components/pages';
import { MainContainer } from '../../components/Layouts';
import BreadCrumbsContext from '../../utils/BreadcrumbsContext';
import { useUserContext } from '../../contexts/UserContext/UserContext';
import Security from '../../pages/Security/Security';
import Report from '../../pages/report/Report';

import '../../assets/scss/styles.scss';
import { Trackers } from '../../pages/trackers/Trackers';

const helpAsidemenu = [
  {
    id: 'dashboard',
    type: 'link',
    text: t`Tableau de bord`,
    link: '/',
  },
  {
    id: 'howtouse',
    type: 'link',
    text: t`Premiers pas`,
    link: '/help-how-to-use',
  },
  {
    id: 'qa',
    type: 'link',
    text: t`Utiliser LoxSafe`,
    link: '/help-index',
  },
];

const App = () => {
  const user = useUserContext();
  const [isLoading, setLoading] = useState(true);
  const [site, setSite] = useState(null);
  const [period, setPeriod] = useState({
    start: moment().startOf('day'),
    end: moment().endOf('day'),
  });
  const [breadcrumbsList, setBreadcrumbs] = useState([]);
  const [machines, setMachines] = useState(0);
  const [metrics, setMetrics] = useState(0);

  useEffect(() => {
    const fetchSessionsSummary = async () => {
      try {
        setLoading(true);
        const params = {
          metricTypes: ['3660', '3661', '4873', '10025'],
          startDate: period.start.toISOString(),
          endDate: period.end.toISOString(),
        };

        if (user) {
          const sessionsSummary = await getSessionsSummary(params);

          console.log(sessionsSummary);
          const reducer = (previousValue, currentValue) => ({
            totalMachines:
              previousValue.totalMachines + currentValue.totalMachines,
            totalMetrics:
              previousValue.totalMetrics + currentValue.totalMetrics,
          });
          const { totalMetrics, totalMachines } =
            sessionsSummary.reduce(reducer);
          setMetrics(totalMetrics);
          setMachines(totalMachines);
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchSessionsSummary();
  }, [period, user]);

  const entries = [
    {
      icon: 'home',
      title: t`Accueil`,
      description: t`Page d'accueil de l'application`,
      showNotificationsPanel: false,
      href: '/',
      value: '/',
    },
    {
      icon: 'list',
      title: t`Flotte`,
      description: t`Ensemble des machines connectées en cours de location et des chantiers.`,
      showNotificationsPanel: false,
      href: '/equipments/map',
      value: 'equipments',
    },
    {
      icon: 'verified_user',
      title: t`Sécurité`,
      description: t`Ensemble des alertes sur vos machines connectées.`,
      notifications: machines,
      alerts: metrics,
      showNotificationsPanel: true,
      // href: '/security',
      value: 'security',
      disabled: true,
    },
    {
      icon: 'spa',
      title: t`Environnement`,
      description: t`Empreinte environnementale de vos machines connectées et chantiers.`,
      disabled: true,
      value: 'environnement',
    },
    {
      icon: 'description',
      title: t`Rapport`,
      description: t`Vos rapports d'activité.`,
      disabled: true,
    },
    {
      icon: 'notification_important',
      title: t`Notifications`,
      description: t`Vos notifications d'activité.`,
      href: '/notifications',
      value: 'notifications',
      disabled: true,
    },
    {
      icon: 'settings_applications',
      title: t`Paramètres`,
      description: t`Vos paramètres.`,
      href: '/settings',
      value: 'settings',
      disabled: false,
    },
  ];

  const filteredEntries = entries.filter((entry) => {
    if (user && entry.roles) {
      return (
        entry.roles
          .map((role) => user.roles[role])
          .filter((role) => role === false).length === 0
      );
    }
    return true;
  });

  const navBarEntries = [...filteredEntries];

  if (filteredEntries.length > 0 && filteredEntries[0].value === '/') {
    filteredEntries.shift();
  }

  return isLoading ? (
    <Loading />
  ) : (
    <BrowserRouter>
      <BreadCrumbsContext.Provider
        value={{
          breadcrumbsList,
          setBreadcrumbsList: (brList) => setBreadcrumbs(brList),
        }}
      >
        <Switch>
          <PrivateRoute exact path="/onboarding" isAuthenticated={!!user}>
            <OnBoarding />
          </PrivateRoute>
          <PrivateRoute exact path="/help-how-to-use" isAuthenticated={!!user}>
            <HowToUse
              asideMenu={helpAsidemenu}
              headerComponent={HeaderHelpPages}
            />
          </PrivateRoute>
          <PrivateRoute exact path="/help-qa" isAuthenticated={!!user}>
            <QuestionAnswer
              asideMenu={helpAsidemenu}
              headerComponent={HeaderHelpPages}
            />
          </PrivateRoute>
          <PrivateRoute exact path="/help-index" isAuthenticated={!!user}>
            <MenuIndex
              asideMenu={helpAsidemenu}
              headerComponent={HeaderHelpPages}
            />
          </PrivateRoute>
          <PrivateRoute path="/" isAuthenticated={!!user}>
            <ToastProvider placement="bottom-right" autoDismiss>
              <SiteContext.Provider
                value={{
                  site,
                  setSite: (site) => setSite(site),
                }}
              >
                <PeriodContext.Provider
                  value={{
                    period,
                    setPeriod: (period) => setPeriod(period),
                  }}
                >
                  <MainContainer links={navBarEntries}>
                    <Switch>
                      <Route
                        exact
                        path="/"
                        render={() => (
                          <HomePage
                            title={t`Mes machines connectées`}
                            links={filteredEntries}
                          />
                        )}
                      />
                      <Route exact path="/equipments/" component={Equipment} />
                      <Route
                        exact
                        path="/equipments/map"
                        component={EquipmentListAndMap}
                      />
                      <Route
                        exact
                        path="/equipments/detail/:id"
                        component={EquipmentDetail}
                      />
                      <Route
                        exact
                        path="/equipments/jobsite/:id"
                        render={EquipmentJobSite}
                      />
                      <Route
                        exact
                        path="/equipments/agencie/:id"
                        render={EquipmentAgency}
                      />
                      <Route path="/settings" component={Settings} />
                      <Route exact path="/security" component={Security} />
                      <Route path="/report" component={Report} />
                    </Switch>
                  </MainContainer>
                </PeriodContext.Provider>
              </SiteContext.Provider>
            </ToastProvider>
          </PrivateRoute>
        </Switch>
      </BreadCrumbsContext.Provider>
    </BrowserRouter>
  );
};

export default App;
