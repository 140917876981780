import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';
import { Loading } from '../../../../lib/apptheme';

import './LoxForm.scss';

const LoxForm = ({ form, onSubmit, status, submitting }) => {
  const firstValues = {};
  if (form.initialValues)
    form.initialValues.forEach((field) => {
      firstValues[field.id] = field.value;
      return firstValues;
    });

  const createValidationRule = (field) => {
    const rules = {};
    if (field.isRequired && field.isRequired === true) {
      rules.required = t`${field.label}: est requis`;
    }
    if (field.type === 'phone') {
      rules.pattern = {
        value: '^((\\+|00)33|0) *[1-9]([ .-]*[0-9]{2}){4}$',
        // phone validation doesn't work
        message: t`${field.label}: n'est pas un numero de téléphone valide`,
      };
    }
    if (field.type === 'email') {
      rules.pattern = {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: t`${field.label}: n'est pas un mail valide`,
      };
    }
    return rules;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: firstValues,
    shouldFocusError: true,
    criteriaMode: 'all',
    reValidateMode: 'onChange',
  });
  const onFormSubmit = (data) => onSubmit(data);
  return (
    <Box className="loxform">
      <form>
        <Stack spacing={2}>
          <Typography
            variant="h3"
            sx={{ color: form.titlePrimary && 'primary.main' }}
          >
            {form.title}
          </Typography>

          <Stack spacing={1}>
            {form.fields &&
              form.fields.map((lxField) => {
                if (
                  lxField.type === 'email' ||
                  lxField.type === 'text' ||
                  lxField.type === 'phone' ||
                  lxField.type === 'number'
                ) {
                  return (
                    <Controller
                      key={`textfield-${lxField.id}`}
                      name={lxField.id}
                      control={control}
                      rules={createValidationRule(lxField)}
                      render={({ field: { onChange, value } }) => (
                        <Box>
                          <TextField
                            className="loxform__textfield"
                            fullWidth
                            id={lxField.id}
                            label={lxField.label}
                            error={errors && errors[lxField.id]}
                            helperText={
                              errors[lxField.id]
                                ? errors[lxField.id].message
                                : null
                            }
                            variant="filled"
                            type={lxField.type}
                            disabled={lxField.disabled}
                            required={lxField.isRequired}
                            value={value}
                            onChange={onChange}
                          />
                        </Box>
                      )}
                    />
                  );
                }
                if (lxField.type === 'select') {
                  return (
                    <Controller
                      key={`select-${lxField.id}`}
                      name={lxField.id}
                      control={control}
                      rules={createValidationRule(lxField)}
                      render={({ field: { onChange, value } }) => (
                        <FormControl
                          className="loxform__select-form"
                          variant="standard"
                          fullWidth
                        >
                          <InputLabel
                            className="loxform__select-form-label"
                            id={`select-${lxField.id}-label`}
                          >
                            {lxField.label}
                          </InputLabel>

                          <Select
                            className="loxform__select-form-select"
                            labelId={`select-${lxField.id}-label`}
                            id={`select-${lxField.id}`}
                            value={value}
                            onChange={onChange}
                            label={lxField.label}
                          >
                            {lxField.options.map((item, index) => {
                              return (
                                <MenuItem
                                  key={`${lxField.id}-${index + 1}`}
                                  value={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      )}
                    />
                  );
                }
                if (lxField.type === 'checkbox') {
                  return (
                    <Controller
                      key={`checkbox-${lxField.id}`}
                      name={lxField.id}
                      control={control}
                      rules={createValidationRule(lxField)}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          className="loxform__checkbox-form"
                          variant="standard"
                          fullWidth
                          control={<Checkbox />}
                          onChange={onChange}
                          label={lxField.label}
                        />
                      )}
                    />
                  );
                }
                return (
                  <Typography color="error.main">
                    <Trans>
                      Type d&apos;attribut non pris en charge: {lxField.type}
                    </Trans>
                  </Typography>
                );
              })}
          </Stack>
          <Typography sx={{ color: 'error.main' }}>{status}</Typography>
          <Stack direction="row">
            {submitting ? (
              <Loading />
            ) : (
              <Button
                className="loxform__save-btn"
                variant="contained"
                color="primary"
                onClick={handleSubmit(onFormSubmit)}
              >
                <Trans>Enregistrer</Trans>
              </Button>
            )}
          </Stack>
        </Stack>
      </form>
    </Box>
  );
};

export default LoxForm;

LoxForm.propTypes = {
  /**
   * form specification object
   */
  form: PropTypes.shape({
    title: PropTypes.string,
    titlePrimary: PropTypes.bool,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        type: PropTypes.string,
        id: PropTypes.string,
        disabled: PropTypes.bool,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ).isRequired,
    initialValues: PropTypes.arrayOf(
      PropTypes.shape({
        fieldID: PropTypes.string,
        fieldValue: PropTypes.oneOf([
          PropTypes.bool,
          PropTypes.string,
          PropTypes.number,
          PropTypes.arrayOf(
            PropTypes.oneOf([
              PropTypes.bool,
              PropTypes.string,
              PropTypes.number,
            ])
          ),
        ]),
      })
    ),
  }).isRequired,
  /**
   * Method to handle form submit.
   * the method recieve the submitted data as param
   */
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.string,
  submitting: PropTypes.bool,
};

LoxForm.defaultProps = {
  status: '',
  submitting: false,
};
