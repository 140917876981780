export const smoothZoom = (map, maxValue, speed = 80) => {
  let cnt = map.getZoom();
  const timer = setInterval(() => {
    if (cnt <= maxValue - 1) {
      map.setZoom(cnt + 1);
      cnt += 1;
    }
    if (cnt === maxValue) window.clearInterval(timer);
  }, speed);
};
