import { Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReportGoobieList } from './ReportGoobieList';
import { Trackers } from './Trackers';
import { TrackerGoobieWarningList } from './TrackerGoobieWarningList';

export const TrackersDashboard = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <div
            style={{
              height: 500,
              maxHeight: 500,

              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              gap: 12,
            }}
          >
            <ReportGoobieList hideDetails />
            <Button variant="contained">
              <Link className="link-button" to="/tracker-reports">
                Show More
              </Link>
            </Button>
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div
            style={{
              height: 500,
              maxHeight: 500,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              gap: 12,
            }}
          >
            <Trackers hideDetails />
            <Button variant="contained">
              <Link className="link-button" to="/trackers/list">
                Show More
              </Link>
            </Button>
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div
            style={{
              height: 500,
              maxHeight: 500,

              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <TrackerGoobieWarningList overActiveOnly />
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div
            style={{
              height: 500,
              maxHeight: 500,

              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <TrackerGoobieWarningList inactiveOnly />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default TrackersDashboard;
