import { FormGroup, FormText, Input, Label } from 'reactstrap';

const ReactStrapComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <FormGroup>
    {props.label && (
      <Label for={props.id}>
        {props.label} {props.required && '*'}
      </Label>
    )}
    {props.children ? (
      <Input
        {...field}
        {...props}
        className={touched[field.name] && errors[field.name] && 'is-invalid'}
      >
        {props.children}
        {props.options &&
          props.options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
      </Input>
    ) : (
      <Input
        {...field}
        {...props}
        className={touched[field.name] && errors[field.name] && 'is-invalid'}
      />
    )}
    {touched[field.name] && errors[field.name] && (
      <FormText color="error" className="invalid-feedback">
        {errors[field.name]}
      </FormText>
    )}
    {props.helptext && <FormText>{props.helptext}</FormText>}
  </FormGroup>
);

export default ReactStrapComponent;
