import { t } from '@lingui/macro';
import { useEffect, useState } from 'react';
import {
  getAgencyJobSites,
  getJobSitesWithSecurityByClient,
} from '../../app/api/jobsites/JobSites';
import { useUserContext } from '../../app/contexts/UserContext/UserContext';
import { useAppModeContext } from '../../app/utils/hooks';
import CustomSelect from '../CustomSelect/CustomSelect';
import {
  JobSiteSelectorProps,
  SelectedAllOptions,
} from './JobSiteSelector.interface';

const JobSiteSelector = ({
  handleChange,
  customStyle,
  handleJobSiteName,
}: JobSiteSelectorProps) => {
  const { isInternalAppMode } = useAppModeContext();
  const user: any = useUserContext();

  const [jobSitesOptions, setJobSitesOptions] = useState([defaultOption]);
  const [selectedJobSite, setSelectedJobSite] = useState(SelectedAllOptions);

  useEffect(() => {
    fetchJobSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchJobSites = async () => {
    let response = [];
    const agencyId = user?.agencyId;

    if (isInternalAppMode) {
      if (agencyId) {
        response = await getAgencyJobSites(agencyId);
      }
    } else {
      response = await getJobSitesWithSecurityByClient();
    }

    setJobSitesOptions([
      defaultOption,
      ...response.map((jobSite: any) => ({
        label: `${jobSite.name} ${
          jobSite.rentalmanId && `(${jobSite.rentalmanId.slice(0, 5)}})`
        }`,
        slicedLabel: `${jobSite.name} ${
          jobSite.rentalmanId && `(${jobSite.rentalmanId.slice(0, 5)})`
        }`,
        originalLabel: `${jobSite.name} ${
          jobSite.rentalmanId && `(${jobSite.rentalmanId})`
        }`,
        value: jobSite.id,
      })),
    ]);
  };

  const handleMouseOver = (e: any) => {
    const value = e.target.getAttribute('data-value');
    const newJobsiteOptions = jobSitesOptions.map((x) => {
      if (value && value !== SelectedAllOptions && x.value === value) {
        // eslint-disable-next-line no-param-reassign
        x.label = x?.originalLabel;
      } else {
        // eslint-disable-next-line no-param-reassign
        x.label = x?.slicedLabel;
      }
      return x;
    });
    setJobSitesOptions(newJobsiteOptions);
  };

  return (
    <CustomSelect
      value={selectedJobSite}
      handleChange={(id: string) => {
        setSelectedJobSite(id);
        handleChange(id);

        // eslint-disable-next-line no-unused-expressions
        handleJobSiteName &&
          handleJobSiteName(
            jobSitesOptions.find((i) => i.value === id)?.originalLabel ?? ''
          );
      }}
      onMouseOver={handleMouseOver}
      options={jobSitesOptions}
      style={{ ...defaultStyle, ...customStyle }}
    />
  );
};

const defaultOption = {
  label: t`Tous les chantiers`,
  slicedLabel: t`Tous les chantiers`,
  originalLabel: t`Tous les chantiers`,
  value: SelectedAllOptions,
};

const defaultStyle = {
  width: `15vw`,
  heigth: '2.5rem',
};

export default JobSiteSelector;
