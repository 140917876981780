import { useState } from 'react';
import moment from 'moment';
import { t } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import CustomSelect from '../../../../../components/CustomSelect/CustomSelect';
import { calendarTypeOptions } from '../../../../components/organisms/MuiCustomCalendar/utils';
import { MonthHoverTypeEnum } from '../../../../components/organisms/MuiCustomCalendar/utils.interface';
import DateRangeForm from '../../../../components/DateRangeForm';
import { Period } from '../../../../../components/EquipmentSecurity/EquipmentSecurity.interface';
import { getFormatedDateRequest } from '../../../../utils/date/format';
import JobSiteSelector from '../../../../../components/JobSiteSelector/JobSiteSelector';
import MachinesByJobSiteSelector from '../../../../../components/MachinesByJobSiteSelector/MachinesByJobSiteSelector';
import { SelectedAllOptions } from '../../../../../components/JobSiteSelector/JobSiteSelector.interface';

import './JobSiteSecurityHeader.scss';

interface JobSiteSecurityHeaderProps {
  period: Period;
  handlePeriod: (period: Period) => void;
  handleJobSiteChange: (customerNumber: string) => void;
  handleMachineChange: (customerNumber: string) => void;
  handlePeriodType: (index: number) => void;
}

const JobSiteSecurityHeader = ({
  period,
  handlePeriod,
  handleJobSiteChange,
  handleMachineChange,
  handlePeriodType,
}: JobSiteSecurityHeaderProps) => {
  const [requestPeriod, setRequestPeriod] = useState({
    start: moment(period.start),
    end: moment(period.end),
  });

  const [selectedJobSite, setSelectedJobSite] = useState(SelectedAllOptions);
  const [selectedJobSiteName, setSelectedJobSiteName] = useState(
    t`Tous les chantiers`
  );

  const [selectedPeriodType, setSelectedPeriodType] = useState(
    MonthHoverTypeEnum.DAY
  );

  return (
    <Stack className="security-header" spacing={3}>
      <Stack className="security-header__title-stack" direction="row">
        <Typography sx={{ color: 'primary.main' }} variant="h3">
          {selectedJobSiteName}
        </Typography>

        <Stack direction="row" spacing={2}>
          <JobSiteSelector
            handleChange={(id: string) => {
              setSelectedJobSite(id);
              handleJobSiteChange(id);
              handleMachineChange(SelectedAllOptions);
            }}
            handleJobSiteName={setSelectedJobSiteName}
          />

          {selectedJobSite !== SelectedAllOptions && (
            <MachinesByJobSiteSelector
              jobSiteId={selectedJobSite}
              handleChange={(id: string) => {
                handleMachineChange(id);
              }}
            />
          )}

          <DateRangeForm
            handleSubmit={(period: any) => {
              setRequestPeriod(period);
              handlePeriod({
                start: getFormatedDateRequest(period.start),
                end: getFormatedDateRequest(period.end),
              });
            }}
            initialValues={{
              dateRange: requestPeriod,
            }}
          />
        </Stack>
      </Stack>

      <Stack className="security-header__subtitle-stack">
        <CustomSelect
          value={selectedPeriodType}
          handleChange={(periodType: any) => {
            setSelectedPeriodType(periodType);
            handlePeriodType(
              periodType === MonthHoverTypeEnum.DAY
                ? 0
                : periodType === MonthHoverTypeEnum.WEEK
                ? 1
                : 2
            );
          }}
          options={calendarTypeOptions(() => {})}
          style={{
            width: '7vw',
            heigth: '2.5rem',
            marginLeft: 'auto',
          }}
        />
      </Stack>
    </Stack>
  );
};

export default JobSiteSecurityHeader;
