import { Stack, Typography, Box, Button } from '@mui/material';
import { cellsWrap } from '../../../../../utils/table/table';
import IsMobile from '../../../../../utils/IsMobile';

const CockpitTrackUnitRow = ({ tracker }) => {
  const isMobile = IsMobile();

  const cells = isMobile
    ? [
        <Stack className="machine-row-mobile">
          <Stack direction="row" spacing={1}>
            <Typography>
              <b>Année: </b>
              {tracker.anne}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography>
              <b>Supposées être équipées: </b>
              {tracker.supposees}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography>
              <b>Equipées d&apos;OEM et de TrackUnit: </b>
              {tracker.equipees}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography>
              <b>Non équipées d&apos;OEM et de TrackUnit: </b>
              {tracker.nonEquipees}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Button variant="contained">Download</Button>
          </Stack>
        </Stack>,
      ]
    : [
        <Typography>{tracker.anne}</Typography>,
        <Typography>{tracker.supposees}</Typography>,
        <Typography>{tracker.equipees}</Typography>,
        <>
          <Typography>{tracker.nonEquipees}</Typography>
          <Button variant="contained">Download</Button>
        </>,
      ];

  return cellsWrap(cells);
};

export default CockpitTrackUnitRow;
