/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { Agency } from '../../../../../api/agencies/Agency.interface';
import { JobSite } from '../../../../../api/jobsites/JobSite.interface';
import { Machine } from '../../../../../api/machines/Machine.interface';
import { useAgenciesContext } from '../../../../../contexts/AgenciesContext';
import { useJobSitesContext } from '../../../../../contexts/JobSitesContext';
import { useMachinesContext } from '../../../../../contexts/MachinesContext';
import { isAllContextLoaded } from '../../../../../contexts/utils';
import { handleAgencies, handleMachines } from './EquipmentData.service';

interface MachinesInfoInterface {
  data: Machine[];
  on?: number;
  off?: number;
  null?: number;
}

interface MachinesInterface {
  machines: MachinesInfoInterface;
  goobies: MachinesInfoInterface;
  allMachines: Machine[];
}

interface JobSiteInterface {
  jobSites: JobSite[];
}

interface AgencyInterface {
  agencies: Agency[];
}

interface AllData {
  machines: MachinesInterface;
  jobsites: JobSiteInterface;
  agencies: AgencyInterface;
}

const initAllData: AllData = {
  machines: {
    machines: { data: [] },
    goobies: { data: [] },
    allMachines: [],
  },
  jobsites: {
    jobSites: [],
  },
  agencies: {
    agencies: [],
  },
};

const MACHINES_STATUS_TO_HIDE = ['S', 'J'];
const HIDE_CODE = '01';

const EquipmentData = (): { isLoaded: boolean; allData: AllData } => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [allData, setAllData] = useState<AllData>(initAllData);

  const { getMachinesLoadStatus, getMachines }: any = useMachinesContext();
  const { getJobSitesLoadStatus, getJobSites }: any = useJobSitesContext();
  const { getAgenciesLoadStatus, getAgencies }: any = useAgenciesContext();

  useEffect(() => {
    if (
      isAllContextLoaded([
        getMachinesLoadStatus(),
        getJobSitesLoadStatus(),
        getAgenciesLoadStatus(),
      ])
    ) {
      const jobSites = getJobSites();
      const agencies = getAgencies();
      const machines = [...getMachines()].filter(
        (x) =>
          !MACHINES_STATUS_TO_HIDE.includes(x.equipmentStatusKey) &&
          x.branch_code &&
          x.branch_code.slice(-2) !== HIDE_CODE
      );

      setAllData((data: any) => ({
        ...data,
        // jobsites: handleJobSites(jobSites),
        agencies: handleAgencies(agencies),
        machines: handleMachines(machines, jobSites, agencies),
      }));
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMachinesLoadStatus, getJobSitesLoadStatus, getAgenciesLoadStatus]);

  return { isLoaded, allData };
};

export default EquipmentData;
