import { Trans } from '@lingui/macro';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  // Stack,
  TableHead,
  TableRow,
} from '@mui/material';
import { StyledArrow, StyledTableCell } from '../../UsedComponentsAndImports';
import {
  // onChangeSearchFilter,
  onClickSortColumn,
} from '../../filtersOperations';
// import { SearchBar } from '../SearchBar/SearchBar';

import './TableHeader.scss';

const TableHeader = ({
  filteredHeader,
  enableFiltersOptions,
  columnsHeadersKey,
  isSorted,
  permVisibleRows,
  setIsSorted,
  headersSorted,
  setHeadersSorted,
  setVisibleRows,
  columnsObject,
}) => {
  return (
    <TableHead className="table-header">
      <TableRow>
        {filteredHeader.map((el, index) => {
          let columsKeys;

          if (enableFiltersOptions) columsKeys = columnsHeadersKey;

          return (
            <StyledTableCell key={`table-row-${index + 2}`}>
              <Box className="table-header__cell">
                <span className="table-header__cell-name">
                  <Trans id={el} />
                </span>

                {enableFiltersOptions &&
                  columsKeys &&
                  columsKeys.includes(el.toLowerCase()) && (
                    <>
                      <Box className="table-header__cell-sort">
                        <StyledArrow
                          aria-hidden="true"
                          className="arrow_icon-filter-array"
                          sx={{
                            color:
                              isSorted[el.toLowerCase()] &&
                              isSorted[el.toLowerCase()].up &&
                              'primary.main',
                          }}
                          onClick={() =>
                            onClickSortColumn({
                              header: el,
                              permVisibleRows,
                              sorting: 'up',
                              isSorted,
                              setIsSorted,
                              headersSorted,
                              setHeadersSorted,
                              setVisibleRows,
                              columnsObject,
                            })
                          }
                        >
                          <KeyboardArrowUpIcon />
                        </StyledArrow>
                        <StyledArrow
                          aria-hidden="true"
                          className="arrow_icon-filter-array"
                          sx={{
                            color:
                              isSorted[el.toLowerCase()] &&
                              isSorted[el.toLowerCase()].down &&
                              'primary.main',
                          }}
                          onClick={() =>
                            onClickSortColumn({
                              header: el,
                              permVisibleRows,
                              sorting: 'down',
                              isSorted,
                              setIsSorted,
                              headersSorted,
                              setHeadersSorted,
                              setVisibleRows,
                              columnsObject,
                            })
                          }
                        >
                          <KeyboardArrowDownIcon />
                        </StyledArrow>
                      </Box>

                      {/* checkboxHeader &&
                      !checkboxHeader.find((f) => {
                        return f.name === el;
                      })?.hideFilter && (
                        <StyledSearchIcon
                          sx={{
                            color:
                              filters[el] &&
                              filters[el].values.length > 0 &&
                              'primary.main',
                            visibility:
                              checkboxHeader.find((f) => f.name === el)
                                ?.checkboxes && 'hidden',
                          }}
                          onClick={(event) =>
                            handleSearchPopOverClick(event, el)
                          }
                        />
                      ) */}
                      {/* checkboxHeader && (
                      <Popover
                        id={popOverId}
                        open={openPopover[el.toLowerCase()] || false}
                        anchorEl={anchorEl}
                        onClose={() => handleClosePopOver()}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        {checkboxHeader?.length > 0 &&
                          checkBoxesStates?.length > 0 &&
                          checkboxHeader.map((c) => {
                            if (c.name === el) {
                              return (
                                <Stack
                                  className="table-header__popover-stack"
                                  key={c.name}
                                  direction="row"
                                >
                                  {[...c.keys].map((name) => {
                                    const checkboxHeaderState =
                                      checkBoxesStates.find(
                                        (cx) => cx.header === c.name
                                      );

                                    let checked;

                                    if (checkboxHeaderState) {
                                      const val = checkboxHeaderState?.values;
                                      checked = val.filter(
                                        (f) => f.name === name
                                      )[0].value;
                                    }

                                    return (
                                      <FormControlLabel
                                        key={name}
                                        control={
                                          <Checkbox
                                            name="SomeName"
                                            value={name}
                                            checked={checked || false}
                                            onChange={() =>
                                              handleCheckBoxChange({
                                                header: el,
                                                name,
                                                keyValue: c.keyValue,
                                                checked,
                                                keysNamesValues:
                                                  c.keysNamesValues,
                                              })
                                            }
                                          />
                                        }
                                        label={name}
                                      />
                                    );
                                  })}
                                </Stack>
                              );
                            }
                            return null;
                          })}
                        {!checkboxHeader.find((f) => f.name === el)
                          ?.hideSearch && (
                          <Stack
                            className="table-header__popover-stack"
                            direction="row"
                          >
                            <SearchBar
                              el={el}
                              permVisibleRows={permVisibleRows}
                              setVisibleRows={setVisibleRows}
                              filters={filters}
                              setFilters={setFilters}
                              handleClose={handleClosePopOver}
                              setPermVisibleRows={setPermVisibleRows}
                              visibleRows={visibleRows}
                              onChangeInput={onChangeSearchFilter}
                              onClickButton={addSearchFilterOnClick}
                              setTempVisibleRows={setTempVisibleRows}
                              columnsObject={columnsObject}
                              parentFilters={parentFilters}
                            />
                          </Stack>
                        )}
                      </Popover>
                    ) */}
                    </>
                  )}
              </Box>
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
