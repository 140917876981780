import {
  Button,
  Modal as ModalStrap,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { t, Trans } from '@lingui/macro';
import {
  ChoiceField,
  TextField,
  EmailField,
} from '../../../lib/apptheme/components';
import { FormSet } from '../../../lib/apptheme';
import { createUser } from '../../api/users/Users';

const roles = [
  { value: 'ROLE_ADMIN', label: t`Administrateur` },
  { value: 'ROLE_AGENT', label: t`Agent` },
];

const SettingMembersAddModal = (props) => {
  const { show, toggle } = props;

  const isLoading = false;

  const handleSubmit = async (values) => {
    await createUser(values);
    toggle();
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
  };

  return (
    <ModalStrap isOpen={show} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <Trans>Inviter un membre</Trans>
      </ModalHeader>
      <FormSet
        data={initialValues}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        hideSubmitButton
      >
        <ModalBody>
          <fieldset>
            <legend>
              <Trans>Informations</Trans>
            </legend>
            <TextField name="firstName" label={t`Prénom`} required />
            <TextField name="lastName" label={t`Nom`} required />
            <EmailField name="email" label={t`Courriel`} required />
            <ChoiceField
              name="role"
              label={t`Rôle`}
              options={roles}
              value={t`Rôle`}
              required
              placeholder={t`Choisissez un rôle`}
            />
          </fieldset>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            <Trans>Annuler</Trans>
          </Button>
          <Button type="submit" color="primary">
            <Trans>Inviter</Trans>
          </Button>
        </ModalFooter>
      </FormSet>
    </ModalStrap>
  );
};

export default SettingMembersAddModal;
