import { FormControlLabel, FormGroup, Switch } from '@mui/material';

interface CustomizedSwitchesProps {
  text: string | any;
  onToggle: (value: any) => void;
  value?: boolean;
  disabled?: boolean;
}

const CustomizedSwitches = ({
  text,
  onToggle,
  value,
  disabled = false,
}: CustomizedSwitchesProps) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={(event) => onToggle(event.target.checked)}
            disabled={disabled}
          />
        }
        label={text}
      />
    </FormGroup>
  );
};

export default CustomizedSwitches;
