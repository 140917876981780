/* eslint-disable no-unused-expressions */
import { t } from '@lingui/macro';
import { Icon, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SpecificationList from '../SpecificationList/SpecificationList';

const contractPeriod = (rentalStartDate, estimatedEndDate) => {
  const rentalStart = rentalStartDate ? moment(rentalStartDate) : null;
  const estimatedEnd = estimatedEndDate ? moment(estimatedEndDate) : null;

  if (
    !rentalStart ||
    !estimatedEnd ||
    !rentalStart.isValid() ||
    !estimatedEnd.isValid()
  ) {
    return '-';
  }

  const du = t`Du`;
  const au = t`au`;
  const nbDays = t`Nombre de jours`;

  return `${du} ${rentalStart.format('L')} ${au} ${estimatedEnd.format(
    'L'
  )} (${nbDays} : ${estimatedEnd.diff(rentalStart, 'days')})`;
};

const DeviceDetailContract = ({ machine, contract, agency }) => {
  const { jobSiteId, agencyId } = machine;
  const { rentalmanId, name } = agency;

  const {
    clientName,
    contractNumber,
    purchaseOrderNumber,
    orderedBy,
    constructionSiteName,
    pickUpAdress,
    contactPhoneNumber,
  } = contract;

  const period = contractPeriod(machine.rentalStart, machine.estimatedEnd);

  const rootData = [];
  const clientData = [];
  const contractData = [];
  const jobSiteData = [];
  const agencyData = [];

  // ** contractData **
  clientName &&
    clientData.push({
      name: t`Nom du client`,
      value: clientName,
    });

  // ** contractData **
  contractNumber &&
    contractData.push({
      name: t`Numéro de contrat`,
      value: contractNumber,
    });

  purchaseOrderNumber &&
    contractData.push({
      name: t`Bon de commande`,
      value: purchaseOrderNumber,
    });

  period !== '-' &&
    contractData.push({
      name: t`Période`,
      value: period,
    });

  orderedBy &&
    contractData.push({
      name: t`Commandé par`,
      value: orderedBy,
    });

  // ** jobSiteData **
  constructionSiteName &&
    jobSiteData.push({
      name: t`Chantier contractuel`,
      value: constructionSiteName,
    });

  pickUpAdress &&
    jobSiteData.push({
      name: t`Adresse de retrait`,
      value: pickUpAdress,
    });

  contactPhoneNumber &&
    jobSiteData.push({
      name: t`N° de contact`,
      value: contactPhoneNumber,
    });

  // ** agencyData **
  rentalmanId &&
    agencyData.push({
      name: t`Code agence`,
      value: rentalmanId,
    });

  name &&
    agencyData.push({
      name: t`Nom de l’agence`,
      value: name,
    });

  // ** rootData **
  clientData.length > 0 &&
    rootData.push({
      title: t`Client`,
      data: clientData,
    });

  contractData.length > 0 &&
    rootData.push({
      title: t`Contrat`,
      data: contractData,
    });

  jobSiteData.length > 0 &&
    rootData.push({
      title: (
        <Typography variant="h3">
          {t`Chantiers`}
          {jobSiteId && (
            <Link
              to={`/equipments/jobsite/${machine.jobSiteId}`}
              style={{ textDecoration: 'none' }}
              disabled
            >
              <Icon sx={{ color: 'secondary.main' }}>info</Icon>
            </Link>
          )}

          {jobSiteId && (
            <Link
              to={`/equipments/map?zoomOn=${machine.jobSiteId}`}
              style={{ textDecoration: 'none' }}
              disabled
            >
              <Icon sx={{ color: 'secondary.main' }}>place</Icon>
            </Link>
          )}
        </Typography>
      ),
      data: jobSiteData,
    });

  agencyData.length > 0 &&
    rootData.push({
      title: (
        <Typography variant="h3">
          {t`Agence`}
          {agencyId && (
            <Link
              to={`/equipments/agency/${machine.agencyId}`}
              style={{ textDecoration: 'none' }}
              disabled
            >
              <Icon sx={{ color: 'secondary.main' }}>info</Icon>
            </Link>
          )}

          {agencyId && (
            <Link
              to={`/equipments/map?zoomOn=${machine.agencyId}`}
              style={{ textDecoration: 'none' }}
              disabled
            >
              <Icon sx={{ color: 'secondary.main' }}>place</Icon>
            </Link>
          )}
        </Typography>
      ),
      data: agencyData,
    });

  return <SpecificationList title={t`Contrat`} specifications={rootData} />;
};

export default DeviceDetailContract;

DeviceDetailContract.propTypes = {
  machine: PropTypes.shape({
    address: PropTypes.string,
    agencyId: PropTypes.string,
    agencyName: PropTypes.string,
    battery: PropTypes.string,
    brand: PropTypes.string,
    buildYear: PropTypes.number,
    catClass: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    currentContractId: PropTypes.string,
    description: PropTypes.string,
    descriptions: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    distance: PropTypes.string,
    engineRunning: PropTypes.bool,
    estimatedEnd: PropTypes.string,
    externalIds: PropTypes.object,
    height: PropTypes.number,
    id: PropTypes.string,
    jobSiteDesc: PropTypes.string,
    jobSiteId: PropTypes.string,
    lastSyncTime: PropTypes.string,
    lat: PropTypes.string,
    length: PropTypes.number,
    lon: PropTypes.string,
    machineGroupId: PropTypes.string,
    model: PropTypes.string,
    name: PropTypes.string,
    pim: PropTypes.shape({
      apoximate: PropTypes.bool,
      code: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          format: PropTypes.string,
          imageType: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    }),
    rentalStart: PropTypes.string,
    rentalmanId: PropTypes.string,
    serialNumber: PropTypes.string,
    statusCode: PropTypes.string,
    vin: PropTypes.string,
    weight: PropTypes.number,
    width: PropTypes.number,
    zipCode: PropTypes.string,
  }).isRequired,
  contract: PropTypes.shape({
    agencyId: PropTypes.string,
    branchCode: PropTypes.string,
    changeDatetime: PropTypes.string,
    clientId: PropTypes.string,
    clientName: PropTypes.string,
    comment: PropTypes.string,
    companyCode: PropTypes.string,
    constructionSiteName: PropTypes.string,
    contactPhoneNumber: PropTypes.string,
    contractNumber: PropTypes.string,
    contractType: PropTypes.string,
    creationDatetime: PropTypes.string,
    customerNumber: PropTypes.string,
    endRentalDateTime: PropTypes.string,
    estimatedDays: PropTypes.string,
    estimatedReturnDatetime: PropTypes.string,
    id: PropTypes.string,
    insured: PropTypes.string,
    orderedBy: PropTypes.string,
    pickUpAdress: PropTypes.string,
    projectNumber: PropTypes.string,
    purchaseOrderNumber: PropTypes.string,
    rentalmanId: PropTypes.string,
    reservationNumber: PropTypes.string,
    startRentalDatetime: PropTypes.string,
    status: PropTypes.string,
    subSaterday: PropTypes.string,
    subSunday: PropTypes.string,
  }).isRequired,
  agency: PropTypes.shape({}).isRequired,
};
