import { t } from '@lingui/macro';
import { capitalize } from 'lodash';
import { JobSite } from '../../../../api/jobsites/JobSite.interface';

import './MarkerPopup.scss';

interface JobSiteWithState extends JobSite {
  state: string;
}

interface JobSiteMarkerPopupProps {
  jobSite: JobSiteWithState;
  title: string;
}

const JobSiteMarkerPopup = ({ jobSite, title }: JobSiteMarkerPopupProps) => {
  const { id, state, name, rentalmanId, city, streetAdress, streetNumber } =
    jobSite;

  return (
    <div className={`infowindow-${state}-${id} marker-popup`}>
      <div className="marker-popup__header">
        <div className="marker-popup__header-image">
          <img
            alt="job-site"
            className="marker-popup__header-image-size"
            src="https://www.batiactu.com/images/auto/620-465-c/20150312_172549_page09bis.jpg"
          />
        </div>
        <h6
          className="linkToDetailsPageEquipmentJobSite marker-popup__header-title"
          id={id}
        >
          {title}
        </h6>
      </div>
      <div className="marker-popup__information-wrapper">
        <span className="marker-popup__text-infos">
          {rentalmanId && (
            <>
              <b>{t`Numéro de Chantier`}:</b>
              <span>{rentalmanId},</span>
            </>
          )}
        </span>
        <span className="marker-popup__text-infos">
          <b>{t`Adresse`}:</b>
          <span>
            {`
              ${capitalize(city) || '-'},
              ${capitalize(streetAdress) || '-'},
              ${capitalize(streetNumber) || '-'}
            `}
          </span>
        </span>
      </div>
    </div>
  );
};

export default JobSiteMarkerPopup;
