import { t, Trans } from '@lingui/macro';
import { Box, Stack, Typography } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { calculFullHoursFromDuration } from '../../../../../utils/date/calcul';

import './ChartBarMachineUsage.scss';

const ChartBarMachineUsage = ({
  chartBarData,
  totalValue,
  graphDisplayInMinutes,
  selectedCalendarFilter,
}: any) => {
  // totalValue is in minutes and 'calculFullHoursFromDuration' accepts secounds
  const { hours, minutes } = calculFullHoursFromDuration(totalValue * 60);

  const maxYValue = graphDisplayInMinutes
    ? 60
    : selectedCalendarFilter === 'month'
    ? 40
    : 8;

  const total = graphDisplayInMinutes
    ? Number(hours) * 60 + Number(minutes)
    : `${hours}:${minutes}`;

  const timeTextInfo = graphDisplayInMinutes ? t`minutes` : t`heures`;

  return (
    <Stack className="chart-bar-machine-usage">
      <Stack className="chart-bar-machine-usage__header" direction="row">
        <Typography variant="h4">
          {t`Total`} : {total}
          <Trans id={timeTextInfo} />
        </Typography>
      </Stack>

      <Box className="chart-bar-machine-usage__bar">
        <Bar
          data={chartBarData}
          width="30%"
          options={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            type: 'bar',
            maintainAspectRatio: false,
            chartBarData,
            scales: {
              yAxis: {
                min: 0,
                max: maxYValue,
                ticks: {
                  stepSize: 1,
                },
              },
            },
            options: {
              indexAxis: 'x',
              elements: {
                bar: {
                  borderWidth: 2,
                },
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
              plugins: {
                legend: {
                  position: 'right',
                },
                title: {
                  display: false,
                },
              },
            },
          }}
        />
      </Box>
    </Stack>
  );
};

export default ChartBarMachineUsage;
