import { t } from '@lingui/macro';
import { Grid, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  getTracker,
  TrackerMode,
  updateTracker,
} from '../../api/trackers/Trackers';
import {
  LoxForm,
  ReturnButton,
  SpecificationList,
} from '../../components/molecules';
import ReportGoobieList from './ReportGoobieList';

/**
 * Page to display tracker's information and to update his behavior
 * url = /trackers/{trackerUUID}
 * @returns {JSX.Element}
 * @constructor
 */
export const TrackerDetail = () => {
  const { trackerId } = useParams();

  const [tracker, setTracker] = useState(undefined);

  useEffect(() => {
    const fetchTracker = async () => {
      const response = await getTracker(trackerId);

      setTracker(response);
    };

    fetchTracker().catch((error) => console.log(error));
  }, [trackerId]);

  return (
    <>
      <ReturnButton label={t`Retour`} sx={{ m: 2 }} />
      <Typography variant="h1" sx={{ mb: 1 }}>{t`Detail tracker`}</Typography>
      {tracker !== undefined && (
        <Grid
          className="tracker-detail-container"
          container
          spacing={1}
          alignItems="flex-start"
        >
          <Grid item xs={12} lg={4}>
            <TrackerUpdate tracker={tracker} onSubmit={setTracker} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TrackerInfo tracker={tracker} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TrackerMachine tracker={tracker} />
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, mb: 2 }}>
              <div
                style={{
                  height: 500,
                  maxHeight: 500,

                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                }}
              >
                <ReportGoobieList hideDetails search={tracker.imei} />
              </div>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
};

/**
 * Form to update tracker behavior
 * @constructor
 */
const TrackerUpdate = ({ tracker, onSubmit }) => {
  const [submitStatus, setSubmitStatus] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (data) => {
    setSubmitting(true);
    setSubmitStatus('');

    const trackerUpdate = { ...tracker, ...data };
    // set delay in seconds (was display in minute)
    trackerUpdate.replyDelay *= 60;

    updateTracker(trackerUpdate, trackerUpdate.id)
      .then((res) => onSubmit(res))
      .catch((error) => setSubmitStatus(error.message))
      .finally(() => setSubmitting(false));
  };

  return (
    <Paper sx={{ p: 2 }}>
      <LoxForm
        status={submitStatus}
        submitting={submitting}
        form={{
          title: t`Mise à jour`,
          titlePrimary: true,
          fields: [
            {
              id: 'replyDelay',
              label: t`Periode de connexion (en min)`,
              type: 'number',
            },
            {
              id: 'requestedMode',
              label: t`Mode demandé`,
              type: 'select',
              options: [
                ...Object.values(TrackerMode).map((key) => {
                  return { value: key, label: key };
                }),
              ],
            },
            {
              id: 'needReboot',
              label: t`Forcer le redémarrage`,
              type: 'checkbox',
            },
          ],
          initialValues: [
            {
              id: 'requestedMode',
              value: tracker.requestedMode,
            },
            {
              id: 'needReboot',
              value: tracker.needReboot,
            },
            {
              id: 'replyDelay',
              value: tracker.replyDelay / 60,
            },
          ],
        }}
        onSubmit={handleSubmit}
      />
    </Paper>
  );
};

/**
 * List of information for a tracker
 * @param tracker
 * @returns {JSX.Element}
 * @constructor
 */
const TrackerInfo = ({ tracker }) => {
  return (
    <>
      <Paper sx={{ p: 2 }}>
        <SpecificationList
          title={t`Information`}
          specifications={[
            {
              data: [
                {
                  name: t`Mode`,
                  value: tracker.mode,
                },
                {
                  name: t`Tag`,
                  value: tracker.tag,
                },
                {
                  name: t`imei`,
                  value: tracker.imei,
                },
                {
                  name: t`Version MCU`,
                  value: tracker.versionMCU,
                },
                {
                  name: t`Version Net`,
                  value: tracker.versionNET,
                },
                {
                  name: t`Mode demandé`,
                  value: tracker.requestedMode,
                },
              ],
            },
          ]}
        />
      </Paper>
    </>
  );
};

const TrackerMachine = ({ tracker }) => {
  if (tracker.machineId !== null) {
    return (
      <>
        <Paper sx={{ p: 2 }}>
          <SpecificationList
            title={t`Machine`}
            specifications={[
              {
                data: [
                  {
                    name: t`Date d'association`,
                    value: tracker.associationDate,
                  },
                  {
                    name: t`Commentaire (association)`,
                    value: tracker.associationComment,
                  },
                  {
                    name: t`Modèle`,
                    value: tracker.machineModel,
                  },
                  {
                    name: t`Marque`,
                    value: tracker.machineBrand,
                  },
                  {
                    name: t`Numéro d'équipement`,
                    value: tracker.machineEquipementNumber,
                  },
                ],
              },
            ]}
          />
        </Paper>
      </>
    );
  }
  return <></>;
};
