import {
  TableRow,
  TableCell,
  tableCellClasses,
  styled,
  Stack,
  tableRowClasses,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import colors from '../../../theme/_colors.scss';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.tableheader
      ? theme.palette.tableheader.bgcolor
      : '#2D2A2C',
    color: theme.palette.common.white,
    paddingTop: 10,
    paddingBottom: 10,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingTop: 12,
    paddingBottom: 12,
  },
}));

export const StyledTableRowAlternateColor = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}`]: {
    height: '50px',
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.light
      ? theme.palette.light.bgcolor
      : '#F0EDEE',
  },
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.light,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}`]: {
    borderBottom: '1px solid #ccc',
    height: '100px !important',
    minHeight: '100px !important',
    maxHeight: '100px !important',
    minWidth: '100px !important',
    maxWidth: '100px !important',
  },
}));

export const StyledArrow = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  alignItems: 'center',
  '&:hover': {
    color: theme.palette.primary.main.primary,
  },
}));

export const StyledSearchIcon = styled(MoreVertIcon)(() => ({
  cursor: 'pointer',
  color: '#fff',
  fontSize: '30px',
  width: '25px',
  marginLeft: '0px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    color: colors.imperialRed,
  },
}));
