import { t } from '@lingui/macro';
import { Box } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { ErrorMessage, Loading } from '../../../../../../lib/apptheme/partials';
import FleetMapAll from '../../../Map/FleetMapAll/FleetMapAll';
import MachineCardOptions from '../MachineCardOptions/MachineCardOptions';

import { Machine } from '../../../../../api/machines/Machine.interface';
import './MachineMap.scss';
import { getRadiusCenter } from '../../../../../api/machines/Machines';

interface MachineMapProps {
  machine: Machine;
  locations: any[];
  geoZone: {
    displayRegions: boolean;
    displayDepartments: boolean;
    geoZoneIds: string[];
    handleGeoZoneClicked: (id: string) => void;
  };
}

interface Coordinate {
  lat: string;
  long: string;
  gbLat?: string;
  gbLong?: string;
}

const MachineMap = ({ machine, locations, geoZone }: MachineMapProps) => {
  const [displayZone, setDisplayZone] = useState(false);
  const [showDraggableMarker, setShowDraggableMarker] = useState(false);
  const [radius, setRadius] = useState({ label: '0.5km', value: 500 });
  const [draggableMarkerCoordinates, setDraggableMarkerCoordinates] = useState(
    {}
  );

  useEffect(() => {
    fetchDraggableMarkerCoordinates(machine.id).then((result) => {
      setDraggableMarkerCoordinates(result.gtCir);
    });
    setDisplayZone(machine?.zone || false);
  }, [machine]);

  const fetchDraggableMarkerCoordinates = async (id: string) => {
    return getRadiusCenter(id);
  };

  const handleDraggableMarkerDisplay = useCallback(() => {
    setShowDraggableMarker(!showDraggableMarker);
  }, [showDraggableMarker]);

  const updateDraggableMarkerCoordinates = useCallback((coord: Coordinate) => {
    setDraggableMarkerCoordinates(coord);
  }, []);

  return machine ? (
    <Box className="agency-carte">
      {/* <MachineCardOptions
        machine={machine}
        displayZone={displayZone}
        setDisplayZone={setDisplayZone}
        showDraggableMarker={showDraggableMarker}
        handleDraggableMarkerDisplay={handleDraggableMarkerDisplay}
        radius={radius}
        setRadius={setRadius}
      /> */}
      {!machine.lon && !machine.lat && !machine.gbLong && !machine.gbLat ? (
        <ErrorMessage message={t`Aucune Coordonnée`} />
      ) : (
        <FleetMapAll
          locations={locations}
          displayZone={displayZone}
          showDraggableMarker={showDraggableMarker}
          draggableMarkerCoordinates={draggableMarkerCoordinates}
          handleMarkerDrag={updateDraggableMarkerCoordinates}
          mapClassName="machine-carte__card"
          geoZone={geoZone}
          radiusSize={radius.value}
          machineId={machine.id}
        />
      )}
    </Box>
  ) : (
    <Loading />
  );
};

export default MachineMap;
