import { Trans } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import { useAppModeContext } from '../../../utils/hooks';

import './LegendAsideBox.scss';

/**
 * Component used on the legends of the main equipments map
 *
 * @param {Array} legends - Array of legends
 * @returns React Component
 */
const LegendAsideBox = ({ legends }) => {
  const { isInternalAppMode } = useAppModeContext();

  return (
    <Stack direction="row" className="legend-aside-box">
      {legends.map((l) => {
        return (
          <Stack key={l.title}>
            <Typography variant="body1Bold">
              <Trans id={l.title} />:
            </Typography>
            <Stack>
              {l.data.map((d) => {
                const cubeStyle = d?.color
                  ? { backgroundColor: d.color }
                  : { ...d.style };

                return (
                  (isInternalAppMode ||
                    (!isInternalAppMode && d.external === true)) && (
                    <Stack
                      key={d.title}
                      direction="row"
                      className="legend-aside-box__element"
                    >
                      <Stack
                        className="legend-aside-box__color-square"
                        sx={cubeStyle}
                      />
                      <Typography>
                        <Trans id={d.title} />
                      </Typography>
                    </Stack>
                  )
                );
              })}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default LegendAsideBox;
