import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { CardLink } from '../../molecules';
import IsMobile from '../../../utils/IsMobile';

import './HomePage.scss';

const HomePage = ({ title, mobileLinks, desktopLinks }) => {
  const isMobile = IsMobile();

  return (
    <Box className="home-page">
      {!isMobile && (
        <>
          <Typography
            className="home-page__title"
            variant="h1"
            data-testid="homepage-title"
          >
            {title}
          </Typography>

          <Box className="home-page__links">
            {desktopLinks.map((link) => {
              return (
                <Box
                  className="home-page__link"
                  key={`box-${link.title}`}
                  data-testid="homepage-link"
                >
                  <CardLink {...link} key={`cardLink-${link.title}`} />
                </Box>
              );
            })}
          </Box>
        </>
      )}

      {isMobile && (
        <Box className="home-page__links">
          {mobileLinks.map((link) => {
            return (
              <Box className="home-page__link" key={`box-${link.title}`}>
                <CardLink {...link} key={`cardLink-${link.title}`} />
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default HomePage;

HomePage.propTypes = {
  /**
   * The page title
   */
  title: PropTypes.string,
  /**
   * The list of CardLinks
   */
  mobileLinks: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Main Icon
       */
      icon: PropTypes.string,
      /**
       * Title
       */
      title: PropTypes.string.isRequired,
      /**
       * Description
       */
      description: PropTypes.string.isRequired,
      /**
       * Number of notifications
       */
      notifications: PropTypes.number,
      /**
       * Number of alerts
       *
       */
      alerts: PropTypes.number,
      /**
       * Show or not the notification panel
       */
      showNotificationsPanel: PropTypes.bool,
      /**
       * Disable the card link
       */
      disabled: PropTypes.bool,
      /**
       * URL
       */
      href: PropTypes.string,
      /**
       * Optional click handler
       */
      onClick: PropTypes.func,
    })
  ).isRequired,
  desktopLinks: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Main Icon
       */
      icon: PropTypes.string,
      /**
       * Title
       */
      title: PropTypes.string.isRequired,
      /**
       * Description
       */
      description: PropTypes.string.isRequired,
      /**
       * Number of notifications
       */
      notifications: PropTypes.number,
      /**
       * Number of alerts
       *
       */
      alerts: PropTypes.number,
      /**
       * Show or not the notification panel
       */
      showNotificationsPanel: PropTypes.bool,
      /**
       * Disable the card link
       */
      disabled: PropTypes.bool,
      /**
       * URL
       */
      href: PropTypes.string,
      /**
       * Optional click handler
       */
      onClick: PropTypes.func,
    })
  ).isRequired,
};

HomePage.defaultProps = {
  title: '',
};
