import { useState, useEffect, useRef, useCallback } from 'react';
import { t } from '@lingui/macro';
import { Stack, Typography, Paper, Box } from '@mui/material';

import { useHistory, useParams } from 'react-router-dom';
import { useBreadcrumbsContext, useAppModeContext } from '../../../utils/hooks';
import { Loading } from '../../../../lib/apptheme';
import {
  MACHINE_FIELDS_TABLE,
  AGENCY_FIELDS_TABLE,
} from '../../../utils/utils';
import { getJobSiteAgencies } from '../../../api/agencies/Agencies';
import { updateJobSitePosition } from '../../../api/jobsites/JobSites';
import {
  TabButton,
  TableWithPagination,
  EquipmentContentDetails,
} from '../../../components/molecules';
import JobSiteCarte from './components/JobSiteCarte';
import JobSiteCarteZone from './components/JobSiteCarteZone';
import PositionModifier from '../../../../components/PositionModifier/PositionModifier';
import { useJobSitesContext } from '../../../contexts/JobSitesContext';
import { useMachinesContext } from '../../../contexts/MachinesContext';
import JobsiteInformation from './components/JobsiteInformation/JobsiteInformation';
import IsMobile from '../../../utils/IsMobile';
import AgencyRow from '../EquipmentRow/components/AgencyRow/AgencyRow';
import SimpleMachineRow from '../EquipmentRow/components/SimpleMachineRow/SimpleMachineRow';
import JobsiteSecurity from './components/JobsiteSecurity/JobsiteSecurity';

import './EquipmentJobSite.scss';

const JobSiteContent = (content, description) => {
  return (
    <EquipmentContentDetails
      details={{
        image:
          'https://www.batiactu.com/images/auto/620-465-c/20150312_172549_page09bis.jpg',
        description,
      }}
      content={content}
    />
  );
};

const EquipmentJobSite = ({ equipment }) => {
  const history = useHistory();
  const isMobile = IsMobile();
  const { id } = useParams();
  const [multipleLocation, setMultipleLocation] = useState({
    oneChecked: false,
    agencies: false,
    machines: false,
  });

  const [jobsite, setJobSite] = useState();
  const [jobsiteRow, setJobSiteRow] = useState();

  const [machinesRow, setMachinesRow] = useState();
  const [agenciesRow, setAgenciesRow] = useState();
  const [isLoading, setLoading] = useState(true);
  const [displayZone, setDisplayZone] = useState(false);
  const [drawingZone, setDrawingZone] = useState(false);
  const [zonePoints, setZonePoints] = useState([]);

  const newPosition = useRef({ lat: 0, lon: 0 });

  const [, setBreadcrumbsList] = useBreadcrumbsContext();
  const { isInternalAppMode } = useAppModeContext();
  const { loadJobSite, getJobSite } = useJobSitesContext();
  const { getMachinesByJobSite } = useMachinesContext();

  const options = [5, 10, 20, 50];

  useEffect(() => {
    if (jobsiteRow && machinesRow && agenciesRow) {
      setLoading(false);
    }
  }, [jobsiteRow, machinesRow, agenciesRow]);

  useEffect(() => {
    if (id) {
      const jobSite = getJobSite(id, !jobsite);

      if (jobSite) {
        jobSite.state = 'Chantiers';
        jobSite.zones = jobSite.zones || [];

        newPosition.current = { lat: jobSite.lat, lon: jobSite.lon };
        setJobSite(jobSite);
        setJobSiteRow([jobSite]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getJobSite, id]);

  useEffect(() => {
    const fetchData = async () => {
      const [machines, agencies] = await Promise.all([
        getMachinesByJobSite(id),
        getJobSiteAgencies(id),
      ]);

      machines.forEach((el) => {
        // eslint-disable-next-line no-param-reassign
        el.state = 'Machines';
      });
      setMachinesRow(machines);

      agencies.forEach((el) => {
        // eslint-disable-next-line no-param-reassign
        el.state = 'Agences';
      });
      setAgenciesRow(agencies);
    };

    fetchData();

    return () => {
      setMachinesRow([]);
      setAgenciesRow([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const breadCrumbsData = [
      {
        label: t`Tableau de bord`,
        to: '/',
      },
      {
        label: t`Chantiers`,
        to: '/equipments/list',
      },
      {
        label: t`Détails`,
        to: '/equipments/details',
      },
    ];

    setBreadcrumbsList(breadCrumbsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      drawingZone &&
      displayZone &&
      !jobsite.zones.length > 0 &&
      zonePoints.length > 0
    ) {
      setDisplayZone(false);
    }
    // TODO - fix eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawingZone]);

  useEffect(() => {
    if (drawingZone && zonePoints.length > 0) {
      setDisplayZone(true);
    }
    // TODO - fix eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zonePoints]);

  useEffect(() => {
    setJobSiteRow((prevRow) =>
      (prevRow ?? []).map((item) => (item.id === id ? jobsite : item))
    );
  }, [id, jobsite]);

  const handleHistoryPush = (path, id) => {
    history.push(`/equipments/${path}/${id}`);
  };

  const handleEquipmentFilter = (toggleValue, type) => {
    const isAgency = type === 'agency';

    if (toggleValue) {
      setMultipleLocation((state) => ({
        ...state,
        oneChecked: true,
        agencies: isAgency ? true : state.agencies,
        machine: !isAgency ? true : state.machine,
      }));

      setJobSiteRow(
        (isAgency
          ? [jobsiteRow, agenciesRow]
          : [jobsiteRow, machinesRow]
        ).flat()
      );
    } else {
      setMultipleLocation((state) => ({
        ...state,
        oneChecked: isAgency
          ? multipleLocation.machines
          : multipleLocation.agencies,
        agencies: isAgency ? false : state.agencies,
        machines: !isAgency ? false : state.machines,
      }));

      if (isAgency) {
        if (multipleLocation.machines) {
          setJobSiteRow([jobsite, machinesRow].flat());
        } else {
          setJobSiteRow([jobsite].flat());
        }
      } else if (multipleLocation.agencies) {
        setJobSiteRow([jobsite, agenciesRow].flat());
      } else {
        setJobSiteRow([jobsite].flat());
      }
    }
  };

  const updatePosition = () => {
    const { lat, lon } = newPosition.current;

    updateJobSitePosition(id, lat, lon, isInternalAppMode).then((resp) => {
      setJobSite((prevJobSite) => ({
        ...prevJobSite,
        lat: resp.lat,
        lon: resp.lon,
      }));
    });
  };

  const machineRowCells = useCallback(
    (machine) => (
      <SimpleMachineRow
        machine={machine}
        handleHistoryPush={() => handleHistoryPush('detail', machine.id)}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const agencyRowCells = useCallback(
    (agency) => (
      <AgencyRow
        agency={agency}
        handleHistoryPush={() => handleHistoryPush('agency', agency.id)}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return isLoading || !jobsite ? (
    <Loading />
  ) : (
    <Stack className="equipment-jobsite" spacing={2}>
      <Typography variant="h1">{jobsite.name || 'unknow'}</Typography>
      <TabButton
        tab={[
          {
            content: JobSiteContent(<JobsiteInformation jobsite={jobsite} />),
            label: t`Informations`,
          },
          {
            content: JobSiteContent(
              <JobSiteCarte
                jobsite={jobsite}
                multipleLocation={multipleLocation}
                jobsiteRow={jobsiteRow}
                machinesRow={machinesRow}
                agenciesRow={agenciesRow}
                displayZone={displayZone}
                drawingZone={drawingZone}
                zonePoints={zonePoints}
                setZonePoints={setZonePoints}
                setDisplayZone={setDisplayZone}
                handleEquipmentFilter={handleEquipmentFilter}
                handleMarkerDrag={(newCoords) => {
                  newPosition.current = newCoords;
                }}
              />,
              [
                <JobSiteCarteZone
                  jobsite={jobsite}
                  drawingZone={drawingZone}
                  zonePoints={zonePoints}
                  setZonePoints={setZonePoints}
                  setDisplayZone={setDisplayZone}
                  setDrawingZone={setDrawingZone}
                  handleZoneUpdated={() => loadJobSite(id)}
                />,

                <PositionModifier
                  handleIsModifying={(isDraggable) =>
                    setJobSite((prevJobSite) => ({
                      ...prevJobSite,
                      isDraggable,
                    }))
                  }
                  handleSave={updatePosition}
                />,
              ],
              () => {
                setDrawingZone((state) => !state);
              }
            ),
            label: t`Carte`,
          },
          {
            content: JobSiteContent(
              <Paper className="equipment-jobsite__map-tab" spacing={2}>
                <TableWithPagination
                  title={t`Machines`}
                  description={t`Nombre de machines`}
                  color="primary.main"
                  headers={[t`Illustration`, t`Machine`, t`Location`, '']}
                  rows={machinesRow}
                  cells={machineRowCells}
                  optionsValue={options}
                  searchFields={!isMobile ? MACHINE_FIELDS_TABLE : null}
                  checkboxHeader={
                    equipment.headersCheckbox.filter((e) => !e.checkboxes) ||
                    null
                  }
                  columnsObject={equipment.columnsObject || null}
                />
              </Paper>
            ),
            label: t`Machines`,
          },
          {
            content: JobSiteContent(
              <Paper className="equipment-jobsite__map-tab" spacing={2}>
                {equipment && (
                  <TableWithPagination
                    title={t`Agences`}
                    description={t`Nombre d'agences`}
                    color="primary.main"
                    headers={[
                      t`Nom`,
                      t`Adresse`,
                      t`Email`,
                      t`Code agence`,
                      t`Ouverture`,
                      '',
                    ]}
                    rows={agenciesRow}
                    cells={agencyRowCells}
                    optionsValue={options}
                    searchFields={!isMobile ? AGENCY_FIELDS_TABLE : null}
                  />
                )}
              </Paper>
            ),
            label: t`Agences`,
          },
          {
            content: JobSiteContent(<Box />),
            disabled: true,
            label: t`Environnement`,
          },
          {
            content: JobSiteContent(
              <Paper className="equipment-jobsite__map-tab" spacing={2}>
                {jobsite && <JobsiteSecurity jobsite={jobsite} />}
              </Paper>
            ),
            disabled: true,
            label: t`Sécurité`,
          },
        ]}
      />
    </Stack>
  );
};

export default EquipmentJobSite;
