import colours from './variables/_colors.scss';

const theme = {
  palette: {
    primary: {
      main: colours.primaryMain,
    },
    secondary: {
      main: colours.secondaryMain,
      contrastText: colours.secondaryContrast,
    },
    success: {
      main: colours.successMain,
    },
    background: {
      default: colours.backgroundDefault,
      light: colours.secondaryContrast,
    },
    text: {
      primary: colours.textPrimary,
      hover: colours.textHover,
      active: colours.textActive,
    },
    cardLink: {
      bgcolor: colours.cardLinkBgcolor,
      color: colours.secondaryContrast,
    },
    light: {
      bgcolor: colours.lightBgcolor,
    },
    tableheader: {
      bgcolor: colours.tableheaderBgcolor,
      color: colours.secondaryContrast,
    },
    search: {
      panelBgcolor: colours.searchPanelBgcolor,
    },
    navbar: {
      color1: colours.navbarColor1,
      color2: colours.navbarColor2,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: '12rem',
          color: 'blue',
        },
      },
    },
  },
  typography: {
    fontFamily: '"Barlow", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 500,
      fontSize: 38,
    },
    h2: {
      fontSize: 34,
      fontWeight: 500,
    },
    h3: {
      fontWeight: 700,
      fontSize: 26,
    },
    h4: {
      fontWeight: 700,
      fontSize: 22,
    },
    h5: {
      fontWeight: 600,
      fontSize: 18,
    },
    body1: {
      fontSize: 16,
    },
    body1Bold: {
      fontSize: 16,
      fontWeight: 600,
    },
    caption: {
      fontSize: 14,
    },
    body2Bold: {
      fontSize: 12,
      fontWeight: 600,
    },
  },
};

export default theme;
