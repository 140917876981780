import { Trans } from '@lingui/macro';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { daysArr, getMondayFromDate, getSundayFromDate } from '../utils';
import MemoizedCalendarDayBox from '../CalendarDayBox/CalendarDayBox';
import { MonthHoverTypeEnum } from '../utils.interface';
import { CalendarMonthProps } from './CalendarMonth.interface';

import './CalendarMonth.scss';

export const CalendarMonth = ({
  month,
  monthValue,
  year,
  totalDays,
  firstDayOfTheMonth,
  daysUseAndDuration,
  selectedType,
  dateHovered,
  dateClicked,
  handleDateHover,
  handleDateClick,
}: CalendarMonthProps) => {
  const mondayHover = dateHovered ? getMondayFromDate(dateHovered) : null;
  const sundayHover = dateHovered ? getSundayFromDate(dateHovered) : null;
  const mondayClick = dateClicked ? getMondayFromDate(dateClicked) : null;
  const sundayClick = dateClicked ? getSundayFromDate(dateClicked) : null;

  const borderDisplayConditon = (day: number) => {
    if (!dateHovered && !dateClicked) {
      return false;
    }

    const date = new Date(year, month - 1, day);

    switch (selectedType) {
      case MonthHoverTypeEnum.DAY: {
        const isDayHover = moment(date).isSame(dateHovered, 'day');
        const isDayClick = moment(date).isSame(dateClicked, 'day');
        return isDayHover || isDayClick;
      }
      case MonthHoverTypeEnum.WEEK: {
        const isWeekHover = moment(date).isBetween(
          mondayHover,
          sundayHover,
          'day',
          '[]'
        );
        const isWeekClick = moment(date).isBetween(
          mondayClick,
          sundayClick,
          'day',
          '[]'
        );
        return isWeekHover || isWeekClick;
      }
      case MonthHoverTypeEnum.MONTH: {
        const isMonthHover = moment(date).isSame(dateHovered, 'month');
        const isMonthClick = moment(date).isSame(dateClicked, 'month');
        return isMonthHover || isMonthClick;
      }
      default: {
        return false;
      }
    }
  };

  return (
    <div className="calendar-month">
      <h6 className="calendar-month__title">
        <Trans id={monthValue} /> (<span>{year}</span>)
      </h6>
      <div className="calendar-month__column">
        <div className="calendar-month__parent-grid">
          {daysArr.map((day) => {
            return (
              <span key={day} className="calendar-month__span">
                {day.charAt(0)}
              </span>
            );
          })}
        </div>
        <div className="calendar-month__grid">
          {[...Array(totalDays)].map((day, index) => {
            const dayInfo = daysUseAndDuration.find(
              (f: any) => f.day === index + 1
            );

            const usageDuration =
              dayInfo &&
              moment
                .utc(parseInt(dayInfo.duration, 10) * 1000)
                .format('HH:mm:ss');

            const date = new Date(year, month - 1, index + 1);

            return (
              <Tooltip
                key={index}
                title={dayInfo ? usageDuration : '0'}
                placement="top-end"
              >
                <MemoizedCalendarDayBox
                  day={index + 1}
                  date={date}
                  isDateHover={borderDisplayConditon(index + 1)}
                  metadata={{
                    firstDayOfTheMonth,
                    duration: dayInfo?.duration,
                    isUsageOutOfContract: dayInfo?.isUsageOutOfContract,
                  }}
                  handleHover={(value: number | null) => {
                    handleDateHover(
                      value ? new Date(year, month - 1, value) : null
                    );
                  }}
                  handleClick={(day: number) => {
                    handleDateClick(new Date(year, month - 1, day));
                  }}
                />
              </Tooltip>
            );
          })}
        </div>
      </div>
    </div>
  );
};
