import { createContext, useState, useContext, useEffect } from 'react';
import { JobSite } from '../api/jobsites/JobSite.interface';
import { getAllJobSite, getJobSiteById } from '../api/jobsites/JobSites';
import { useAppModeContext } from '../utils/hooks';
import ContextLoadStatus from './utils';

const JobSitesContext = createContext(null);

const JobSitesProvider = ({ children, jobsites: jobsitesInit }: any) => {
  const { isInternalAppMode } = useAppModeContext();

  const [loadStatus, setLoadStatus] = useState<ContextLoadStatus>(
    jobsitesInit ? ContextLoadStatus.LOADED : ContextLoadStatus.NOTLOADED
  );

  const [jobSites, setJobSites] = useState<JobSite[]>(jobsitesInit || []);

  const [isLoadingJobSite, setIsLoadingJobSite] = useState<boolean>(false);

  useEffect(() => {
    setJobSites(jobsitesInit || []);
    setLoadStatus(
      jobsitesInit ? ContextLoadStatus.LOADED : ContextLoadStatus.NOTLOADED
    );
  }, [jobsitesInit]);

  const loadJobSites = async () => {
    if (!isInternalAppMode) {
      setLoadStatus(ContextLoadStatus.LOADING);

      getAllJobSite().then(
        (result) => {
          setJobSites(result.jobSites);
          setLoadStatus(ContextLoadStatus.LOADED);
        },
        (error) => {
          setLoadStatus(ContextLoadStatus.ERROR);
        }
      );
    }
  };

  const loadJobSite = async (id: string) => {
    setIsLoadingJobSite(true);

    getJobSiteById(id).then((result) => {
      setJobSites((prevJobSites) => [
        ...prevJobSites.filter((j) => j.id !== result.id),
        result,
      ]);

      setIsLoadingJobSite(false);
    });
  };

  const getJobSitesLoadStatus = (): ContextLoadStatus => {
    return loadStatus;
  };

  const getJobSites = (): any[] => {
    if (loadStatus === ContextLoadStatus.NOTLOADED) {
      loadJobSites();

      return [];
    }

    return jobSites;
  };

  const getJobSite = (id: string, force: boolean): JobSite | null => {
    const jobSitesFiltered: JobSite[] = jobSites.filter((j) => j.id === id);

    if (
      (jobSitesFiltered.length === 0 && !isLoadingJobSite) ||
      (force && !isLoadingJobSite)
    ) {
      loadJobSite(id);

      return null;
    }
    return jobSitesFiltered[0];
  };

  const jobSitesData: any = {
    getJobSitesLoadStatus,
    loadJobSites,
    loadJobSite,
    getJobSites,
    getJobSite,
  };

  return (
    <JobSitesContext.Provider value={jobSitesData}>
      {children}
    </JobSitesContext.Provider>
  );
};

const useJobSitesContext = () => {
  return useContext(JobSitesContext);
};

export { JobSitesProvider, useJobSitesContext };
