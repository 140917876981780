import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';

/**
 * interactive Mapbox GL map with clusterd geoJSON data and custom popup component
 *
 * @param dataGeoJSON list of coordinates according to GeoJSON standards
 * @param pinImage image used for marker
 * @param openDetailsComponent function that takes as parameters an id, a map, and the coordinates of the element on the map in order to open the component of its choice on the map with the information relative to the id
 * @constructor
 */

export const MapGeoJSONData = ({
  dataGeoJSON,
  pinImage,
  openDetailsComponent,
}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    /* const initializeMap = () => {
      if (map.current || !dataGeoJSON.features) return;
      const sourceId = 'trackers-machines';
      const layerCluserId = 'cluster-machines';
      const layerMachineId = 'unclustered-machine';
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11?optimize=true', // style URL
        center: [2.333333, 48.866667], // starting position [lng, lat]
        zoom: 2, // starting zoom
      });
      map.current.on('load', () => {
        map.current.addSource(sourceId, {
          type: 'geojson',
          data: dataGeoJSON,
          cluster: true,
          clusterMaxZoom: 9, // Max zoom to cluster points on
          clusterRadius: 50, // Radius of each cluster when clustering points
        });
        map.current.addLayer({
          id: layerCluserId,
          type: 'circle',
          source: sourceId,
          paint: {
            'circle-color': '#FB263D',
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              20,
              100,
              30,
              750,
              40,
            ],
          },
        });
        map.current.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: sourceId,
          filter: ['has', 'point_count'],
          layout: {
            'text-field': ['get', 'point_count_abbreviated'],
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12,
          },
        });

        map.current.loadImage(pinImage, (error, image) => {
          if (error) throw error;
          const imageId = 'goobiePin';
          // Add the image to the map style.
          map.current.addImage(imageId, image);
          map.current.addLayer({
            id: layerMachineId,
            type: 'symbol',
            source: sourceId,
            filter: ['!', ['has', 'point_count']],
            layout: {
              'icon-image': imageId,
              'icon-size': 0.25,
            },
          });
        });
        // inspect a cluster on click
        map.current.on('click', layerCluserId, (e) => {
          const features = map.current.queryRenderedFeatures(e.point, {
            layers: [layerCluserId],
          });
          const clusterId = features[0].properties.cluster_id;
          map.current
            .getSource(sourceId)
            .getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err || !zoom) return;
              map.current.easeTo({
                center: features[0].geometry.coordinates,
                zoom,
              });
            });
        });
        map.current.on('click', layerMachineId, async (e) => {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const itemInfos = e.features[0].properties;

          // Ensure that if the map is zoomed out such that
          // multiple copies of the feature are visible, the
          // popup appears over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          openDetailsComponent(itemInfos.id, map.current, coordinates);
        });
      });
    };
    initializeMap(); */
  }, [dataGeoJSON, pinImage, openDetailsComponent]);

  return (
    <>
      <Box sx={{ mt: 6 }}>
        <div ref={mapContainer} style={{ height: '800px' }} />
      </Box>
    </>
  );
};
export default MapGeoJSONData;
