import { t } from '@lingui/macro';
import EquipmentSecurity from '../../../../../../components/EquipmentSecurity/EquipmentSecurity';
import { JobSite } from '../../../../../api/jobsites/JobSite.interface';
import { handleJobSiteSecurityInfo } from './JobsiteSecurity.service';
import { StyledTableCell } from '../../../../../components/molecules/TableWithPagination/UsedComponentsAndImports';
import { CellsInterface } from '../../../../../../components/EquipmentSecurity/components/SecurityTable/SecurityTable.interface';
import { getJobSiteAlertsSecurity } from '../../../../../api/jobsites/JobSites';
import { formatDuration } from '../../../../../utils/date/calcul';

interface CellInterface extends CellsInterface {
  machine: string;
  contract: string;
  dateDescription: string;
}

const generateCells = (row: CellInterface) => {
  return (
    <>
      <StyledTableCell>{t`${row.type}`}</StyledTableCell>
      <StyledTableCell>{row.machine || '-'}</StyledTableCell>
      <StyledTableCell>{row.contract || '-'}</StyledTableCell>
      <StyledTableCell>{t`${row.dateDescription}`}</StyledTableCell>
      <StyledTableCell>{formatDuration(row.duration)}</StyledTableCell>
    </>
  );
};

const JobsiteSecurity = ({ jobsite }: { jobsite: JobSite }) => {
  const securityMetricsCallback = () => {
    return handleJobSiteSecurityInfo(jobsite.id);
  };

  const securityMetricsTableCallback = () => {
    return getJobSiteAlertsSecurity({
      jobSiteId: jobsite.id,
      machineId: null,
      startDate: null,
      endDate: null,
      page: 0,
      size: 9999,
    });
  };

  return (
    <EquipmentSecurity
      clientsOptions={[]}
      jobSiteName={jobsite.name}
      GenerateTableCells={generateCells}
      headersTable={['Type', 'Machine', 'Contrat', 'Date', 'Durée']}
      metricsCallBack={securityMetricsCallback}
      metricsTableCallBack={securityMetricsTableCallback}
    />
  );
};

export default JobsiteSecurity;
