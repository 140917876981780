import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import EngineCard from '../../../../EngineCard/EngineCard';

import './StatusEngine.scss';
import { MACHINE_WORKING_STATES } from '../../../../../../../utils/utils';
import { StatusEnum } from '../../../../../../../utils/equipment/Status/EquipmentStatus.constant';
import { statusToMachineStatus } from '../../../../../../../utils/equipment/Status/EquipmentStatus.service';

export const findEngineCard = (engineRunning: boolean | null) => {
  let engineCard;

  switch (engineRunning) {
    case false: {
      engineCard = (
        <EngineCard
          status="NOTWORK"
          libelle={MACHINE_WORKING_STATES.stopped}
          icon="close"
        />
      );
      break;
    }
    case null: {
      engineCard = (
        <EngineCard
          status="UNKNOWN"
          libelle={MACHINE_WORKING_STATES.stateUndefined}
          icon="sync_problem"
        />
      );
      break;
    }
    case true: {
      engineCard = (
        <EngineCard
          status="WORK"
          libelle={MACHINE_WORKING_STATES.working}
          icon="done"
        />
      );
      break;
    }
    default: {
      engineCard = (
        <EngineCard
          status="UNKNOWN"
          libelle={MACHINE_WORKING_STATES.stateUndefined}
          icon="sync_problem"
        />
      );
    }
  }

  return <I18nProvider i18n={i18n}>{engineCard}</I18nProvider>;
};

export const findEngineStatusCard = (machineStatus: string | StatusEnum) => {
  let machineStatusCard = (
    <EngineCard
      status="UNKNOWN"
      libelle={MACHINE_WORKING_STATES.stateUndefined}
    />
  );

  const machineStatusX = statusToMachineStatus(machineStatus);

  if (machineStatusX) {
    machineStatusCard = (
      <EngineCard status={machineStatusX.key} libelle={machineStatusX.value} />
    );
  }

  return <I18nProvider i18n={i18n}>{machineStatusCard}</I18nProvider>;
};
