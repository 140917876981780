import { setup } from 'axios-cache-adapter';

const api = setup({
  baseURL: 'https://france-geojson.gregoiredavid.fr',

  // 12 hours of cache
  cache: {
    maxAge: 720 * 60 * 1000,
  },
});

export const getAllRegions = () => {
  return api.get('/repo/regions.geojson');
};

export const getAllDepartments = () => {
  return api.get('/repo/departements.geojson');
};
