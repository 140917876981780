import { t } from '@lingui/macro';
import { Button, Stack, Typography } from '@mui/material';
import SaveCancelStack from '../../../../../../components/SaveCancelStack/SaveCancelStack';
import { updateAgencyGeoZones } from '../../../../../api/agencies/Agencies';

import './AgencyGeozone.scss';

interface AgencyGeozoneProps {
  agencyId: string;
  geoZonesId: number[];

  displayRegions: boolean;
  displayDepartments: boolean;

  setDisplayRegions: (value: boolean) => void;
  setDisplayDepartments: (value: boolean) => void;
}

const AgencyGeozone = ({
  agencyId,
  geoZonesId,
  displayRegions,
  displayDepartments,
  setDisplayRegions,
  setDisplayDepartments,
}: AgencyGeozoneProps) => {
  const handleSave = () => {
    if (displayRegions && !displayDepartments) {
      updateAgencyGeoZones(
        agencyId,
        geoZonesId.map((r) => ({ type: 'region', id: r }))
      );

      setDisplayRegions(false);
    } else if (!displayRegions && displayDepartments) {
      updateAgencyGeoZones(
        agencyId,
        geoZonesId.map((r) => ({ type: 'department', id: r }))
      );

      setDisplayDepartments(false);
    }
  };

  return (
    <Stack
      className="agency-geozone"
      direction="column"
      justifyContent="space-between"
    >
      <Typography
        className="agency-geozone__title"
        variant="h3"
        sx={{ color: 'primary.main' }}
      >
        {t`Géozone`}
      </Typography>

      <Typography variant="body1" className="agency-geozone__desc">
        {t`Zones attitrées à l'ensemble des machines de l'agence`}
      </Typography>

      <Stack direction="column" spacing={1} justifyContent="space-between">
        {!displayRegions && !displayDepartments ? (
          <Button
            className="agency-geozone__region-btn"
            variant="contained"
            onClick={() => {
              setDisplayRegions(true);
            }}
          >
            {t`Choisir une ou plusieurs regions`}
          </Button>
        ) : !displayDepartments ? (
          <SaveCancelStack
            onSave={handleSave}
            onCancel={() => setDisplayRegions(false)}
          />
        ) : null}

        {!displayDepartments && !displayRegions ? (
          <Button
            className="agency-geozone__depart-btn"
            variant="contained"
            onClick={() => {
              setDisplayDepartments(true);
            }}
          >
            {t`Choisir un ou plusieurs départements`}
          </Button>
        ) : !displayRegions ? (
          <SaveCancelStack
            onSave={handleSave}
            onCancel={() => setDisplayDepartments(false)}
          />
        ) : null}
      </Stack>
    </Stack>
  );
};

export default AgencyGeozone;
