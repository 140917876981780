/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from 'react';
import { Redirect, useHistory, Route } from 'react-router-dom';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { useAppModeContext } from '../../../../app/utils/hooks';
import LoxLoginForm from './components/LoxLoginForm/LoxLoginForm';

import './Login.scss';

const Login = ({ handleLogin, isAuthenticated }) => {
  const { isInternalAppMode } = useAppModeContext();
  if (isInternalAppMode) {
    return <Redirect to="/" />;
  }

  const history = useHistory();
  const [status, setStatus] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (values) => {
    setSubmitting(true);
    handleLogin(values.email, values.password)
      .then(() => {
        history.push('/');
      })
      .catch((error) => {
        if (error && error.message) {
          setStatus(error.message);
        } else {
          setStatus(
            'Erreur lors de la demande de réinitialisation de mot de passe'
          );
        }
        setSubmitting(false);
      });
  };

  return isAuthenticated ? (
    <Route
      render={({ location }) => {
        return (
          <Redirect
            to={
              location.state?.from?.pathname
                ? location.state.from.pathname
                : '/'
            }
          />
        );
      }}
    />
  ) : (
    <Paper className="login">
      <Stack
        className="login__stack"
        justifyContent="space-around"
        alignItems="center"
        spacing={3}
      >
        <Paper className="login__paper">
          <Stack spacing={3}>
            <Box className="login__app-name">
              <Typography
                className="notranslate"
                variant="h3"
                display="inline-block"
                color="primary.main"
              >
                Lox
              </Typography>
              <Typography
                className="notranslate"
                variant="h3"
                display="inline-block"
                color="secondary.main"
              >
                Connect
              </Typography>
            </Box>
            <LoxLoginForm
              status={status}
              submitting={submitting}
              onSubmit={handleSubmit}
              initialValues={[
                {
                  id: 'email',
                  value: '',
                },
                {
                  id: 'password',
                  value: '',
                },
              ]}
            />
          </Stack>
        </Paper>
      </Stack>
    </Paper>
  );
};

export default Login;
