import {
  genereteKeyBorrowedStatus,
  statusMap,
} from './EquipmentStatusIcon.constant';
import { StatusEnumProps } from '../../utils/equipment/Status/EquipmentStatus.constant';
import { MachineStatus } from '../../utils/equipment/Status/EquipmentStatus.service';

export const findIconByMachineStatus = (machineStatus: MachineStatus) => {
  if (machineStatus.isBorrowed) {
    return findIconByBorrowedStatus(machineStatus.status);
  }

  return findIconByStatus(machineStatus.status);
};

export const findIconByBorrowedStatus = (machineStatus: StatusEnumProps) => {
  return statusMap.get(genereteKeyBorrowedStatus(machineStatus));
};

export const findIconByStatus = (machineStatus: StatusEnumProps) => {
  return statusMap.get(machineStatus);
};
