/* eslint-disable @typescript-eslint/no-var-requires */
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
} from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { RecoilRoot } from 'recoil';
import { Loading } from '../../lib/apptheme';
import { checkToken } from '../api/users/Users';
import UserContext from '../contexts/UserContext/UserContext';
import { defaultLocale, dynamicActivate } from '../i18n';
import AppModeContext from '../utils/context/AppModeContext';
import { getAppMode } from '../utils/context/utils';
import FrameApp from './Frame/FrameApp';
import NoApp from './Frame/components/NoApp/NoApp';
import PlainAppAuthSource from './PlanAppAuthSource';

import '../assets/scss/styles.scss';

const appMode = getAppMode();

const theme = createTheme(
  appMode.isInternalAppMode
    ? require('../theme/internal/theme').default
    : require('../theme/external/theme').default
);

function App() {
  const [isLoading, setLoading] = useState(true);
  const [isValid, setValid] = useState(false);
  const [isFrame, setFrame] = useState(false);
  const [user, setUser] = useState(null);
  // eslint-disable-next-line no-unused-vars

  const getQueryVariable = (variable) => {
    const query = window.location.search.substring(1);

    const vars = query.split('&');

    // eslint-disable-next-line no-restricted-syntax
    for (const v of vars) {
      const pair = v.split('=');
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  };

  useEffect(() => {
    const effect = async () => {
      const mode = getQueryVariable('mode');
      const token = getQueryVariable('apiKey');
      let lang = getQueryVariable('lang');
      if (mode === 'iframe') {
        try {
          const langRex = '([a-z]+)_([A-Z]+)';
          const m = lang.match(langRex);
          if (m) {
            // eslint-disable-next-line prefer-destructuring
            lang = m[2];
            dynamicActivate(m[1]);
          } else {
            dynamicActivate(defaultLocale);
          }
          const usr = await checkToken();
          setUser({
            iframe: true,
            id: usr.userId,
            firstName: usr.firstName,
            lastName: usr.lastName,
            email: usr.email,
            role: usr.auth[0].authority,
            token,
            lang,
          });
          setValid(true);
        } catch (e) {
          console.log('e', e);
        }
        setFrame(true);
      } else {
        dynamicActivate(defaultLocale);
      }
      setLoading(false);
    };
    effect();
  }, []);

  let iframeModeApp;
  if (isFrame) {
    iframeModeApp = isValid ? (
      <UserContext.Provider value={user}>
        <FrameApp />
      </UserContext.Provider>
    ) : (
      <NoApp />
    );
  }
  const loadedApp = isFrame ? (
    iframeModeApp
  ) : (
    <AppModeContext.Provider value={appMode}>
      <PlainAppAuthSource />
    </AppModeContext.Provider>
  );
  return (
    <MUIThemeProvider theme={theme}>
      <I18nProvider i18n={i18n}>
        <RecoilRoot>{isLoading ? <Loading /> : loadedApp}</RecoilRoot>
      </I18nProvider>
    </MUIThemeProvider>
  );
}

export default App;
