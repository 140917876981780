import { Machine } from '../../../api/machines/Machine.interface';
import { keysName } from '../../../pages/equipments/constants';
import { isDateBellow24h } from '../../date/calcul';

interface MachineDescState {
  on: number;
  off: number;
  null: number;
  totalMachines: number;
}

type MachineWithState = Machine & { state?: keysName };

const getMachinesState = (
  machine: MachineWithState,
  prevMachineState: MachineDescState
) => {
  if (machine.engineRunning) {
    return { on: prevMachineState.on + 1 };
  }

  if (
    machine.engineRunning === null ||
    !isDateBellow24h(machine.lastSyncTime)
  ) {
    return { null: prevMachineState.null + 1 };
  }

  return { off: prevMachineState.off + 1 };
};

const getMachineStatusCount = (
  machines: MachineWithState[]
): MachineDescState => {
  const filteredMachines =
    machines.filter(({ state }) => state === keysName.Machines) ?? [];
  const baseMachinesState = {
    on: 0,
    off: 0,
    null: 0,
    totalMachines: filteredMachines?.length ?? 0,
  };

  const machinesState = machines.reduce(
    (acc, machine) => ({ ...acc, ...getMachinesState(machine, acc) }),
    baseMachinesState
  );

  return machinesState;
};

export default getMachineStatusCount;
