import { Trans } from '@lingui/macro';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Stack } from '@mui/material';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import IsMobile from '../../../utils/IsMobile';
import './Filter.scss';

const Filter = ({ children }) => {
  const [isFiltersClicked, setIsFiltersClicked] = useState(false);

  const isMobile = IsMobile();

  const handleClose = useCallback(() => {
    setIsFiltersClicked((prevState) => !prevState);
  }, []);

  if (React.Children.count(children) !== 1) {
    return (
      <div>
        <Trans>Le composant Filter doit avoir exactement un enfant.</Trans>
      </div>
    );
  }

  const firstChildren = React.Children.only(children);

  const overLoadedChildren = React.cloneElement(firstChildren, {
    isFiltersClicked,
    handleClose,
  });

  return (
    <>
      <Stack>
        <Box
          className="filter"
          sx={{
            backgroundColor: 'primary.main',
            '&:hover': {
              color: 'primary.main',
            },
          }}
          onClick={handleClose}
        >
          <FilterAltIcon
            className={classNames({
              'filter__icon--desktop-font-size': !isMobile,
            })}
          />
        </Box>
      </Stack>
      {isFiltersClicked && overLoadedChildren}
    </>
  );
};

export default Filter;
