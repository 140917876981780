import { Trans } from '@lingui/macro';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Chip, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { isEqual } from 'lodash';
import {
  Children,
  useEffect,
  useMemo,
  useRef,
  useState,
  lazy,
  Suspense,
} from 'react';
import ButtonPositionsView from '../../../components/molecules/ButtonPositionsView/ButtonPositionsView';
import { CURRENT } from '../../../components/molecules/ButtonPositionsView/ButtonPositionsView.constants';
import { LegendAsideBox } from '../../../components/organisms';
import IsMobile from '../../../utils/IsMobile';
import { useAppModeContext } from '../../../utils/hooks';
import { updateStatsWhenMachinesFilter } from '../Equipment/Equipment.service';
import { useEquipmentPageState } from '../Equipment/Equipment.state';
import {
  getFiltersLength,
  initFilters,
  keysName,
  legends,
  obj,
  testIfThereIsLocalisation,
} from './utils';
import { MACHINE_WORKING_STATES } from '../../../utils/utils';
import { Loading } from '../../../../lib/apptheme';
import { useUserContext } from '../../../contexts/UserContext/UserContext';

import './EquipmentListAndMap.scss';

const FleetMapAll = lazy(() => import('../Map/FleetMapAll/FleetMapAll'));

const EquipmentListAndMap = ({
  equipment,
  setState,
  dataDescription,
  parentFilters,
  checkboxesStatesResp,
}) => {
  const { isInternalAppMode } = useAppModeContext();
  const [pageState, setPageState] = useEquipmentPageState();

  const user = useUserContext();
  const isDR = user.mergedProfil === 'DR';

  const isCurrentView = useMemo(
    () => pageState.currentView === CURRENT,
    [pageState.currentView]
  );
  const haveSeeAllFilter = useMemo(
    () => equipment.value === keysName.seeAll,
    [equipment.value]
  );
  const haveMachinesFilter = useMemo(
    () => equipment.value === keysName.machines,
    [equipment.value]
  );
  const showSwitchMachinePosition = useMemo(
    () => isInternalAppMode && (haveMachinesFilter || haveSeeAllFilter),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [equipment.value]
  );
  const [isLocalisation, setIsLocalisation] = useState(true);
  const [filters, setFilters] = useState({});
  const [filtersLength, setFiltersLength] = useState(0);
  const dataPrepared = useRef([]);
  const [dataFormated, setDataFormated] = useState([]);
  const isMobile = IsMobile();

  const resetStates = () => {
    const filtersInit = {};

    filtersInit.Etat = {
      values: equipment.headersCheckbox
        .find((el) => el.name === 'Etat')
        .keys.map((v) => v)
        .sort(),
      isCheckBox: true,
    };

    parentFilters.set(filtersInit);
    setFilters(filtersInit);
  };

  const filterMarkers = () => {
    if (parentFilters) {
      const { columnsObject } = equipment;

      const filtersObj = parentFilters.get;

      if (getFiltersLength(parentFilters.get) > 0) {
        const filtersArr = Object.keys(filtersObj);
        const data = dataPrepared.current;
        const newFilteredDate = [];

        data.forEach((d) => {
          let isEqualToAll = 0;
          filtersArr.forEach((f) => {
            let isEqual = 0;

            if (filtersObj[f].isCheckBox) {
              filtersObj[f].values.forEach((v) => {
                const key = columnsObject[f];
                const rowValue = key.search(d) || '';

                if (rowValue.toLowerCase().includes(v.toLowerCase())) {
                  isEqual = 1;
                }
              });
              if (isEqual === 1) {
                isEqualToAll += 1;
              }
            } else {
              filtersObj[f].values.forEach((v) => {
                const key = columnsObject[f];
                const rowValue = key.search(d) || '';

                if (rowValue.toLowerCase().includes(v.toLowerCase())) {
                  isEqual += 1;
                }
              });
              if (isEqual === filtersObj[f].values.length) {
                isEqualToAll += 1;
              }
            }
          });
          if (isEqualToAll === filtersArr.length) {
            newFilteredDate.push(d);
          }
        });

        if (equipment.value === keysName.machines) {
          setDataFormated((prevDataFormated) => {
            if (
              !isEqual(prevDataFormated, newFilteredDate) &&
              !isEqual(equipment.data.machines, newFilteredDate)
            ) {
              setState((prevState) => prevState);
              setPageState({
                ...pageState,
                stats: updateStatsWhenMachinesFilter(
                  newFilteredDate,
                  isCurrentView
                ),
              });
            }
            return newFilteredDate;
          });
        } else {
          setDataFormated(newFilteredDate);
        }

        testIfThereIsLocalisation(dataFormated, setIsLocalisation);
      }
    }
  };

  useEffect(() => {
    changeFilter(equipment.value);
    dataPrepared.current = prepareData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipment.value, equipment.data.length, equipment.data, isCurrentView]);

  useEffect(() => {
    if (dataFormated) {
      testIfThereIsLocalisation(dataPrepared.current, setIsLocalisation);
    }

    if (haveMachinesFilter && parentFilters) {
      initFilters(setFilters, parentFilters.get);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPrepared.current, parentFilters]);

  useEffect(() => {
    const filtersLength = getFiltersLength(parentFilters.get);
    setFiltersLength(filtersLength);
    if (filtersLength === 0 && haveMachinesFilter) {
      resetStates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPrepared.current, filters, parentFilters]);

  useEffect(() => {
    if (
      JSON.stringify(Object.keys(filters)) !==
      JSON.stringify(Object.keys(parentFilters.get))
    ) {
      setFilters(parentFilters.get);
    }
    // TODO - fix eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentFilters]);

  const removeFilterOnClick = ({ header, value }) => {
    const removedFilter = { ...filters[header] };
    const nex = [...removedFilter.values].filter((el) => el !== value);
    // should reverse undefined and stopped ?
    removedFilter.values =
      header === 'Etat' && nex.length === 0
        ? [
            MACHINE_WORKING_STATES.working,
            MACHINE_WORKING_STATES.undefined,
            MACHINE_WORKING_STATES.stopped,
          ]
        : nex;

    const newFilters = (oldFilters) => {
      const filtersObj = {
        ...oldFilters,
      };

      if (removedFilter.values.length === 0) {
        delete filtersObj[header];
      } else {
        filtersObj[header] = removedFilter;
      }

      return filtersObj;
    };

    setFilters((f) => newFilters(f));
    parentFilters.set((f) => newFilters(f));
  };

  function generateChipChildren(filters, el) {
    return filters[el].values.map((val) => {
      const isCheckBoxState = false;

      return (
        <Chip
          className="equipment-list-and-map-filter-chip"
          label={<Trans id={val} />}
          variant="outlined"
          sx={{
            backgroundColor: 'primary.main',
          }}
          deleteIcon={<CloseIcon />}
          onDelete={() =>
            removeFilterOnClick({
              header: el,
              value: val,
              filters,
              setFilters,
              isCheckBoxState,
              parentFilters,
            })
          }
        />
      );
    });
  }

  const printChip = () => {
    return Object.keys(filters)
      .filter(
        (el) =>
          !(
            filters[el].isCheckBox &&
            filters[el].values.length ===
              checkboxesStatesResp.filter((f) => f.header === el)[0]?.values
                .length
          )
      )
      .map((el) => {
        // eslint-disable-next-line consistent-return
        return (
          <Stack
            className="equipment-list-and-map-filter"
            color="primary.main"
            direction="row"
            spacing={1}
            key={`stack-filter-${el}`}
          >
            {filters[el].values.length > 0 && (
              <>
                {!isMobile && (
                  <Typography className="equipment-list-and-map-filter-header">
                    <Trans id={el} />:
                  </Typography>
                )}

                {Children.map(
                  generateChipChildren(filters, el),
                  (item) => item
                )}
              </>
            )}
          </Stack>
        );
      });
  };
  useEffect(() => {
    setDataFormated(dataPrepared.current);
    setPageState({
      ...pageState,
      stats: updateStatsWhenMachinesFilter(dataPrepared.current, isCurrentView),
    });
    // TODO - fix eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPrepared.current, isCurrentView]);

  useEffect(() => {
    if (haveMachinesFilter) {
      filterMarkers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, parentFilters]);

  const changeView = (value) => {
    const isCurrent = value === CURRENT;

    setPageState({
      ...pageState,
      currentView: value,
      showExtract: isDR || !isCurrent,
      titleExtract: isCurrent ? null : 'Goobies',
    });
  };

  const changeFilter = (value) => {
    setPageState({
      ...pageState,
      filters: {
        ...pageState.filter,
        type: value,
      },
    });
  };

  const prepareData = () => {
    if (haveSeeAllFilter) {
      return isCurrentView
        ? equipment.data.filter((el) => el.state !== 'goobie')
        : equipment.data.filter((el) => el.state !== 'Machines');
    }
    if (haveMachinesFilter) {
      return isCurrentView
        ? equipment.data.machines.data
        : equipment.data.goobies.data;
    }

    return equipment.data[obj[equipment.value]];
  };

  return (
    <Stack className="equipment-list-and-map-container">
      {showSwitchMachinePosition && (
        <ButtonPositionsView
          isCurrentView={isCurrentView}
          onChange={changeView}
        />
      )}
      <Suspense fallback={<Loading />}>
        {equipment.enableFiltersOptions && printChip().length > 0 && (
          <Stack
            direction="row"
            className="equipment-list-and-map-filters-options-container"
          >
            <Stack
              direction="row"
              className="equipment-list-and-map-filters"
              spacing={1}
            >
              {filters && printChip()}
            </Stack>
            <Stack className="equipment-list-and-map-filters-reset">
              <Button onClick={resetStates} disabled={!filtersLength > 0}>
                <Typography
                  className="equipment-list-and-map-filters-reset__btn"
                  sx={{
                    color: !filtersLength ? 'cardLink.bgcolor' : 'primary.main',
                  }}
                >
                  <Trans>Réinitialiser</Trans>
                </Typography>
              </Button>
            </Stack>
          </Stack>
        )}
        {dataDescription()}
        <Box className="equipment-list-and-map-box">
          <FleetMapAll
            locations={!dataFormated || !isLocalisation ? [] : dataFormated}
          />
        </Box>
        <LegendAsideBox legends={legends} />
      </Suspense>
    </Stack>
  );
};

export default EquipmentListAndMap;
