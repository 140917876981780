import { t } from '@lingui/macro';

export const localStorageTranslatedKey = {
  Agency: t`Agence`,
  JobSite: t`Chantier`,
  Machine: t`Machine`,
  'Bon de commande': t`Bon de commande`,
};

export const CATEGORY_MACHINE = 'machine';
export const CATEGORY_JOBSITE = 'jobsite';
export const CATEGORY_AGENCY = 'agency';
export const CATEGORY_CONTRACT = 'contract';
