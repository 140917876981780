/* eslint-disable prettier/prettier */
/* eslint-disable import/no-unresolved */
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { useEffect, useState } from 'react';

import { Autocomplete, TextField } from '@mui/material';


const AutoCompleteSelect = ({
    options,
    defaultIndex = 0,
    updateParentIndex,
    className = '',
}) => {
    const cleanOptions = Array.from(new Set(options)).filter(opt => opt.label.match(/[a-zA-Z0-9]/g))
    const [state, setState] = useState({
        value: cleanOptions[defaultIndex]?.label || cleanOptions[0]?.label,
        index: defaultIndex,
    });

    const initDefault = (e) => {
        setState(() => {
            return {
            value: cleanOptions[defaultIndex]?.label || cleanOptions[0]?.label,
            index: defaultIndex,
            }
        })
        /* if (updateParentIndex) {
            updateParentIndex(
                options[defaultIndex]?.label || options[0]?.label
            );
        } */
    }

    const handleChange = (event) => {
        if (event.target.outerText) {
            const index = cleanOptions.map((e) => e.label).indexOf(event.target.outerText);
            setState(() => {
                return { value: event.target.outerText, index }
            });
            if (updateParentIndex) {
                updateParentIndex(
                    cleanOptions.map((e) => e.label).indexOf(event.target.outerText)
                );
            } 
        } 
    };

    useEffect(() => {
        if (defaultIndex === 0) {
            setState({
                value: cleanOptions[defaultIndex]?.label || cleanOptions[0]?.label,
                index: defaultIndex,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultIndex])

    return (
        <Box className={`select-options ${className}`}>
            <FormControl fullWidth variant="filled">
                {state && (
                    <Autocomplete
                        disablePortal
                        className="select-options__select"
                        labelid="simple-select-label"
                        id="simple-select"
                        options={cleanOptions}
                        value={state.value}
                        sx={{ width: 300 }}
                        onChange={handleChange}
                        disableClearable
                        freeSolo 
                        onInputChange={initDefault}
                        renderInput={(params) => <TextField {...params} />}
                    />
                )}
            </FormControl>
        </Box>
    );
};

export default AutoCompleteSelect;
