/* eslint-disable import/no-cycle */

import React, { createElement } from 'react';

import { TopBarItemRight } from '.';

const Burger = ({ toggle }) => (
  <div className="top-bar-item px-2 d-md-none d-lg-none d-xl-none">
    <button
      className="hamburger hamburger-squeeze"
      type="button"
      onClick={toggle}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </button>
  </div>
);

const Header = ({
  toggle,
  topBarItemRightComponent,
  headerNavs,
  userDropdownItems,
}) => (
  <header className="app-header app-header-dark">
    <div className="top-bar">
      <div className="top-bar-list">
        <Burger toggle={toggle} />
        {topBarItemRightComponent ? (
          createElement(topBarItemRightComponent)
        ) : (
          <TopBarItemRight
            headerNavs={headerNavs}
            userDropdownItems={userDropdownItems}
          />
        )}
      </div>
    </div>
  </header>
);

export default Header;
