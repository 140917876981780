import PropTypes from 'prop-types';
import { Grid, Paper, Stack } from '@mui/material';
import classNames from 'classnames';
import { MachineIcon } from '../../MachineIcon/MachineIcon';

import './DeviceDetailContainer.scss';

const DeviceDetailContainer = ({
  machine,
  children,
  className,
  geoLocLastInfo,
}) => (
  <Grid
    className="device-detail-container"
    container
    spacing={1}
    alignItems="flex-start"
  >
    <Grid item xs={12} lg={2}>
      <Paper className="device-detail-container__paper">
        <Stack direction="row" justifyContent="center" alignItems="center">
          {machine && <MachineIcon pim={machine.pim} pimKey="thumbnail" />}
        </Stack>
      </Paper>
    </Grid>
    <Grid item xs={12} lg={10}>
      <Paper
        className={classNames('device-detail-container__paper', {
          [className]: className,
        })}
      >
        {children}
      </Paper>
    </Grid>
  </Grid>
);

export default DeviceDetailContainer;
DeviceDetailContainer.propTypes = {
  children: PropTypes.node.isRequired,
  machine: PropTypes.shape({
    address: PropTypes.string,
    agencyId: PropTypes.string,
    agencyName: PropTypes.string,
    battery: PropTypes.string,
    brand: PropTypes.string,
    buildYear: PropTypes.number,
    catClass: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    currentContractId: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    descriptions: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    distance: PropTypes.string,
    engineRunning: PropTypes.bool,
    estimatedEnd: PropTypes.string,
    externalIds: PropTypes.object,
    height: PropTypes.number,
    id: PropTypes.string,
    jobSiteDesc: PropTypes.string,
    jobSiteId: PropTypes.string,
    lastSyncTime: PropTypes.string,
    lat: PropTypes.string,
    length: PropTypes.number,
    lon: PropTypes.string,
    machineGroupId: PropTypes.string,
    model: PropTypes.string,
    name: PropTypes.string,
    pim: PropTypes.shape({
      apoximate: PropTypes.bool,
      code: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          format: PropTypes.string,
          imageType: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    }),
    rentalStart: PropTypes.string,
    rentalmanId: PropTypes.string,
    serialNumber: PropTypes.string,
    statusCode: PropTypes.string,
    vin: PropTypes.string,
    weight: PropTypes.number,
    width: PropTypes.number,
    zipCode: PropTypes.string,
  }).isRequired,
  style: PropTypes.shape({}),
};

DeviceDetailContainer.defaultProps = {
  style: null,
};
