// Molecule Component !
import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { SearchInput } from '../../components/molecules';
import getMachineStatusCount from '../../utils/equipment/machine/getMachineStatusCount';
import {
  AGENCY_FIELDS,
  AGENCY_FIELDS_TABLE,
  JOBSITE_FIELDS,
  JOBSITE_FIELDS_TABLE,
  MACHINE_FIELDS,
  MACHINE_FIELDS_TABLE,
  createSearchQuery,
  extractSearcMatchedData,
  generateLunrIndex,
} from '../../utils/utils';
import { keysName } from './constants';

const SearchLunr = ({ allData, state, setState, userIsOnList }) => {
  const keyName = state.value;
  const [machinesLunrIdx, setMachinesLunrIdx] = useState();
  const [jobSitesLunrIdx, setJobSitesLunrIdx] = useState();
  const [agenciesLunrIdx, setAgenciesLunrIdx] = useState();

  const localState = useRef(state);
  const searchValue = useRef();

  // hacky way of not overwrite search results after apply filters
  useEffect(() => {
    let isChanged;

    if (Array.isArray(state.data)) {
      isChanged = !isEqual(state.data, localState.current);
    } else if (Array.isArray(state.data.allMachines)) {
      isChanged = !isEqual(state.data.allMachines, localState.current);
    } else {
      isChanged = !isEqual(
        Object.values(state.data)
          .flatMap((t) => t)
          .filter((t) => typeof t === 'object'),
        localState.current
      );
    }

    if (searchValue.current && isChanged) {
      search(searchValue.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    // build lunr index
    setMachinesLunrIdx(
      generateLunrIndex(
        userIsOnList ? MACHINE_FIELDS_TABLE : MACHINE_FIELDS,
        allData.machines.allMachines
      )
    );
    // create jobsite lunr index
    setJobSitesLunrIdx(
      generateLunrIndex(
        userIsOnList ? JOBSITE_FIELDS_TABLE : JOBSITE_FIELDS,
        allData.jobsites.jobSites
      )
    );
    // create agencies index
    setAgenciesLunrIdx(
      generateLunrIndex(
        userIsOnList ? AGENCY_FIELDS_TABLE : AGENCY_FIELDS,
        allData.agencies.agencies
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsOnList]);

  // search an update state
  const searchIntoGoobies = (key) => {
    const goobies =
      key.search.length > 2 && machinesLunrIdx
        ? extractSearcMatchedData(
            allData.machines.goobies.data,
            machinesLunrIdx.search(createSearchQuery(key.search))
          )
        : allData.machines.goobies.data;

    return goobies;
  };
  const searchIntoMachines = (key) => {
    const machines =
      key.search.length > 2 && machinesLunrIdx
        ? extractSearcMatchedData(
            allData.machines.machines.data,
            machinesLunrIdx.search(createSearchQuery(key.search))
          )
        : allData.machines.machines.data;

    return machines;
  };

  const searchIntoJobsites = (key, updateState) => {
    const jobSites =
      key.search.length > 2
        ? extractSearcMatchedData(
            allData.jobsites.jobSites,
            jobSitesLunrIdx.search(createSearchQuery(key.search))
          )
        : allData.jobsites.jobSites;

    if (updateState) {
      localState.current = jobSites;
      setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          jobSites,
          totalElements: jobSites.length,
        },
      }));
    }
    return jobSites;
  };

  const searchIntoAgencies = (key, updateState) => {
    const agencies =
      key.search.length > 2
        ? extractSearcMatchedData(
            allData.agencies.agencies,
            agenciesLunrIdx.search(createSearchQuery(key.search))
          )
        : allData.agencies.agencies;
    if (updateState) {
      localState.current = agencies;
      setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          agencies,
          totalElements: agencies.length,
        },
      }));
    }
    return agencies;
  };

  const search = (key) => {
    if (keyName === keysName.Machines) {
      const machines = searchIntoMachines(key);
      const goobies = searchIntoGoobies(key);

      localState.current = [...machines, ...goobies];

      setState((prevState) => {
        const machinesState = getMachineStatusCount(machines);
        return {
          ...prevState,
          data: {
            ...prevState.data,
            machines: {
              data: machines,
              ...machinesState,
              totalElements: machines.length,
            },
            goobies: {
              data: goobies,
              totalElements: goobies.length,
            },
            allMachines: [...machines, ...goobies],
          },
        };
      });
    }
    if (keyName === keysName.JobSites) {
      searchIntoJobsites(key, true);
    }
    if (keyName === keysName.Agencies) {
      searchIntoAgencies(key, true);
    }
    if (keyName === keysName.SeeAll) {
      const machines = searchIntoMachines(key, false);
      const jobSites = searchIntoJobsites(key, false);
      const agencies = searchIntoAgencies(key, false);
      const goobies = searchIntoGoobies(key, false);

      localState.current = [...machines, ...goobies, ...jobSites, ...agencies];

      setState((prevState) => ({
        ...prevState,
        data: [...machines, ...goobies, ...jobSites, ...agencies],
        dataAll: {
          nbGoobies: goobies.length,
          nbMachines: machines.length,
          nbJobSites: jobSites.length,
          nbAgencies: agencies.length,
        },
      }));
    }
  };

  return (
    <SearchInput
      id="lunr-search-input"
      isLoading={false}
      performSearch={(key) => {
        searchValue.current = key;
        search(key);
      }}
    />
  );
};

export default SearchLunr;
