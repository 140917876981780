import { t } from '@lingui/macro';
import { Stack } from '@mui/material';
import Icon from '@mui/material/Icon';
import classNames from 'classnames';
import moment from 'moment';
import { FunctionComponent } from 'react';
import { MachineIcon } from '../../../../../components/MachineIcon/MachineIcon';
import { getFullDate } from '../../../../../utils/date/format';
import {
  MachineStatus,
  isToHideMachineDetails,
} from '../../../../../utils/equipment/Status/EquipmentStatus.service';
import StatusEngine from './components/StatusEngine/StatusEngine';

import CopyIcon from '../../../../../assets/images/copy.svg';
import '../MarkerPopup.scss';
import './MachineMarkerPopup.scss';

interface MachineMarkerPopupProps {
  machine: any;
  title: string;
  machineStatus: MachineStatus;
  isInternalAppMode: any;
}

interface GoogleURL {
  lat: string;
  lon: string;
  zoom: number;
}

const MachineMarkerPopup: FunctionComponent<MachineMarkerPopupProps> = ({
  machine,
  title,
  machineStatus,
  isInternalAppMode,
}) => {
  const {
    id,
    pim,
    agencyId,
    agencyName,
    jobSiteId,
    jobSiteLocation,
    jobSiteDesc,
    estimatedEnd,
    rentalStart,
    lastSyncTime,
    gbLastSyncTime,
    state,
    engineRunning,
    equipmentStatusKey,
  } = machine;
  const actualDate = new Date();
  const hideInformation = isToHideMachineDetails(machineStatus);
  const isGoobie = state === 'goobie';
  const machineEndLocationDate = new Date();

  const { lat, lon, gbLat, gbLong } = machine;

  const latLonData = {
    lat: isGoobie ? gbLat : lat,
    lon: isGoobie ? gbLong : lon,
    zoom: 18,
  };

  machineEndLocationDate.setFullYear(moment(estimatedEnd).year());
  machineEndLocationDate.setMonth(moment(estimatedEnd).month() + 1);
  machineEndLocationDate.setDate(moment(estimatedEnd).date());

  const getGoogleMapLocationString = ({ lat, lon, zoom }: GoogleURL) => {
    return `https://www.google.fr/maps/search/?api=1&query=${lat},${lon}&z=${zoom}`;
  };

  return (
    <div
      className={classNames(
        `infowindow-${state}-${id} marker-popup machine-marker-popup`
      )}
    >
      <div className={classNames('marker-popup__header')}>
        <div className="marker-popup__header-image">
          <MachineIcon
            className="marker-popup__header-image-size"
            pimKey="product"
            pim={pim}
          />
        </div>

        <h6
          className="linkToDetailsPageEquipmentMachine marker-popup__header-title"
          id={id}
        >
          {title}
        </h6>

        {isInternalAppMode && equipmentStatusKey && (
          <StatusEngine machineStatus={equipmentStatusKey || null} />
        )}
      </div>
      <div className="marker-popup__information-wrapper">
        <div className="machine-marker-popup__agency-info">
          <span>
            <b>{t`Agence`}:</b> {agencyName}
          </span>
          <div className="machine-marker-popup__links">
            <span
              className="linkToDetailsPageInfoIcon machine-marker-popup__icons"
              id={agencyId}
              data-name="Agency"
            />
          </div>
        </div>

        {!hideInformation && (
          <>
            {jobSiteId && jobSiteDesc && (
              <div className="machine-marker-popup__jobsite-info">
                {jobSiteDesc && (
                  <span>
                    <b>{t`Chantier`}:</b> {jobSiteDesc}
                  </span>
                )}
                <div className="machine-marker-popup__links">
                  <span
                    className="linkToDetailsPageInfoIcon"
                    id={jobSiteId}
                    data-name="JobSite"
                  />
                </div>
              </div>
            )}
            {rentalStart && estimatedEnd && (
              <span>
                <b>{t`Location`} :</b>
                <span className="machine-marker-popup__rent-info-date">
                  &nbsp;{moment(rentalStart).format('DD/MM/YYYY')}
                  &nbsp;{t`au`}&nbsp;
                  {moment(estimatedEnd).format('DD/MM/YYYY')}&nbsp;
                </span>
              </span>
            )}
            {actualDate > machineEndLocationDate && (
              <span className="machine-marker-popup__rent-date-expired">{t`Attention la date de retour est dépassée`}</span>
            )}

            {lastSyncTime && !isGoobie && (
              <Stack direction="column">
                <span>
                  <b>{t`Coordonnées`}:</b> {machine.lat || '-'},{' '}
                  {machine.lon || '-'}
                </span>
                <Stack direction="row" sx={{ gap: '5px' }}>
                  <span>{` ${
                    machine.gpsFixTime
                      ? `(${getFullDate(machine.gpsFixTime)})`
                      : ''
                  }`}</span>
                  <button
                    id={`copy-coordinates-button-${id}`}
                    type="button"
                    aria-label="Copy coordinates"
                    style={{
                      height: '20px',
                      width: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      border: 'none',
                      background: 'transparent',
                      padding: 0,
                      cursor: 'pointer',
                    }}
                  >
                    <img
                      src={CopyIcon}
                      alt=""
                      style={{
                        height: '100%',
                        width: '100%',
                      }}
                    />
                  </button>
                  <a
                    href={getGoogleMapLocationString(latLonData)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon className="machine-marker-popup__google-icon">
                      map
                    </Icon>
                  </a>
                </Stack>
              </Stack>
            )}
            {gbLastSyncTime && isGoobie && (
              <span>
                <b>{t`Coordonnées Goobie`}:</b> {machine.gbLat || '-'},{' '}
                {machine.gbLong || '-'}{' '}
                {` (${getFullDate(machine.gbLastSyncTime)})`}
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MachineMarkerPopup;
