/* eslint-disable react/require-default-props */
import { SecureRoute } from '@okta/okta-react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useAppModeContext } from './hooks';

function PrivateRoute(props) {
  const { isInternalAppMode } = useAppModeContext();
  const { children, isAuthenticated, ...rest } = props;

  const internalPrivateRoute = (
    <Route
      {...rest}
      render={({ location }) => {
        return isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
  return isInternalAppMode ? (
    <SecureRoute {...rest}>{children}</SecureRoute>
  ) : (
    internalPrivateRoute
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
  isAuthenticated: PropTypes.bool,
};

export default PrivateRoute;
