import { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { useMachinesContext } from '../../app/contexts/MachinesContext';
import { getEquipmentDescription } from '../../app/utils/utils';
import CustomSelect from '../CustomSelect/CustomSelect';
import { SelectedAllOptions } from '../JobSiteSelector/JobSiteSelector.interface';

interface MachinesByJobSiteSelectorProps {
  jobSiteId: string;
  handleChange: (machine: string) => void;
  customStyle?: any;
}

const MachinesByJobSiteSelector = ({
  jobSiteId,
  handleChange,
  customStyle,
}: MachinesByJobSiteSelectorProps) => {
  const { getMachinesByJobSite }: any = useMachinesContext();

  const [selectedMachine, setSelectedMachine] = useState(SelectedAllOptions);

  const [machinesOptions, setMachinesOptions] = useState([
    machineDefaultOption,
  ]);

  useEffect(() => {
    setSelectedMachine(SelectedAllOptions);
    fetchMachines();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobSiteId]);

  const fetchMachines = async () => {
    const response = await getMachinesByJobSite(jobSiteId);

    setMachinesOptions([
      machineDefaultOption,
      ...response.map((mach: any) => ({
        label: `${getEquipmentDescription(mach, mach.model)} ${
          mach.rentalmanId && `(${mach.rentalmanId})`
        }`,
        value: mach.id,
      })),
    ]);
  };

  return (
    <CustomSelect
      value={selectedMachine}
      handleChange={(id: string) => {
        setSelectedMachine(id);
        handleChange(id);
      }}
      options={machinesOptions}
      style={{ ...defaultStyle, ...customStyle }}
    />
  );
};

const machineDefaultOption = {
  label: t`Toutes les machines`,
  value: SelectedAllOptions,
};

const defaultStyle = {
  width: `15vw`,
  heigth: '2.5rem',
};

export default MachinesByJobSiteSelector;
