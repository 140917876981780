import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { t } from '@lingui/macro';
import 'react-phone-number-input/style.css';

import { Grid, Paper } from '@mui/material';
import { Loading } from '../../../../../lib/apptheme';

import { getUserById, updateUser } from '../../../../api/users/Users';
import { LoxForm } from '../../../../components/molecules';
import { useUserContext } from '../../../../contexts/UserContext/UserContext';

import './SettingsProfile.scss';

const SettingsProfile = () => {
  const history = useHistory();
  const user = useUserContext();
  const [isLoading, setLoading] = useState(true);
  const [submitStatus, setSubmitStatus] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState([
    {
      id: 'email',
      value: '',
    },
    {
      id: 'firstName',
      value: '',
    },
    {
      id: 'lastName',
      value: '',
    },
    {
      id: 'phone',
      value: '',
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      getUserById(user.id)
        .then((usr) => {
          const userValue = [];
          userValue.push({
            id: 'email',
            value: usr.email,
          });
          userValue.push({
            id: 'firstName',
            value: usr.firstName,
          });
          userValue.push({
            id: 'lastName',
            value: usr.lastName,
          });
          userValue.push({
            id: 'phone',
            value: usr.phone,
          });
          setInitialValues(userValue);
          setLoading(false);
        })
        .catch(() => history.push('/error'));
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlSubmit = async (values) => {
    setLoading(true);
    try {
      const resp = await updateUser(user.id, values);
      const userValue = [];
      userValue.push({
        id: 'email',
        value: resp.email,
      });
      userValue.push({
        id: 'firstName',
        value: resp.firstName,
      });
      userValue.push({
        id: 'lastName',
        value: resp.lastName,
      });
      userValue.push({
        id: 'phone',
        value: resp.phone,
      });
      setInitialValues(userValue);
    } catch (error) {
      if (error) {
        setSubmitStatus(error.message);
      } else {
        setSubmitStatus(t`Erreur durant la mise à jour`);
      }
    }
    setSubmitting(false);
    setLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Paper className="settings-profile">
          <Grid container>
            <Grid item xs={12} md={4}>
              <LoxForm
                status={submitStatus}
                submitting={submitting}
                form={{
                  fields: [
                    {
                      disabled: true,
                      id: 'email',
                      label: t`Adresse Email`,
                      type: 'email',
                    },
                    {
                      disabled: true,
                      id: 'firstName',
                      label: t`Prénom`,
                      type: 'text',
                    },
                    {
                      disabled: true,
                      id: 'lastName',
                      label: t`Nom`,
                      type: 'text',
                    },
                    {
                      id: 'phone',
                      isRequired: true,
                      label: t`Téléphone`,
                      type: 'phone',
                    },
                  ],
                  initialValues,
                  title: t`Mon compte`,
                  titlePrimary: true,
                }}
                onSubmit={handlSubmit}
              />
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default SettingsProfile;
