/* eslint-disable no-param-reassign */
import { t } from '@lingui/macro';
import moment from 'moment';
import { Machine } from '../../../api/machines/Machine.interface';
import { getFormatedDate } from '../../../utils/date/format';
import getMachineStatusCount from '../../../utils/equipment/machine/getMachineStatusCount';
import {
  getEquipmentDescription,
  MACHINE_WORKING_STATES,
} from '../../../utils/utils';
import { keysName } from '../constants';
import { getMachineState } from '../utils';
import { EquipmentPageState } from './Equipment.interface';

export const initOptions = (el: any, allData: any) => {
  allData.machines.machines.data = allData.machines.machines.data.filter(
    (machine: any) => machine.lat && machine.lon && machine.gpsFixTime
  );

  allData.machines.goobies.data = allData.machines.goobies.data.filter(
    (machine: any) => machine.gbLat && machine.gbLong && machine.gbLastSyncTime
  );

  if (el.value === keysName.SeeAll) {
    el.data = [
      ...allData.machines.machines.data,
      ...allData.machines.goobies.data,
      ...allData.jobsites.jobSites,
      ...allData.agencies.agencies,
    ];
    el.dataAll = {
      nbMachines: allData.machines.machines.data.length,
      nbGoobies: allData.machines.goobies.data.length,
      nbJobSites: allData.jobsites.jobSites.length,
      nbAgencies: allData.agencies.agencies.length,
    };
  } else if (el.value === keysName.Machines) {
    el.data = allData.machines;
  } else if (el.value === keysName.JobSites) {
    el.data = allData.jobsites;
  } else if (el.value === keysName.Agencies) {
    el.data = allData.agencies;
  }
  return el;
};

export const updateStatsWhenMachinesFilter = (
  newMachines: Machine[],
  isCurrentView = true
) => {
  const data = {
    ...(isCurrentView && getMachineStatusCount(newMachines)),
    totalElements: newMachines.length,
  };

  return data;
};

function createParams(params: any): EquipmentPageState {
  return {
    data: [],
    machinesGroupData: [],
    hideColumns: [],
    columnsObject: [],
    headersCheckbox: [],
    headers: [],
    enableFiltersOptions: false,
    ...params,
  };
}

type equipmentStateTypes = {
  [key: string]: string;
};

// Function used to create the filter options on the map filter
export const equipmentsOptions: EquipmentPageState[] = [
  // Function used to create the See All option on the map filter
  createParams({
    value: keysName.SeeAll,
    label: t`Voir tout`,
    title: t`Flotte`,
    description: t`Visualisez l'ensemble des machines équipées de télématique, des chantiers et des agences de votre flotte`,
    headers: [t`Illustration`, t`Machine`, t`Location`, t`Etat`],
    page: 'detail',
    breadcrumbs: t`Machines connectées`,
  }),
  // Function used to create the Machines option on the map filter
  createParams({
    value: keysName.Machines,
    label: t`Machines`,
    machinesGroup: 'All',
    title: t`Machines`,
    description: t`Visualisez l'ensemble des machines équipées de télématique de votre flotte`,
    headers: [
      t`Illustration`,
      t`Machine`,
      t`Statut`,
      t`Etat`,
      t`Location`,
      t`Bon de commande`,
      t`Chantier`,
      t`Agence`,
    ],
    /* initFilters: {
      Machine: {
        values: ['compact', '37'],
        isCheckBox: false,
      },
    }, */
    hideColumns: [t`Agence`],
    headersCheckbox: [
      {
        name: t`Location`,
        keys: [t`Début`, t`Fin`],
        hideFilter: true,
      },
      {
        name: t`Etat`,
        keys: [
          MACHINE_WORKING_STATES.working,
          MACHINE_WORKING_STATES.stopped,
          MACHINE_WORKING_STATES.undefined,
        ],
        hideSearch: true,
        checkboxes: true,
        keyValue: 'engineRunning',
        keysNamesValues: {
          [MACHINE_WORKING_STATES.working]: 'true',
          [MACHINE_WORKING_STATES.stopped]: 'false',
          [MACHINE_WORKING_STATES.undefined]: null,
        },
      },
    ],
    page: 'detail',
    breadcrumbs: t`Machines connectées`,
    enableFiltersOptions: true,
    columnsObject: {
      Machine: {
        search: (e: Machine) =>
          `${getEquipmentDescription(e, e.model)} (${e.rentalmanId})`,
        value: (e: Machine) =>
          `${getEquipmentDescription(e, e.model)} (${e.rentalmanId})`,
      },
      Etat: {
        search: (e: Machine): string => {
          const machineStates: equipmentStateTypes = {
            true: MACHINE_WORKING_STATES.working,
            false: MACHINE_WORKING_STATES.stopped,
            null: MACHINE_WORKING_STATES.undefined,
          };
          return machineStates[`${getMachineState(e)}`];
        },
        value: (e: Machine): string => {
          const machineStates: equipmentStateTypes = {
            true: MACHINE_WORKING_STATES.working,
            false: MACHINE_WORKING_STATES.stopped,
            null: MACHINE_WORKING_STATES.undefined,
          };
          return machineStates[`${getMachineState(e)}`];
        },
      },
      'Bon de commande': {
        search: (e: Machine) => `${e.purchaseOrderNumber}`,
        value: (e: Machine) => e.purchaseOrderNumber,
      },
      Chantier: {
        search: (e: Machine) => `${e.jobSiteDesc} (${e.jobSiteId})`,
        value: (e: Machine) => e.jobSiteDesc,
      },
      Agence: {
        search: (e: Machine) => `${e.agencyName}`,
        value: (e: Machine) => e.agencyName,
      },
    },
  }),
  // Function used to create the Sites option on the map filter

  // createParams({
  //   value: keysName.JobSites,
  //   label: t`Chantiers`,
  //   title: t`Chantiers`,
  //   description: t`Visualisez l'ensemble des chantiers de votre flotte`,
  //   headers: [t`Nom`, t`Adresse`, t`Date`],
  //   page: 'jobsite',
  //   breadcrumbs: keysName.JobSites,
  // }),
  // Function used to create the Agencies option on the map filter
  createParams({
    value: keysName.Agencies,
    label: t`Agences`,
    title: t`Agences`,
    description: t`Visualisez l'ensemble des agences de votre flotte`,
    headers: [t`Nom`, t`Adresse`, t`Email`, t`Code agence`, t`Ouverture`],
    page: 'agency',
    breadcrumbs: keysName.Agencies,
  }),
];
