import { Bar } from 'react-chartjs-2';

import colors from '../../../../../theme/_colors.scss';

interface HorizontalChartBarProps {
  usageOutOfContract: number;
  currentUsage: number;
  estimatedUsage: number;
  totalRent: number;
}

function createDiagonalPattern(color = 'black') {
  // create a 10x10 px canvas for the pattern's base shape
  const shape = document.createElement('canvas');
  shape.width = 10;
  shape.height = 10;
  // get the context for drawing
  const c = shape.getContext('2d');
  // draw 1st line of the shape
  if (c) {
    c.strokeStyle = color;
    c.beginPath();
    c.moveTo(2, 0);
    c.lineTo(10, 8);
    c.stroke();
    // draw 2nd line of the shape
    c.beginPath();
    c.moveTo(0, 8);
    c.lineTo(2, 10);
    c.stroke();
    // create the pattern from the shape
    return c.createPattern(shape, 'repeat');
  }
  return null;
}

const HorizontalChartBar = ({
  usageOutOfContract,
  currentUsage,
  estimatedUsage,
  totalRent,
}: HorizontalChartBarProps) => {
  const options = {
    indexAxis: 'y',
    plugins: {
      title: {
        display: false,
        text: '',
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        suggestedMin: -20,
        suggestedMax: 60,
      },
      y: {
        stacked: true,
      },
    },
  };
  const labels = ['', ''];
  const data = {
    labels,
    datasets: [
      {
        label: 'Utilisation hors contrat',
        data: [[-usageOutOfContract, 0]],
        backgroundColor: colors.jasper,
      },
      {
        label: 'Utilisation actuelle',
        data: [[0, currentUsage]],
        backgroundColor: colors.blueJeans,
      },
      {
        label: 'Utilisation estimée sur la période',
        data: [[0, estimatedUsage]],
        backgroundColor: colors.ateneoBlue,
      },
      {
        label: 'Durée totale de location',
        // eslint-disable-next-line no-sparse-arrays
        data: [, [0, totalRent]],
        backgroundColor: createDiagonalPattern(colors.blueJeans),
      },
    ],
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Bar options={options} data={data} />;
};

export default HorizontalChartBar;
