import { createElement } from 'react';
import { Link } from 'react-router-dom';

import { TopBarItemRight } from '../../lib/apptheme/partials';
import Burger from './Burger';

const HeaderHelpPages = ({
  toggle,
  user,
  topBarItemRightComponent,
  headerNavs,
  userDropdownItems,
}) => (
  <header className="app-header app-header-dark">
    <div className="top-bar">
      <div className="top-bar-list">
        <Burger toggle={toggle} />
        <div className="top-bar-item top-bar-item-full custom-header">
          <ul className="navbar-nav mr-auto">
            <div style={{ marginLeft: '15px' }}>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Retour au tableau de bord
                </Link>
              </li>
            </div>
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to="/help-how-to-use" className="nav-link">
                Premiers pas
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/help-index" className="nav-link">
                Utiliser LoxSafe
              </Link>
            </li>
          </ul>
        </div>
        {topBarItemRightComponent ? (
          createElement(topBarItemRightComponent)
        ) : (
          <TopBarItemRight
            user={user}
            headerNavs={headerNavs}
            userDropdownItems={userDropdownItems}
          />
        )}
      </div>
    </div>
  </header>
);

export default HeaderHelpPages;
