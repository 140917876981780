import { StyleSheet } from '@react-pdf/renderer';
import colors from '../../../../theme/_colors.scss';

export const InfoPDFStyle = StyleSheet.create({
  infoSection: {
    backgroundColor: colors.chineseBlack,
    width: 500,
    height: 'auto',
    margin: '20px 50px 10px 50px',
    padding: 20,
    fontSize: 12,
  },
  title: {
    marginBottom: 20,
    fontSize: 16,
  },
  subTitle: {
    marginBottom: 20,
  },
  marginBottom: {
    marginBottom: 5,
  },
  underline: {
    textDecoration: 'underline',
  },
});
