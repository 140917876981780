import moment from 'moment';
import { Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { updateImeiData } from '../../../../api/agencies/Agencies';
import IsMobile from '../../../../utils/IsMobile';
import CockpitGoobieInformationDesktop from './CockpitGoobieInformationDesktop';
import CockpitGoobieInformationMobile from './CockpitGoobieInformationMobile';

const CockpitGoobieInformation = ({ goobie, setGoobieData }) => {
  const isMobile = IsMobile();
  const [isPosting, setIsPosting] = useState(false);
  const [resultMessage, setResultMessage] = useState('');

  const showNextReport = (mode) => {
    if (mode?.toLowerCase() === 'storage') {
      return 'No Communication';
    }

    if (mode?.toLowerCase() === 'service') {
      return 'Every 24 hours';
    }

    if (mode?.toLowerCase() === 'tracking') {
      return 'Every 20 minutes';
    }

    return '-';
  };

  const formatDate = (date) => {
    if (date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }
    return '-';
  };

  const formatNextDate = (mode, date) => {
    if (mode && date) {
      return moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss');
    }
    return '-';
  };

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <Paper className="cockpit-goobie__informations">
      <Typography variant="h4" className="cockpit-goobie__informations-title">
        {t`Informations`}
      </Typography>
      {isMobile ? (
        <CockpitGoobieInformationMobile
          goobie={goobie}
          updateImeiData={updateImeiData}
          isPosting={isPosting}
          setIsPosting={setIsPosting}
          resultMessage={resultMessage}
          setResultMessage={setResultMessage}
          showNextReport={showNextReport}
          setGoobieData={setGoobieData}
          formatDate={formatDate}
          formatNextDate={formatNextDate}
        />
      ) : (
        <CockpitGoobieInformationDesktop
          goobie={goobie}
          updateImeiData={updateImeiData}
          isPosting={isPosting}
          setIsPosting={setIsPosting}
          resultMessage={resultMessage}
          setResultMessage={setResultMessage}
          showNextReport={showNextReport}
          setGoobieData={setGoobieData}
          formatDate={formatDate}
          formatNextDate={formatNextDate}
        />
      )}
    </Paper>
  );
};

export default CockpitGoobieInformation;
