import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import EquipmentHistoryDateSelection from './EquipmentHistoryDateSelection';

const EquipmentHistoryOptions = ({
  currentOption,
  setCurrentOption,
  options,
  start,
  end,
  searchHistory,
  setStart,
  setEnd,
  loading,
}) => {
  return (
    <Stack
      justifyContent="space-between"
      sx={{
        flexDirection: { xs: 'column', md: 'row' },
        width: '100%',
        gap: '15px',
        paddingY: '20px',
      }}
    >
      <Typography
        variant="h2"
        fontWeight="bold"
        sx={{ display: 'flex', alignItems: 'center', color: 'primary.main' }}
      >
        <Trans>Historique</Trans>
      </Typography>
      <Stack
        gap="20px"
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <FormControl
          className="loxform__select-form"
          variant="filled"
          sx={{ width: 'auto' }}
        >
          <InputLabel className="loxform__select-form-label">OEM</InputLabel>
          <Select
            className="loxform__select-form-select"
            value={currentOption}
            onChange={(e) => setCurrentOption(e.target.value)}
            label="OEM"
            disabled={options.length === 1} // Don't allow the user to click if it's only one
          >
            {options.map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <EquipmentHistoryDateSelection
          start={start}
          end={end}
          setStart={setStart}
          setEnd={setEnd}
        />
        <LoadingButton
          onClick={searchHistory}
          loading={loading}
          variant="contained"
          data-testid="history-options-button"
        >
          <Trans>Historique de recherche</Trans>
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default EquipmentHistoryOptions;
