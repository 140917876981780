import { t } from '@lingui/macro';
import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import SaveCancelStack from '../SaveCancelStack/SaveCancelStack';

import './PositionModifier.scss';

interface PositionModifierProps {
  handleIsModifying: (isModifying: boolean) => void;
  handleSave: () => void;
}

const PositionModifier = ({
  handleIsModifying,
  handleSave,
}: PositionModifierProps) => {
  const [isModifying, setIsModifying] = useState(false);

  const handleModifying = () => {
    const newModifyingValue = !isModifying;

    setIsModifying(newModifyingValue);
    handleIsModifying(newModifyingValue);
  };

  return (
    <Stack
      className="save-cancel-stack position-modifier"
      direction="column"
      justifyContent="space-between"
      spacing={1}
    >
      <Typography
        className="position-modifier__title"
        variant="h3"
        sx={{ color: 'primary.main' }}
      >
        {t`Emplacement`}
      </Typography>

      {!isModifying ? (
        <Button
          className="jobsite-carte-zone__inline-block"
          variant="contained"
          onClick={handleModifying}
        >
          {t`Modifier l'emplacement du curseur`}
        </Button>
      ) : (
        <SaveCancelStack
          onSave={() => {
            handleSave();
            handleModifying();
          }}
          onCancel={handleModifying}
        />
      )}
    </Stack>
  );
};

export default PositionModifier;
