/* eslint-disable no-restricted-globals */
import { Trans } from '@lingui/macro';
import classNames from 'classnames';

import './EngineCard.scss';

interface EngineCardProps {
  status: string;
  libelle: string;
  icon?: string;
}

const EngineCard = ({ status, libelle, icon }: EngineCardProps) => {
  return (
    <div
      className={classNames('engine-card', {
        [`engine-card--${status}`]: status,
      })}
    >
      {icon && <span className="material-icons">{icon}</span>}
      <span className="engine-card__libelle">
        <Trans id={libelle} />
      </span>
    </div>
  );
};
export default EngineCard;
