import { t } from '@lingui/macro';
import { capitalize } from 'lodash';
import { Agency } from '../../../../api/agencies/Agency.interface';
import agencyDefaultImage from '../../../../assets/images/agencyDefaultImage.png';

import './MarkerPopup.scss';

interface AgencyWithState extends Agency {
  state: string;
}

interface AgencyMarkerPopupProps {
  agency: AgencyWithState;
  title: string;
}

const AgencyMarkerPopup = ({ agency, title }: AgencyMarkerPopupProps) => {
  const { id, state, name, rentalmanId, city, streetAdress, streetNumber } =
    agency;

  return (
    <div className={`infowindow-${state}-${id} marker-popup`}>
      <div className="marker-popup__header">
        <div className="marker-popup__header-image">
          <img
            alt="agency"
            className="marker-popup__header-image-size"
            src={agencyDefaultImage}
          />
        </div>
        <h6
          className="linkToDetailsPageEquipmentAgency marker-popup__header-title"
          id={id}
        >
          {title}
        </h6>
      </div>
      <div className="marker-popup__information-wrapper">
        <span className="marker-popup__text-infos">
          {rentalmanId && (
            <>
              <b>{t`Code agence`}:</b>
              <span>{rentalmanId},</span>
            </>
          )}
        </span>
        <span className="marker-popup__text-infos">
          <b>{t`Adresse`}:</b>
          <span>
            {`
              ${capitalize(agency.streetNumber) || '-'},
              ${capitalize(agency.postalCode) || '-'},
              ${capitalize(agency.city) || '-'}
            `}
          </span>
        </span>
      </div>
    </div>
  );
};

export default AgencyMarkerPopup;
