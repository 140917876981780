import { Trans, t } from '@lingui/macro';
import {
  Alert,
  Box,
  Checkbox,
  Paper,
  Stack,
  Typography,
  Zoom,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
/* import { getUserPrefs } from '../../../api/users/UserPrefs'; // get userprefs
import { dynamicSort } from '../../../utils/utils'; */
import { postCreateNotificationsParam } from '../../../api/notifications/Notifications';
import { TableWithPagination } from '../../../components/molecules';
import { StyledTableCell } from '../../../components/molecules/TableWithPagination/UsedComponentsAndImports';
import { useMachinesContext } from '../../../contexts/MachinesContext';
import { getEquipmentDescription } from '../../../utils/utils';
import './SettingsUserPref.scss';

const NotificationsCheckBoxAndDescriptions = ({
  title,
  descritption,
  value,
  onChange,
}) => {
  return (
    <Stack className="notifications-checkbox-and-descriptions">
      <FormControlLabel
        sx={{ margin: '0 0 0 -12px' }}
        label={title}
        control={<Checkbox checked={value} onChange={onChange} pl={0} />}
      />
      <Typography width="70%" variant="caption">
        {descritption}
      </Typography>
    </Stack>
  );
};

const defaultNotificationSetting = {
  duration: false,
  occurence: false,
  sms: false,
  email: false,
};

const SettingsUserPref = () => {
  const { getMachines } = useMachinesContext();
  const [machinesRows, setMachinesRows] = useState([]);
  const [notificationsSettings, setNotificationsSettings] = useState(
    defaultNotificationSetting
  );
  const [isSuccess, setIsSuccess] = useState(false);
  const [isEchec, setIsEchec] = useState(false);
  const machines = getMachines();

  useEffect(() => {
    if (machines) {
      const formatedRows = machines.map((mach) => {
        return {
          id: mach.id,
          machine: `${getEquipmentDescription(mach, mach?.model)} (${
            mach?.rentalmanId
          })`,
          jobsite: mach?.jobSiteDesc || '-',
          checked: false,
        };
      });
      setMachinesRows(formatedRows);
    }
  }, [machines]);

  const handleMachineClick = (id) => {
    const newArray = machinesRows.map((x) => {
      if (x.id === id) {
        return {
          ...x,
          checked: !x.checked,
        };
      }
      return x;
    });
    setMachinesRows(newArray);
  };

  const handleNotifcationsClick = (key) => {
    setNotificationsSettings((x) => {
      return {
        ...x,
        [key]: !notificationsSettings[key],
      };
    });
  };

  const handleSubmit = () => {
    const body = {
      machineIds: machinesRows.filter((x) => x.checked).map((x) => x.id),
      ...notificationsSettings,
    };
    postCreateNotificationsParam(body)
      .then(() => {
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
          setNotificationsSettings(defaultNotificationSetting);
          const defaultMachineRow = [...machinesRows].map((x) => {
            return {
              ...x,
              checked: false,
            };
          });
          setMachinesRows(defaultMachineRow);
        }, 3000);
      })
      .catch((e) => {
        console.error('Error creating notifications param : ', e);
        setIsEchec(true);
        setTimeout(() => {
          setIsEchec(false);
        }, 3000);
      });
  };

  const generateCells = (row) => {
    return (
      <>
        <StyledTableCell>{t`${row.machine}`}</StyledTableCell>
        <StyledTableCell>{t`${row.jobsite}`}</StyledTableCell>
        <StyledTableCell>
          <Checkbox
            checked={row.checked}
            onClick={() => handleMachineClick(row.id)}
          />
        </StyledTableCell>
      </>
    );
  };

  return (
    <Box>
      <Paper className="notifications-settings-paper">
        <Stack pt={2} pb={3}>
          <Typography variant="h3" color="primary.main">
            {t`Paramétrer les notifications`}
          </Typography>
          <Typography variant="body1">
            {t`Valider les paramètres de notifications qui vous ont été attribués.`}
          </Typography>
        </Stack>
        <TableWithPagination
          color="primary.main"
          headers={[t`Machine`, t`Chantier`, t`Notifier`]}
          rows={machinesRows}
          optionsValue={[4]}
          cells={generateCells}
          simpleTable
          alternateBg
          noSearch
        />
        <Typography variant="h4">
          {t`Recevoir les notifications de`} :
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <NotificationsCheckBoxAndDescriptions
            title={t`Durée`}
            descritption={t`Vous serez notifié sur l’ensemble des types d’alertes pour toute durée supérieure à 10 secondes.`}
            value={notificationsSettings.duration}
            onChange={() => handleNotifcationsClick('duration')}
          />
          <NotificationsCheckBoxAndDescriptions
            title={t`Occurrence`}
            descritption={t`Vous serez notifié sur l’ensemble des types d’alertes toutes les 3 occurrences de cette alerte.`}
            value={notificationsSettings.occurence}
            onChange={() => handleNotifcationsClick('occurence')}
          />
        </Stack>
        <Typography variant="h4">
          {t`Recevoir les notifications par`} :
        </Typography>
        <Stack direction="row" mt={2} mb={2}>
          <FormControlLabel
            label={t`Mail`}
            control={
              <Checkbox
                checked={notificationsSettings.email}
                onChange={() => handleNotifcationsClick('email')}
              />
            }
          />
          <FormControlLabel
            label={t`SMS`}
            control={
              <Checkbox
                sx={{ ml: '50px' }}
                checked={notificationsSettings.sms}
                onChange={() => handleNotifcationsClick('sms')}
              />
            }
          />
        </Stack>
        {/* type="submit" */}
        <Stack direction="row" gap="5px">
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={
              (!notificationsSettings.duration &&
                !notificationsSettings.occurence) ||
              (!notificationsSettings.sms && !notificationsSettings.email) ||
              machinesRows.every((x) => !x.checked)
            }
          >
            <Trans>{t`Enregistrer`}</Trans>
          </Button>
          <Stack sx={{ position: 'relative', width: '100%' }}>
            <Zoom in={isSuccess}>
              <Alert severity="success" sx={{ position: 'absolute' }}>
                {t`Création des paramètres de notifications`} !
              </Alert>
            </Zoom>
            <Zoom in={isEchec}>
              <Alert
                severity="error"
                sx={{ position: 'absolute', width: 'auto' }}
              >
                {t`Echec lors de la création des paramètres de notifications`} !
              </Alert>
            </Zoom>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};

export default SettingsUserPref;
