import { instance } from './axios';
import {
  ApiRequestHeaders,
  ApiRequestMethod,
  ApiRequestParams,
  ApiRequestSignal,
} from './axios.types';

type CustomAgent = {
  body?: ApiRequestParams;
  headers?: ApiRequestHeaders;
  method: ApiRequestMethod;
  signal?: ApiRequestSignal;
  url: string;
};

export const apiRequest = async ({
  method,
  url,
  body,
  signal,
  headers,
}: CustomAgent) => {
  const config = {
    ...(method === 'GET' && body && { params: body }),
    ...(method !== 'GET' && body && { data: body }),
    ...(headers && { headers }),
  };

  const { data } = await instance({
    ...config,
    method,
    url: `${process.env.REACT_APP_BACKEND_URL}/api/${url}`,
    signal,
  });

  return data;
};

export const getRequest = (
  url: string,
  queryParams?: ApiRequestParams,
  signal?: ApiRequestSignal,
  headers?: ApiRequestHeaders
) =>
  apiRequest({
    method: ApiRequestMethod.GET,
    url,
    body: queryParams,
    signal,
    headers,
  });

export const postRequest = (
  url: string,
  body?: ApiRequestParams,
  signal?: ApiRequestSignal,
  headers?: ApiRequestHeaders
) =>
  apiRequest({
    method: ApiRequestMethod.POST,
    url,
    body,
    signal,
    headers,
  });

export const deleteRequest = (
  url: string,
  body?: ApiRequestParams,
  signal?: ApiRequestSignal,
  headers?: ApiRequestHeaders
) =>
  apiRequest({ method: ApiRequestMethod.DELETE, url, body, signal, headers });

export const putRequest = (
  url: string,
  body?: ApiRequestParams,
  signal?: ApiRequestSignal,
  headers?: ApiRequestHeaders
) => apiRequest({ method: ApiRequestMethod.PUT, url, body, signal, headers });

export const patchRequest = (
  url: string,
  body?: ApiRequestParams,
  signal?: ApiRequestSignal,
  headers?: ApiRequestHeaders
) => apiRequest({ method: ApiRequestMethod.PATCH, url, body, signal, headers });
