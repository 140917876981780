import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Typography from '@mui/material/Typography';

import './ReturnButton.scss';

const ReturnButton = ({ label, style, onClick }) => {
  return (
    <Button
      className="return-button"
      variant="outlined"
      startIcon={<KeyboardArrowLeftIcon style={{ color: 'primary.main' }} />}
      fontSize=""
      sx={{ ...style }}
      size="medium"
      onClick={() => (!onClick ? window.history.back() : onClick())}
    >
      {' '}
      <Typography
        className="return-button__text"
        variant="body1Bold"
        sx={{
          color: 'primary.main',
        }}
      >
        {' '}
        {label}{' '}
      </Typography>{' '}
    </Button>
  );
};
export default ReturnButton;
ReturnButton.propTypes = {
  label: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.object]),
  onClick: PropTypes.func,
};
ReturnButton.defaultProps = { style: {}, onClick: null };
