import { t } from '@lingui/macro';
import moment from 'moment';
import { MonthHoverTypeEnum } from '../../app/components/organisms/MuiCustomCalendar/utils.interface';
import colors from '../../app/theme/_colors.scss';

interface SecurityCalendarLegends {
  description: string;
  color: string;
}

export const securityCalendarLegends: SecurityCalendarLegends[] = [
  {
    description: t`Présence d´alert`,
    color: colors.jasper,
  },
  {
    description: t`Durée de location`,
    color: colors.moonstoneBlue,
  },
];

export const getAlertsGraphFilters = (
  alerts: any[] | undefined,
  begin: moment.Moment,
  end: moment.Moment
) => {
  const alertsFilters: any[] = [];

  (alerts || [])
    .filter(
      (alert: any) => moment(alert.day) >= begin && moment(alert.day) <= end
    )
    .forEach((alert: any) => {
      let totalDayDuration = 0;

      alert.sessionsByHourList.forEach((h: any) => {
        totalDayDuration += h.duration;
      });

      alert.sessionsByHourList.forEach((metric: any) => {
        alertsFilters.push({
          type: metric.metricType,
          day: alert.day,
          hour: metric.hour,
          duration: metric.duration,
          totalDayDuration,
          outOfContract: metric.usageOutOfTime,
        });
      });
    });

  return alertsFilters;
};

export const getAlertsGraphNumbers = (
  alertsFilters: any[],
  labelToUse: {
    value: string;
    index?: number;
  }[],
  selectedCalendarFilter: MonthHoverTypeEnum
) => {
  return labelToUse.map((l: any) => {
    let number = 0;
    alertsFilters.forEach((info: any) => {
      let infoTime;
      let matchCondition;
      if (selectedCalendarFilter === MonthHoverTypeEnum.DAY) {
        infoTime = moment(info.hour).utc().hours();
        // uses parseInt because value is e.g '06h'.
        matchCondition = infoTime === parseInt(l.value, 10);
      }
      if (selectedCalendarFilter === MonthHoverTypeEnum.WEEK) {
        infoTime = moment(info.day).day();
        matchCondition = infoTime === Number(l.index);
      }
      if (selectedCalendarFilter === MonthHoverTypeEnum.MONTH) {
        infoTime =
          moment(info.day).week() -
          moment(info.day).startOf('month').week() +
          1;
        matchCondition = infoTime === Number(l.index);
      }
      if (matchCondition) {
        number += 1;
      }
    });
    return number;
  });
};
