import PropTypes from 'prop-types';
import { Divider, Stack, Typography, Box } from '@mui/material';

import './SpecificationList.scss';

const valueColor = (color) => {
  switch (color) {
    case 'success':
      return 'success.main';
    case 'error':
      return 'error.main';
    case 'secondary':
      return 'secondary.main';
    default:
      return 'text.main';
  }
};

const SpecificationList = ({ title, specifications }) => {
  return (
    <Box className="specification-list">
      <Stack sx={{ flexDirection: 'column' }} spacing={2}>
        <Typography sx={{ color: 'primary.main' }} variant="h3">
          {title}
        </Typography>

        {specifications.map((spec, index1) => (
          <Stack
            sx={{ flexDirection: 'column' }}
            spacing={1}
            key={`${spec.title}-${index1 + 1}`}
          >
            {spec.title && typeof spec.title === 'string' ? (
              <Typography variant="h3">{spec.title}</Typography>
            ) : (
              spec.title
            )}
            {spec.data
              .filter((el) => Object.keys(el).length !== 0)
              .map((data, index2) => {
                return (
                  <Stack
                    sx={{
                      display: data.component && 'flex',
                      flexDirection: data.component ? 'column' : 'row',
                    }}
                    spacing={1}
                    key={`${data.value}-${index2 + 1}`}
                  >
                    {data.name && data.value && (
                      <Typography
                        className="specification-list__name"
                        variant={data.normalName ? 'body1' : 'body1Bold'}
                      >
                        {data.name} :
                      </Typography>
                    )}
                    <Typography
                      className="specification-list__value"
                      variant={data.valueImportant ? 'body1Bold' : 'body1'}
                      sx={{
                        color:
                          data.isEngineRunningDatetimeBellow24h === false
                            ? 'primary.main'
                            : valueColor(data.valueColor),
                      }}
                    >
                      {data.value}
                    </Typography>
                    {data.child}
                    {data.component}
                  </Stack>
                );
              })}
            {spec.divider && <Divider variant="middle" />}
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default SpecificationList;

SpecificationList.propTypes = {
  /**
   * Title of specification list
   */
  title: PropTypes.string.isRequired,

  /**
   * Specification list
   */
  specifications: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Specification bloc title
       */
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

      /**
       * create a divider at the bottom of the specification bloc
       */
      divider: PropTypes.bool,
      /**
       * key/value pair array that represent an specification field
       */
      data: PropTypes.arrayOf(
        PropTypes.shape({
          /**
           * Specification field name
           */
          name: PropTypes.string,

          /**
           * if true, the specification name will not be bold
           */
          normalName: PropTypes.bool,

          /**
           * specification field value, can be null
           */
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

          /**
           * Specifier the collor of the value.
           * Possible values are, null(not defined), success, error
           */
          valueColor: PropTypes.string,

          /**
           * define if value display must be bold
           */
          valueImportant: PropTypes.bool,

          /**
           * define if value display must be bold
           */
          component: PropTypes.any,
        })
      ),
    })
  ).isRequired,
};

SpecificationList.defaultProps = {};
