import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
} from '../utils/axios.utils';

export const getAllUsers = (values) => {
  return postRequest(`users`, values);
};

export const getUserById = (id) => {
  return getRequest(`users/${id}`);
};

export const getUserByEmail = (email) => {
  return getRequest(`users/email/${email}`);
};

export const updateUser = (id, values) => {
  return putRequest(`users/${id}`, values);
};

export const deleteUser = (id) => {
  return deleteRequest(`users/${id}`);
};

export const createUser = (values) => {
  return postRequest(`users/register`, values);
};

export const checkToken = () => {
  return getRequest(`introspection`);
};
