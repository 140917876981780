/* eslint-disable no-param-reassign */
import { Trans, t } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState, lazy, Suspense } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Loading } from '../../../../lib/apptheme';
import { ReturnButton } from '../../../components/molecules';
import { keysName } from '../constants';
import { equipmentsOptions, initOptions } from './Equipment.service';
import DataDescription from './components/DataDescription/DataDescription';
import EquipmentData from './components/EquipmentData/EquipmentData';
import FlotteInteractionIcones from './components/FlotteInteractionIcones/FlotteInteractionIcones';
import MapAndListButtons from './components/MapAndListButtons/MapAndListButtons';

import { MACHINE_WORKING_STATES } from '../../../utils/utils';
import './Equipment.scss';

const EquipmentAgency = lazy(() =>
  import('../EquipmentAgency/EquipmentAgency')
);
const EquipmentDetail = lazy(() =>
  import('../EquipmentDetail/EquipmentDetail')
);
const EquipmentJobSite = lazy(() =>
  import('../EquipmentJobSite/EquipmentJobSite')
);
const EquipmentList = lazy(() => import('../EquipmentList'));
const EquipmentListAndMap = lazy(() =>
  import('../EquipmentListAndMap/EquipmentListAndMap')
);

const Equipment = (props) => {
  const { location } = props;

  // Differents States and their options Machines, JobSites ...
  const [options, setOptions] = useState(equipmentsOptions);
  // Current State
  const [state, setState] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [isResetFilter, setIsResetFilter] = useState(false);
  const [pFilters, setPFilters] = useState();

  const [checkboxesStatesResp, setCheckboxesStatesResp] = useState([]);

  const { isLoaded, allData } = EquipmentData();

  const parentFilters = {
    get: pFilters,
    set: setPFilters,
  };

  const initialState = useRef();
  const pathLocation = useLocation();

  const zoomOn = useMemo(
    () => new URLSearchParams(pathLocation.search),
    [pathLocation.search]
  ).get('zoomOn');

  useEffect(() => {
    if (state && isLoaded) {
      setIsLoading(false);
    }
  }, [state, isLoaded]);

  useEffect(() => {
    // checks if isn't loaded to only run it once
    if (isLoading) {
      const updatedOptions = options.map((el) => initOptions(el, allData));
      const [optionsObj] = updatedOptions;

      setOptions(updatedOptions);
      setState(optionsObj);
      initialState.current = optionsObj;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allData]);

  useEffect(() => {
    const filterKeyFromLocalStorage = localStorage.getItem(
      'filters-object-keys'
    );

    if (filterKeyFromLocalStorage && JSON.parse(filterKeyFromLocalStorage)) {
      setPFilters({
        ...JSON.parse(filterKeyFromLocalStorage),
        Etat: {
          isCheckBox: true,
          values:
            pFilters && pFilters.values && pFilters.values.length > 0
              ? pFilters.values
              : [
                  MACHINE_WORKING_STATES.working,
                  MACHINE_WORKING_STATES.undefined,
                  MACHINE_WORKING_STATES.stopped,
                ],
        },
      });
      localStorage.setItem('filters-object-keys', null);
    } else {
      setPFilters((f) => {
        return {
          ...f,
        };
      });
    }
    // TODO - fix eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // select seeAll when zoomOn is defined
  useEffect(() => {
    if (zoomOn !== null) {
      setState(initialState.current);
    }
  }, [zoomOn]);

  useEffect(() => {
    if (state?.headersCheckbox) {
      const newCheckboxesStatesRes = [];
      state.headersCheckbox
        .filter((f) => f.checkboxes)
        .forEach((c) => {
          const keysValues = c.keys.map((k) => {
            return {
              name: k,
              value: true,
            };
          });

          newCheckboxesStatesRes.push({
            header: c.name,
            values: keysValues,
          });
        });

      setCheckboxesStatesResp(newCheckboxesStatesRes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.headersCheckbox]);

  useEffect(() => {
    if (checkboxesStatesResp && parentFilters) {
      const filtersInit = {};

      checkboxesStatesResp.forEach((el) => {
        filtersInit[el.header] = {
          values: el.values.map((v) => v.name).sort(),
          isCheckBox: true,
        };
      });

      parentFilters.set((f) => {
        return {
          ...f,
          ...filtersInit,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxesStatesResp]);

  // handling change for Machines hand other options
  const onChange = (value) => {
    const newState = options.find((option) => option.value === value);
    setState(newState);
  };

  // check if the selector is on seeAll and change the state to default key (Machine)
  try {
    if (
      location.pathname.includes('/equipments/list') &&
      state &&
      state.value === keysName.SeeAll
    ) {
      const infos = options.find((el) => el.value === keysName.Machines);
      setState(infos);
    }
  } catch (e) {
    console.error(e);
  }

  const userIsOnMap = location.pathname.includes('/equipments/map');
  const userIsOnList = location.pathname.includes('/equipments/list');
  const userIsOnDetailMachine =
    location.pathname.includes('/equipments/detail');
  const userIsOnDetailAgency = location.pathname.includes('/equipments/agency');
  const userIsOnDetailJobsite = location.pathname.includes(
    '/equipments/jobsite'
  );
  const userIsOnMapOrList = userIsOnMap || userIsOnList;
  const userIsOnDetail =
    userIsOnDetailMachine || userIsOnDetailAgency || userIsOnDetailJobsite;
  const returnOrMapListButton = userIsOnMapOrList ? (
    <Stack spacing={1} className="equipment">
      <Typography variant="h1">
        <Trans id={state?.title} />
      </Typography>
      <Typography variant="body1">
        <Trans id={state?.description} />
      </Typography>

      <FlotteInteractionIcones
        userIsOnList={userIsOnList}
        options={options}
        state={state}
        onChange={onChange}
        location={location}
        allData={allData}
        setState={setState}
        filters={parentFilters}
      />
    </Stack>
  ) : (
    <Stack direction="row" justifyContent="space-between">
      <ReturnButton label={t`Retour`} />
      {userIsOnDetail && (
        <MapAndListButtons
          current="/equipments/detail"
          firstPath="/equipments/map"
          secondPath="/equipments/list"
        />
      )}
    </Stack>
  );

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {returnOrMapListButton}
      <Switch>
        <Route
          path="/equipments/list"
          exact
          render={() =>
            state &&
            parentFilters.get && (
              <EquipmentList
                equipment={state}
                setEquipment={setState}
                checkboxesStatesResp={checkboxesStatesResp}
                isResetFilter={isResetFilter}
                setIsResetFilter={setIsResetFilter}
                parentFilters={parentFilters}
              />
            )
          }
        />
        <Route
          path="/equipments/map"
          exact
          render={() =>
            state &&
            pFilters && (
              <EquipmentListAndMap
                equipment={state}
                setState={setState}
                dataDescription={() => DataDescription(state)}
                parentFilters={parentFilters}
                checkboxesStatesResp={checkboxesStatesResp}
              />
            )
          }
        />
        <Route
          exact
          path="/equipments/detail/:id"
          component={EquipmentDetail}
        />
        <Route
          exact
          path="/equipments/jobsite/:id"
          render={() => (
            <EquipmentJobSite
              equipment={options.find((f) => f.value === keysName.Machines)}
            />
          )}
        />
        <Route
          exact
          path="/equipments/agency/:id"
          render={() => (
            <EquipmentAgency
              equipment={options.find((f) => f.value === keysName.Machines)}
            />
          )}
        />
      </Switch>
    </>
  );
};

export default Equipment;
