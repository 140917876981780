import { Stack, Typography } from '@mui/material';

import './Legend.scss';

const Legend = ({
  text,
  color,
  isStriped,
  isSquare,
}: {
  text: string;
  color: string;
  isStriped?: boolean;
  isSquare?: boolean;
}) => {
  return (
    <Stack className="legend" direction="row">
      <Stack
        className={isSquare ? 'legend__color-square' : 'legend__stack'}
        sx={{
          background: !isStriped
            ? color
            : `repeating-linear-gradient(45deg,#fff,#fff 5px,${color} 5px,${color} 7px);`,
        }}
      />
      <Typography>{text}</Typography>
    </Stack>
  );
};

export default Legend;
