import { Trans } from '@lingui/macro';
import { Alert, Snackbar } from '@mui/material';
import { useState } from 'react';

import './NoCoordsWarning.scss';

interface NoCoordsWarningProps {
  hasWarning: boolean;
}

const NoCoordsWarning = ({ hasWarning }: NoCoordsWarningProps) => {
  const [openWarning, setOpenWarning] = useState(hasWarning);

  return (
    <Snackbar
      open={openWarning}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      onClose={() => setOpenWarning(false)}
    >
      <Alert className="no-coords-warning" severity="error">
        <Trans>Cet élément n&apos;est pas localisé !</Trans>
      </Alert>
    </Snackbar>
  );
};

export default NoCoordsWarning;
