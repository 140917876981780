import { t } from '@lingui/macro';
import { Text, View } from '@react-pdf/renderer';
import { InfoPDFStyle } from './InfoPDF.constants';

const InfoPDF = () => {
  return (
    <View break style={InfoPDFStyle.infoSection}>
      <Text style={InfoPDFStyle.title}>{t`Rapport`}</Text>

      <Text style={InfoPDFStyle.subTitle}>
        {t`Chaque métrique permet de suivre l'évolution des alertes sur la
      semaine dernière`}
        .
      </Text>

      <Text
        style={InfoPDFStyle.marginBottom}
      >{t`Pour chaque métrique vous trouverez les informations suivantes:`}</Text>

      <Text style={InfoPDFStyle.marginBottom}>
        <Text style={InfoPDFStyle.underline}>{t`Premier chiffre`}:</Text>
        {` ${t`Le premier chiffre correspond au nombre d'alertes
        générées sur la période indiquée dans l'entête.`}`}
      </Text>

      <Text style={InfoPDFStyle.marginBottom}>
        <Text style={InfoPDFStyle.underline}>{t`Pourcentage`}:</Text>
        {` ${t`Le pourcentage correspond à l'évolution du nombre d'alertes par 
        rapport à la période précédente: en rouge le nombre d'alertes a augmenté, en 
        vert le nombre d'alertes a diminué.`}`}
      </Text>

      <Text>
        <Text style={InfoPDFStyle.underline}>
          {t`Nombre entre parenthèses`}:
        </Text>
        {` ${t`Le nombre entre parenthèses correspond au nombre 
        d'alertes sur la période précédente (S-1).`}`}
      </Text>
    </View>
  );
};

export default InfoPDF;
