import { Breadcrumbs, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const LoxBreadcrumbs = ({ data, separator }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" separator={separator}>
      {data &&
        data.map((elt, index) => {
          const last = index === data.length - 1;
          return last ? (
            <Typography variant="body1Bold" key={elt.to}>
              {elt.label}
            </Typography>
          ) : (
            <LinkRouter
              underline="hover"
              color="inherit"
              to={elt.to}
              key={elt.to}
              sx={{
                '&:hover': {
                  color: 'text.hover',
                },
                '&:active': {
                  color: 'text.hover',
                },
              }}
            >
              {elt.label}
            </LinkRouter>
          );
        })}
    </Breadcrumbs>
  );
};

export default LoxBreadcrumbs;
LoxBreadcrumbs.propTypes = {
  /**
   * List of element of the breadcrumbs
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Link to the page
       */
      to: PropTypes.string,

      /**
       * Label to show
       */
      label: PropTypes.string,
    })
  ).isRequired,
  /**
   * Breadcrumb separator:
   * the default is : /
   */
  separator: PropTypes.string,
};

LoxBreadcrumbs.defaultProps = {
  separator: '/',
};
