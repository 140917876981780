import { Typography } from '@mui/material';
import { Machine } from '../../../../../../api/machines/Machine.interface';
import { getEquipmentDescription } from '../../../../../../utils/utils';

import './MachineName.scss';

const MachineName = ({ machine }: { machine: Machine }) => {
  return (
    <Typography className="machine-name">
      {`${getEquipmentDescription(machine, machine.model)} (${
        machine.rentalmanId
      })`}
    </Typography>
  );
};

export default MachineName;
