/* eslint-disable camelcase */
import Icone_Machine_No_Information from '../../assets/images/equipment-status/Icone_Machine_No_Information.svg';
import Icone_Machine_Off from '../../assets/images/equipment-status/Icone_Machine_Off.svg';
import Icone_Machine_On from '../../assets/images/equipment-status/Icone_Machine_On.svg';
import Pins_Machine_Empruntee from '../../assets/images/equipment-status/Pins_Machine_Empruntee.svg';
import Pins_Machine_Empruntee_Agence from '../../assets/images/equipment-status/Pins_Machine_Empruntee_Agence.svg';
import Pins_Machine_Empruntee_Arret from '../../assets/images/equipment-status/Pins_Machine_Empruntee_Arret.svg';
import Pins_Machine_Empruntee_Marche from '../../assets/images/equipment-status/Pins_Machine_Empruntee_Marche.svg';
import Pins_Machine_En_Agence from '../../assets/images/equipment-status/Pins_Machine_En_Agence.svg';
import Pins_Machine_Pretee from '../../assets/images/equipment-status/Pins_Machine_Pretee.svg';
import Pins_Machine_Volee from '../../assets/images/equipment-status/Pins_Machine_Volee-Perdue.svg';
import {
  MachineStatusEnum,
  MachineStatusEnumProps,
  StatusEnumProps,
} from '../../utils/equipment/Status/EquipmentStatus.constant';

/* eslint-disable camelcase */

export const genereteKeyBorrowedStatus = (status: StatusEnumProps): string =>
  `${MachineStatusEnum.RENTALSALE.key}_${status}`;

export const statusMap = new Map();

// COMMON ICON
setMap(
  MachineStatusEnum.INTRANSIT,
  Icone_Machine_On,
  Pins_Machine_Empruntee_Marche
);
setMap(
  MachineStatusEnum.STOPPED,
  Icone_Machine_Off,
  Pins_Machine_Empruntee_Arret
);
setMap(
  MachineStatusEnum.UNDEFINED,
  Icone_Machine_No_Information,
  Pins_Machine_Empruntee
);
setMap(
  MachineStatusEnum.AVAILABLE,
  Pins_Machine_En_Agence,
  Pins_Machine_Empruntee_Agence
);
setMap(MachineStatusEnum.STOLEN, Pins_Machine_Volee);
setMap(MachineStatusEnum.RENTAL, Pins_Machine_Pretee);

function setMap(
  status: MachineStatusEnumProps,
  icon: string,
  iconBorrowed: string = icon
) {
  statusMap.set(status.key, icon);
  statusMap.set(genereteKeyBorrowedStatus(status.key), iconBorrowed);
}
