import { t } from '@lingui/macro';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Box, Stack, Typography } from '@mui/material';
import { Agency } from '../../../../../api/agencies/Agency.interface';

import './AgencyInformationContact.scss';

const AgencyInformationContact = ({ agency }: { agency: Agency }) => {
  return (
    <Box className="agency-information-contact">
      <Typography
        className="agency-information-contact__title"
        sx={{ color: 'primary.main' }}
        variant="h3"
      >
        {t`Contact`}
      </Typography>

      <Typography className="agency-information-contact__info">
        {t`Par téléphone`}:
      </Typography>

      <Typography className="agency-information-contact__info">
        ${t`Via les réseaux sociaux`}:
      </Typography>

      <Stack direction="row" justifyContent="flex-start" spacing={1}>
        <LinkedInIcon fontSize="large" sx={{ color: 'primary.main' }} />
        <FacebookIcon fontSize="large" sx={{ color: 'primary.main' }} />
        <TwitterIcon fontSize="large" sx={{ color: 'primary.main' }} />
      </Stack>
    </Box>
  );
};

export default AgencyInformationContact;
