import { t } from '@lingui/macro';
import { MACHINE_WORKING_STATES } from '../../utils';

export const ONWORK = 'ONWORK';
export const NOTWORK = 'NOTWORK';
export const UNDEFINED = 'UNDEFINED';

export enum StatusEnumProps {
  INTRANSIT = 'INTRANSIT',
  STOPPED = 'STOPPED',
  UNDEFINED = 'UNDEFINED',
  PLANNEDRETURN = 'PLANNEDRETURN',
  RETURNSELLER = 'RETURNSELLER',
  SOLD = 'SOLD',
  STOLEN = 'STOLEN',
  RETURNAREACTRLREQUIRED = 'RETURNAREACTRLREQUIRED',
  AVAILABLE = 'AVAILABLE',
  INPROGRESSTRANSPORT = 'INPROGRESSTRANSPORT',
  SCRAPS = 'SCRAPS',
  RENTAL = 'RENTAL',
  RENTALSALE = 'RENTALSALE',
  SHORTTERMBREAKDOWN = 'SHORTTERMBREAKDOWN',
  LONGTERMBREAKDOWN = 'LONGTERMBREAKDOWN',
  LOST = 'LOST',
  HIDE = 'HIDE',
}

export type MachineStatusType = Readonly<{
  INTRANSIT: MachineStatusEnumProps;
  STOPPED: MachineStatusEnumProps;
  UNDEFINED: MachineStatusEnumProps;
  PLANNEDRETURN: MachineStatusEnumProps;
  RETURNSELLER: MachineStatusEnumProps;
  SOLD: MachineStatusEnumProps;
  STOLEN: MachineStatusEnumProps;
  RETURNAREACTRLREQUIRED: MachineStatusEnumProps;
  AVAILABLE: MachineStatusEnumProps;
  INPROGRESSTRANSPORT: MachineStatusEnumProps;
  SCRAPS: MachineStatusEnumProps;
  RENTAL: MachineStatusEnumProps;
  RENTALSALE: MachineStatusEnumProps;
  SHORTTERMBREAKDOWN: MachineStatusEnumProps;
  LONGTERMBREAKDOWN: MachineStatusEnumProps;
  LOST: MachineStatusEnumProps;
  HIDE: MachineStatusEnumProps;
}>;

export interface MachineStatusEnumProps {
  key: StatusEnumProps;
  value: string;
}

export const MachineStatusEnum: MachineStatusType = Object.freeze({
  INTRANSIT: {
    key: StatusEnumProps.INTRANSIT,
    value: MACHINE_WORKING_STATES.working,
  },
  STOPPED: {
    key: StatusEnumProps.STOPPED,
    value: MACHINE_WORKING_STATES.stopped,
  },
  UNDEFINED: {
    key: StatusEnumProps.UNDEFINED,
    value: MACHINE_WORKING_STATES.stateUndefined,
  },
  PLANNEDRETURN: { key: StatusEnumProps.PLANNEDRETURN, value: t`Retour prévu` },
  RETURNSELLER: { key: StatusEnumProps.RETURNSELLER, value: t`Retour vendeur` },
  SOLD: { key: StatusEnumProps.SOLD, value: t`Vendu` },
  STOLEN: { key: StatusEnumProps.STOLEN, value: t`Volé` },
  RETURNAREACTRLREQUIRED: {
    key: StatusEnumProps.RETURNAREACTRLREQUIRED,
    value: t`Zone de retour CTRL requis`,
  },
  AVAILABLE: { key: StatusEnumProps.AVAILABLE, value: t`Disponible` },
  INPROGRESSTRANSPORT: {
    key: StatusEnumProps.INPROGRESSTRANSPORT,
    value: t`En cours transport`,
  },
  SCRAPS: { key: StatusEnumProps.SCRAPS, value: t`Férraille` },
  RENTAL: { key: StatusEnumProps.RENTAL, value: t`Location` },
  RENTALSALE: { key: StatusEnumProps.RENTALSALE, value: t`Location vente` },
  SHORTTERMBREAKDOWN: {
    key: StatusEnumProps.SHORTTERMBREAKDOWN,
    value: t`Panne à court terme`,
  },
  LONGTERMBREAKDOWN: {
    key: StatusEnumProps.LONGTERMBREAKDOWN,
    value: t`Panne à long terme`,
  },
  LOST: { key: StatusEnumProps.LOST, value: t`Perdu` },
  HIDE: { key: StatusEnumProps.HIDE, value: '' },
});

export enum StatusEnum {
  U = 'U',
  V = 'V',
  S = 'S',
  T = 'T',
  R = 'R',
  A = 'A',
  K = 'K',
  I = 'I',
  J = 'J',
  O = 'O',
  P = 'P',
  N = 'N',
  D = 'D',
  M = 'M',
}
