import { t } from '@lingui/macro';
import { Stack } from '@mui/material';
import classNames from 'classnames';
import CustomSelect from '../../../../../../components/CustomSelect/CustomSelect';
import Filter from '../../../../../components/atoms/Filter/Filter';
import IsMobile from '../../../../../utils/IsMobile';
import DialogContent from '../../../DialogContent/DialogContent';
import SearchLunr from '../../../SearchLunr';
import { keysName } from '../../../constants';
import MapAndListButtons from '../MapAndListButtons/MapAndListButtons';
import { useEquipmentPageState } from '../../Equipment.state';

import './FlotteInteractionIcones.scss';
import MachineFilterDialog from './components/MachineFilterDialog/MachineFilterDialog';

const MachineFilter = ({ filters, state }) => {
  return (
    <Filter>
      <MachineFilterDialog
        dialogContent={<DialogContent />}
        filters={filters}
        state={state}
      />
    </Filter>
  );
};

const FlotteInteractionIcones = ({
  userIsOnList,
  options,
  state,
  onChange,
  location,
  allData,
  setState,
  filters,
}) => {
  const [, setPageState] = useEquipmentPageState();
  const isMobile = IsMobile();

  const handleOnChange = (value) => {
    // Update dropdown menu value
    onChange(value);

    // Show the extract functionality only if machines are visible
    setPageState((prevState) => ({
      ...prevState,
      showExtract: value === keysName.Machines || value === keysName.SeeAll,
    }));
  };

  const FlotteTypeSelect = (
    <CustomSelect
      value={
        (userIsOnList && state.value === keysName.SeeAll ? options[1] : state)
          .value || 'placeholder'
      }
      placeholder={t`Options`}
      handleChange={handleOnChange}
      options={userIsOnList ? options.slice(1) : options}
    />
  );

  return (
    <Stack
      className={classNames('flotte-interaction', {
        'flotte-interaction--align-end': isMobile,
      })}
      direction={isMobile ? 'column' : 'row'}
    >
      {!isMobile ? (
        <Stack
          className="flotte-interaction__search-filter"
          direction="row"
          spacing={2}
        >
          <SearchLunr
            allData={allData}
            setState={setState}
            state={state}
            userIsOnList={userIsOnList}
          />

          {state.value === 'Machines' && (
            <MachineFilter filters={filters} state={state} />
          )}
        </Stack>
      ) : (
        FlotteTypeSelect
      )}

      <Stack
        className={classNames('flotte-interaction__filter-options', {
          'flotte-interaction__filter-options--static-position': isMobile,
        })}
        spacing={2}
        direction="row"
      >
        {!isMobile ? (
          FlotteTypeSelect
        ) : (
          <MachineFilter filters={filters} state={state} />
        )}
        <MapAndListButtons
          current={location.pathname}
          firstPath="/equipments/map"
          secondPath="/equipments/list"
        />
      </Stack>
    </Stack>
  );
};

export default FlotteInteractionIcones;
