/* eslint-disable prettier/prettier */
import { t } from '@lingui/macro';
import colors from '../../../theme/_colors.scss';
import { MACHINE_WORKING_STATES } from '../../../utils/utils';

// Object used to create the aside component of the map
export const legends = [
  {
    title: t`Agences`,
    data: [
      {
        color: colors.imperialRed,
        title: t`Ouvertes`,
        external: true,
      },
      {
        color: colors.quickSilver,
        title: t`Fermées`,
        external: true,
      },
    ],
  },
  {
    title: t`Machines`,
    data: [
      {
        color: colors.oliveDrab,
        title: MACHINE_WORKING_STATES.working,
        external: true,
      },
      {
        color: colors.dimGray,
        title: MACHINE_WORKING_STATES.stopped,
        external: true,
      },
      {
        style: {
          background: `repeating-linear-gradient( 135deg, ${colors.oliveDrab}, ${colors.oliveDrab} 2.5px, ${colors.dimGray} 2.5px, ${colors.dimGray} 5px );`,
        },
        title: MACHINE_WORKING_STATES.stateUndefined,
        external: true,
      },
    ],
  },
];

export const testIfThereIsLocalisation = (arr, setIsLocalisation) => {
  setIsLocalisation(true);
  let numberOfNullLocationElement = 0;
  arr.forEach((el) => {
    if (!el.lat && !el.lon) {
      numberOfNullLocationElement += 1;
    }
  });
  if (numberOfNullLocationElement === arr.length) {
    setIsLocalisation(false);
  }
};

export const getFiltersLength = (arr) => {
  const filtersKeys = Object.keys(arr).filter((el) => el);
  let filtersLengthN = 0;
  filtersKeys.forEach((f) => {
    filtersLengthN += arr[f].values.length;
  });

  return filtersLengthN;
};

export const initFilters = (set, arr) => {
  set((x) => {
    return {
      ...x,
      ...arr,
    };
  });
};

export const keysName = {
  seeAll: 'Voir tout',
  machines: 'Machines',
  jobSites: 'Chantiers',
  agencies: 'Agences',
};
export const obj = {
  Machines: 'machines',
  Chantiers: 'jobSites',
  Agences: 'agencies',
};
