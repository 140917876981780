import { useState, useEffect, useMemo, createContext } from 'react';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import {
  getMonthDifference,
  getDaysInMonth,
  getFirstDayOfMonth,
  days,
  months,
  calendarTypeOptions,
  getMaxColumnsToDisplay,
} from './utils';
import useWindowSize from '../../../hooks/useWindowSize';
import { CalendarMonthList } from './CalendarMonthList/CalendarMonthList';
import {
  MuiCustomCalendarProps,
  DateFormated,
} from './MuiCustomCalendar.interface';
import Carousel from '../../../../components/Carousel/Carousel';

import './MuiCustomCalendar.scss';

export const MuiCustomCalendarIsSecurityCtx = createContext({
  startDate: new Date(),
  endDate: new Date(),
  isSecurity: false,
});

const MuiCustomCalendar = ({
  title,
  startDate,
  endDate,
  data,
  isSecurity = false,
  periodType,
  setSelectedDate,
  handlePeriodType,
}: MuiCustomCalendarProps) => {
  const [width] = useWindowSize();
  const [maxMonthDisplayedValue, setMaxMonthDisplayedValue] = useState(3);
  const [dateClicked, setDateClicked] = useState<Date | null>(null);
  const numberOfMonthsBetweenDatesMemoized = useMemo(
    () => getMonthDifference(startDate, endDate),
    [startDate, endDate]
  );

  useEffect(() => {
    setMaxMonthDisplayedValue(getMaxColumnsToDisplay(width));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    if (dateClicked) {
      setSelectedDate(dateClicked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateClicked]);

  const arrOfDayOfUsageWithDuration = data.map((d: any) => {
    const date = new Date(d.day);

    const isUsageOutOfContract = d.sessionsByHourList.filter(
      (f: any) => f.usageOutOfTime
    );

    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      duration: d.usageOnContract + d.usageOutOfContract,
      isUsageOutOfContract: isUsageOutOfContract.length > 0,
    };
  });

  const dateFormated: DateFormated[] = useMemo(() => {
    const dataTransformed = [];

    for (
      let currMonth = 0;
      currMonth <= numberOfMonthsBetweenDatesMemoized;
      currMonth += 1
    ) {
      const newDate = new Date(startDate);
      newDate.setMonth(currMonth + startDate.getMonth());

      dataTransformed.push({
        month: newDate.getMonth() + 1,
        monthValue: months[newDate.getMonth() + 1],
        year: newDate.getFullYear(),
        totalDays: getDaysInMonth(
          newDate.getFullYear(),
          newDate.getMonth() + 1
        ),
        firstDayOfTheMonth: getFirstDayOfMonth(
          newDate.getFullYear(),
          newDate.getMonth()
        ),
        firstDayOfTheMonthValue:
          days[getFirstDayOfMonth(newDate.getFullYear(), newDate.getMonth())],
        daysUseAndDuration: arrOfDayOfUsageWithDuration.filter(
          (f: any) =>
            f.month === newDate.getMonth() + 1 &&
            f.year === newDate.getFullYear()
        ),
      });
    }

    return dataTransformed;
  }, [
    startDate,
    arrOfDayOfUsageWithDuration,
    numberOfMonthsBetweenDatesMemoized,
  ]);

  const processCalenderSlides = useMemo(() => {
    const totalSlidesToDisplay = Math.ceil(
      dateFormated.length / maxMonthDisplayedValue
    );
    const calendarSlides = [];

    const selectElementsBySlide = (start: number, offSet: number) =>
      dateFormated.slice(start, offSet);

    for (let index = 0; index < totalSlidesToDisplay; index++) {
      calendarSlides.push(
        <CalendarMonthList
          key={index}
          selectedType={calendarTypeOptions(handlePeriodType)[periodType].value}
          monthList={selectElementsBySlide(
            index * maxMonthDisplayedValue,
            index * maxMonthDisplayedValue + maxMonthDisplayedValue
          )}
          dateClicked={dateClicked}
          handleDayClick={(day) => {
            setDateClicked((prevDay) =>
              moment(prevDay).isSame(day, 'day') ? null : day
            );
          }}
        />
      );
    }

    return calendarSlides;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateClicked, dateFormated, maxMonthDisplayedValue, periodType]);

  const [labelLeftSide, labelRightSide]: [string, string] = useMemo(() => {
    return [
      dateFormated[0] && dateFormated[0].monthValue
        ? dateFormated[0].monthValue
        : '0',
      dateFormated[dateFormated.length - 1] &&
      dateFormated[dateFormated.length - 1].monthValue
        ? dateFormated[dateFormated.length - 1].monthValue
        : `${dateFormated.length}`,
    ];
  }, [dateFormated]);

  return (
    <Stack>
      <Stack className="mui-custom-calendar__month-details" direction="row">
        <Typography variant="h4">{title}</Typography>
      </Stack>
      <MuiCustomCalendarIsSecurityCtx.Provider
        value={{
          isSecurity,
          startDate,
          endDate,
        }}
      >
        <Carousel
          labelBtnleft={labelLeftSide}
          labelBtnRight={labelRightSide}
          observeValueChangeAndRefresh={width}
        >
          {processCalenderSlides}
        </Carousel>
      </MuiCustomCalendarIsSecurityCtx.Provider>
    </Stack>
  );
};

export default MuiCustomCalendar;
