import { Stack, Typography } from '@mui/material';
import { t } from '@lingui/macro';
import TableWithPagination from '../../../../app/components/molecules/TableWithPagination/TableWithPagination';
import { StyledTableCell } from '../../../../app/components/molecules/TableWithPagination/UsedComponentsAndImports';
import { getPeriodFullDate } from '../../../../app/utils/date/format';
import { formatDuration } from '../../../../app/utils/date/calcul';
import { CellsInterface } from './SecurityTable.interface';

const generateCells = (row: CellsInterface) => {
  return (
    <>
      <StyledTableCell>{t`${row.type}`}</StyledTableCell>
      <StyledTableCell>
        {getPeriodFullDate(row.startDate, row.endDate)}
      </StyledTableCell>
      <StyledTableCell>{formatDuration(row.duration)}</StyledTableCell>
    </>
  );
};

// eslint-disable-next-line prettier/prettier
interface SecurityTableProps<T extends CellsInterface> {
  data: T[];
  headers?: string[];
  GenerateCells?: (row: T) => JSX.Element;
}

const SecurityTable = <T extends CellsInterface>({
  data = [],
  headers = [t`Type`, t`Date`, t`Durée`],
  GenerateCells = generateCells,
}: SecurityTableProps<T>) => {
  return (
    <Stack>
      <Typography variant="inherit" fontSize={18} fontWeight={600}>
        {t`Alertes sur la durée totale de location`}
      </Typography>

      <TableWithPagination
        headers={headers}
        rows={data}
        cells={GenerateCells}
        alternateBg
        checkboxesStatesResp={undefined}
        columnsObject={undefined}
        dataDescription={undefined}
        parentFilters={undefined}
        hideColumns={undefined}
        equipment={undefined}
      />
    </Stack>
  );
};

export default SecurityTable;
