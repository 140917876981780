const Burger = ({ toggle }) => (
  <div className="top-bar-item px-2 d-md-none d-lg-none d-xl-none">
    <button
      className="hamburger hamburger-squeeze"
      type="button"
      onClick={toggle}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </button>
  </div>
);

export default Burger;
