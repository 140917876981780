const APP_USER_SOURCE_INTERNAL = 'internal';

const APP_USER_SOURCE_ENV = process.env.REACT_APP_APP_USER_SOURCE_ENV; // get value from  ENV
const INTERNAL_URLS = [
  'agenceconnect.digital.loxam.com',
  'rec.agenceconnect.digital.loxam.com',
  'dev.agenceconnect.digital.loxam.com',
  'localhost',
];
const isInternalApp = () => {
  if (APP_USER_SOURCE_ENV) {
    return APP_USER_SOURCE_ENV === APP_USER_SOURCE_INTERNAL;
  }
  return INTERNAL_URLS.includes(window.location.hostname);
};

export const getAppMode = () => {
  const isInternalAppMode = isInternalApp();
  return { isInternalAppMode, isExternalAppMode: !isInternalAppMode };
};
