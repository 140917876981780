/* eslint-disable no-unused-expressions */
import { t, Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { getEquipmentDescription } from '../../../utils/utils';
import SpecificationList from '../SpecificationList/SpecificationList';

import './DeviceDetailInformation.scss';

const DeviceDetailInformation = ({ machine }) => {
  const {
    model,
    equipmentStatusLib,
    rentalmanId,
    serialNumber,
    brand,
    height,
    width,
    length,
    weight,
  } = machine;

  const machineName = getEquipmentDescription(machine, model);

  const data = equipmentStatusLib
    ? [
        {
          title: t`Etat`,
          data: [
            {
              value: capitalize(equipmentStatusLib),
            },
          ],
        },
      ]
    : [];

  const descData = [];
  const dimensionsData = [];

  // ** Description **
  machineName &&
    descData.push({
      name: t`Machine`,
      value: machineName,
    });

  rentalmanId &&
    descData.push({
      name: t`Identifiant Loxam `,
      value: rentalmanId,
    });

  serialNumber &&
    descData.push({
      name: t`Numéro de série`,
      value: serialNumber,
    });

  brand &&
    descData.push({
      name: t`Marque`,
      value: brand,
    });

  model &&
    descData.push({
      name: t`Modèle`,
      value: model,
    });

  // ** Dimensions **
  height &&
    dimensionsData.push({
      name: t`Hauteur`,
      value: `${height} m`,
    });

  width &&
    dimensionsData.push({
      name: t`Largeur`,
      value: `${width} m`,
    });

  length &&
    dimensionsData.push({
      name: t`Longueur`,
      value: `${length} m`,
    });

  weight &&
    dimensionsData.push({
      name: t`Poids`,
      value: `${weight} kg`,
    });

  // Data
  descData.length > 0 &&
    data.push({
      title: t`Description`,
      data: descData,
    });

  dimensionsData.length > 0 &&
    data.push({
      title: t`Dimensions`,
      data: dimensionsData,
    });

  return <SpecificationList title={t`Informations`} specifications={data} />;
};

export default DeviceDetailInformation;

DeviceDetailInformation.propTypes = {
  machine: PropTypes.shape({
    address: PropTypes.string,
    agencyId: PropTypes.string,
    agencyName: PropTypes.string,
    battery: PropTypes.string,
    brand: PropTypes.string,
    buildYear: PropTypes.number,
    catClass: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    currentContractId: PropTypes.string,
    description: PropTypes.string,
    descriptions: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    distance: PropTypes.string,
    engineRunning: PropTypes.bool,
    estimatedEnd: PropTypes.string,
    externalIds: PropTypes.object,
    height: PropTypes.number,
    id: PropTypes.string,
    jobSiteDesc: PropTypes.string,
    jobSiteId: PropTypes.string,
    lastSyncTime: PropTypes.string,
    lat: PropTypes.string,
    length: PropTypes.number,
    lon: PropTypes.string,
    machineGroupId: PropTypes.string,
    model: PropTypes.string,
    name: PropTypes.string,
    pim: PropTypes.shape({
      apoximate: PropTypes.bool,
      code: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          format: PropTypes.string,
          imageType: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    }),
    rentalStart: PropTypes.string,
    rentalmanId: PropTypes.string,
    serialNumber: PropTypes.string,
    statusCode: PropTypes.string,
    vin: PropTypes.string,
    weight: PropTypes.number,
    width: PropTypes.number,
    zipCode: PropTypes.string,
  }).isRequired,
};
