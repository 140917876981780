import { Button, Stack } from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useAppModeContext } from '../../app/utils/hooks';

import './PDFDisplay.scss';

interface PDFDisplayProps {
  document: any;
  documentName: string;
}

const PDFDisplay = ({ document, documentName }: PDFDisplayProps) => {
  const { isInternalAppMode } = useAppModeContext();

  return (
    <Stack className="pdf-display" spacing={1}>
      {isInternalAppMode && (
        <Button
          className="pdf-display__download-btn"
          variant="contained"
          style={{ color: 'primary.main' }}
        >
          <PDFDownloadLink
            className="pdf-display__download-link"
            document={document}
            fileName={documentName ?? `file-${Date.now()}.pdf`}
          >
            {({ blob, url, loading, error }) =>
              loading ? 'Loading...' : 'Download'
            }
          </PDFDownloadLink>
        </Button>
      )}

      <PDFViewer className="pdf-display__viewer">{document}</PDFViewer>
    </Stack>
  );
};

export default PDFDisplay;
