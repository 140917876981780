import { t, Trans } from '@lingui/macro';
import { Paper, Stack, Typography } from '@mui/material';
import { Agency } from '../../../../../api/agencies/Agency.interface';
import HoursLimit from './components/HoursLimit/HoursLimit';

import './AgencyInformation.scss';

const days: string[] = [
  t`Lundi`,
  t`Mardi`,
  t`Mercredi`,
  t`Jeudi`,
  t`Vendredi`,
  t`Samedi`,
  t`Dimanche`,
];

const AgencyInformation = ({
  agency,
  openingTimes,
}: {
  agency: Agency;
  openingTimes: any;
}) => {
  return (
    <Paper className="agency-information">
      <Typography
        className="agency-information__title"
        sx={{
          color: 'primary.main',
        }}
        variant="h3"
      >
        {t`Informations`}
      </Typography>

      <Typography className="agency-information__hours-limit" variant="body1">
        <HoursLimit openingTimes={openingTimes} />
      </Typography>

      <Typography className="agency-information__info" variant="body1">
        {`${t`Code agence`} : ${agency.rentalmanId}`}
      </Typography>

      <Typography className="agency-information__info" variant="body1">
        {`${t`Adresse`} :`}
      </Typography>

      <Typography variant="body2">{`${agency.streetNumber || '-'}`}</Typography>
      <Typography className="agency-information__postal-code" variant="body2">
        {`${agency.postalCode || '-'} ${agency.city || '-'}`}
      </Typography>

      <Typography className="agency-information__schedule" variant="body1">
        {t`Horaires d’ouverture`}:
      </Typography>

      <Stack direction="column" spacing={1.5}>
        {days.map((day, index) => {
          return (
            <Stack direction="row" key={day.toLowerCase()}>
              <Typography
                className="agency-information__week-day"
                variant="body2"
              >
                <Trans id={day} />
                {': '}
              </Typography>

              {index !== days.length - 1 ? (
                <Typography variant="body2">
                  {`${openingTimes.morning.opening} - ${openingTimes.morning.closing} / ${openingTimes.afternoon.opening} - ${openingTimes.afternoon.closing}`}
                </Typography>
              ) : (
                <Typography variant="body2">{t`Fermé`}</Typography>
              )}
            </Stack>
          );
        })}
      </Stack>
    </Paper>
  );
};

export default AgencyInformation;
