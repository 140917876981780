import { t } from '@lingui/macro';
import moment from 'moment';
import { SecurityAlertProps } from '../../../../components/SecurityAlert/SecurityAlert';
import {
  getMachineClients,
  getMachineSecurity,
  getMachineUsageAlerts,
  getMachineUsageForAnInterval,
} from '../../../api/machines/Machines';
import { MonthHoverTypeEnum } from '../../../components/organisms/MuiCustomCalendar/utils.interface';
import { getNumberOfWeeksInMonth } from '../../../utils/date/calcul';
import { machineUtilisationAggByWeek } from '../../../utils/equipment/machine/machineUtilisation.utils';
import {
  formatSecurityAlerts,
  securityAlertsAggByWeek,
} from '../../../utils/security/security.utils';

export const chartBarLabels = [
  { value: '06h' },
  { value: '07h' },
  { value: '08h' },
  { value: '09h' },
  { value: '10h' },
  { value: '11h' },
  { value: '12h' },
  { value: '13h' },
  { value: '14h' },
  { value: '15h' },
  { value: '16h' },
  { value: '17h' },
  { value: '18h' },
  { value: '19h' },
];

export const chartBarLabelsDays = [
  { value: t`Lundi`, index: 1 },
  { value: t`Mardi`, index: 2 },
  { value: t`Mercredi`, index: 3 },
  { value: t`Jeudi`, index: 4 },
  { value: t`Vendredi`, index: 5 },
  { value: t`Samedi`, index: 6 },
  { value: t`Dimanche`, index: 0 },
];

export const getGraphLabels = (
  selectedCalendarFilter: MonthHoverTypeEnum,
  endDate: moment.Moment
) => {
  switch (selectedCalendarFilter) {
    case MonthHoverTypeEnum.DAY:
      return chartBarLabels;
    case MonthHoverTypeEnum.WEEK:
      return chartBarLabelsDays;
    case MonthHoverTypeEnum.MONTH:
      return Array(...new Array(getNumberOfWeeksInMonth(endDate))).map(
        (el, i) => {
          // eslint-disable-next-line no-param-reassign
          i++;
          return { value: `S${i}`, index: i };
        }
      );
    default:
      return chartBarLabels;
  }
};

export const asyncDataCallAndLogic = async ({
  id,
  customerNumber,
  startDate,
  endDate,
  setMachineUsage,
  setPeriod,
  setMachineUsageTotalDuration,
  setGlobalMachineUsage,
  machine,
  setMachine,
}: {
  id: string;
  customerNumber: number | null;
  startDate?: string;
  endDate?: string;
  setMachineUsage: (x: any) => void;
  setPeriod?: (x: any) => void;
  setMachineUsageTotalDuration?: (x: any) => void;
  setGlobalMachineUsage?: (x: any) => void;
  machine?: any;
  setMachine?: (x: any) => void;
}) => {
  try {
    const response = await getMachineUsageForAnInterval(
      id,
      customerNumber,
      startDate,
      endDate
    );

    if (response) {
      const { startDate, endDate, sessionsDay, totalUsage } = response;
      if (totalUsage && machine && setMachine) {
        const data = {
          ...machine,
          totalUsage,
        };
        setMachine(data);
      }

      const machineTotalUsagesTimeInSecondes =
        sessionsDay?.sessionPeriodAggList?.reduce((acc: any, obj: any) => {
          return acc + (obj.usageOnContract + obj.usageOutOfContract);
        }, 0);

      setMachineUsage(sessionsDay?.sessionPeriodAggList ?? []);

      if (setPeriod) {
        setPeriod({ start: startDate, end: endDate });
      }
      if (setGlobalMachineUsage) {
        setGlobalMachineUsage(response?.globalInformation);
      }
      if (setMachineUsageTotalDuration && machineTotalUsagesTimeInSecondes) {
        setMachineUsageTotalDuration(machineTotalUsagesTimeInSecondes);
      }
    }
  } catch (e) {
    console.error(e);
  }
};

export const getMachineClientsOptions = async (
  id: any,
  setMachineClients: any
) => {
  const machineClients = await getMachineClients(id);
  if (machineClients && machineClients.length > 0) {
    setMachineClients(machineClients);
  }
};

export const handleMachineUsageAlerts = async (
  id: any,
  customerNumber: number,
  page: any,
  size: any,
  setUsageAlerts: any
) => {
  const alertsResp = await getMachineUsageAlerts(
    id,
    customerNumber,
    page,
    size
  );
  if (alertsResp) {
    setUsageAlerts(alertsResp);
  }
};

export const chartLogicBySelectedTimeFilter = (
  timeFilter: string,
  info: any,
  label: any,
  _isEveryUsageUnderOneHour: boolean,
  totalDayDuration: number
) => {
  if (timeFilter === MonthHoverTypeEnum.DAY) {
    const infoHours = moment(info.hour).hours();
    // uses parseInt because value is e.g '06h'.
    const matchCondition = infoHours === parseInt(label.value, 10);

    if (totalDayDuration && matchCondition) {
      const time = _isEveryUsageUnderOneHour
        ? totalDayDuration
        : Number((totalDayDuration / 60).toFixed(2));

      return [0, time];
    }
    return [];
  }
  if (timeFilter === MonthHoverTypeEnum.WEEK) {
    const matchCondition = moment(info.day).day() === Number(label.index);

    if (matchCondition) {
      const time = _isEveryUsageUnderOneHour
        ? totalDayDuration
        : Number((totalDayDuration / 60).toFixed(2));

      return [0, time];
    }
    return [];
  }

  return [];
};

export const getChartArray = (
  labelToUse: any,
  machineUsageFilter: any,
  selectedCalendarFilter: any,
  usageOutOfTime: boolean,
  isMachineUsageUnderOneHours: boolean,
  isAlerts?: boolean
) => {
  return labelToUse.map((label: any) => {
    let resp: any[] = [];

    if (selectedCalendarFilter === MonthHoverTypeEnum.DAY) {
      const metricsList = isAlerts
        ? machineUsageFilter
        : machineUsageFilter[0].sessionsByHourList;

      metricsList.forEach((info: any) => {
        let duration = 0;

        if (
          isAlerts ||
          (usageOutOfTime && info.usageOutOfTime) ||
          (!usageOutOfTime && !info.usageOutOfTime)
        ) {
          duration = info.duration;
        }

        const data = chartLogicBySelectedTimeFilter(
          selectedCalendarFilter,
          info,
          label,
          isMachineUsageUnderOneHours,
          duration
        );

        if (data.length > 0) {
          resp = data;
        }
      });
    } else if (selectedCalendarFilter === MonthHoverTypeEnum.WEEK) {
      machineUsageFilter.forEach((info: any) => {
        let totalDayDuration = 0;

        if (isAlerts) {
          totalDayDuration = info.totalDayDuration;
        } else {
          totalDayDuration = usageOutOfTime
            ? info.usageOutOfContract
            : info.usageOnContract;
        }

        const data = chartLogicBySelectedTimeFilter(
          selectedCalendarFilter,
          info,
          label,
          isMachineUsageUnderOneHours,
          totalDayDuration
        );

        if (data.length > 0) {
          resp = data;
        }
      });
    } else if (selectedCalendarFilter === MonthHoverTypeEnum.MONTH) {
      const time = isAlerts
        ? securityAlertsAggByWeek(machineUsageFilter, Number(label.index))
        : machineUtilisationAggByWeek(
            machineUsageFilter,
            Number(label.index),
            usageOutOfTime
          );

      resp = [
        0,
        isMachineUsageUnderOneHours ? time : Number((time / 60).toFixed(2)),
      ];
    }

    return resp;
  });
};

export const handleMachineSecurityInfo = (
  id: string,
  customerNumber: string
) => {
  let alerts: SecurityAlertProps[] = [];
  let calendarData: any = [];
  let period: any = null;

  return getMachineSecurity(id, customerNumber)
    .then((response) => {
      const {
        startDate,
        endDate,
        aggSumMetrics,
        metricsSummaryDetailsDTOS,
        sessionMachPeriodAggDTO,
      } = response;

      alerts = formatSecurityAlerts([
        aggSumMetrics,
        ...metricsSummaryDetailsDTOS,
      ]);

      alerts = alerts.map((alert) => ({ ...alert, hideMachine: true }));

      calendarData = sessionMachPeriodAggDTO?.sessionPeriodAggList;

      period = { start: startDate, end: endDate };

      return { alerts, calendarData, period };
    })
    .catch((e) => {
      console.log('Getting machine security error:', e);

      return { alerts, calendarData, period };
    });
};
