import { Link } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import Loading from '../../../Loading';

import './LoxLoginForm.scss';

interface Value {
  id: string;
  value: string;
}

interface LoxLoginFormProps {
  // eslint-disable-next-line no-restricted-globals
  status: string;
  submitting: boolean;
  initialValues: Value[];
  onSubmit: (data: any) => void;
}

const LoxLoginForm = ({
  status,
  submitting,
  initialValues,
  onSubmit,
}: LoxLoginFormProps) => {
  const firstValues: any = {};

  if (initialValues)
    initialValues.forEach((field) => {
      firstValues[field.id] = field.value;
      return firstValues;
    });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: firstValues,
    shouldFocusError: true,
    criteriaMode: 'all',
    reValidateMode: 'onChange',
  });

  const onFormSubmit = (data: any) => onSubmit(data);

  return (
    <Box className="lox-login-form">
      <form>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Controller
              name="email"
              control={control}
              rules={{
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t`Email: n'est pas un mail valide`,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Box>
                  <TextField
                    className="lox-login-form__text-field lox-login-form__email"
                    fullWidth
                    id="email"
                    label={t`Nom d'utilisateur`}
                    InputLabelProps={{ sx: { fontStyle: 'italic' } }}
                    error={errors && errors.email}
                    helperText={errors?.email?.message}
                    variant="filled"
                    type="email"
                    required
                    value={value}
                    onChange={onChange}
                  />
                </Box>
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box>
                  <TextField
                    className="lox-login-form__text-field"
                    fullWidth
                    id="password"
                    label={t`Mot de passe`}
                    InputLabelProps={{ sx: { fontStyle: 'italic' } }}
                    error={errors && errors.password}
                    helperText={errors?.password?.message}
                    variant="filled"
                    type="password"
                    required
                    value={value}
                    onChange={onChange}
                  />
                </Box>
              )}
            />
            <Stack className="lox-login-form__links">
              <Link to="/forgot-password" className="link">
                <Typography variant="body1">
                  <Trans>Mot de passe oublié</Trans> ?
                </Typography>
              </Link>
            </Stack>
          </Stack>
          <Typography sx={{ color: 'error.main' }}>{status}</Typography>

          <Stack direction="row" justifyContent="space-around">
            {submitting ? (
              <Loading />
            ) : (
              <Button
                className="lox-login-form__login-btn"
                variant="contained"
                color="primary"
                onClick={handleSubmit(onFormSubmit)}
              >
                <Trans>Se connecter</Trans>
              </Button>
            )}
          </Stack>
        </Stack>
      </form>
    </Box>
  );
};

export default LoxLoginForm;
