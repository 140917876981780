import IsMobile from '../../../utils/IsMobile';

export const checkUserAuthorized = (user, rolesAuthorized) => {
  if (!user && rolesAuthorized && !rolesAuthorized.length) {
    return false;
  }
  if (!rolesAuthorized || rolesAuthorized.length === 0) {
    return true;
  }

  return !!user && rolesAuthorized.includes(user.mergedProfil);
};

const checkInternalEntry = (entry, isInternalMode) =>
  entry.isInternal && isInternalMode !== entry.isInternal;

/**
 * This function returns the menu array based on the user.
 * @param {object} user - The logged user object
 * @param {function} isInternalMode - Function to checks if app is in internal mode
 * @param {Array} entries - The menu items array
 */
export const prepareEntries = (user, isInternalMode, entries) =>
  entries
    .filter((entry) => {
      if (
        checkInternalEntry(entry, isInternalMode) ||
        !checkUserAuthorized(user, entry.rolesAuthorized)
      ) {
        return false;
      }

      return !entry.hide;
    })
    .map((entry) => {
      if (isInternalMode) {
        // eslint-disable-next-line no-param-reassign
        entry.description = entry.descriptionInternal;
        // eslint-disable-next-line no-param-reassign
        entry.title = entry.titleInternal;

        const regionsAuthorized =
          user.codeRegion &&
          entry.regionsAuthorized &&
          entry.regionsAuthorized.length > 0 &&
          !entry.regionsAuthorized.includes(user.codeRegion);

        const isOnMobile = IsMobile();

        if (regionsAuthorized) {
          // eslint-disable-next-line no-param-reassign
          entry.disabled = true;
          // eslint-disable-next-line no-param-reassign
          entry.href = null;
          // eslint-disable-next-line no-param-reassign
          entry.value = null;
        }

        if (entry.value === 'scan' && isOnMobile) {
          // eslint-disable-next-line no-param-reassign
          entry.disabled = false;
          // eslint-disable-next-line no-param-reassign
          entry.href = '/scan';
          // eslint-disable-next-line no-param-reassign
          return entry;
        }
      }

      return entry;
    });
