import Stack from '@mui/material/Stack';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { memo, useContext } from 'react';
import colors from '../../../../theme/_colors.scss';
import { MuiCustomCalendarIsSecurityCtx } from '../MuiCustomCalendar';
import { machineUsageDurationColor } from '../utils';
import { CalendarDayBoxProps } from './CalendarDayBox.interface';

import './CalendarDayBox.scss';

function isDateHoverPropEqual(
  prevProps: CalendarDayBoxProps,
  nextProps: CalendarDayBoxProps
) {
  return (
    isEqual(prevProps.isDateHover, nextProps.isDateHover) &&
    isEqual(prevProps.metadata.duration, nextProps.metadata.duration)
  );
}

const CalendarDayBox = ({
  day,
  date,
  isDateHover,
  metadata,
  handleHover,
  handleClick,
}: CalendarDayBoxProps) => {
  const { isSecurity, startDate, endDate } = useContext(
    MuiCustomCalendarIsSecurityCtx
  );
  const { firstDayOfTheMonth, duration, isUsageOutOfContract } = metadata;

  const className = classNames('calendar-day-box', {
    'calendar-day-box--date-hover': isDateHover,
  });

  const dayPosition =
    day === 1 && firstDayOfTheMonth === 0 ? 7 : firstDayOfTheMonth;

  const machineUsageBGColors = duration
    ? machineUsageDurationColor(duration, isUsageOutOfContract)
    : 'transparent';

  const dayInLocation = startDate < date && date < endDate;

  const dayColored = dayInLocation ? colors.brightGray : 'transparent';

  const machineSecurityBGColors = duration ? colors.americanOrange : dayColored;

  return (
    <Stack
      className={className}
      onMouseOver={() => handleHover(day)}
      onMouseOut={() => handleHover(null)}
      onClick={() => handleClick(day)}
      sx={{
        gridColumn: day === 1 ? dayPosition : 0,
        backgroundColor: isSecurity
          ? machineSecurityBGColors
          : machineUsageBGColors,
      }}
    >
      {day}
    </Stack>
  );
};

const MemoizedCalendarDayBox = memo(CalendarDayBox, isDateHoverPropEqual);

export default MemoizedCalendarDayBox;
