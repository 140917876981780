import { t } from '@lingui/macro';
import { Box, Stack, Typography } from '@mui/material';
import { Chart, registerables } from 'chart.js';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import EquipmentSecurity from '../../../../components/EquipmentSecurity/EquipmentSecurity';
import { Loading } from '../../../../lib/apptheme';
import { ErrorMessage } from '../../../../lib/apptheme/partials';
import {
  getMachineAvailableProviders,
  getMachineSecurityAlerts,
} from '../../../api/machines/Machines';
import { DeviceDetailContainer } from '../../../components/Layouts';
import {
  DeviceDetailContract,
  DeviceDetailInformation,
  DeviceDetailTelematic,
  DeviceDetailUsage,
  EquipmentContentDetails,
  TabButton,
} from '../../../components/molecules';
import { useAgenciesContext } from '../../../contexts/AgenciesContext';
import { useContractsContext } from '../../../contexts/ContractsContext';
import { useJobSitesContext } from '../../../contexts/JobSitesContext';
import { useMachinesContext } from '../../../contexts/MachinesContext';
import { useAppModeContext, useBreadcrumbsContext } from '../../../utils/hooks';
import { getEquipmentDescription } from '../../../utils/utils';
import { keysName } from '../constants';
import {
  getMachineClientsOptions,
  handleMachineSecurityInfo,
} from './EquipmentDetail.utils';
import EquipmentUtilisation from './components/EquipmentUtilisation/EquipmentUtilisation';
import SecurityTableRow from './components/SecurityTableRow/SecurityTableRow';

import './EquipmentDetail.scss';
import EquipmentHistory from './components/EquipmentHistory/EquipmentHistory';
import MachineMap from './components/MachineMap/MachineMap';

Chart.register(...registerables);

const MachineContent = (content, description) => (
  <EquipmentContentDetails
    details={{
      description,
    }}
    content={content}
  />
);

const EquipmentDetail = () => {
  const { id } = useParams();
  const [machine, setMachine] = useState(null);
  const [jobSite, setJobSite] = useState([]);
  const [agency, setAgency] = useState([]);
  const [machineClients, setMachineClients] = useState([]);
  const [machineClientsOptions, setMachineClientsOptions] = useState([]);
  const [contract, setContract] = useState();
  const [isLoading, setLoading] = useState(true);
  const [displayRegions] = useState(false);
  const [displayDepartments] = useState(false);
  const [geoZonesId, setGeoZonesId] = useState([]);

  const [, setBreadcrumbsList] = useBreadcrumbsContext();
  const { getMachine } = useMachinesContext();
  const { getContractByMachineId } = useContractsContext();
  const { getJobSite } = useJobSitesContext();
  const { getAgency } = useAgenciesContext();
  const { isInternalAppMode } = useAppModeContext();
  const [loadingMachineProviders, setLoadingMachineProviders] = useState(false);
  const [oemOptionsState, setOemOptionsState] = useState({
    loaded: false,
    oemOptions: [],
  });

  const [lastInfoGeoLocForEachProvider, setLastInfoGeoLocForEachProvider] =
    useState({});

  const storedMachine = useMemo(() => {
    const machine = getMachine(id);
    return machine;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getMachineProviders = useCallback(async () => {
    const optionsProviders = {
      OEM: { order: 1, optionName: 'TRACKUNIT & OEM' },
      GOOBIE: { order: 2, optionName: 'GOOBIE' },
    };

    setLoadingMachineProviders(true);

    try {
      const machineProviders = await getMachineAvailableProviders(id);
      setLastInfoGeoLocForEachProvider(
        Object.fromEntries(machineProviders.map((item) => [item.origin, item]))
      );
      setOemOptionsState({
        loaded: true,
        oemOptions: machineProviders
          .map(({ origin }) => optionsProviders[origin])
          .sort(({ order: ordera }, { order: orderb }) => ordera - orderb)
          .map(({ optionName }) => optionName),
      });
    } finally {
      setLoadingMachineProviders(false);
    }
  }, [id]);

  useEffect(() => {
    if (!loadingMachineProviders) {
      getMachineProviders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setMachine({ ...storedMachine, state: keysName.Machines });
    getMachineClientsOptions(storedMachine.id, setMachineClients);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedMachine]);

  useEffect(() => {
    if (!isEmpty(machine)) {
      setContract(getContractByMachineId(machine.id) ?? {});
    }
  }, [getContractByMachineId, machine]);

  useEffect(() => {
    if (!isEmpty(machine)) {
      if (machine.jobSiteId) {
        const jobSiteLocal = getJobSite(machine.jobSiteId);

        if (jobSiteLocal) {
          jobSiteLocal.state = keysName.JobSites;
          setJobSite(jobSiteLocal);
        }
      }

      const agencyLocal = getAgency(machine.agencyId);
      if (agencyLocal) {
        agencyLocal.state = keysName.Agencies;
        setAgency(agencyLocal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machine]);

  useEffect(() => {
    if (machine && jobSite && agency) {
      setLoading(false);
    }
  }, [machine, contract, jobSite, agency]);

  useEffect(() => {
    const breadCrumbsData = [
      {
        label: t`Tableau de bord`,
        to: '/',
      },
      {
        label: t`Machines connectées`,
        to: '/equipments/list',
      },
      {
        label: t`Details`,
        to: '/equipments/details',
      },
    ];
    setBreadcrumbsList(breadCrumbsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (machineClients && machineClients.length > 0) {
      let resp = machineClients
        .filter((f) => f.customer_name)
        .map((el) => {
          return {
            label: el.customer_name,
            customerNumber: el.customer_number,
            startRentalDateTime: el.start_rental_date_time,
            endRentalDateTime: el.end_rental_date_time,
          };
        });

      if (resp.length > 1) {
        resp = [
          {
            label: t`Tous les contrats`,
            customerNumber: null,
          },
          ...resp,
        ];
      }

      setMachineClientsOptions(resp);
    }
  }, [machineClients]);

  const goobie = useMemo(
    () => ({
      ...machine,
      state: 'goobie',
      lon: machine?.gbLong,
      lat: machine?.gbLat,
    }),
    [machine]
  );

  const securityMetricsTableRow = useCallback(
    (row) => <SecurityTableRow {...row} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const securityMetricsCallback = (customerNumber) => {
    return handleMachineSecurityInfo(id, customerNumber);
  };

  const securityMetricsTableCallback = (customerNumber) => {
    return getMachineSecurityAlerts(id, customerNumber, 0, 9999);
  };

  const handleGeoZoneClicked = (id) => {
    setGeoZonesId((zonesId) => {
      return zonesId.includes(id)
        ? zonesId.filter((item) => item !== id)
        : [...zonesId, id];
    });
  };

  let title;
  if (!isLoading) {
    title = (
      <Typography variant="h1" fontSize="36px !important">
        {getEquipmentDescription(machine, machine.model)}{' '}
        {machine.rentalmanId && `(${machine.rentalmanId})`}
      </Typography>
    );
  }

  return isLoading || loadingMachineProviders ? (
    <Loading />
  ) : (
    <Stack spacing={2} className="equipment-detail">
      {title}
      <TabButton
        tab={[
          {
            content: (
              <DeviceDetailContainer machine={machine}>
                <DeviceDetailTelematic
                  id={id}
                  machine={machine}
                  geoLocLastInfo={lastInfoGeoLocForEachProvider}
                />
              </DeviceDetailContainer>
            ),
            label: t`Télématiques`,
          },
          {
            content: (
              <DeviceDetailContainer machine={machine}>
                <DeviceDetailUsage>
                  <EquipmentUtilisation
                    id={id}
                    machine={machine}
                    setMachine={setMachine}
                    contract={contract}
                    machineClientsOptions={machineClientsOptions}
                  />
                </DeviceDetailUsage>
              </DeviceDetailContainer>
            ),
            disabled: true,
            label: t`Utilisation`,
          },
          {
            content: (
              <DeviceDetailContainer machine={machine}>
                <DeviceDetailInformation machine={machine} />
              </DeviceDetailContainer>
            ),
            label: t`Informations`,
          },
          {
            content: (
              <DeviceDetailContainer machine={machine}>
                {contract ? (
                  <DeviceDetailContract
                    machine={machine}
                    contract={contract}
                    agency={agency}
                  />
                ) : (
                  <Stack className="equipment-detail__no-data">
                    <Typography variant="h5">
                      {t`La machine n'a pas d'information d'utilisation`}
                    </Typography>
                  </Stack>
                )}
              </DeviceDetailContainer>
            ),
            label: t`Contrat`,
          },
          {
            content: MachineContent(
              ((!machine.lon && !machine.lat) ||
                (!machine.gbLong && !machine.gbLat)) &&
                !isInternalAppMode ? (
                <ErrorMessage message={t`Aucune Coordonnée`} />
              ) : (
                <MachineMap
                  machine={machine}
                  locations={[goobie, machine].flat()}
                  geoZone={{
                    displayRegions,
                    displayDepartments,
                    geoZoneIds: geoZonesId,
                    handleGeoZoneClicked,
                  }}
                />
              )
            ),
            label: t`Carte`,
          },
          {
            content: <Box />,
            disabled: true,
            label: t`Environnement`,
          },
          {
            content: <Box />,
            disabled: true,
            label: t`Accès`,
          },
          {
            content: (
              <DeviceDetailContainer machine={machine}>
                <DeviceDetailUsage>
                  <EquipmentSecurity
                    id={id}
                    clientsOptions={machineClientsOptions}
                    jobSiteName={contract?.constructionSiteName}
                    headersTable={[t`Type`, t`Date`, t`Durée`]}
                    metricsCallBack={securityMetricsCallback}
                    metricsTableCallBack={securityMetricsTableCallback}
                    GenerateTableCells={securityMetricsTableRow}
                  />
                </DeviceDetailUsage>
              </DeviceDetailContainer>
            ),
            disabled: true,
            label: t`Sécurité`,
          },
          {
            content: <Box />,
            disabled: true,
            label: t`Documentation`,
          },
          {
            content:
              oemOptionsState.oemOptions?.length >= 1 ? (
                <DeviceDetailContainer machine={machine}>
                  <DeviceDetailUsage>
                    <EquipmentHistory
                      id={id}
                      rentalmanId={machine.rentalmanId}
                      oemOptions={oemOptionsState.oemOptions}
                    />
                  </DeviceDetailUsage>
                </DeviceDetailContainer>
              ) : null,
            disabled: oemOptionsState.oemOptions?.length === 0,
            showspinner: !oemOptionsState.loaded,
            label: t`Historique`,
          },
        ]}
      />
    </Stack>
  );
};

export default EquipmentDetail;
