import React from 'react';
import { Link } from 'react-router-dom';

const EditData = (props) => (
  <Link
    // eslint-disable-next-line react/destructuring-assignment
    to={`${props.path}/${props.data}`}
    className="btn btn-sm btn-icon btn-secondary"
  >
    <i className="fa fa-pencil-alt" />
    <span className="sr-only">Edit</span>
  </Link>
);

EditData.defaultProps = {
  action: true,
};

export default EditData;
