import moment from 'moment';
import { SecurityAlertProps } from '../../../components/SecurityAlert/SecurityAlert';
import { MonthHoverTypeEnum } from '../../components/organisms/MuiCustomCalendar/utils.interface';
import { calculFullHoursFromDuration } from '../date/calcul';

export const formatSecurityAlerts = (alerts: any[]) => {
  const alertsFormatted: SecurityAlertProps[] = [];

  const addAlert = (alert: any) => {
    // alert.metricsSum are in secounds, alert display it in minutes
    const { hours, minutes, seconds } = calculFullHoursFromDuration(
      alert.metricsSum
    );

    const totalMinutes = Number(hours) * 60 + Number(minutes);

    alertsFormatted.push({
      title: alert.metric,
      notification: {
        total: alert.metricEvents,
      },
      machine: {
        total: alert.machine,
      },
      time: {
        total: `${totalMinutes}:${seconds}`,
      },
    });
  };

  alerts.forEach((alert: any) => addAlert(alert));

  return alertsFormatted;
};

export const securityAlertsAggByWeek = (alerts: any[], weekIndex: number) => {
  let time = 0;

  alerts.forEach((alert: any) => {
    const dayWeek =
      moment(alert.day).week() - moment(alert.day).startOf('month').week() + 1;

    if (dayWeek === weekIndex) {
      time += alert.duration;
    }
  });

  return time;
};

export const checkSecurityGraphDisplayInMinutes = (
  alerts: any[],
  selectedCalendarFilter: MonthHoverTypeEnum
) => {
  const alertDurations: number[] = [];

  if (selectedCalendarFilter === MonthHoverTypeEnum.DAY) {
    alerts.forEach((alert: any) => alertDurations.push(alert.duration));
  } else if (selectedCalendarFilter === MonthHoverTypeEnum.WEEK) {
    alerts.forEach((alert: any) => alertDurations.push(alert.totalDayDuration));
  } else if (selectedCalendarFilter === MonthHoverTypeEnum.MONTH) {
    const weeksIndices = Array(5)
      .fill(0)
      .map((_, i) => i + 1);

    weeksIndices.forEach((weekIndex) => {
      alertDurations.push(securityAlertsAggByWeek(alerts, weekIndex));
    });
  }

  const isAlertsUnderOneHours =
    alertDurations.filter((a) => a > 60).length === 0;

  return isAlertsUnderOneHours;
};
