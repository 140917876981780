import { useState } from 'react';

import { Card, CardBody, Input } from 'reactstrap';

import { Form, TextField } from '../../../lib/apptheme/components';

import defaultLogo from '../../../lib/apptheme/assets/images/brand-logo.svg';

const OnBoarding = ({ logo }) => {
  const [checkBox, setCheckbox] = useState(false);

  const handleSubmit = (values, actions) => {
    if (values.newPassword !== values.passwordCheck) {
      actions.setStatus('Les mots de passes ne sont pas identiques');
      actions.setSubmitting(false);
    }
    if (!checkBox) {
      actions.setStatus(
        "Veuillez accepter les conditions générales d'utilisation"
      );
      actions.setSubmitting(false);
    }
  };

  const handleChange = () => {
    setCheckbox(!checkBox);
  };

  return (
    <main className="auth">
      <header id="auth-header" className="auth-header">
        <h1>
          <img src={logo || defaultLogo} height="28" alt="" />
          <span className="sr-only">S&apos;authentifier</span>
        </h1>
      </header>
      <div className="auth-form" style={{ maxWidth: '50%' }}>
        <h4 className="enable-responsive-font-size mb-4">
          Bienvenue sur <strong>LoxSafe</strong>
        </h4>
        <p className="lead text-muted mb-5" id="la-barre-laterale">
          Merci de compléter les informations ci-dessous pour accéder à
          l&apos;application
        </p>
        <Form
          onSubmit={handleSubmit}
          initialValues={{ newPassword: '', passwordCheck: '' }}
          buttonName="Enregistrer"
          buttonClassName="btn btn-lg btn-primary btn-block"
        >
          <TextField
            type="password"
            name="newPassword"
            label="Nouveau mot de passe"
            required
          />
          <TextField
            type="password"
            name="passwordCheck"
            label="Vérification du mot de passe"
            required
          />
          <div className="text-center pt-3">
            <b>Condition générales d&apos;utilisation</b>
          </div>
          <Card
            style={{
              height: '300px',
              overflowX: 'scroll',
              wordBreak: 'break-word',
              marginBottom: '10px',
            }}
          >
            <CardBody>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                convallis mauris nisl, eu eleifend velit scelerisque quis.
                Aenean a est sit amet nisl pharetra imperdiet. Integer congue
                ante id urna placerat aliquet. Donec id arcu nec sem volutpat
                feugiat sodales ut nibh. Mauris sit amet lobortis metus, et
                porta erat. Sed libero lorem, hendrerit vitae lorem sed,
                vestibulum fermentum mi. Pellentesque pharetra, nunc blandit
                fringilla semper, metus risus efficitur dolor, et dapibus nulla
                magna lacinia metus. Vestibulum quis dictum leo. Proin
                vulputate, urna at euismod suscipit, eros dolor porttitor erat,
                eget dictum tortor orci eu tortor.
              </p>
              <p>
                Sed accumsan lorem vitae tristique venenatis. Duis interdum
                risus ac elit malesuada eleifend. Curabitur condimentum metus
                sit amet mi semper sollicitudin. Aliquam ut convallis magna.
                Aliquam et elementum orci. Nam aliquam sem eget elit ultrices,
                et vestibulum ante tempor. Integer pharetra sit amet quam eget
                convallis. Phasellus gravida vel ex at pellentesque. Suspendisse
                bibendum cursus justo eget rhoncus. Maecenas vel est accumsan
                neque consectetur aliquam. Cras ullamcorper felis dolor, vel
                placerat ante pellentesque quis.{' '}
              </p>
              <p>
                Vivamus sollicitudin, leo vitae lobortis ultrices, lectus dolor
                sodales sapien, at feugiat lacus felis ut enim. Nullam a nunc
                rutrum, cursus magna at, viverra libero. Sed vitae diam sodales,
                malesuada felis vitae, ullamcorper nulla. Nulla facilisi.
                Maecenas vitae vestibulum magna, nec dictum neque. Phasellus ut
                orci sagittis nunc egestas suscipit a a sem. Nunc tempus mauris
                sit amet dolor rhoncus, at fringilla magna dignissim. Vivamus
                sapien ipsum, dignissim tincidunt leo sed, imperdiet eleifend
                arcu. Proin tristique non erat ut accumsan. In pretium viverra
                ante et luctus. Duis vulputate mi eget lectus molestie ultrices.
                Maecenas in lectus libero. Nulla porttitor mollis mollis. Ut et
                massa id tellus fringilla lacinia.
              </p>
              <p>
                Nullam at maximus dui. Aliquam erat volutpat. Nulla facilisi.
                Aliquam dapibus a mi id sollicitudin. In ac tellus ligula.
                Aenean et elementum justo. Nam commodo suscipit velit.
                Pellentesque vitae molestie tellus, nec efficitur est. Donec
                tortor tortor, tincidunt nec mattis in, imperdiet et orci.
                Vestibulum metus diam, tempor pretium libero ac, volutpat
                consectetur nibh. Phasellus libero magna, suscipit in mattis at,
                efficitur eu lacus. Nullam non tempus ligula. Suspendisse
                condimentum vehicula augue sagittis euismod. Pellentesque
                elementum mauris nec mauris laoreet maximus. Aliquam aliquam
                ante eu sodales sollicitudin.
              </p>
              <p>
                Phasellus blandit ultrices scelerisque. Aenean malesuada est
                mauris, et hendrerit ligula porta et. Phasellus eu elit eget
                ligula lacinia auctor. Aenean nunc sapien, ullamcorper non
                ligula a, pharetra rhoncus tellus. Donec maximus, dolor eget
                interdum auctor, erat lectus tincidunt sem, at semper lorem
                turpis et odio. Vivamus semper, nisl eu ornare venenatis, metus
                augue ullamcorper diam, non mattis orci nibh cursus lorem. Etiam
                ac lacinia odio. Nullam at velit non dolor sollicitudin ultrices
                ac vitae diam. Cras vehicula nibh urna, non mollis turpis
                imperdiet vitae. Phasellus eget nisl ac sapien auctor imperdiet
                eu eu est. Praesent tellus risus, ultricies venenatis gravida
                sit amet, consectetur et neque. Sed dictum mauris dictum elit
                egestas, vel dapibus mi egestas. Integer eu enim placerat,
                maximus erat quis, aliquam nulla. Donec ut nibh elit. Donec
                vestibulum justo a ligula commodo, a imperdiet arcu tincidunt.
                Aliquam nec leo vel ipsum iaculis tincidunt.
              </p>
            </CardBody>
          </Card>
          <div className="custom-control custom-control-inline custom-checkbox">
            <Input type="checkbox" onChange={handleChange} />
            J&apos;ai lu et approuvé les conditions générales d&apos;utilisation
          </div>
        </Form>
      </div>
    </main>
  );
};

export default OnBoarding;
