import { useState } from 'react';
import { DropdownItem } from 'reactstrap';

// eslint-disable-next-line import/no-cycle
import { UserDropdown } from '.';
import { useUserContext } from '../../../app/contexts/UserContext/UserContext';

const TopBarItemRight = ({ headerNavs, userDropdownItems }) => {
  const [dropdownOpen, toggle] = useState(false);
  const user = useUserContext();

  return (
    <div className="top-bar-item top-bar-item-right px-0 d-none d-sm-flex">
      <ul className="header-nav nav">{headerNavs}</ul>
      <UserDropdown isOpen={dropdownOpen} toggle={() => toggle(!dropdownOpen)}>
        <DropdownItem header disabled className="d-none d-md-block d-lg-none">
          {user.firstName}
        </DropdownItem>
        {userDropdownItems}
      </UserDropdown>
    </div>
  );
};

export default TopBarItemRight;
