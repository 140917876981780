import { t } from '@lingui/macro';
import { Grid } from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { ErrorMessage } from '../../../../../../lib/apptheme/partials';
import {
  getMachineHistoryData,
  getMachineJourneyCsv,
} from '../../../../../api/machines/Machines';
import HistoryMap from '../../../Map/HistoryMap/HistoryMap';
import './EquipmentHistory.scss';
import EquipmentHistoryOptions from './EquipmentHistoryOptions';
import EquipmentHistoryTable from './EquipmentHistoryTable/EquipmentHistoryTable';
import { getDateTimeWithUserTimeZone } from '../../../../../utils/date/format';

const START_DATE = moment().startOf('day').toDate();
const END_DATE = new Date();

// Repeated error message because lingui couldn't keep the translation as a constant
const EquipmentHistory = ({ id, rentalmanId, oemOptions }) => {
  const [locations, setLocations] = useState([]);
  const [locationsToHighlight, setLocationsToHighlight] = useState([]);
  const [selectedJourney, setSelectedJourney] = useState();
  const [geolocationCsv, setgeolocationCsv] = useState([]);
  const [journeysCsv, setjourneysCsv] = useState([]);
  const [currentOption, setCurrentOption] = useState('');
  const [start, setStart] = useState(START_DATE);
  const [end, setEnd] = useState(END_DATE);
  const [errorMessage, setErrorMessage] = useState(
    t`Sélectionnez le tracker, une plage de dates et cliquez sur le bouton pour charger les données.`
  );
  const [loading, setLoading] = useState(false);

  // Reset history state when Machine URL change
  useEffect(() => {
    setLoading(true);
    setLocations([]);
    setStart(START_DATE);
    setEnd(END_DATE);
    setErrorMessage(
      t`Sélectionnez le tracker, une plage de dates et cliquez sur le bouton pour charger les données.`
    );
  }, [id]);

  // Set the first available provider as the default
  useEffect(() => {
    setLoading(false);
    setCurrentOption(oemOptions[0]);
  }, [oemOptions]);

  const updateMachineCoordinates = (machineId, currentOption, start, end) => {
    setLoading(true);
    const data = {
      origin: currentOption,
      startDate: start,
      startDateFastTime: getDateTimeWithUserTimeZone(start),
      endDate: end,
      endDateFastTime: getDateTimeWithUserTimeZone(end),
    };
    getMachineHistoryData(machineId, data).then((resp) => {
      const filteredResp = resp.filter((el) => el.lon && el.lat);

      // Create a new array with the necessary format to download the Geolocation CSV
      const geolocationCsvTemp = filteredResp.reduce((acc, location) => {
        const newLocation = {
          date: location.historyDate || location.gpsFixTime,
          // eslint-disable-next-line
          geolocation: `${location.gbLat || location.lat}, ${
            location.gbLong || location.lon
          }`,
          address: location.address,
          engine: location.engine,
        };
        return [...acc, newLocation];
      }, []);

      setLocations(filteredResp);
      setgeolocationCsv(geolocationCsvTemp);

      if (resp?.length === 0) {
        setErrorMessage(
          t`Pas de coordonnées pour ${currentOption} aux dates du ${moment(
            start
          ).format('DD/MM/YYYY')} au ${moment(end).format('DD/MM/YYYY')}`
        );
      }
      setLoading(false);
    });

    getMachineJourneyCsv(machineId, data).then((resp) => {
      setjourneysCsv(resp || []);
    });
  };

  // Function used to highlight a journey on the table and map
  const highlightJourneyOnRowClick = useCallback(
    (journey) => {
      // If a journey don't have the final coordinates it's incomplete
      // Don't do anything
      if (!journey.finalCoordinates) {
        return;
      }
      // Checks if a clicked journey is the current selected journey
      const clickOnSelectedJourney = journey === selectedJourney;
      if (journey && !clickOnSelectedJourney) {
        const coords = journey.initialCoordinates
          .split(',')
          .concat(journey.finalCoordinates?.split(','));
        setLocationsToHighlight(coords);
        setSelectedJourney(journey);
        // If the clicked journey is the current selected journey
        // Reset everything
      } else {
        setLocationsToHighlight([]);
        setSelectedJourney({});
      }
    },
    [selectedJourney]
  );

  const handleLoadDataMap = useCallback(() => {
    updateMachineCoordinates(id, currentOption, start, end);
    setLocationsToHighlight([]);
  }, [id, currentOption, start, end]);

  return (
    <Grid container>
      <EquipmentHistoryOptions
        options={oemOptions}
        currentOption={currentOption}
        setCurrentOption={setCurrentOption}
        start={start}
        searchHistory={handleLoadDataMap}
        end={end}
        setStart={setStart}
        setEnd={setEnd}
        loading={loading}
      />
      {locations && locations?.length < 1 ? (
        <ErrorMessage message={errorMessage} />
      ) : (
        <>
          <HistoryMap
            locations={locations}
            locationsToHighlight={locationsToHighlight}
          />
        </>
      )}
      <>
        <EquipmentHistoryTable
          data={journeysCsv}
          handleRowClick={highlightJourneyOnRowClick}
          selectedRow={selectedJourney}
          journeysCsv={journeysCsv}
          geolocationCsv={geolocationCsv}
          id={id}
          rentalmanId={rentalmanId}
          start={start}
          end={end}
        />
      </>
    </Grid>
  );
};

export default EquipmentHistory;
