import React from 'react';
import { useHistory } from 'react-router-dom';
import { Table as TableStrap } from 'reactstrap';

const CustomTable = (props) => {
  const { hover, children, data, isLoading } = props;
  const history = useHistory();

  const handleRowClick = (id) => {
    if (hover) {
      history.push(`${hover.path}/${id}`);
    }
  };

  const hoverr = !!hover;

  return (
    <div className="table-responsive">
      <TableStrap hover={hoverr}>
        <thead>
          <tr>
            {React.Children.map(children, (child) => (
              <th
                style={
                  child.props.action && { width: '100px', minWidth: '100px' }
                }
              >
                {child.props.label ? child.props.label : ' '}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!isLoading ? (
            data.length > 0 ? (
              data.map((item, index) =>
                hover ? (
                  <tr key={index} onClick={() => handleRowClick(item.id)}>
                    {React.Children.map(children, (child, index) => (
                      <td
                        key={index}
                        className={`align-middle ${
                          child.props.action && 'text-right'
                        }`}
                      >
                        {React.cloneElement(child, {
                          data: child.props.name
                            ? item[child.props.name]
                            : item,
                        })}
                      </td>
                    ))}
                  </tr>
                ) : (
                  <tr key={index}>
                    {React.Children.map(children, (child, index) => (
                      <td
                        key={index}
                        className={`align-middle ${
                          child.props.action && 'text-right'
                        }`}
                      >
                        {React.cloneElement(child, {
                          data: child.props.name
                            ? item[child.props.name]
                            : item,
                        })}
                      </td>
                    ))}
                  </tr>
                )
              )
            ) : (
              <tr>
                <td colSpan={React.Children.count(children)}>
                  Vous n&apos;avez pas encore de rapport personnalisé
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan={React.Children.count(children)}>
                Chargement en cours...
              </td>
            </tr>
          )}
        </tbody>
      </TableStrap>
    </div>
  );
};

CustomTable.defaultProps = {
  isLoading: false,
};

export default CustomTable;
