import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  StyledTableCell,
  StyledTableRow,
} from '../../../../../components/molecules/TableWithPagination/UsedComponentsAndImports';
import IsMobile from '../../../../../utils/IsMobile';
import CockpitTrackUnitRow from './CockpitTrackUnitRow';

const tableHeaders = [
  'Année',
  'Supposées être équipées',
  "Equipées d'OEM et de TrackUnit",
  "Non équipées d'OEM et de TrackUnit",
];

const mockData = [
  {
    anne: 2014,
    supposees: 100,
    equipees: 80,
    nonEquipees: 20,
  },
  {
    anne: 2015,
    supposees: 100,
    equipees: 80,
    nonEquipees: 20,
  },
  {
    anne: 2016,
    supposees: 100,
    equipees: 80,
    nonEquipees: 20,
  },
];

const CockpitTrackUnitTable = () => {
  const isMobile = IsMobile();

  return (
    <>
      <Typography variant="h3">TrackUnit et OEM</Typography>
      <TableContainer className="table-with-pagination__table">
        <Table
          sx={{
            borderSpacing: '0 8px !important',
            borderCollapse: 'separate !important',
          }}
          aria-label="simple table"
          stickyHeader
        >
          {!isMobile && (
            <TableHead className="table-header">
              <TableRow>
                {tableHeaders.map((th, i) => (
                  <StyledTableCell key={i}>
                    <Box className="table-header__cell">{th}</Box>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
          )}

          <TableBody className="table-with-pagination__table-body">
            {mockData.map((d, i) => (
              <StyledTableRow component={Paper} key={i}>
                <CockpitTrackUnitRow tracker={d} key={i} />
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CockpitTrackUnitTable;
