import { StyleSheet } from '@react-pdf/renderer';
import colors from '../../../../theme/_colors.scss';

export const AlertPDFStyles = StyleSheet.create({
  alert: {
    backgroundColor: colors.isabelline,
    color: colors.chineseBlack,
    width: 500,
    height: 'auto',
    margin: '10px 50px 0px 50px',
  },
  alertTitle: {
    margin: '20px 20px 10px 20px',
    fontSize: 20,
  },
  alertRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
  },
  alertCell: {
    width: '100%',
    alignItems: 'center',
  },
  alertCount: {
    marginBottom: 10,
    fontSize: 25,
  },
  alertName: {
    marginBottom: 8,
    fontSize: 15,
  },
  alertPercentage: {
    fontSize: 15,
  },
});
