import { Trans } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import AutoCompleteSelect from '../AutoCompleteSelect/AutoCompleteSelect';
import './FilterAutoComplete.scss';

const FilterAutoComplete = ({
  result,
  options,
  index,
  title,
  filterKey,
  onChange,
}) => {
  return (
    <Stack className="stack">
      <Typography variant="h3" className="title">
        {title}:{' '}
        {/* <Typography className="length">
          (<Trans>Options</Trans> : {result !== 0 ? result - 1 : 0})
        </Typography> */}
      </Typography>
      <AutoCompleteSelect
        className="select"
        options={options}
        defaultIndex={index}
        updateParentIndex={(e) => onChange(e, filterKey)}
      />
    </Stack>
  );
};

export default FilterAutoComplete;
