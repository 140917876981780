import { t, Trans } from '@lingui/macro';
import DownloadIcon from '@mui/icons-material/Download';
import { IconButton, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import {
  getAsyncCockpitDetails,
  getSyncCockpitDetails,
} from '../../api/machines/Machines';
import IsMobile from '../../utils/IsMobile';
import { cellsWrap } from '../../utils/table/table';
import { displayPercentageOrDefault } from './utils';

const CockpitRow = ({ origin, message, count, percentage, isSync, error }) => {
  const isMobile = IsMobile();
  const csvDownloadRef = useRef(null);

  const headers = [];

  if (origin === 'GOOBIE') {
    headers.push(
      { label: t`company_code`, key: 'company_code' },
      { label: t`description`, key: 'description' },
      { label: t`Numéro de la machine`, key: 'equipment_number' },
      { label: t`imei`, key: 'imei' },
      { label: t`installation_date`, key: 'start_date' },
      { label: t`machine_id`, key: 'machine_id' },
      { label: t`marque`, key: 'marque' },
      { label: t`provider`, key: 'provider' },
      { label: t`gb_lat`, key: 'gb_lat' },
      { label: t`gb_long`, key: 'gb_long' },
      { label: t`gb_last_sync_time `, key: 'gb_last_sync_time' }
    );
  } else {
    headers.push(
      { label: t`boitier`, key: 'serial_number' },
      { label: t`company_code`, key: 'company_code' },
      { label: t`description`, key: 'description' },
      { label: t`equipment_number`, key: 'equipment_number' },
      { label: t`last_sync_time`, key: 'last_sync_time' },
      { label: t`lat`, key: 'lat' },
      { label: t`lon`, key: 'lon' },
      { label: t`machine_id`, key: 'machine_id' },
      { label: t`make`, key: 'make' },
      { label: t`origin`, key: 'origin' }
    );
  }

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getCsvDetail = (e) => {
    e.stopPropagation();
    setLoading(true);
    const fetchData = isSync ? getSyncCockpitDetails : getAsyncCockpitDetails;
    fetchData(origin)
      .then((resp) => {
        setData(resp);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setData([]);
      });
  };

  useEffect(() => {
    if (data.length) {
      setTimeout(() => {
        csvDownloadRef.current.link.click();
      }, 500);
    }
  }, [data]);

  const cells = isMobile
    ? [
        <Stack
          className={
            error
              ? 'machine-row-mobile cockpit-warning-text'
              : 'machine-row-mobile'
          }
        >
          <Stack direction="row" spacing={1}>
            <Typography>
              <b>
                <Trans>Type de boitier</Trans>:
              </b>
              {message}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography>
              <b>
                <Trans>Nombre de boitiers: </Trans>:
              </b>
              {count}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography>
              <b>
                <Trans>Pourcentage</Trans>:
              </b>
              {displayPercentageOrDefault(percentage)}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            {!data.length > 0 ? (
              <IconButton
                sx={{ width: 'fit-content' }}
                onClick={() => {
                  getCsvDetail();
                }}
              >
                {!loading ? <DownloadIcon /> : <CircularProgress size="20px" />}
              </IconButton>
            ) : (
              <CSVLink
                headers={headers}
                separator=";"
                filename={`${origin}-${
                  isSync ? 'sync' : 'async'
                }-cockpit-list-.csv`}
                data={data}
              >
                <Typography variant="body2Bold" fontSize="14px">
                  <Trans>Télécharger la liste</Trans>
                </Typography>
              </CSVLink>
            )}
          </Stack>
        </Stack>,
      ]
    : [
        <Typography className={error ? 'cockpit-warning-text' : ''}>
          {message}
        </Typography>,
        <Typography className={error ? 'cockpit-warning-text' : ''}>
          {count}
        </Typography>,
        <Typography className={error ? 'cockpit-warning-text' : ''}>
          {displayPercentageOrDefault(percentage)}
        </Typography>,
        <Stack>
          <IconButton
            onClick={getCsvDetail}
            sx={{ borderRadius: '50%', width: 40, height: 40 }}
          >
            {!loading ? (
              <DownloadIcon
                sx={{ color: data.length > 0 ? 'gray' : 'primary.main' }}
              />
            ) : (
              <CircularProgress size="20px" />
            )}
          </IconButton>
          <CSVLink
            headers={headers}
            separator=";"
            className="d-none"
            filename={`${origin}-${
              isSync ? 'sync' : 'async'
            }-cockpit-list-.csv`}
            data={data}
            target="_blank"
            ref={csvDownloadRef}
          >
            CSVLink
          </CSVLink>
        </Stack>,
      ];

  return cellsWrap(cells, '50px');
};

export default CockpitRow;
