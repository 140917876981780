import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import IsMobile from '../../../../utils/IsMobile';

interface LinkRef {
  title: string;
  disabled: boolean;
  href: string;
  value: string;
  mainIcon: string;
}

interface LinksMenuProps {
  pathName: string;
  mobileLinks: LinkRef[];
  desktopLinks: LinkRef[];
}

const LinksMenu = ({ pathName, mobileLinks, desktopLinks }: LinksMenuProps) => {
  const isMobile = IsMobile();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderLink = (link: LinkRef) => {
    return link.disabled ? (
      <Typography
        key={`${link.title}${link.mainIcon}${Math.random()}`}
        className="plain-app-nav-bar__link--disabled"
      >
        <Trans id={link.title} />
      </Typography>
    ) : (
      <Link
        key={link.href}
        to={link.href}
        style={{ textDecoration: 'none' }}
        className={`plain-app-nav-bar__link ${
          pathName === link.value ? 'plain-app-nav-bar__link--active' : ''
        }`}
      >
        <Trans id={link.title} />
      </Link>
    );
  };

  return isMobile ? (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled={window.location.href.includes('/scan')}
        hidden={window.location.href.includes('/scan')}
      >
        {open ? <MenuOpenIcon /> : <MenuIcon />}
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {mobileLinks.map((link, index) => (
          <MenuItem key={index} onClick={handleClose}>
            {renderLink(link)}
          </MenuItem>
        ))}
      </Menu>
    </>
  ) : (
    <Stack alignContent="end" direction="row" flexWrap="wrap">
      {desktopLinks.map((link) => renderLink(link))}
    </Stack>
  );
};

export default LinksMenu;
