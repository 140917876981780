import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import './CustomDialog.scss';

// Storybook
const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: #939191;
  }
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
`;

const CustomDialog = ({
  title,
  open,
  onClose,
  onClick,
  buttonText,
  children,
}) => {
  const handleButtonClick = () => {
    onClick();
    onClose('closeButtonClick');
  };

  return (
    <StyledDialog
      className="custom-dialog"
      onClose={(_, reason) => onClose(reason)}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle className="custom-dialog__title">
        {title}
        <CloseIcon
          className="custom-dialog__title-icon"
          onClick={(_, reason) => onClose(reason)}
        />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions className="custom-dialog__actions">
        <Button onClick={handleButtonClick} variant="contained">
          {buttonText}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default CustomDialog;

CustomDialog.propTypes = {
  /**
   * Title
   */
  title: PropTypes.oneOfType([PropTypes.object]).isRequired,
  /**
   * isOpen
   */
  open: PropTypes.bool,
  /**
   * onClose function
   */
  onClose: PropTypes.func.isRequired,
  /**
   * buttonText
   */
  buttonText: PropTypes.string.isRequired,
  /**
   * Children
   */
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
  /**
   * onClick
   */
  onClick: PropTypes.func.isRequired,
};

CustomDialog.defaultProps = {
  open: true,
};
