import { t } from '@lingui/macro';
import { Box, Stack } from '@mui/material';
import classNames from 'classnames';
import CustomizedSwitches from '../../../../../../components/CustomizedSwitches/CustomizedSwitches';
import { ErrorMessage } from '../../../../../../lib/apptheme/partials';
import IsMobile from '../../../../../utils/IsMobile';
import FleetMapAll from '../../../Map/FleetMapAll/FleetMapAll';
import SingleEquipmentMap from '../../../Map/SingleEquipmentMap/SinglEquipmentMap';

import './AgencyCarte.scss';

interface AgencyCarteProps {
  agency: any;
  multipleLocation: any;

  agencyRow: any[];
  machinesRow: any[];
  jobSitesRow: any[];
  displayZone: boolean;

  setDisplayZone: (value: boolean) => void;

  handleEquipmentFilter: (
    toggleValue: boolean,
    equipmentType: 'jobsite' | 'machine'
  ) => void;

  handleMarkerDrag: (coords: { lat: number; lon: number }) => void;

  geoZone: {
    displayRegions: boolean;
    displayDepartments: boolean;
    geoZoneIds: string[];
    handleGeoZoneClicked: (id: string) => void;
  };
}

const AgencyCarte = ({
  agency,
  multipleLocation,
  agencyRow,
  machinesRow,
  jobSitesRow,
  displayZone,
  setDisplayZone,
  handleEquipmentFilter,
  handleMarkerDrag,
  geoZone,
}: AgencyCarteProps) => {
  const isMobile = IsMobile();

  return (
    <Box className="agency-carte">
      {!agency.lon && !agency.lat && !multipleLocation.oneChecked ? (
        <ErrorMessage message={t`Aucune Coordonnée`} />
      ) : (
        <>
          <Stack
            className={classNames('agency-carte__switches-header', {
              'agency-carte__switches-header--mobile-view': isMobile,
            })}
            direction={!isMobile ? 'row' : 'column'}
            spacing={!isMobile ? '10px' : '0px'}
          >
            {/* {jobSitesRow.length > 0 && (
              <CustomizedSwitches
                text={t`Afficher les chantiers`}
                onToggle={(value: boolean) =>
                  handleEquipmentFilter(value, 'jobsite')
                }
              />
            )} */}
            {machinesRow.length > 0 && (
              <CustomizedSwitches
                text={t`Afficher les machines`}
                onToggle={(value: boolean) =>
                  handleEquipmentFilter(value, 'machine')
                }
              />
            )}

            {/* <CustomizedSwitches
              text={
                multipleLocation.oneChecked
                  ? t`Afficher les zones`
                  : t`Afficher la zone`
              }
              value={displayZone}
              onToggle={setDisplayZone}
            /> */}
          </Stack>
          {multipleLocation.oneChecked ? (
            <FleetMapAll
              locations={agencyRow}
              displayZone={displayZone}
              hideZoneForIds={['Machines']}
              mapClassName="agency-carte__map"
              handleMarkerDrag={handleMarkerDrag}
              geoZone={geoZone}
            />
          ) : (
            <SingleEquipmentMap
              lon={agency.lon}
              lat={agency.lat}
              equipment={agency}
              displayZone={displayZone}
              type="Agences"
              mapClassName="agency-carte__map"
              isDraggable={!!agency.isDraggable}
              handleMarkerDrag={handleMarkerDrag}
              geoZone={geoZone}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default AgencyCarte;
