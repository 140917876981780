import moment from 'moment';
import { MonthHoverTypeEnum } from '../../../components/organisms/MuiCustomCalendar/utils.interface';

export const machineUtilisationAggByWeek = (
  machineUsage: any[],
  weekIndex: number,
  usageOutOfTime: boolean
) => {
  let time = 0;

  machineUsage.forEach((m: any) => {
    const totalDayDuration = usageOutOfTime
      ? m.usageOutOfContract
      : m.usageOnContract;

    const dayWeek =
      moment(m.day).week() - moment(m.day).startOf('month').week() + 1;

    if (dayWeek === weekIndex) {
      time += totalDayDuration;
    }
  });

  return time;
};

export const checkGraphDisplayInMinutes = (
  machineUsage: any[],
  displayOutOfContract: boolean,
  selectedCalendarFilter: MonthHoverTypeEnum
) => {
  const usagesTimes: number[] = [];

  if (selectedCalendarFilter === MonthHoverTypeEnum.DAY) {
    machineUsage[0]?.sessionsByHourList?.forEach((metric: any) => {
      // eslint-disable-next-line no-unused-expressions
      !metric.usageOutOfTime &&
        !displayOutOfContract &&
        usagesTimes.push(metric.duration);

      // eslint-disable-next-line no-unused-expressions
      metric.usageOutOfTime &&
        displayOutOfContract &&
        usagesTimes.push(metric.duration);
    });
  } else if (selectedCalendarFilter === MonthHoverTypeEnum.WEEK) {
    usagesTimes.push(...machineUsage.map((u: any) => u.usageOnContract));

    // eslint-disable-next-line no-unused-expressions
    displayOutOfContract &&
      usagesTimes.push(...machineUsage.map((u: any) => u.usageOutOfContract));
  } else if (selectedCalendarFilter === MonthHoverTypeEnum.MONTH) {
    const weeksIndices = Array(5)
      .fill(0)
      .map((_, i) => i + 1);

    weeksIndices.forEach((weekIndex) => {
      usagesTimes.push(
        machineUtilisationAggByWeek(machineUsage, weekIndex, false)
      );

      // eslint-disable-next-line no-unused-expressions
      displayOutOfContract &&
        usagesTimes.push(
          machineUtilisationAggByWeek(machineUsage, weekIndex, true)
        );
    });
  }

  const isMachineUsageUnderOneHours =
    usagesTimes.filter((u) => u > 60).length === 0;

  return isMachineUsageUnderOneHours;
};
