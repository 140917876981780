import { isDateBellow24h } from '../../date/calcul';
import {
  MachineStatusEnum,
  MachineStatusEnumProps,
  NOTWORK,
  ONWORK,
  StatusEnum,
  StatusEnumProps,
  UNDEFINED,
} from './EquipmentStatus.constant';

export interface MachineStatus {
  isLoaned: boolean;
  isBorrowed: boolean;
  status: StatusEnumProps; // eslint-disable-line no-restricted-globals
  libelle: string;
}

export const findMachineStatus = (
  lastSyncTime: string,
  haveEngineRunning: boolean,
  equipmentStatus?: string | undefined | null,
  agencyOwner?: string | undefined | null,
  agencyCurrent?: string | undefined | null,
  agencyIdUser?: string | undefined | null,
  mergedProfil?: string | undefined | null,
  onlyEngineRunning = false
): MachineStatus => {
  const isLoaned = false;
  let isBorrowed = false;
  let statusRef: MachineStatusEnumProps = MachineStatusEnum.UNDEFINED;

  const isMachineUpdateBellow24H = isDateBellow24h(lastSyncTime);

  if (equipmentStatus && !onlyEngineRunning) {
    statusRef = statusToMachineStatus(checkIfStatusExist(equipmentStatus));
  } else if (isMachineUpdateBellow24H && haveEngineRunning !== null) {
    const statusEngine = haveEngineRunning ? ONWORK : NOTWORK;
    statusRef = statusToMachineStatus(statusEngine);
  } else {
    const statusEngine = UNDEFINED;
    statusRef = statusToMachineStatus(statusEngine);
  }

  if (
    agencyOwner !== agencyCurrent &&
    mergedProfil === 'AG' &&
    agencyCurrent === agencyIdUser
  ) {
    isBorrowed = true;
  }

  return {
    isLoaned,
    isBorrowed,
    status: statusRef.key,
    libelle: statusRef.value,
  };
};

export const statusToMachineStatus = (
  statusEnum: string | StatusEnum
): MachineStatusEnumProps => {
  switch (statusEnum) {
    case StatusEnum.U:
      return MachineStatusEnum.PLANNEDRETURN;
    case StatusEnum.I:
      return MachineStatusEnum.INTRANSIT;
    case StatusEnum.K:
      return MachineStatusEnum.INPROGRESSTRANSPORT;
    case StatusEnum.V:
      return MachineStatusEnum.RETURNSELLER;
    case StatusEnum.R:
      return MachineStatusEnum.RETURNAREACTRLREQUIRED;
    case StatusEnum.N:
      return MachineStatusEnum.SHORTTERMBREAKDOWN;
    case StatusEnum.D:
      return MachineStatusEnum.LONGTERMBREAKDOWN;
    case StatusEnum.J:
      return MachineStatusEnum.SCRAPS;
    case StatusEnum.A:
      return MachineStatusEnum.AVAILABLE;
    case StatusEnum.O:
      return MachineStatusEnum.RENTAL;
    case StatusEnum.P:
      return MachineStatusEnum.RENTALSALE;
    case StatusEnum.T:
      return MachineStatusEnum.STOLEN;
    case StatusEnum.M:
      return MachineStatusEnum.LOST;
    case StatusEnum.S:
      return MachineStatusEnum.HIDE;
    case ONWORK:
      return MachineStatusEnum.INTRANSIT;
    case NOTWORK:
      return MachineStatusEnum.STOPPED;
    default:
      return MachineStatusEnum.UNDEFINED;
  }
};

const checkIfStatusExist = (
  equipmentStatus: string | undefined | null
): string | StatusEnum => {
  return (
    Object.keys(StatusEnum).find((v) => v === equipmentStatus) || UNDEFINED
  );
};

export const isToHideMachineDetails = (status: MachineStatus) => {
  return [StatusEnumProps.STOLEN].includes(status.status);
};
