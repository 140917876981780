import { t } from '@lingui/macro';
import { Document, Page, Text, View, Image, Link } from '@react-pdf/renderer';
import { ReportPDFStyles } from './ReportPDF.constant';
import loxamLogo from '../../../../assets/images/loxam-logo-white-default.png';
import reportIcon from '../../../../assets/images/report-icon.png';
import AlertPDF from '../AlertPDF/AlertPDF';
import InfoPDF from '../InfoPDF/InfoPDF';
import { SecurityAlertProps } from '../../../../../components/SecurityAlert/SecurityAlert';
import { Period } from '../../../../../components/EquipmentSecurity/EquipmentSecurity.interface';
import { getPeriodDate } from '../../../../utils/date/format';

interface ReportPDFProps {
  alerts: SecurityAlertProps[];
  period: Period;
}

const ReportPDF = ({ alerts, period }: ReportPDFProps) => {
  return (
    <Document>
      <Page size="A4" style={ReportPDFStyles.page}>
        <Image src={loxamLogo} style={ReportPDFStyles.loxamLogo} />

        <View style={ReportPDFStyles.titleSection}>
          <Image src={reportIcon} style={ReportPDFStyles.reportIcon} />
          <Text style={ReportPDFStyles.periodText}>
            {t`Rapport hebdomadaire des alertes ${getPeriodDate(
              period.start,
              period.end
            )}`}
          </Text>
        </View>

        {alerts.map((alert) => (
          <AlertPDF alert={alert} />
        ))}

        <InfoPDF />

        <View style={ReportPDFStyles.linkSection}>
          <Text>
            {`${t`Pour plus d'informations sur les alertes veuillez vous connecter sur votre espace`} `}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link src="https://myloxconnect.digital.loxam.com">
              <Text style={ReportPDFStyles.linkText}>MyLoxam</Text>
            </Link>
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default ReportPDF;
