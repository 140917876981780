import React from 'react';
import { Formik } from 'formik';
import { Form as FormStrap, Alert } from 'reactstrap';
import { t } from '@lingui/macro';

const Form = ({
  initialValues,
  onSubmit,
  validate,
  enableReinitialize,
  children,
  hideSubmitButton,
  buttonClassName,
  buttonName,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validate={validate}
    enableReinitialize={enableReinitialize}
  >
    {(formikProps) => (
      <FormStrap onSubmit={formikProps.handleSubmit}>
        {formikProps.status && (
          <Alert color="danger">{formikProps.status}</Alert>
        )}
        <fieldset disabled={formikProps.isSubmitting}>
          {React.Children.map(children, (child, index) =>
            React.cloneElement(child, {
              key: index,
              setFieldValue: formikProps.setFieldValue,
              setFieldError: formikProps.setFieldError,
              submitForm: formikProps.submitForm,
            })
          )}
        </fieldset>
        {!hideSubmitButton && (
          <button
            type="submit"
            className={buttonClassName || 'btn btn-primary'}
            disabled={formikProps.isSubmitting}
          >
            {buttonName || t`Enregistrer`}
          </button>
        )}
      </FormStrap>
    )}
  </Formik>
);

export default Form;
