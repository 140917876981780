import useTheme from '@mui/system/useTheme';

const Loading = ({ size = 8, position = { top: '50%', left: '50%' } }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        position: 'absolute',
        top: position.top,
        left: position.left,
        marginLeft: `-${size / 2}rem`,
        marginTop: `-${size / 2}rem`,
      }}
    >
      <div
        className="spinner-grow"
        style={{
          width: `${size}rem`,
          height: `${size}rem`,
          backgroundColor: theme.palette.primary?.main,
        }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Loading;
