import { t } from '@lingui/macro';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { updateJobSiteZone } from '../../../../api/jobsites/JobSites';
import { useUserContext } from '../../../../contexts/UserContext/UserContext';
import SaveCancelStack from '../../../../../components/SaveCancelStack/SaveCancelStack';

import './JobSiteCarteZone.scss';

interface JobSiteCarteZoneProps {
  jobsite: any;
  drawingZone: boolean;
  zonePoints: any[];

  setZonePoints: (values: any[]) => void;
  setDisplayZone: (value: boolean) => void;
  setDrawingZone: (value: boolean) => void;
  handleZoneUpdated: () => void;
}

const JobSiteCarteZone = ({
  jobsite,
  drawingZone,
  zonePoints,
  setZonePoints,
  setDisplayZone,
  setDrawingZone,
  handleZoneUpdated,
}: JobSiteCarteZoneProps) => {
  const user = useUserContext();

  const updateZone = async () => {
    if (user) {
      await updateJobSiteZone(jobsite.id, zonePoints);

      handleZoneUpdated();
      setDrawingZone(false);
      setZonePoints([]);
      setDisplayZone(false);
    }
  };

  return (
    <>
      <Typography
        className="jobsite-carte-zone"
        sx={{ color: 'primary.main' }}
        variant="h3"
      >
        {t`Zone`}
      </Typography>

      {drawingZone ? (
        <SaveCancelStack
          onSave={updateZone}
          onCancel={() => {
            setDrawingZone(false);
            setZonePoints([]);
            setDisplayZone(false);
          }}
        />
      ) : jobsite.zones.length > 0 ? (
        <Button
          className="jobsite-carte-zone__inline-block"
          variant="contained"
          onClick={() => {
            setDrawingZone(true);
            setDisplayZone(true);
          }}
        >
          {t`Nouvelle Zone`}
        </Button>
      ) : (
        <Button
          className="jobsite-carte-zone__inline-block"
          variant="contained"
          onClick={() => {
            setDrawingZone(true);
          }}
          disabled={!jobsite.lat || !jobsite.lon}
        >
          {t`Créer la zone`}
        </Button>
      )}
    </>
  );
};

export default JobSiteCarteZone;
