import Typography from '@mui/material/Typography';

import './MachineJobSite.scss';

const MachineJobSite = ({ jobSiteDesc }: { jobSiteDesc: string }) => {
  return (
    <Typography className="machine-jobsite">{jobSiteDesc || '-'}</Typography>
  );
};

export default MachineJobSite;
