import { Trans } from '@lingui/macro';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Loading } from '../../lib/apptheme';

import './ConfirmDialog.scss';

const ConfirmDialog = ({
  title,
  open,
  onClose,
  onConfirm,
  errorMessage,
}: any) => {
  const [disableButtons, setDisableButtons] = useState(false);
  const [confirming, setConfirming] = useState(false);

  useEffect(() => {
    setConfirming(false);
    setDisableButtons(false);
  }, [open]);

  return (
    <Dialog
      className="confirm-dialog"
      onClose={onClose}
      aria-labelledby="confirm-dialog"
      open={open}
      maxWidth="sm"
    >
      <DialogTitle className="confirm-dialog__title">
        <Typography className="confirm-dialog__title-text" variant="h4">
          <Trans id={title} />
        </Typography>

        <CloseIcon className="confirm-dialog__title-icon" onClick={onClose} />
      </DialogTitle>

      {confirming && (
        <Box className="confirm-dialog__loading">
          <Loading />
        </Box>
      )}

      <DialogActions className="confirm-dialog__actions">
        <Button
          onClick={() => {
            onConfirm();
            setConfirming(true);
            setDisableButtons(true);
          }}
          variant="contained"
          disabled={disableButtons}
        >
          <Trans>Oui</Trans>
        </Button>

        <Button onClick={onClose} variant="contained" disabled={disableButtons}>
          <Trans>Non</Trans>
        </Button>
      </DialogActions>

      {errorMessage !== '' && (
        <Typography
          variant="caption"
          color="error"
          className="confirm-dialog__error"
        >
          <Trans id={errorMessage} />
        </Typography>
      )}
    </Dialog>
  );
};

export default ConfirmDialog;
