import PropTypes from 'prop-types';
import moment from 'moment';
import { t } from '@lingui/macro';

import { getFullDate } from '../../../../utils/date/format';
import { isDateBellow24h } from '../../../../utils/date/calcul';

const TelematicEngineRunningDateTime = ({ engineRunningDatetime }) => {
  let message;
  const isEngineRunningDatetimeValid = moment(engineRunningDatetime).isValid();

  if (isEngineRunningDatetimeValid) {
    const isBellow24h = isDateBellow24h(engineRunningDatetime);
    const label = `${isBellow24h ? t`Le` : t`Dernière mise à jour`}`;
    const dateTimeInfo = `${
      isBellow24h
        ? `${getFullDate(engineRunningDatetime)}`
        : `(${getFullDate(engineRunningDatetime)})`
    }`;
    message = `${label} ${dateTimeInfo}`;
  } else {
    message = `(${t`Pas d'information reçue`})`;
  }

  return <>{message}</>;
};

export default TelematicEngineRunningDateTime;

TelematicEngineRunningDateTime.propTypes = {
  engineRunningDatetime: PropTypes.any,
};
