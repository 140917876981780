import { Stack, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const HoursLimit = ({ openingTimes }: { openingTimes: any }) => {
  const actualTime = new Date();

  if (
    actualTime > openingTimes.afternoon.closingDate &&
    actualTime < openingTimes.morning.openingDate
  ) {
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        <AccessTimeIcon fontSize="small" sx={{ color: 'primary.main' }} />
        <Typography variant="body1" sx={{ color: 'primary.main' }}>
          {openingTimes.close} {openingTimes.morning.opening}
        </Typography>
      </Stack>
    );
  }
  if (
    actualTime > openingTimes.morning.openingDate &&
    actualTime < openingTimes.morning.closingDate
  ) {
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        <AccessTimeIcon fontSize="small" sx={{ color: 'success.main' }} />
        <Typography variant="body1" sx={{ color: 'success.main' }}>
          {openingTimes.open} {openingTimes.morning.closing}
        </Typography>
      </Stack>
    );
  }
  if (
    actualTime > openingTimes.morning.closingDate &&
    actualTime < openingTimes.afternoon.openingDate
  ) {
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        <AccessTimeIcon fontSize="small" sx={{ color: 'primary.main' }} />
        <Typography variant="body1" sx={{ color: 'primary.main' }}>
          {openingTimes.close} {openingTimes.afternoon.opening}
        </Typography>
      </Stack>
    );
  }
  if (
    actualTime > openingTimes.afternoon.openingDate &&
    actualTime < openingTimes.afternoon.closingDate
  ) {
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        <AccessTimeIcon fontSize="small" sx={{ color: 'success.main' }} />
        <Typography variant="body1" sx={{ color: 'success.main' }}>
          {openingTimes.open} {openingTimes.afternoon.closing}
        </Typography>
      </Stack>
    );
  }

  return null;
};

export default HoursLimit;
