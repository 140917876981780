import { i18n } from '@lingui/core';

const sortAlphaNumeric = (
  a: string | boolean | null,
  b: string | boolean | null,
  sortType: 'asc' | 'desc' = 'asc'
) => {
  const isABoolean = typeof a === 'boolean';
  const isBBoolean = typeof b === 'boolean';

  // If a or b are booleans, then alphabetically sort
  if (a !== null && b !== null && (isABoolean || isBBoolean)) {
    const sortValue = sortType === 'asc' ? 1 : -1;

    if (a < b) return -sortValue;
    if (a > b) return sortValue;

    return 0;
  }

  // If a or b are null, then move these null values always to the end
  if (a === null) return 1;
  if (b === null) return -1;

  // Finally, alpha numeric sort
  if (sortType === 'asc') {
    return (a as string).localeCompare(b as string, i18n.locale, {
      numeric: true,
    });
  }

  return (b as string).localeCompare(a as string);
};

export default sortAlphaNumeric;
