/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { isDateBellow24h } from '../../utils/date/calcul';

import Pins_Agence_Access_Off from '../../assets/images/agencyIcons/Pins_Agence_Access_Off.svg';
import Pins_Agence_Access from '../../assets/images/agencyIcons/Pins_Agence_Access.svg';

import Pins_Agence_Closed from '../../assets/images/agencyIcons/Pins_Agence_Closed.svg';
import Pins_Agence_Open from '../../assets/images/agencyIcons/Pins_Agence_Open.svg';

import Pins_Agence_Event_Off from '../../assets/images/agencyIcons/Pins_Agence_Event_Off.svg';
import Pins_Agence_Event from '../../assets/images/agencyIcons/Pins_Agence_Event.svg';

import Pins_Agence_LahoTec_Off from '../../assets/images/agencyIcons/Pins_Agence_LahoTec_Off.svg';
import Pins_Agence_LahoTec from '../../assets/images/agencyIcons/Pins_Agence_LahoTec.svg';

import Pins_Agence_Module_Off from '../../assets/images/agencyIcons/Pins_Agence_Module_Off.svg';
import Pins_Agence_Module from '../../assets/images/agencyIcons/Pins_Agence_Module.svg';

import Pins_Agence_Power_Off from '../../assets/images/agencyIcons/Pins_Agence_Power_Off.svg';
import Pins_Agence_Power from '../../assets/images/agencyIcons/Pins_Agence_Power.svg';

import Pins_Agence_TP_Off from '../../assets/images/agencyIcons/Pins_Agence_TP_Off.svg';
import Pins_Agence_TP from '../../assets/images/agencyIcons/Pins_Agence_TP.svg';

export const equipmentObjectsKeyName = {
  Machines: 'machines',
  Chantiers: 'jobSites',
  Agences: 'agencies',
};

const agencyIcons = {
  RENTAL: {
    on: Pins_Agence_Open,
    off: Pins_Agence_Closed,
  },
  ACCESS: {
    on: Pins_Agence_Access,
    off: Pins_Agence_Access_Off,
  },
  TP: {
    on: Pins_Agence_TP_Off,
    off: Pins_Agence_TP,
  },
  POWER: {
    on: Pins_Agence_Power,
    off: Pins_Agence_Power_Off,
  },
  MODULE: {
    on: Pins_Agence_Module,
    off: Pins_Agence_Module_Off,
  },
  LAHOTEC: {
    on: Pins_Agence_LahoTec,
    off: Pins_Agence_LahoTec_Off,
  },
  EVENT: {
    on: Pins_Agence_Event,
    off: Pins_Agence_Event_Off,
  },
  LEV: {
    on: Pins_Agence_Access,
    off: Pins_Agence_Access_Off,
  },
};

export const getAgencyIcon = (label, isOpen) => {
  if (!label) {
    if (isOpen) {
      return agencyIcons.RENTAL.on;
    }
    return agencyIcons.RENTAL.off;
  }

  if (isOpen) {
    return agencyIcons[label].on;
  }
  return agencyIcons[label].off;
};

export const getMachineState = (machine) => {
  const { engineRunning, engineRunningDatetime } = machine;
  if (engineRunningDatetime) {
    return isDateBellow24h(engineRunningDatetime) ? engineRunning : null;
  }
  return engineRunning;
};
