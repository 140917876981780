import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useAppModeContext } from '../../app/utils/hooks';
import { isTokenExpired } from '../../app/utils/token/token';
import { instance, removeAuthorizationToken } from '../../app/api/utils/axios';
import { logout } from '../../app/api/auth/customAuth';

const AxiosInterceptor = ({ children }: any) => {
  const { isInternalAppMode } = useAppModeContext();
  const okta = useOktaAuth();

  useEffect(() => {
    const resInterceptor = (req: any) => {
      const common: any = req.config?.headers?.common;

      const jwtToken: string = (
        req.config?.headers?.Authorization ||
        common?.Authorization ||
        ''
      ).toString();

      if (jwtToken && isTokenExpired(jwtToken)) {
        removeAuthorizationToken();

        if (isInternalAppMode) {
          okta.oktaAuth.signOut();
        } else {
          logout().then(() => {
            window.location.reload();
          });
        }
      }

      return req;
    };

    const errInterceptor = (error: any) => {
      if (error.response.status === 401) {
        removeAuthorizationToken();
        window.location.reload();
      }

      return Promise.reject(error);
    };

    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => instance.interceptors.request.eject(interceptor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

export default AxiosInterceptor;
