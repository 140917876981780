import PropTypes from 'prop-types';
import PaginationMUI from '@mui/material/Pagination';

const Pagination = ({ totalItemsCount, itemsCountPerPage, page, onChange }) => {
  return (
    <PaginationMUI
      page={page}
      count={
        Math.ceil(totalItemsCount / itemsCountPerPage) < 2
          ? 0
          : Math.ceil(totalItemsCount / itemsCountPerPage)
      }
      color="primary"
      onChange={onChange}
      hideNextButton={Math.ceil(totalItemsCount / itemsCountPerPage) < 2}
      hidePrevButton={Math.ceil(totalItemsCount / itemsCountPerPage) < 2}
      showFirstButton={Math.ceil(totalItemsCount / itemsCountPerPage) > 2}
      showLastButton={Math.ceil(totalItemsCount / itemsCountPerPage) > 2}
      disabled={Math.ceil(totalItemsCount / itemsCountPerPage) < 1}
    />
  );
};

export default Pagination;

Pagination.propTypes = {
  /**
   * Total items count
   */
  totalItemsCount: PropTypes.number.isRequired,
  /**
   * Items count per page
   */
  itemsCountPerPage: PropTypes.number.isRequired,
  /**
   * Current page
   */
  page: PropTypes.number.isRequired,
  /**
   * Callback function
   */
  onChange: PropTypes.func.isRequired,
};
