import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { t } from '@lingui/macro';
import CockpitTable from '../../CockpitTable';

const tableHeaders = [
  t`Etat de synchronisation`,
  t`Nombre de boitiers`,
  t`Pourcentage`,
  t`Export`,
];

interface iManufacturer {
  logoSrc: string;
  origin: string;
  sync_count: number;
  sync_percentage: number;
  unsync_count: number;
  unsync_percentage: number;
}

const ManufacturerStatus = ({ data }: { data: iManufacturer }) => {
  return (
    <Accordion className="cockpit-recence__manufacturer-accordion">
      <AccordionSummary
        expandIcon={
          <ExpandMore className="cockpit-recence__manufacturer-icon" />
        }
      >
        <img src={data.logoSrc} alt={`${data.logoSrc} logo`} />
      </AccordionSummary>
      <AccordionDetails>
        <CockpitTable tableHeaders={tableHeaders} data={data} />
      </AccordionDetails>
    </Accordion>
  );
};

export default ManufacturerStatus;
