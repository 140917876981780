import { createContext, useContext, useEffect, useState } from 'react';
import { getAgencyById, getAllAgencies } from '../api/agencies/Agencies';
import { Agency } from '../api/agencies/Agency.interface';
import { useAppModeContext } from '../utils/hooks';
import ContextLoadStatus from './utils';

const AgenciesContext = createContext(null);

const AgenciesProvider = ({ children, agencies: agenciesInit }: any) => {
  const { isInternalAppMode } = useAppModeContext();

  const [loadStatus, setLoadStatus] = useState<ContextLoadStatus>(
    agenciesInit ? ContextLoadStatus.LOADED : ContextLoadStatus.NOTLOADED
  );

  const [agencies, setAgencies] = useState<Agency[]>(agenciesInit || []);

  const [isLoadingAgency, setIsLoadingAgency] = useState<boolean>(false);

  useEffect(() => {
    setAgencies(agenciesInit || []);
    setLoadStatus(
      agenciesInit ? ContextLoadStatus.LOADED : ContextLoadStatus.NOTLOADED
    );
  }, [agenciesInit]);

  const loadAgencies = async () => {
    if (!isInternalAppMode) {
      setLoadStatus(ContextLoadStatus.LOADING);

      getAllAgencies().then(
        (result) => {
          setAgencies(result.agencies);
          setLoadStatus(ContextLoadStatus.LOADED);
        },
        (error) => {
          setLoadStatus(ContextLoadStatus.ERROR);
        }
      );
    }
  };

  const loadAgency = async (id: string) => {
    setIsLoadingAgency(true);

    getAgencyById(id).then((result) => {
      setAgencies((prevAgencies) =>
        prevAgencies.map((a) => {
          return a.id !== result.id ? a : result;
        })
      );

      setIsLoadingAgency(false);
    });
  };

  const getAgenciesLoadStatus = (): ContextLoadStatus => {
    return loadStatus;
  };

  const getAgencies = (): any[] => {
    if (loadStatus === ContextLoadStatus.NOTLOADED) {
      loadAgencies();

      return [];
    }

    return agencies;
  };

  const getAgency = (id: string): Agency | undefined => {
    const agenciesFiltered: Agency | undefined = agencies.find(
      (a) => a.id === id
    );

    if (!agenciesFiltered && !isLoadingAgency) {
      loadAgency(id);

      return undefined;
    }
    return agenciesFiltered;
  };

  const agenciesData: any = {
    getAgenciesLoadStatus,
    loadAgencies,
    loadAgency,
    getAgencies,
    getAgency,
  };

  return (
    <AgenciesContext.Provider value={agenciesData}>
      {children}
    </AgenciesContext.Provider>
  );
};

const useAgenciesContext = () => {
  return useContext(AgenciesContext);
};

export { AgenciesProvider, useAgenciesContext };
