import { t } from '@lingui/macro';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Paper, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { TableWithPagination } from '../../../components/molecules';
import { CardTableDetails, CustomDialog } from '../../../components/organisms';

import './MachineGroupDialog.scss';

const MachineGroupDialog = ({
  title,
  headers,
  keyName,
  getCells,
  generateCells,
  asyncCall,
  selectedData,
  setSelectedData,
  disabled,
}) => {
  const [data, setData] = useState([]);
  const [availabeMachines, setAvailabeMachines] = useState([]);
  const [formatedDataRows, setFormatedDataRows] = useState([]);

  const [tempSelectedData, setTempSelectedData] = useState([]);

  const [isLoading, setLoading] = useState(true);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [checkedState, setCheckedState] = useState([]);
  const [totalChecked, setTotalChecked] = useState(0);

  const [open, setOpen] = useState(false);
  const captializeTitle = title.charAt(0).toUpperCase() + title.slice(1);

  const header = disabled ? [...headers] : [...headers, ''];

  const removeElement = (id) => {
    const result = selectedData.filter((el) => el.id !== id);
    setSelectedData(result);

    setTotalChecked((state) => state - 1);
    setIsCheckAll(false);

    const resetCheckedState = checkedState.map((el) => {
      if (el.id === id) {
        return { ...el, checked: false };
      }
      return el;
    });
    setCheckedState(resetCheckedState);
  };

  const fetchData = async () => {
    const response = await asyncCall({});
    const respData =
      keyName === 'machines'
        ? [...selectedData, ...response[keyName]]
        : response[keyName];

    setData(respData);
    setAvailabeMachines(respData.length);
    const checkedResp = respData.map((el) => ({
      id: el.id,
      checked: false,
    }));
    setCheckedState(checkedResp);

    const formatedResp = respData.map((res) => ({
      id: res.id,
      cells: getCells(res),
    }));

    setFormatedDataRows(formatedResp);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // TODO - fix eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeAllCheckbox = () => {
    setIsCheckAll((prevCheck) => !prevCheck);

    const updatedAllCheckedState = checkedState.map((item) => {
      return item.checked !== isCheckAll
        ? { id: item.id, checked: item.checked }
        : { id: item.id, checked: !item.checked };
    });
    setCheckedState(updatedAllCheckedState);
    setTotalChecked(!isCheckAll ? checkedState.length : 0);

    if (!isCheckAll) {
      setTempSelectedData(data);
    } else {
      setTempSelectedData([]);
    }
  };

  const handleOnChange = (position) => {
    setIsCheckAll(false);
    const updatedCheckedState = checkedState.map((item) =>
      item.id === position.id
        ? { id: item.id, checked: !item.checked }
        : { id: item.id, checked: item.checked }
    );
    setCheckedState(updatedCheckedState);

    let test;
    checkedState.forEach((item) => {
      if (item.id === position.id) {
        if (item.checked) {
          if (tempSelectedData.length === 1) {
            test = [];
          } else {
            test = tempSelectedData.filter((el) => el.id !== position.id);
          }
        } else {
          test = [
            ...tempSelectedData,
            data.filter((mach) => mach.id === position.id),
          ].flat();
        }
      }
    });
    setTempSelectedData(test);

    const totalPrice = updatedCheckedState.reduce((sum, currentState) => {
      if (currentState.checked === true) {
        return sum + 1;
      }
      return sum;
    }, 0);
    setTotalChecked(totalPrice);

    if (updatedCheckedState.every((elem) => elem.checked)) {
      setIsCheckAll(true);
    }
  };

  const handleAddMachine = () => {
    setOpen(true);
    setTempSelectedData(selectedData);
    setTotalChecked(selectedData.length);
    const selectedDataId = selectedData.map((mach) => mach.id);
    const resetCheckedState = checkedState.map((el) => {
      if (selectedDataId.includes(el.id)) {
        return { ...el, checked: true };
      }
      return { ...el, checked: false };
    });
    setCheckedState(resetCheckedState);
    setIsCheckAll(selectedData.length === checkedState.length);
  };

  const handleClose = () => {
    setTempSelectedData(selectedData);
    setTotalChecked(selectedData.length);
    const selectedDataId = selectedData.map((mach) => mach.id);
    const resetCheckedState = checkedState.map((el) => {
      if (selectedDataId.includes(el.id)) {
        return { ...el, checked: true };
      }
      return { ...el, checked: false };
    });
    setCheckedState(resetCheckedState);
    setIsCheckAll(selectedData.length === checkedState.length);
    setOpen(false);
  };

  const handleSavingSelection = () => {
    setSelectedData(tempSelectedData);
  };

  const renderListDescription = () => {
    return isLoading ? (
      <Typography>Loading...</Typography>
    ) : (
      <Typography className="machine-group-dialog__data-desc" variant="body2">
        {data.length > 0
          ? `${t`Vous n’avez pas encore de`} ${title} ${t`sélectionnées`}.`
          : `${t`Aucune ${title} disponible`}`}
      </Typography>
    );
  };

  return (
    <Box className="machine-group-dialog">
      {!open && (
        <>
          <Typography className="machine-group-dialog__title" variant="body1">
            {`${t`Listes des`} ${title} ${t`associés`}`}
          </Typography>

          {selectedData.length > 0 ? (
            <Paper>
              <TableWithPagination
                color="primary.main"
                headers={header}
                rows={selectedData}
                cells={(row) => generateCells(row, removeElement)}
                simpleTable
                alternateBg
                optionsValue={[3]}
              />
            </Paper>
          ) : (
            renderListDescription()
          )}

          {!disabled &&
            data.length > 0 &&
            selectedData.length < availabeMachines && (
              <Button
                className="machine-group-dialog__add-btn"
                startIcon={<AddCircleIcon />}
                onClick={handleAddMachine}
              >
                {`${t`Ajouter des`} ${title}`}
              </Button>
            )}
        </>
      )}

      {open && !isLoading && data.length > 0 && (
        <CustomDialog
          title={
            <Typography variant="h4">{`${t`Ajouter des`} ${title}`}</Typography>
          }
          open={open}
          onClose={handleClose}
          buttonText={t`Enregistrer`}
          onClick={handleSavingSelection}
        >
          <CardTableDetails
            maxHeight="500px"
            header={headers}
            rows={formatedDataRows}
            checkboxType
            checkboxState={{
              all: isCheckAll,
              setAll: changeAllCheckbox,
              checkedStates: checkedState,
              onCheckboxClick: handleOnChange,
            }}
            alternateBg
          />

          <Typography
            className="machine-group-dialog__selected-text"
            variant="body1"
          >
            {`${captializeTitle} ${t`sélectionnées`} : ${totalChecked}`}
          </Typography>
        </CustomDialog>
      )}
    </Box>
  );
};

export default MachineGroupDialog;
