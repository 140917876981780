import moment from 'moment-timezone';
import { t } from '@lingui/macro';

export const getFullYearMoment = (date) =>
  Number(moment(date).local().format('YYYY'));

export const getMonthMoment = (date) => Number(moment(date).format('MM')) - 1;

export const getDayMoment = (date) => Number(moment(date).format('DD'));

export const getDateMoment = (date) => moment(date);

export const getFormatedDateWithoutHours = (date) =>
  moment(date).format('DD/MM/YYYY');

export const getFormatedDateRequest = (date) =>
  moment(date).format('YYYY-MM-DD');

export const getFormatedDate = (date) =>
  moment(date).isValid() ? moment(date).local().format('DD/MM/YYYY') : '-';

export const getFormatedFullDate = (date) =>
  moment(date).isValid()
    ? moment(date).local().format('DD/MM/YYYY à HH:mm')
    : '-';

export const formatTime = {
  hour: 'HH:mm',
  day: 'DD MMM',
  month: 'MMM YY',
};

export const getFullDate = (date) =>
  `${moment(date).format('DD/MM/YYYY')} ${t`à`} ${moment(date).format(
    'HH:mm:ss'
  )}`;

export const getFormatedHours = (date) => moment(date).format('HH:mm:ss');

export const getPeriodFullDate = (date, date2) =>
  moment(date).isValid() && moment(date2).isValid()
    ? `${t`Du`} ${getFormatedFullDate(date)} ${t`au`} ${getFormatedFullDate(
        date2
      )}`
    : '-';

export const getPeriodDate = (date, date2) =>
  moment(date).isValid() && moment(date2).isValid()
    ? `${t`Du`} ${getFormatedDateWithoutHours(
        date
      )} ${t`au`} ${getFormatedDateWithoutHours(date2)}`
    : '-';

export const getUserTimeZone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getDateTimeWithUserTimeZone = (dateTime) =>
  moment(dateTime).tz(getUserTimeZone()).format('x');

export const getDateWithGMTInfo = (date) =>
  `${moment(date).format('DD-MM-YYYY HH:mm:ss ([GMT]Z)')}`;
