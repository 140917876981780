import { t, Trans } from '@lingui/macro';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Stack } from '@mui/material';
import { capitalize } from 'lodash';
import moment from 'moment';
import { Notification } from '../../../../api/notifications/Notification.interface';
import { StyledTableCell } from '../../../../components/molecules/TableWithPagination/UsedComponentsAndImports';
import { formatDuration } from '../../../../utils/date/calcul';
import { getFullDate } from '../../../../utils/date/format';
import { AlertModeValues, AlertTypeValues } from './NotificationsRow.constants';

import './NotificationsRow.scss';

interface NotificationsRowProps {
  notification: Notification;
  rowIdClicked: string;
}

const NotificationsRow = ({
  notification,
  rowIdClicked,
}: NotificationsRowProps) => {
  const {
    id,
    alertDate,
    alertMode,
    alertNotificationDetails,
    alertType,
    machineDescription,
  } = notification;

  const { jobSiteDescription } = alertNotificationDetails;

  return (
    <>
      {id === rowIdClicked ? (
        <StyledTableCell sx={{ cursor: 'pointer', width: '21.85vw' }}>
          <ExpandedDetails notification={notification} />
        </StyledTableCell>
      ) : (
        <StyledTableCell sx={{ cursor: 'pointer' }}>
          <Stack direction="column">
            <span>{machineDescription || '-'}</span>

            <DetailsText isExpanded={false} />
          </Stack>
        </StyledTableCell>
      )}

      <StyledTableCell sx={{ cursor: 'pointer' }}>
        {jobSiteDescription || '-'}
      </StyledTableCell>

      <StyledTableCell sx={{ cursor: 'pointer' }}>
        <Trans id={AlertTypeValues[alertType]} />
      </StyledTableCell>

      <StyledTableCell sx={{ cursor: 'pointer' }}>
        {getFullDate(moment(alertDate))}
      </StyledTableCell>

      <StyledTableCell sx={{ cursor: 'pointer' }}>
        <Trans id={AlertModeValues[alertMode]} />
      </StyledTableCell>
    </>
  );
};

const DetailsText = ({ isExpanded }: { isExpanded: boolean }) => {
  return (
    <span>
      {`${t`Details`} `}
      {isExpanded ? (
        <KeyboardArrowUpIcon sx={{ color: 'primary.main' }} />
      ) : (
        <KeyboardArrowDownIcon sx={{ color: 'primary.main' }} />
      )}
    </span>
  );
};

const ExpandedDetails = ({ notification }: { notification: Notification }) => {
  const { alertNotificationDetails, machineDescription, alertMode } =
    notification;

  const {
    dateDebut,
    dateFin,
    duration,
    jobSiteDescription,
    notificationType,
    occurrence,
  } = alertNotificationDetails;

  return (
    <Stack direction="column" className="expanded-details">
      <span>{machineDescription || '-'}</span>

      <DetailsText isExpanded />

      <span className="expanded-details__title">
        <b>
          {t`Notification reçue par ${AlertModeValues[
            alertMode
          ].toLowerCase()}`}
          :
        </b>
      </span>

      <span>
        <span className="bold-600">{t`Machine`}:</span>{' '}
        {machineDescription || '-'}
      </span>

      <span>
        <span className="bold-600">{t`Chantier`}:</span>{' '}
        {jobSiteDescription || '-'}
      </span>

      <span>
        <span className="bold-600">{t`Du`}:</span>{' '}
        {getFullDate(moment(dateDebut))}&nbsp;&nbsp;
        <span className="bold-600">{t`Au`}:</span>{' '}
        {getFullDate(moment(dateFin))}
      </span>

      <span>
        <span className="bold-600">{`${t`Durée`}: `}</span>
        {formatDuration(duration)}
      </span>

      <span>
        <span className="bold-600">{t`Type d'alerte`}:</span>{' '}
        {capitalize(notificationType) || '-'}
      </span>

      <span>
        <span className="bold-600">{t`Occurrence`}:</span> {occurrence}
      </span>
    </Stack>
  );
};

export default NotificationsRow;
