import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import Icon from '@mui/material/Icon';

import './ResultItem.scss';

const ResultItem = ({
  text,
  detailLink,
  handleLinkClick,
  eventValueId,
  eventKeyName,
}) => {
  return (
    <Stack className="result-item" direction="row">
      {detailLink && (
        <Link className="result-item__link" to={detailLink} disabled>
          <Icon
            sx={{ color: 'primary.main' }}
            onClick={() => handleLinkClick(eventKeyName, eventValueId)}
          >
            info
          </Icon>
        </Link>
      )}
      <Typography className="result-item__text">{text}</Typography>
    </Stack>
  );
};

ResultItem.propTypes = {
  text: PropTypes.string.isRequired,
  detailLink: PropTypes.string,
  handleLinkClick: PropTypes.func.isRequired,
  eventKeyName: PropTypes.string,
};

ResultItem.defaultProps = {
  detailLink: undefined,
  eventKeyName: null,
};

export default ResultItem;
