enum ContextLoadStatus {
  NOTLOADED,
  LOADING,
  LOADED,
  ERROR,
}

export default ContextLoadStatus;

export type ContextLoadUnsuccessfulStatus =
  | ContextLoadStatus.NOTLOADED
  | ContextLoadStatus.ERROR;

export const isAllContextLoaded = (loadStatus: ContextLoadStatus[]) => {
  const loadedLength = loadStatus.filter(
    (status) => status === ContextLoadStatus.LOADED
  ).length;

  return loadedLength === loadStatus.length;
};
