/* eslint-disable no-unused-vars */
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '0oa2lse12l7T2tW0z1d7';
const ISSUER = process.env.REACT_APP_ISSUER || 'https://loxam.oktapreview.com';
const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const BASENAME = process.env.PUBLIC_URL || '';
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;

const config = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
};

export default config;
