import { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import classNames from 'classnames';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { CarouselProps } from './Carousel.interface';

import './Carousel.scss';

const Carousel = ({
  children,
  labelBtnleft,
  labelBtnRight,
  observeValueChangeAndRefresh,
}: CarouselProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const onSelectCurrentSlide = (currentSlide: number) => {
    setCurrentSlide(currentSlide);
  };

  useEffect(() => {
    setCurrentSlide(0);
  }, [observeValueChangeAndRefresh]);

  const activeSlide: JSX.Element | undefined = children.find(
    (slide, index) => currentSlide === index
  );

  return (
    <Box className="carousel">
      <Box className="carousel__actions">
        <Button
          className={classNames({
            'carousel__actions-btn--hidden': currentSlide === 0,
          })}
          onClick={() => onSelectCurrentSlide(currentSlide - 1)}
        >
          <KeyboardArrowLeftIcon fontSize="large" />
          {labelBtnleft}
        </Button>
      </Box>

      <Box className="carousel__content">{activeSlide}</Box>

      <Box className="carousel__actions">
        <Button
          className={classNames({
            'carousel__actions-btn--hidden':
              currentSlide === children.length - 1 || children.length === 0,
          })}
          onClick={() => onSelectCurrentSlide(currentSlide + 1)}
        >
          {labelBtnRight}
          <KeyboardArrowRightIcon fontSize="large" />
        </Button>
      </Box>
    </Box>
  );
};

export default Carousel;
