import { Trans, t } from '@lingui/macro';
import { Divider, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { SelectOptions } from '../../../../app/components/molecules';
import { calendarTypeOptions } from '../../../../app/components/organisms/MuiCustomCalendar/utils';
import { getPeriodDate } from '../../../../app/utils/date/format';
import { useAppModeContext } from '../../../../app/utils/hooks';

import './SecurityHeader.scss';

interface SecurityHeaderProps {
  clientsOptions: any[];
  startDate?: Date;
  endDate?: Date;
  jobSiteName: string | null;
  handleClientChange: (customerNumber: number | null) => void;
  periodType: number;
  handlePeriodType: (index: number) => void;
}

const SecurityHeader = ({
  clientsOptions,
  startDate,
  endDate,
  jobSiteName,
  handleClientChange,
  periodType,
  handlePeriodType,
}: SecurityHeaderProps) => {
  const [selectedClientIndex, setSelectedClientIndex] = useState(0);

  const { isInternalAppMode } = useAppModeContext();

  useEffect(() => {
    const customerNumber = clientsOptions[selectedClientIndex]?.customerNumber;

    if (customerNumber !== undefined) {
      handleClientChange(customerNumber);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientIndex]);

  return (
    <Stack className="security-header" spacing={3}>
      <Stack className="security-header__title-stack" direction="row">
        <Typography sx={{ color: 'primary.main' }} variant="h3">
          <Trans>Sécurité</Trans>
        </Typography>

        <Stack direction="row" spacing={2}>
          {isInternalAppMode && clientsOptions.length > 1 && (
            <SelectOptions
              defaultIndex={0}
              updateParentIndex={setSelectedClientIndex}
              options={clientsOptions}
            />
          )}

          <SelectOptions
            defaultIndex={periodType}
            options={calendarTypeOptions(handlePeriodType)}
          />
        </Stack>
      </Stack>

      <Stack className="security-header__description" spacing={1}>
        <Typography variant="h3">
          {clientsOptions && clientsOptions[selectedClientIndex]?.label}
        </Typography>

        <Stack
          direction="row"
          spacing={1}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Stack direction="row" alignItems="baseline" spacing={1}>
            <Typography variant="inherit" fontSize={16} fontWeight={600}>
              {t`Dates de location`}:
            </Typography>
            {startDate && endDate && (
              <Typography>{getPeriodDate(startDate, endDate)}</Typography>
            )}
          </Stack>

          <Stack direction="row" alignItems="baseline" spacing={1}>
            <Typography variant="inherit" fontSize={16} fontWeight={600}>
              {t`Chantier`}:
            </Typography>
            <Typography>{jobSiteName}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SecurityHeader;
