import { t, Trans } from '@lingui/macro';
import { Grid, Stack, Typography } from '@mui/material';
import HorizontalChartBar from '../HorizontalChartBar/HorizontalChartBar';
import Legend from '../Legend/Legend';

import colors from '../../../../../theme/_colors.scss';
import './GlobalMachineUsage.scss';

interface GlobalMachineUsageInterface {
  durationUtilisationOffContract: number;
  durationUtilisationOnContract: number;
  locationDuration: number;
  locationDurationTillToday: number;
}

interface GlobalMachineUsageProps {
  contractDaysNumber: number;
  globalMachineUsage: GlobalMachineUsageInterface;
}

const GlobalMachineUsage = ({
  contractDaysNumber = 0,
  globalMachineUsage,
}: GlobalMachineUsageProps) => {
  return (
    <Stack className="global-machine-usage">
      <Typography variant="h4">
        <Trans>Utilisation globale de la machine</Trans>
      </Typography>
      <Typography>
        {`${t`Durée totale de location`}: ${contractDaysNumber} ${t`jours soit`} ${
          contractDaysNumber * 8
        }${t`h (journée de 8h, hors samedi, dimanche et jours fériés)`}`}
      </Typography>

      <Stack className="global-machine-usage__content">
        <HorizontalChartBar
          usageOutOfContract={globalMachineUsage.durationUtilisationOffContract}
          currentUsage={globalMachineUsage.durationUtilisationOnContract}
          estimatedUsage={globalMachineUsage.locationDurationTillToday}
          totalRent={globalMachineUsage.locationDuration}
        />
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Legend
              text="Utilisation estimée sur la période"
              color={colors.ateneoBlue}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Legend text="Utilisation actuelle" color={colors.blueJeans} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Legend text="Utilisation hors contrat" color={colors.jasper} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Legend
              text="Durée totale de location"
              color={colors.blueJeans}
              isStriped
            />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default GlobalMachineUsage;
