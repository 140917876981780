import { Fragment } from 'react';
import { t } from '@lingui/macro';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Machine } from '../../../../../api/machines/Machine.interface';
import { cellsWrap } from '../../../../../utils/table/table';
import MachineDetailsButton from './components/MachineDetailsButton';
import MachineImage from './components/MachineImage';
import MachineJobSite from './components/MachineJobSite';
import MachineLastSync from './components/MachineLastSync';
import MachineLocation from './components/MachineLocation';
import MachineName from './components/MachineName';
import MachinePurchaseOrder from './components/MachinePurchaseOrder';
import MachineStatus from './components/MachineStatus';
import MachineStatusLib from './components/MachineStatusLib';

import './MachineRow.scss';

const MachineRow = ({
  machine,
  handleHistoryPush,
  isMobile,
}: {
  machine: Machine;
  handleHistoryPush: () => void;
  isMobile: boolean;
}) => {
  const cells = isMobile
    ? [
        <Stack
          key="machine-row"
          className="machine-row-mobile"
          onClick={handleHistoryPush}
        >
          <Stack direction="row" spacing={2}>
            <MachineImage pim={machine.pim} />
            <MachineName machine={machine} />
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography fontWeight="500">{t`Location`}:</Typography>
            <MachineLocation machine={machine} />
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography fontWeight="500">{t`Etat`}:</Typography>
            <MachineStatusLib equipmentStatusLib={machine.equipmentStatusLib} />
            {machine.equipmentStatusLib === 'LOCATION' && (
              <MachineStatus machine={machine} />
            )}
          </Stack>

          <Box className="machine-row-mobile__last-sync">
            <MachineLastSync
              engineRunningDatetime={machine.engineRunningDatetime}
            />
          </Box>
        </Stack>,
      ]
    : [
        <MachineImage key="machine-image" pim={machine.pim} />,
        <MachineName key="machine-name" machine={machine} />,
        <MachineStatusLib
          key="machine-rentalman-status"
          equipmentStatusLib={machine.equipmentStatusLib}
        />,
        <Fragment key="machine-operating-status">
          <MachineStatus machine={machine} />
          <MachineLastSync
            engineRunningDatetime={machine.engineRunningDatetime}
          />
        </Fragment>,
        <MachineLocation key="machine-location" machine={machine} />,
        <MachinePurchaseOrder
          key="machine-purchase-order"
          purchaseOrderNumber={machine.purchaseOrderNumber}
        />,
        <MachineJobSite
          key="machine-job-site"
          jobSiteDesc={machine.jobSiteDesc}
        />,
        <MachineDetailsButton
          key="machine-details-button"
          handleHistoryPush={handleHistoryPush}
        />,
      ];

  return cellsWrap(cells);
};

export default MachineRow;
