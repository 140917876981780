import { Trans } from '@lingui/macro';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import {
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import './TextTable.scss';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.tableheader
      ? theme.palette.tableheader.bgcolor
      : '#2D2A2C',
    color: theme.palette.common.white,
    paddingTop: 10,
    paddingBottom: 10,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingTop: 12,
    paddingBottom: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.light
      ? theme.palette.light.bgcolor
      : '#F0EDEE',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TextTable = ({
  rows,
  headers,
  onItemEdit,
  onItemClick,
  onItemDelete,
  editItem,
  showItem,
  deleteItem,
  emptyText,
}) => {
  const addEditCol = editItem ? 1 : 0;
  const addShowCol = showItem ? 1 : 0;
  const addDeleteCol = deleteItem ? 1 : 0;
  const emptyCellColSpan =
    headers.length + addEditCol + addShowCol + addDeleteCol;
  return (
    <TableContainer className="text-table" component={Paper}>
      <Table className="text-table__table" aria-label="customized table">
        <TableHead>
          <TableRow>
            {headers.map((elt, index) => (
              <StyledTableCell key={`row-${index + 2}`}>{elt}</StyledTableCell>
            ))}
            {(showItem || editItem || deleteItem) && <StyledTableCell />}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows && rows.length > 0 ? (
            rows.map((row, index) => (
              <StyledTableRow key={`row-${index + 2}`}>
                {row.map((elt, index2) => (
                  <StyledTableCell key={`row-${index2 + 2}`}>
                    {elt}
                  </StyledTableCell>
                ))}
                {(showItem || editItem || deleteItem) && (
                  <TableCell className="text-table__cell">
                    <Stack direction="row" spacing={1}>
                      {showItem && (
                        <Button
                          className="text-table__show-details-btn text-table__pointer"
                          variant="body1Bold"
                          sx={{
                            color: 'primary.main',
                          }}
                          onClick={() => onItemClick(row)}
                        >
                          <Trans>voir details</Trans>
                        </Button>
                      )}
                      {editItem && (
                        <IconButton
                          className="text-table__pointer"
                          sx={{
                            color: 'primary.main',
                          }}
                          onClick={() => onItemEdit(row)}
                        >
                          <Edit />
                        </IconButton>
                      )}
                      {deleteItem && (
                        <IconButton
                          className="text-table__pointer"
                          sx={{
                            color: 'primary.main',
                          }}
                          onClick={() => onItemDelete(row)}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </Stack>
                  </TableCell>
                )}
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell
                className="text-table__empty-text"
                colSpan={emptyCellColSpan}
              >
                {emptyText}
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TextTable;

TextTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  onItemClick: PropTypes.func,
  showItem: PropTypes.bool,
  onItemEdit: PropTypes.func,
  editItem: PropTypes.bool,
  onItemDelete: PropTypes.func,
  deleteItem: PropTypes.bool,
  emptyText: PropTypes.string,
};

TextTable.defaultProps = {
  onItemEdit: undefined,
  onItemClick: undefined,
  onItemDelete: undefined,
  editItem: false,
  showItem: false,
  deleteItem: false,
  emptyText: null,
};
