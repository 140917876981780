import { Grid, Paper, Stack } from '@mui/material';
import PropTypes from 'prop-types';

import './EquipmentContentDetails.scss';

const EquipmentContentDetails = ({ details, content }) => {
  return (
    <Grid className="equipment-content-details" container spacing={2.5}>
      {/* <Grid item xs={12} md={3} lg={3}>
        {details?.image && (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <img
              className="equipment-content-details__image"
              src={details.image}
              alt="site"
            />
          </Stack>
        )}

        {details?.description?.map((item, idx) => (
          <Paper className="equipment-content-details__description" key={idx}>
            {item}
          </Paper>
        ))}
      </Grid> */}

      <Grid item xs={12} md={12} lg={12}>
        {content}
      </Grid>
    </Grid>
  );
};

export default EquipmentContentDetails;

EquipmentContentDetails.propTypes = {
  details: PropTypes.shape({
    image: PropTypes.string,
    description: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.object]).isRequired
    ),
  }).isRequired,
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
