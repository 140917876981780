import { t } from '@lingui/macro';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { IconButton, Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { Machine } from '../../../../../../api/machines/Machine.interface';

import './MachineLocation.scss';
import {
  getDayMoment,
  getFullYearMoment,
  getMonthMoment,
  getFormatedDate,
} from '../../../../../../utils/date/format';

const MachineLocation = ({ machine }: { machine: Machine }) => {
  const actualDate = new Date();
  const machineEstimatedEnd = new Date();

  machineEstimatedEnd.setFullYear(getFullYearMoment(machine.estimatedEnd));
  machineEstimatedEnd.setMonth(getMonthMoment(machine.estimatedEnd));
  machineEstimatedEnd.setDate(getDayMoment(machine.estimatedEnd));

  const machineEstimatedEndPast = actualDate > machineEstimatedEnd;

  return (
    <Stack className="machine-location">
      <Typography
        className="machine-location__location-text"
        color={machineEstimatedEndPast ? 'primary.main' : ''}
      >
        {machine.rentalStart && machine.estimatedEnd
          ? `${t`Du`} ${getFormatedDate(
              machine.rentalStart
            )} ${t`au`} ${getFormatedDate(machine.estimatedEnd)}`
          : '-'}
      </Typography>

      {machineEstimatedEndPast && (
        <Tooltip
          className="machine-location__tooltip"
          title={t`Date de retour dépassée`}
        >
          <IconButton>
            <ErrorIcon sx={{ color: 'primary.main' }} />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export default MachineLocation;
