import { useEffect } from 'react';
import { t } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import { useBreadcrumbsContext } from '../../utils/hooks';
import { TabButton } from '../../components/molecules';
import SettingsProfile from './components/SettingsProfile/SettingsProfile';
import SettingsUserPref from './SettingsUserPref/SettingsUserPref';
import SettingsMembers from './components/SettingsMembers/SettingsMembers';
import { UsersProvider } from '../../contexts/UsersContext';
import NotificationsTable from '../notifications/components/NotificationsTable/NotificationsTable';
import MachineGroup from '../MachineGroup/MachineGroup';

const Settings = () => {
  // eslint-disable-next-line no-unused-vars
  const [_, setBreadcrumbs] = useBreadcrumbsContext();

  useEffect(() => {
    setBreadcrumbs([
      {
        label: t`Tableau de bord`,
        to: '/',
      },
      {
        label: t`Paramètres`,
        to: '/settings',
      },
    ]);
    // TODO - fix eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UsersProvider>
      <Stack spacing={2}>
        <Typography variant="h1">{t`Paramètres`}</Typography>
        <TabButton
          tab={[
            {
              content: <SettingsProfile />,
              label: t`Comptes`,
            },
            {
              content: <SettingsMembers />,
              label: t`Membres`,
              disabled: true,
            },
            {
              content: <SettingsUserPref />,
              disabled: false, // undo
              label: t`Notifications`,
            },
            {
              content: <NotificationsTable />,
              disabled: false, // undo
              label: t`Tableau des notifications`,
            },
            {
              content: <MachineGroup />,
              label: t`Groupements de machines`,
              disabled: false,
            },
          ]}
        />
      </Stack>
    </UsersProvider>
  );
};

export default Settings;
