import sortAlphaNumeric from '../../../utils/sortAlphaNumeric';

export const onChangeSearchFilter = ({
  header,
  event,
  ref,
  permVisibleRows,
  setVisibleRows,
  columnsObject,
}) => {
  const data = permVisibleRows;

  const resp = data.filter((row) => {
    const key = columnsObject[header];
    const rowValue = key.search(row) || '';

    return rowValue.toLowerCase().includes(event.target.value.toLowerCase());
  });

  setVisibleRows(resp);

  // eslint-disable-next-line no-param-reassign
  ref.current.value = event.target.value;
};

export const onClickSortColumn = ({
  header,
  permVisibleRows,
  sorting,
  isSorted,
  setIsSorted,
  headersSorted,
  setHeadersSorted,
  setVisibleRows,
  columnsObject,
}) => {
  const sortedColumn = [...permVisibleRows].sort((a, b) => {
    const isSortingUp = sorting === 'up';
    const column = columnsObject[header];

    return sortAlphaNumeric(
      column.value(a),
      column.value(b),
      isSortingUp ? 'asc' : 'desc'
    );
  });

  const headersSortedState = isSorted;
  Object.keys(isSorted).map((el) => {
    headersSortedState[el].up = false;
    headersSortedState[el].down = false;
    return el;
  });

  const t = isSorted[header.toLowerCase()];
  if (sorting === 'up') {
    t.up = true;
    t.down = false;
  } else {
    t.up = false;
    t.down = true;
  }

  setIsSorted(() => {
    return {
      ...headersSortedState,
      [header.toLowerCase()]: t,
    };
  });

  setHeadersSorted((state) => {
    return {
      ...state,
      [header.toLowerCase()]: !headersSorted[header.toLowerCase()],
    };
  });

  setVisibleRows(sortedColumn);
};

export const addSearchFilterOnClick = ({
  ref,
  header,
  filters,
  setFilters,
  handleClose,
  setPermVisibleRows,
  visibleRows,
  setTempVisibleRows,
  parentFilters,
}) => {
  const obj = {
    [header]: {
      values: [ref.current.value],
      isCheckBox: filters[header]?.isCheckBox || false,
    },
  };

  const limit = 3;

  if (filters[header]) {
    if (
      filters[header].values.includes(ref.current.value) ||
      filters[header].values.length > limit ||
      !ref.current.value
    ) {
      return;
    }
    setFilters((state) => {
      return {
        ...state,
        [header]: {
          values: [...filters[header].values, ...obj[header].values].flat(),
          isCheckBox: filters[header].isCheckBox,
        },
      };
    });
    if (parentFilters) {
      parentFilters.set((state) => {
        return {
          ...state,
          [header]: {
            values: [...filters[header].values, ...obj[header].values].flat(),
            isCheckBox: filters[header].isCheckBox,
          },
        };
      });
    }
  } else {
    setFilters((state) => {
      return {
        ...state,
        ...obj,
      };
    });
    if (parentFilters) {
      parentFilters.set((state) => {
        return {
          ...state,
          ...obj,
        };
      });
    }
  }
  const testIfHeaderIsCheckBox = (boolValue) => {
    return Object.keys(filters)
      .map((el) => {
        const testIsCheckBox = boolValue
          ? filters[el].isCheckBox
          : !filters[el].isCheckBox;

        if (testIsCheckBox) {
          return el;
        }
        return null;
      })
      .filter((el) => el);
  };

  const tt = testIfHeaderIsCheckBox(true);

  let isCheckBoxChange = false;

  tt.forEach((el) => {
    const originValuesLength = 3;
    if (filters[el].values) {
      if (filters[el].values.length < originValuesLength) {
        isCheckBoxChange = true;
      }
    }
  });

  handleClose(true);

  if (isCheckBoxChange) {
    // if isCheckBoxChange apply a different treatment wip
    setPermVisibleRows(visibleRows);
    setTempVisibleRows(visibleRows);
  } else {
    setPermVisibleRows(visibleRows);
    setTempVisibleRows(visibleRows);
  }
};

export const removeFilterOnClick = ({
  header,
  value,
  filters,
  setFilters,
  rows,
  checkBoxesStates,
  setCheckBoxesStates,
  checkboxHeader,
  initCheckBoxState,
  columnsObject,
  checkboxesStatesRespD,
  parentFilters,
  setEquipmentState,
}) => {
  let newEquipmentState;
  // Array of all the filters for a header
  const filteredArray = filters[header].values;
  // New Array without the deleted value => value
  const columnFilters = filteredArray.filter((el) => el !== value);

  const updatedFilter = {
    ...filters,
    [header]: {
      values: columnFilters,
      isCheckBox: filters[header].isCheckBox,
    },
  };

  if (columnFilters.length === 0) {
    delete updatedFilter[header];
  }
  setFilters(updatedFilter);

  if (parentFilters) {
    if (
      parentFilters.get.Etat.values.length === 1 &&
      parentFilters.get.Etat.values.includes(value)
    ) {
      const filtersInit = {};
      checkboxesStatesRespD.forEach((el) => {
        filtersInit[el.header] = {
          values: el.values.map((v) => v.name).sort(),
          isCheckBox: true,
        };
      });

      parentFilters.set((p) => {
        return {
          ...p,
          ...filtersInit,
        };
      });
    } else {
      parentFilters.set(() => {
        return {
          ...updatedFilter,
        };
      });
    }
  }

  // Map on all the header with filters that are or were applied and return checkBox Header or nonCheckBox Header
  const testIfHeaderIsCheckBox = (boolValue) => {
    return Object.keys(filters)
      .map((el) => {
        const testIsCheckBox = boolValue
          ? filters[el].isCheckBox
          : !filters[el].isCheckBox;

        if (testIsCheckBox) {
          return el;
        }
        return null;
      })
      .filter((el) => el);
  };

  if (updatedFilter[header]) {
    const filtersKeys = testIfHeaderIsCheckBox(true);

    // variable to track if there is 0 filters
    let filtersLength = 0;

    filtersKeys.forEach((f) => {
      filtersLength += updatedFilter[f].values.length;
    });

    // Creation of the new filtered array base on the origin data
    const newFilteredArray = rows.filter((row) => {
      let respValue = false;

      const filtersCheckBoxElementKeys = testIfHeaderIsCheckBox(false);

      filtersCheckBoxElementKeys.forEach((el) => {
        // get the element value base on the column search function
        const rowVal = columnsObject[el].search(row) || '';
        let isEqualToAllColumns = 0;

        updatedFilter[el].values.forEach((vv) => {
          if (
            rowVal.toLowerCase().includes(vv.toLowerCase()) ||
            updatedFilter[el].values.length < 1
          ) {
            isEqualToAllColumns += 1;
          }
        });

        if (isEqualToAllColumns === updatedFilter[el].values.length) {
          respValue = true;
        }
      });

      if (filtersCheckBoxElementKeys.length === 0) {
        respValue = true;
      }

      return respValue;
    });

    if (filtersLength === 0) {
      setEquipmentState(newFilteredArray);
      const checkboxesStatesResp = [];

      checkboxHeader
        .filter((f) => f.checkboxes)
        .forEach((c) => {
          const keysValues = c.keys.map((k) => {
            return {
              name: k,
              value: true,
            };
          });

          checkboxesStatesResp.push({
            header: c.name,
            values: keysValues,
          });
        });
      setCheckBoxesStates(checkboxesStatesResp);
      if (checkBoxesStates && checkboxesStatesRespD) {
        initCheckBoxState();
      }
      return;
    }

    const state = checkBoxesStates.find((chck) => chck.header === header);

    if (state) {
      const z = state.values.map((v) => {
        return {
          ...v,
          value: v.name === value ? !v.value : v.value,
        };
      });

      const rr = checkBoxesStates.map((el) => {
        if (el.header === header) {
          return {
            ...el,
            values: z,
          };
        }
        return el;
      });

      setCheckBoxesStates(rr);
    }

    newEquipmentState = newFilteredArray;
  } else {
    newEquipmentState = updatedFilter;
  }

  if (
    setEquipmentState &&
    typeof setEquipmentState === 'function' &&
    newEquipmentState
  ) {
    setEquipmentState(newEquipmentState);
  }
};
