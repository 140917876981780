import { Trans, t } from '@lingui/macro';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { getMachinesDynamicFilter } from '../../../../../../../api/machines/Machines';
import SimpleButton from '../../../../../../../components/atoms/SimpleButton/SimpleButton';
import SwitchButton from '../../../../../../../components/atoms/SwitchButton/SwitchButton';
import FilterAutoComplete from '../../../../../../../components/molecules/FilterAutoComplete/FilterAutoComplete';
import './MachineFilterDialog.scss';
import {
  OPTIONS_KEYS,
  formatOptionObject,
  getOtherOptions,
  machineStatesInit,
  optionFilterInit,
} from './MachineFilterDialog.utils';

// The Autocomplete options that will be iterated below
const OPTIONS = [
  { value: 'jobSitesMach', title: t`Chantiers` },
  { value: 'contractsMach', title: t`Contrats` },
  { value: 'agenciesMach', title: t`Agences` },
];

const MachineRunningStateButtons = ({ states, onClick }) => {
  return (
    <Stack className="machine-filter-dialog__machines-stack">
      <Typography className="machine-filter-dialog__text" variant="h3">
        <Trans>Etat de fonctionnement</Trans>:
      </Typography>
      <Stack className="machine-filter-dialog__machines-states">
        {states &&
          Object.keys(states).map((el) => {
            const { value, text } = states[el];
            return (
              <SwitchButton
                key={`btn-${value}-${text}`}
                value={value}
                text={text}
                onClick={() => onClick(el)}
              />
            );
          })}
      </Stack>
    </Stack>
  );
};

const MachineFilterDialog = ({
  isFiltersClicked,
  handleClose,
  filters,
  state,
}) => {
  const initOptions = useCallback(() => {
    return {
      jobSitesMach: optionFilterInit(filters, state, OPTIONS_KEYS.typeJobSite),
      contractsMach: optionFilterInit(
        filters,
        state,
        OPTIONS_KEYS.typeContract
      ),
      agenciesMach: optionFilterInit(filters, state, OPTIONS_KEYS.typeAgency),
    };
  }, [filters, state]);
  const [data, setData] = useState(initOptions());
  const [machRunningStates, setMachRunningStates] = useState({});

  const callMachineStateInit = useCallback(() => {
    machineStatesInit(
      state.headersCheckbox,
      data,
      filters,
      setMachRunningStates
    );
  }, [state, data, filters]);

  useEffect(() => {
    callMachineStateInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.get, state.headersCheckbox, state.value]);

  const handleClickOnRunningStatus = useCallback(
    (runningStatus) => {
      const activeRunningStatus = Object.keys(machRunningStates)
        .map((status) =>
          status === runningStatus
            ? !machRunningStates[status].value
            : machRunningStates[status].value
        )
        .filter((el) => el);

      if (activeRunningStatus.length < 1) {
        callMachineStateInit();
      } else {
        setMachRunningStates((states) => {
          const { text, value, isRunning } = machRunningStates[runningStatus];
          return {
            ...states,
            [runningStatus]: {
              text,
              value: !value,
              isRunning,
            },
          };
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [machRunningStates]
  );

  const resetAllSelection = useCallback(() => {
    const runningStatusSetToSelected = { ...machRunningStates };
    Object.keys(runningStatusSetToSelected).forEach((s) => {
      runningStatusSetToSelected[s].value = true;
    });
    callMachineStateInit();
    setData(initOptions());
    setMachRunningStates(runningStatusSetToSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machRunningStates]);

  const updateOtherOptions = useCallback(
    (otherFilters, filters, newFiltersOptions) => {
      return otherFilters.map((el) => {
        const formatedOptionsForFilter = formatOptionObject(
          newFiltersOptions[el.value]
        );

        const newData = el.filterFunc(
          filters,
          formatedOptionsForFilter,
          el.type
        ).data;

        const newIndex = newData.findIndex(
          (f) => f.id === data[el.name].data[data[el.name].index].id
        );

        const filteredOptions = {
          ...data[el.name],
          data: newData,
          index: newIndex,
        };

        const newOptions =
          filteredOptions.index > 0
            ? filteredOptions
            : el.filterFunc(filters, formatedOptionsForFilter, el.type);

        return newOptions;
      });
    },
    [data]
  );

  const selectFilter = useCallback(
    async (selectedIndex, type) => {
      const optionsSelectedIndexes = Object.keys(data).map(
        (e) => data[e].index
      );
      const otherFilters = getOtherOptions(type);
      const optionId = data[type].data[selectedIndex].id;

      const isSelectedIndexNotDefault =
        selectedIndex !== 'All' &&
        optionId &&
        !optionsSelectedIndexes.includes(1);

      let updatedData = {};
      if (isSelectedIndexNotDefault) {
        const newOptionsForOtherFilters = await getMachinesDynamicFilter({
          status: false,
          jobsiteId: type === OPTIONS_KEYS.jobSites ? optionId : null,
          contractId: type === OPTIONS_KEYS.contracts ? optionId : null,
          agencyId: type === OPTIONS_KEYS.agencies ? optionId : null,
        });

        const [firstOption, secondOption] = updateOtherOptions(
          otherFilters,
          filters,
          newOptionsForOtherFilters
        );

        updatedData = {
          ...data,
          [otherFilters[0].name]: { ...firstOption },
          [otherFilters[1].name]: { ...secondOption },
          [type]: { ...data[type], index: selectedIndex },
        };
      } else if (
        ['All', 0].includes(data[otherFilters[0].name].index) &&
        ['All', 0].includes(data[otherFilters[1].name].index)
      ) {
        setData(initOptions());
        updatedData = initOptions();
      } else {
        updatedData = {
          ...data,
          [type]: {
            ...data[type],
            index: selectedIndex,
          },
        };
      }

      setData(updatedData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, filters]
  );

  const saveData = useCallback(() => {
    const machineSortedRunningStatus = filters.get.Etat.values.sort();
    const selectedRunningStatus = Object.keys(machRunningStates)
      .filter((f) => machRunningStates[f].value)
      .sort();

    const runningStatusRemoved = machineSortedRunningStatus.filter(
      (x) => !selectedRunningStatus.includes(x)
    );
    const runningStatusAdded = selectedRunningStatus.filter(
      (x) => !machineSortedRunningStatus.includes(x)
    );

    const prevRunningStatus = {
      ...filters.get,
    };

    const optionFiltersKeys = Object.keys(data).filter(
      (f) => !data[f].hide && data[f].isSelector
    );
    optionFiltersKeys.forEach((el) => {
      const dataIndex = data[el].index < 0 ? 0 : data[el].index;
      const currentOption =
        data[el].data[typeof dataIndex === 'string' ? 0 : dataIndex];

      if (currentOption) {
        if (currentOption?.id === 'All') {
          delete prevRunningStatus[data[el].value];
        } else {
          prevRunningStatus[data[el].value] = {
            values: [currentOption.label],
          };
        }
      }
    });

    if (runningStatusRemoved.length === 0 && runningStatusAdded.length === 0) {
      filters.set(() => {
        return {
          ...prevRunningStatus,
        };
      });
    } else {
      filters.set(() => {
        return {
          ...prevRunningStatus,
          Etat: {
            ...filters.get.Etat,
            values: selectedRunningStatus,
          },
        };
      });
    }

    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, data, machRunningStates]);

  return (
    <Dialog
      className="machine-filter-dialog"
      open={isFiltersClicked}
      maxWidth="md"
      autoFocus={false}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: '95vh',
        },
      }}
    >
      <DialogTitle className="machine-filter-dialog__title">
        <Typography fontWeight="bold" fontSize="32px">
          <Trans>Filtres</Trans>
        </Typography>
        <CloseIcon
          className="machine-filter-dialog__title-icon"
          onClick={handleClose}
        />
      </DialogTitle>

      <DialogContent className="machine-filter-dialog__content">
        <Stack direction="row">
          <SimpleButton
            onClick={resetAllSelection}
            className="machine-filter-dialog__reset-button"
            type="text"
            text={
              <Typography
                className="machine-filter-dialog__reset-text"
                sx={{
                  color: 'primary.main',
                }}
              >
                <Trans>Réinitialiser</Trans>
              </Typography>
            }
          />
        </Stack>

        {/* <MachineRunningStateButtons
          states={machRunningStates}
          onClick={handleClickOnRunningStatus}
        /> */}

        <Stack className="machine-filter-dialog__filters" direction="column">
          {OPTIONS.map(({ value, title }) => {
            // Iterate available options and populate with available data
            if (data[value]) {
              return (
                <FilterAutoComplete
                  key={value}
                  // result={data[value].data.length}
                  options={data[value].data}
                  index={data[value].index}
                  title={title}
                  filterKey={value}
                  onChange={selectFilter}
                />
              );
            }
            return null;
          }).filter((x) => x)}
        </Stack>
      </DialogContent>

      <DialogActions className="machine-filter-dialog__actions">
        <SimpleButton
          text={t`Enregistrer`}
          onClick={saveData}
          className="machine-filter-dialog__save-button"
        />
      </DialogActions>
    </Dialog>
  );
};

export default MachineFilterDialog;
