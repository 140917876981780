import { useCallback, useEffect, useState } from 'react';
import {
  Paper,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Alert,
  Autocomplete,
  TextField,
  IconButton,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { t, Trans } from '@lingui/macro';
import {
  getAgenciesByRegionList,
  getAgencyTracker,
  getRegionsList,
  getTrackerByRegion,
  getTrackersByAgency,
} from '../../app/api/agencies/Agencies';
import {
  StyledTableCell,
  StyledTableRow,
} from '../../app/components/molecules/TableWithPagination/UsedComponentsAndImports';
import IsMobile from '../../app/utils/IsMobile';
import ScanEquipmenRow from './ScanEquipmentRow';
import { postUnpairTracker } from '../../app/api/machines/Machines';
import { ReturnButton } from '../../app/components/molecules';
import { useUserContext } from '../../app/contexts/UserContext/UserContext';

const tableHeaders = [
  t`Numéro d'équipement`,
  t`Agence`,
  t`IMEI`,
  t`Mode`,
  t`Mode demandé`,
  t`Dernier temps de synchronisation Goobie`,
  t`Prochaine synchronisation`,
  '',
];

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = useCallback(() => {
    onSearch(searchTerm);
  }, [onSearch, searchTerm]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  useEffect(() => {
    if (searchTerm === '') {
      onSearch('');
    }
  }, [searchTerm, onSearch]);

  return (
    <div>
      <TextField
        label={t`Rechercher`}
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyPress={handleKeyPress}
        size="small"
        style={{ width: '85%' }}
      />
      <IconButton onClick={handleSearch}>
        <Search />
      </IconButton>
    </div>
  );
};

const ScanEquipmentList = () => {
  const isMobile = IsMobile();
  const [machines, setMachines] = useState([]);
  const [filteredMachines, setFilteredMachines] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [regions, setRegions] = useState([]);
  const [status, setStatus] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedAgence, setSelectedAgence] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [displayAgence, setDisplayAgence] = useState(false);
  const user = useUserContext();
  const isDR = user.mergedProfil === 'DR';
  const isRegion01 = user.codeRegion === '01';
  const voirTout = 'Voir tout';
  const dialogMessage = `${t`Vous voulez vraiment déconnecter le Goobie de la machine `}
  ${selectedMachine?.equipmentNumber} ?`;

  const handleUnpairTracker = () => {
    const data = {
      comment: '',
      machine: selectedMachine.equipmentNumber,
      mode: 0,
      imei: selectedMachine.imei,
    };

    try {
      postUnpairTracker(data);
      refreshData();
      setOpenDialog(false);
      setTimeout(() => {
        refreshData();
      }, 5000);
      setStatus(
        t`${`Le tracker a bien été désassocié de la machine ${selectedMachine.equipmentNumber}`}`
      );
      setTimeout(() => {
        setStatus('');
      }, 5000);
    } catch (e) {
      setOpenDialog(false);
      setStatus(
        t`${`Erreur lors de la dissociation de la machine ${selectedMachine.equipmentNumber}`}`
      );
    }
  };

  const fetchData = async () => {
    if (isRegion01) {
      setDisplayAgence(false);
      const regions = await getRegionsList();
      setRegions(regions);
    } else if (isDR) {
      const agencies = await getAgenciesByRegionList(user.codeRegion);
      agencies.push(voirTout);
      setAgencies(agencies);
    } else {
      setDisplayAgence(false);
      const result = await getAgencyTracker();
      setMachines(result);
      setFilteredMachines(result);
    }
    if (!isDR) {
      tableHeaders.splice(1, 1);
    }
  };

  const refreshData = async () => {
    const newMachines = [];
    machines.forEach((machine, key, map) => {
      if (!machine.equipmentNumber === selectedMachine.equipmentNumber) {
        newMachines.push(machine);
      }
    });
    setMachines(newMachines);
    setFilteredMachines(newMachines);
  };
  const onChangeRegion = async (event) => {
    setDisplayAgence(false);
    setAgencies([]);
    setMachines([]);
    setFilteredMachines([]);
    setSelectedAgence('');
    const region = event.target.outerText;
    setSelectedRegion(region);
    const agencies = await getAgenciesByRegionList(region);
    agencies.push(voirTout);
    setAgencies(agencies);
    setDisplayAgence(true);
  };
  const onChangeAgence = async (event) => {
    if (event.target.outerText === '') {
      return;
    }
    setSelectedAgence(event.target.outerText);
    let result;
    if (event.target.outerText === voirTout) {
      if (isRegion01) {
        result = await getTrackerByRegion(selectedRegion);
      } else {
        result = await getTrackerByRegion(user.codeRegion);
      }
    } else {
      result = await getTrackersByAgency(event.target.outerText);
    }
    setMachines(result);
    setFilteredMachines(result);
  };

  const onSearch = useCallback(
    (term) => {
      if (!term) {
        setFilteredMachines(machines);
        return;
      }

      const lowerCaseTerm = term.toLowerCase();
      const filtered = machines.filter((row) => {
        return Object.values(row).some((value) => {
          return (
            value && value.toString().toLowerCase().includes(lowerCaseTerm)
          );
        });
      });
      setFilteredMachines(filtered);
    },
    [machines]
  );

  const onHandleFilterChange = (arrayData, mode) => {
    if (mode === 'ALL') {
      setFilteredMachines(machines);
      return;
    }

    const result = arrayData.filter((item) => item.mode === mode);
    setFilteredMachines(result);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ReturnButton label={t`Retour`} />
      {status && status !== '' && (
        <Alert severity="info">
          <Typography variant="body1">{status}</Typography>
        </Alert>
      )}
      {isRegion01 && (
        <>
          <Typography>
            <b className="cockpit-goobie__list-title">{t`Region`}</b>
            <Autocomplete
              options={regions}
              disableClearable
              blurOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  size="small"
                  hiddenLabel
                />
              )}
              style={{ display: 'inline-block', width: '150px' }}
              onChange={(event) => onChangeRegion(event)}
            />
          </Typography>
        </>
      )}
      {(displayAgence || isDR) && (
        <>
          <Typography>
            <b className="cockpit-goobie__list-title">{t`Agence`}</b>
            <Autocomplete
              value={selectedAgence}
              options={agencies}
              disableClearable
              blurOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  size="small"
                  hiddenLabel
                />
              )}
              style={{ display: 'inline-block', width: '150px' }}
              onChange={(event) => onChangeAgence(event)}
            />
          </Typography>
        </>
      )}
      <Stack spacing={2}>
        <SearchBar onSearch={onSearch} />
        {isDR && (
          <FormControl size="small" style={{ marginRight: '5px' }}>
            <InputLabel shrink>Mode</InputLabel>
            <Select
              label="Mode"
              displayEmpty
              defaultValue="ALL"
              onChange={(e) => {
                onHandleFilterChange(machines, e.target.value);
              }}
            >
              <MenuItem value="ALL">
                <Trans>Show All</Trans>
              </MenuItem>
              <MenuItem value={null}>
                <Trans>None</Trans>
              </MenuItem>
              <MenuItem value="SERVICE">
                <Trans>Service</Trans>
              </MenuItem>
              <MenuItem value="STORAGE">
                <Trans>Storage</Trans>
              </MenuItem>
              <MenuItem value="TRACKING">
                <Trans>Tracking</Trans>
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </Stack>

      {filteredMachines.length > 0 ? (
        <>
          <Box className="table-with-pagination">
            <TableContainer className="table-with-pagination__table">
              <Table
                sx={{
                  borderSpacing: '0 8px !important',
                  borderCollapse: 'separate !important',
                }}
                aria-label="simple table"
                stickyHeader
              >
                {!isMobile && (
                  <TableHead className="table-header">
                    <TableRow>
                      {tableHeaders.map((th, i) => (
                        <StyledTableCell key={i}>
                          <Box className="table-header__cell">{th}</Box>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                )}

                <TableBody className="table-with-pagination__table-body">
                  {filteredMachines.map((machine, i) => (
                    <StyledTableRow component={Paper} key={i}>
                      <ScanEquipmenRow
                        machine={machine}
                        key={machine.equipmentNumber}
                        openDialogFunction={setOpenDialog}
                        selectMachine={setSelectedMachine}
                      />
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Dialog open={openDialog}>
            <DialogContent>
              <Typography>{dialogMessage}</Typography>
              <DialogActions>
                <Button onClick={() => handleUnpairTracker()}>
                  <Trans>Oui</Trans>
                </Button>
                <Button onClick={() => setOpenDialog(false)}>
                  <Trans>Non</Trans>
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <Box>
          <Typography>
            <Trans>Aucune donnée disponible</Trans>
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ScanEquipmentList;
