import { t } from '@lingui/macro';
import { Box, Stack, Typography } from '@mui/material';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import colors from '../../../../app/theme/_colors.scss';
import CustomizedSwitches from '../../../CustomizedSwitches/CustomizedSwitches';
import './ChartBarAndAreaGraph.scss';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Filler,
  Tooltip
);

const ChartBarAndAreaGraph = ({
  labels,
  maxYValue,
  numbersOfAlerts,
  alertsDuration,
  usageOnContract,
  usageOutOfContract,
  showUtilisationSwitches,
}: any) => {
  const [displayAlertsNumber, setHideAlertsNumber] = useState(true);
  const [displayUsageOnContract, setHideUsageOnContract] = useState(false);
  const [displayUsageOutOfContract, setHideUsageOutOfContract] =
    useState(false);

  const [data, setData] = useState();

  useEffect(() => {
    const datasets = [
      {
        // eslint-disable-next-line prettier/prettier
        type: 'line' as const,
        label: "Nombre d'alertes",
        borderColor: colors.americanOrange,
        backgroundColor: 'rgba(255, 138, 0, 0.2)',
        borderWidth: 2,
        fill: true,
        data: numbersOfAlerts,
      },
      {
        type: 'bar' as const,
        label: 'Alerte',
        backgroundColor: colors.americanOrange,
        data: alertsDuration,
        stack: 'Stack 0',
      },
    ];

    // eslint-disable-next-line no-unused-expressions
    displayUsageOnContract &&
      datasets.push({
        type: 'bar' as const,
        label: 'Utilisation horaires contrats',
        backgroundColor: colors.blueJeans,
        data: usageOnContract,
        stack: 'Stack 1',
      });

    // eslint-disable-next-line no-unused-expressions
    displayUsageOutOfContract &&
      datasets.push({
        type: 'bar' as const,
        label: 'Utilisation hors contrat',
        backgroundColor: colors.jasper,
        data: usageOutOfContract,
        stack: 'Stack 2',
      });

    setData({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      labels,
      datasets,
    });
  }, [
    numbersOfAlerts,
    alertsDuration,
    usageOnContract,
    usageOutOfContract,
    labels,
    displayUsageOnContract,
    displayUsageOutOfContract,
  ]);

  useEffect(() => {
    setData((d: any) => {
      return {
        ...d,
        datasets: d?.datasets.map((d: any) => {
          if (d.label === "Nombre d'alertes") {
            // eslint-disable-next-line no-param-reassign
            d.data = displayAlertsNumber ? numbersOfAlerts : [];
          }
          if (d.label === 'Utilisation horaires contrats') {
            // eslint-disable-next-line no-param-reassign
            d.data = displayUsageOnContract ? usageOnContract : [];
          }
          if (d.label === 'Utilisation hors contrat') {
            // eslint-disable-next-line no-param-reassign
            d.data = displayUsageOutOfContract ? usageOutOfContract : [];
          }
          return d;
        }),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayAlertsNumber, usageOnContract, displayUsageOutOfContract]);

  const options = {
    scales: {
      yAxis: maxYValue
        ? {
            min: 0,
            max: maxYValue,
            ticks: {
              stepSize: 5,
            },
          }
        : {
            min: 0,
            ticks: {
              stepSize: 5,
            },
          },
    },
    plugins: {
      legend: {
        position: 'top' as const,
        display: false,
      },
    },
    options: {
      plugins: {
        title: {
          display: false,
        },
      },
      responsive: true,
      interaction: {
        mode: 'index' as const,
        intersect: false,
      },
      scales: {
        x: {
          stacked: false,
        },
        y: {
          stacked: false,
        },
      },
    },
  };

  return (
    <Stack className="chart-bar-area--machine-usage">
      <Stack
        className="chart-bar-area--machine-usage__switch"
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        {showUtilisationSwitches && (
          <>
            <CustomizedSwitches
              text={
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <Typography variant="body2Bold">{t`Afficher l'utilisation horaires contrats`}</Typography>
              }
              onToggle={(value: boolean) => setHideUsageOnContract(value)}
              value={displayUsageOnContract}
              disabled={!usageOnContract || usageOnContract?.length < 1}
            />
            <CustomizedSwitches
              text={
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <Typography variant="body2Bold">{t`Afficher l'utilisation hors contrat`}</Typography>
              }
              onToggle={(value: boolean) => setHideUsageOutOfContract(value)}
              value={displayUsageOutOfContract}
              disabled={!usageOutOfContract || usageOutOfContract?.length < 1}
            />
          </>
        )}
        <CustomizedSwitches
          text={
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Typography variant="body2Bold">{t`Afficher le nombre d’alertes`}</Typography>
          }
          onToggle={(value: boolean) => setHideAlertsNumber(value)}
          value={displayAlertsNumber}
          disabled={numbersOfAlerts?.length < 1}
        />
      </Stack>
      <Box className="chart-bar-area-machine-usage__bar">
        {data && <Chart type="bar" data={data} options={options} />}
      </Box>
    </Stack>
  );
};

export default ChartBarAndAreaGraph;
