import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { getMachineStatus } from '../../../../utils/utils';

const TelematicMachineStatus = ({
  isEngineRunningDatetimeBellow24h,
  engineRunning,
}) => {
  const machineStatus = getMachineStatus(
    engineRunning,
    isEngineRunningDatetimeBellow24h
  );
  const machineStatusColor =
    engineRunning && isEngineRunningDatetimeBellow24h ? 'success' : 'secondary';

  return (
    <Typography
      className="specification-list__value"
      variant="body1Bold"
      sx={{
        color: `${machineStatusColor}.main`,
        paddingRight: '10px',
      }}
    >
      {machineStatus} :
    </Typography>
  );
};

export default TelematicMachineStatus;

TelematicMachineStatus.propTypes = {
  isEngineRunningDatetimeBellow24h: PropTypes.bool,
  engineRunning: PropTypes.bool,
};
