import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Paper, Icon, Typography, Box, Badge } from '@mui/material';
import { Link } from 'react-router-dom';
import IsMobile from '../../../utils/IsMobile';

import './CardLink.scss';

const CardLink = ({
  icon,
  iconColor,
  title,
  description,
  notifications,
  alerts,
  showNotificationsPanel,
  disabled,
  href,
  onClick,
  width,
}) => {
  const isMobile = IsMobile();

  const content = (
    <Paper
      className="card-link__paper"
      sx={{
        bgcolor: disabled && 'cardLink.bgcolor',
        width,
      }}
    >
      {icon && (
        <Icon
          className="card-link__icon"
          sx={{
            color: iconColor ?? (disabled ? 'cardLink.color' : 'primary.main'),
          }}
        >
          {icon}
        </Icon>
      )}

      <Typography
        className="card-link__title"
        variant="h4"
        align="center"
        sx={{
          color: disabled && 'cardLink.color',
        }}
      >
        <Trans id={title} />
      </Typography>

      {!isMobile && (
        <Typography
          className="card-link__description"
          variant="body1"
          align="center"
          sx={{ color: disabled && 'cardLink.color' }}
        >
          <Trans id={description} />
        </Typography>
      )}

      {showNotificationsPanel && (
        <Box className="card-link__notifications-panel">
          <Box className="card-link__notification-box">
            <Badge
              badgeContent={
                Number.isInteger(notifications) && notifications > 0
                  ? notifications
                  : '0'
              }
              color="secondary"
              max={9999}
            >
              <Icon
                className="card-link__notification-icon"
                sx={{
                  color: disabled ? 'cardLink.color' : alerts < 1 && '#DADADA',
                }}
              >
                local_shipping
              </Icon>
            </Badge>
          </Box>

          <Box className="card-link__notification-box">
            <Badge
              badgeContent={
                Number.isInteger(alerts) && alerts > 0 ? alerts : '0'
              }
              color="secondary"
              max={9999}
            >
              <Icon
                className="card-link__notification-icon"
                sx={{
                  color:
                    alerts && alerts > 0
                      ? 'warning.main'
                      : disabled
                      ? 'cardLink.color'
                      : '#DADADA',
                }}
              >
                notifications
              </Icon>
            </Badge>
          </Box>
        </Box>
      )}
    </Paper>
  );

  return href ? (
    <Link className="card-link__link" to={href} onClick={onClick}>
      {content}
    </Link>
  ) : (
    <Box className="card-link__box" onClick={onClick}>
      {content}
    </Box>
  );
};

export default CardLink;

CardLink.propTypes = {
  /**
   * Main Icon
   */
  icon: PropTypes.string,
  /**
   * Title
   */
  title: PropTypes.string.isRequired,
  /**
   * Description
   */
  description: PropTypes.string.isRequired,
  /**
   * Number of notifications
   */
  notifications: PropTypes.number,
  /**
   * Number of alerts
   *
   */
  alerts: PropTypes.number,
  /**
   * Show or not the notification panel
   */
  showNotificationsPanel: PropTypes.bool,
  /**
   * Disable the card link
   */
  disabled: PropTypes.bool,
  /**
   * URL
   */
  href: PropTypes.string,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

CardLink.defaultProps = {
  icon: null,
  notifications: 0,
  alerts: 0,
  showNotificationsPanel: false,
  disabled: false,
  href: null,
  onClick: null,
};
