import { Stack, Typography } from '@mui/material';
import { SelectOptions } from '../../../components/molecules';

import './DialogContent.scss';

const DialogContent = ({ state }) => {
  const grpmntMach = state.machinesGroupData.map((e) => e.name);
  return (
    <Stack className="dialog-content dialog-content__stack" direction="row">
      <Stack className="dialog-content__stack">
        <Typography variant="h3">Groupes de machines:</Typography>
        <Stack className="dialog-content__select-stack" direction="row">
          <SelectOptions
            className="dialog-content__select"
            options={grpmntMach.map((option) => {
              return {
                label: option,
                onClick: () => console.log('clicked'),
              };
            })}
            defaultIndex={0}
          />
        </Stack>
      </Stack>
      <Stack className="dialog-content__stack">
        <Typography variant="h3">Chantiers:</Typography>
        <Stack className="dialog-content__select-stack" direction="row">
          <SelectOptions
            className="dialog-content__select"
            options={['Tous les chantiers', 'test2'].map((option) => {
              return {
                label: option,
                onClick: () => console.log('clicked'),
              };
            })}
            defaultIndex={0}
          />
        </Stack>
      </Stack>
      <Stack className="dialog-content__stack">
        <Typography variant="h3">Contrats:</Typography>
        <Stack className="dialog-content__select-stack" direction="row">
          <SelectOptions
            className="dialog-content__select"
            options={['Tous les contrats', 'test2'].map((option) => {
              return {
                label: option,
                onClick: () => console.log('clicked'),
              };
            })}
            defaultIndex={0}
          />
        </Stack>
      </Stack>
      <Stack className="dialog-content__stack">
        <Typography variant="h3">Agences:</Typography>
        <Stack className="dialog-content__select-stack" direction="row">
          <SelectOptions
            className="dialog-content__select"
            options={['Toutes les agences', 'test2'].map((option) => {
              return {
                label: option,
                onClick: () => console.log('clicked'),
              };
            })}
            defaultIndex={0}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DialogContent;
