import PropTypes from 'prop-types';
import { Stack } from '@mui/material';

import './IconInfoBar.scss';

const IconInfoBar = ({ icon, content }) => {
  return (
    <Stack className="icon-info-bar" direction="row">
      {icon}
      {content}
    </Stack>
  );
};

export default IconInfoBar;

IconInfoBar.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object]).isRequired,
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
