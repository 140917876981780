import { Trans, t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AssociateTrackerToMachine } from '../../app/api/machines/Machines';
import './ScanNFC.scss';

const ScanNFC = () => {
  const history = useHistory();
  const [tracker, setTracker] = useState('');
  const [machine, setMachine] = useState('');
  const [comment, setComment] = useState('');
  const [status, setStatus] = useState();
  const [isSuccess, setSuccess] = useState(undefined);
  const [showLoading, setShowLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEnableNfcScan, setIsEnableNfcScan] = useState(false);

  const onReadingReceived = ({ message, serialNumber }) => {
    if (
      message.records.length > 0 &&
      message.records[0].recordType === 'text'
    ) {
      // This is a machine
      // eslint-disable-next-line no-case-declarations
      const textDecoder = new TextDecoder(message.records[0].encoding);
      setMachine(textDecoder.decode(message.records[0].data));
    } else {
      // This is a tracker
      const formated = serialNumber.replace(/:/g, '').toUpperCase();
      setTracker(formated);
    }
  };

  const submitPairTrackerAfterConfirm = useCallback(async () => {
    setOpenDialog(false);
    const body = {
      tag: tracker,
      machine,
      comment,
      forceUpdate: true,
    };
    const result = await AssociateTrackerToMachine(body);
    if (result.hasError) {
      if (result.message === 'EQUIPMENT_NOT_EXIST_IN_RENTALMAN') {
        setStatus(t`EQUIPMENT_NOT_EXIST_IN_RENTALMAN`);
      } else {
        setStatus(t`Association impossible vérifiez le tag du tracker`);
      }
      setShowLoading(false);
      return;
    }
    if (result) {
      setSuccess(true);
      setStatus(
        t`${`La machine ${machine} est associée au tracker ${tracker}`}`
      );
    }
  }, [machine, tracker, comment]);

  const submitPairTrackerToMachine = useCallback(async () => {
    if (!tracker || !machine) {
      setStatus(t`Veuillez entrer ou scanner les tags machine et tracker`);
      return;
    }
    setShowLoading(true);
    const body = {
      tag: tracker,
      machine,
      comment,
    };
    try {
      const result = await AssociateTrackerToMachine(body);
      if (result.hasError) {
        if (result.message === 'EQUIPMENT_NOT_EXIST_IN_RENTALMAN') {
          setStatus(t`EQUIPMENT_NOT_EXIST_IN_RENTALMAN`);
        } else if (result.message.includes('MULTIPLE_ASSOCIATION_')) {
          const nbTracker = result.message.split('_')[2];
          const msg = t`Cette machine est déjà appairée à`
            .concat(' ')
            .concat(nbTracker)
            .concat(' ')
            .concat(
              t`goobies, voulez-vous mettre fin à tous ces appairages pour l’appairer au goobie que vous venez de scanner ?`
            );
          setStatus(msg);
          setOpenDialog(true);
        } else {
          setStatus(t`Association impossible vérifiez le tag du tracker`);
        }
        setShowLoading(false);
        return;
      }
      if (result.newPairing) {
        setStatus(
          t`${`La machine ${result.machineEquipmentNumber} est associée au tracker ${result.trackerTag}`}`
        );
        setSuccess(true);
      } else if (!result.newPairing) {
        if (result.oldTag) {
          setStatus(
            t`${`La machine ${result.machineEquipmentNumber} est déjà associée au tracker ${result.oldTag}, Confirmez-vous vouloir changer de tracker ?`}`
          );
          setOpenDialog(true);
        } else if (result.oldMachine) {
          setStatus(
            t`${`Le tracker ${result.trackerTag} est déjà associé à la machine ${result.oldMachine}, Confirmez-vous vouloir changer de machine ?`}`
          );
          setOpenDialog(true);
        } else {
          const pairingDate = new Date(result.startDate);
          setStatus(
            t`Association déja réalisée le ${`${pairingDate.getDate()}/${
              pairingDate.getMonth() + 1
            }/${pairingDate.getFullYear()}`}`
          );
        }
      }
    } catch (error) {
      setStatus(t`Association impossible vérifiez le tag du tracker`);
    }

    setShowLoading(false);
  }, [tracker, machine, comment]);

  const handleListClick = useCallback(() => {
    /* eslint-disable no-restricted-globals */
    history.push('/scan-machines');
  }, [history]);

  const resetAssociation = useCallback(() => {
    setOpenDialog(false);
    setStatus(t`Vous pouvez procéder à un nouvel appairage`);
    setSuccess(false);
    setMachine('');
    setTracker('');
    setComment('');
  }, []);

  async function activateScan() {
    if ('NDEFReader' in window) {
      try {
        const ndef = new window.NDEFReader();
        await ndef.scan();
        ndef.addEventListener('reading', (event) => {
          onReadingReceived(event);
        });
        ndef.addEventListener('readingerror', () => {
          console.log('Cannot read data from the NFC tag. Try another one?');
        });
        setIsEnableNfcScan(true);
      } catch (error) {
        console.log(`Error! Scan failed to start: ${error}.`);
        window.location.replace('/help_scan.html');
      }
    }
  }

  useEffect(() => {
    async function checkPermissions() {
      const nfcPermissionStatus = await navigator.permissions.query({
        name: 'nfc',
      });
      if (nfcPermissionStatus.state === 'granted') {
        // NFC access was previously granted, so we can start NFC scanning now.
        await activateScan();
        setIsEnableNfcScan(true);
      } else {
        // Show a "scan" button.
        setIsEnableNfcScan(false);
      }
    }

    async function activateScan() {
      if ('NDEFReader' in window) {
        try {
          const ndef = new window.NDEFReader();
          await ndef.scan();
          ndef.addEventListener('reading', (event) => {
            onReadingReceived(event);
          });
          ndef.addEventListener('readingerror', () => {
            console.log('Cannot read data from the NFC tag. Try another one?');
          });
        } catch (error) {
          console.log(`Error! Scan failed to start: ${error}.`);
        }
      }
    }

    checkPermissions();
    return () => {
      if ('NDEFReader' in window) {
        const ndef = new window.NDEFReader();
        ndef.onreading = null;
        ndef.onreadingerror = null;
      }
    };
  }, []);

  return (
    <>
      {!isSuccess && (
        <>
          <Box className="modal-scan">
            <Stack
              className="nfc-scan-stack"
              direction="column"
              justifyContent="flex-start"
              spacing={1}
            >
              <Typography variant="h3">{t`Associer un tracker`}</Typography>
              {status && (
                <Alert severity="info">
                  <Typography variant="body1">{status}</Typography>
                </Alert>
              )}
              <TextField
                autoFocus
                id="input-tag-tracker"
                placeholder={t`scannez le tracker`}
                value={tracker}
                label={t`TAG Tracker`}
                variant="filled"
                onChange={(event) => setTracker(event.target.value)}
                disabled
                required
              />

              <TextField
                id="input-machine-id"
                placeholder={t`scannez le code machine`}
                value={machine}
                label={t`Code machine`}
                onChange={(event) => setMachine(event.target.value)}
                variant="filled"
                disabled
                required
              />

              <TextField
                id="outlined-multiline-static"
                label={t`Commentaire`}
                multiline
                rows={4}
                value={comment}
                onChange={(event) => setComment(event.target.value)}
                variant="filled"
              />
              {!isEnableNfcScan && (
                <Button
                  className="scanner-link-to-list"
                  variant="contained"
                  onClick={() => activateScan()}
                >
                  <Typography variant="h6">{t`Activer le scan`}</Typography>
                </Button>
              )}
              <LoadingButton
                size="medium"
                onClick={() => submitPairTrackerToMachine()}
                loading={showLoading}
                variant="contained"
              >
                <Typography variant="h6">{t`Associer`}</Typography>
              </LoadingButton>

              <Link to="/scan-machines">
                <Button className="scanner-link-to-list" variant="contained">
                  <Typography variant="h6">{t`Voir les machines associées`}</Typography>
                </Button>
              </Link>
            </Stack>
          </Box>
          <Dialog open={openDialog}>
            <DialogContent>
              <Typography variant="body1">{status}</Typography>
              <DialogActions>
                <Button onClick={() => submitPairTrackerAfterConfirm()}>
                  <Trans>Oui</Trans>
                </Button>
                <Button onClick={() => resetAssociation()}>
                  <Trans>Non</Trans>
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </>
      )}
      {isSuccess && (
        <SuccessAssociation
          status={status}
          resetAssociation={resetAssociation}
          handleListClick={handleListClick}
        />
      )}
    </>
  );
};

const SuccessAssociation = ({ status, resetAssociation, handleListClick }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {status && (
        <Alert severity="success">
          <Typography variant="body1">{status}</Typography>
        </Alert>
      )}
      <Button
        variant="contained"
        onClick={() => resetAssociation()}
      >{t`Associer un nouveau tracker`}</Button>
      <Button
        variant="contained"
        onClick={() => handleListClick()}
      >{t`Retour à la liste de tracker`}</Button>
    </Box>
  );
};

export default ScanNFC;
