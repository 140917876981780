import { Reducer } from 'react';

export const initialData = {
  isLoading: true,
  isError: false,
  data: null,
  error: '',
};

interface State extends Payload {
  isLoading: boolean;
  isError: boolean;
}

interface Payload {
  data?: any;
  error?: string;
}

export const FETCH_INIT = 'FETCH_INIT';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAILURE = 'FETCH_FAILURE';

export type Action =
  | { type: typeof FETCH_INIT }
  | { type: typeof FETCH_SUCCESS; payload: Payload }
  | { type: typeof FETCH_FAILURE; payload: Payload };

const dataFetchReducer: Reducer<State, Action> = (
  state: State = initialData,
  action: Action
): State => {
  switch (action.type) {
    case FETCH_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: '',
        ...action.payload,
      };
    case FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        ...action.payload,
      };
    default:
      throw new Error();
  }
};

export default dataFetchReducer;
