import { t } from '@lingui/macro';
import Typography from '@mui/material/Typography';
import moment, { Moment } from 'moment';
import { isDateBellow24h } from '../../../../../../utils/date/calcul';

import './MachineLastSync.scss';

const MachineLastSync = ({
  engineRunningDatetime,
}: {
  engineRunningDatetime: string;
}) => {
  const isLastSyncBellow24h = isDateBellow24h(engineRunningDatetime);

  const engineRunningDatetimeFormated = ((date: Moment) => {
    return date.isValid() ? date.format('L ') + t`à` + date.format(' LT') : '';
  })(moment(engineRunningDatetime));

  return (
    <Typography
      className="machine-last-sync"
      color={!isLastSyncBellow24h ? 'primary.main' : ''}
    >
      {`${isLastSyncBellow24h ? t`Le` : t`Dernière mise à jour`} ${
        isLastSyncBellow24h
          ? `${engineRunningDatetimeFormated}`
          : `(${engineRunningDatetimeFormated})`
      }`}
    </Typography>
  );
};

export default MachineLastSync;
