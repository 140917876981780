import { t } from '@lingui/macro';
import { Paper } from '@mui/material';
import _ from 'lodash';
import { JobSite } from '../../../../../api/jobsites/JobSite.interface';
import SpecificationList from '../../../../../components/molecules/SpecificationList/SpecificationList';
import { getFormatedDate } from '../../../../../utils/date/format';
import { useAppModeContext } from '../../../../../utils/hooks';

import './JobsiteInformation.scss';

const JobsiteInformation = ({ jobsite }: { jobsite: JobSite }) => {
  const { isInternalAppMode } = useAppModeContext();

  const {
    customerNumber,
    rentalmanId,
    city,
    streetAdress,
    streetNumber,
    startDate,
    endDate,
  } = jobsite;

  const data = [];

  // eslint-disable-next-line no-unused-expressions
  isInternalAppMode &&
    customerNumber &&
    data.push({
      name: t`Numéro Client`,
      value: customerNumber,
    });

  // eslint-disable-next-line no-unused-expressions
  isInternalAppMode &&
    rentalmanId &&
    data.push({
      name: t`Rentalman Id`,
      value: rentalmanId,
    });

  data.push({
    name: t`Adresse`,
    value: `
        ${_.capitalize(city) || '-'},
        ${_.capitalize(streetAdress) || '-'},
        ${_.capitalize(streetNumber) || '-'}
      `,
  });

  // eslint-disable-next-line no-unused-expressions
  startDate &&
    endDate &&
    data.push({
      name: t`Dates`,
      value: `${t`Du`} ${getFormatedDate(startDate)} ${t`au`} ${getFormatedDate(
        endDate
      )}`,
    });

  return (
    <Paper className="jobsite-information">
      <SpecificationList
        title={t`Information`}
        specifications={[
          {
            data,
          },
        ]}
      />
    </Paper>
  );
};

export default JobsiteInformation;
