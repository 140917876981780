import { i18n } from '@lingui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, MenuItem, Select } from '@mui/material';
import daFlag from '../../../../../assets/images/country_flags/da_flag.png';
import deFlag from '../../../../../assets/images/country_flags/de_flag.png';
import frFlag from '../../../../../assets/images/country_flags/fr_flag.png';
import ukFlag from '../../../../../assets/images/country_flags/uk_flag.png';
import nlFlag from '../../../../../assets/images/country_flags/nl_flag.png';
import { dynamicActivate } from '../../../../../i18n';

const LanguageSelector = () => {
  return (
    <Select
      value={i18n.locale}
      onChange={(e) => dynamicActivate(e.target.value)}
      variant="standard"
      IconComponent={ArrowIcon}
      sx={customStyle}
    >
      <MenuItem key="fr" value="fr">
        <FlagOption flagImage={frFlag} flagName="FR flag" />
      </MenuItem>
      <MenuItem key="en" value="en">
        <FlagOption flagImage={ukFlag} flagName="UK flag" />
      </MenuItem>
      {/* <MenuItem key="de" value="de">
        <FlagOption flagImage={deFlag} flagName="DE flag" />
      </MenuItem>
      <MenuItem key="da" value="da">
        <FlagOption flagImage={daFlag} flagName="DA flag" />
      </MenuItem>
      <MenuItem key="nl" value="nl">
        <FlagOption flagImage={nlFlag} flagName="NL flag" />
      </MenuItem> */}
    </Select>
  );
};

const ArrowIcon = (props: any) => {
  return <KeyboardArrowDownIcon {...props} />;
};

const customStyle = {
  '& > svg': {
    color: 'white',
  },
  '&:before': {
    all: 'unset',
  },
  '&:after': {
    all: 'unset',
  },
};

const FlagOption = ({
  flagImage,
  flagName,
}: {
  flagImage: any;
  flagName: string;
}) => {
  return (
    <Box
      component="img"
      sx={{
        width: 32,
        filter: 'brightness(130%)',
        borderRadius: '50%',
      }}
      alt={flagName}
      src={flagImage}
    />
  );
};

export default LanguageSelector;
