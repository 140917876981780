import { MetricsCallBack } from '../../../../../../components/EquipmentSecurity/EquipmentSecurity.interface';
import { SecurityAlertProps } from '../../../../../../components/SecurityAlert/SecurityAlert';
import { getJobSiteSecurity } from '../../../../../api/jobsites/JobSites';
import { formatSecurityAlerts } from '../../../../../utils/security/security.utils';

export const handleJobSiteSecurityInfo = async (
  jobSiteId: string
): Promise<MetricsCallBack> => {
  let alerts: SecurityAlertProps[] = [];
  let calendarData: any = [];
  let period: any = null;

  return getJobSiteSecurity({
    jobSiteId,
    machineId: null,
    startDate: null,
    endDate: null,
  })
    .then((response) => {
      const { startDate, endDate, jobSiteMainSummary, sessionsDay } = response;

      alerts = formatSecurityAlerts(jobSiteMainSummary);
      calendarData = sessionsDay?.sessionPeriodAggList;
      period = { start: startDate, end: endDate };

      return { alerts, calendarData, period };
    })
    .catch((e) => {
      console.error('Getting job site security error:', e);

      return { alerts, calendarData, period };
    });
};
