import { t } from '@lingui/macro';

export const AlertTypeValues: { [key: string]: string } = {
  BOTH: t`Les deux`,
  DURATION: t`Durée`,
  OCCURRENCE: t`Occurrence`,
};

export const AlertModeValues: { [key: string]: string } = {
  BOTH: t`Les deux`,
  EMAIL: t`Email`,
  SMS: t`SMS`,
};
