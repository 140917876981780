/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */

import { t } from '@lingui/macro';
import { useEffect, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import iconAgencyClosed from '../../../../assets/images/Icon_Agency_Closed.svg';
import iconAgencyOpen from '../../../../assets/images/Icon_Agency_Open.svg';
import iconJobSite from '../../../../assets/images/Icon_JobSite.svg';
import iconMachineOff from '../../../../assets/images/Icon_Machine_Off.svg';
import iconMachineOn from '../../../../assets/images/Icon_Machine_On.svg';
import { findIconByMachineStatus } from '../../../../components/EquipmentStatusIcon/EquipmentStatusIcon.service';
import { useUserContext } from '../../../../contexts/UserContext/UserContext';
import useMap from '../../../../hooks/useMap/useMap';
import { findMachineStatus } from '../../../../utils/equipment/Status/EquipmentStatus.service';
import { generateDrawingManager } from '../../../../utils/f4map/drawingManager.utils';
import { createIconProps } from '../../../../utils/f4map/iconProps.utils';
import { createMarker } from '../../../../utils/f4map/marker.utils';
import { useAppModeContext } from '../../../../utils/hooks';
import { getEquipmentDescription } from '../../../../utils/utils';
import {
  MARKER_POPUP_CLOSE_DELAY,
  MARKER_POPUP_OPEN_DELAY,
} from '../Map.constants';
import AgencyMarkerPopup from '../MarkersPopup/AgencyMarkerPopup';
import JobSiteMarkerPopup from '../MarkersPopup/JobSiteMarkerPopup';
import MachineMarkerPopup from '../MarkersPopup/MachineMarkerPopup/MachineMarkerPopup';
import { DrawAllDepartements, DrawAllRegions } from '../Zones/MapZones';

const SingleEquipmentMap = ({
  lon,
  lat,
  equipment,
  type,
  displayZone,
  drawingZone = false,
  zonePoints = [],
  setZonePoints = (pointsList) => {},
  mapClassName = '',
  isDraggable = false,
  geoZone = {
    displayRegions: false,
    displayDepartments: false,
    geoZoneIds: [''],
    handleGeoZoneClicked: (geoZoneId) => {},
  },
  handleMarkerDrag = (coords) => {},
}) => {
  const { isInternalAppMode } = useAppModeContext();

  const myLatlng = new f4.map.LatLng(lat, lon);
  const mapOptions = {
    zoom: 17,
    center: myLatlng,
  };

  const { map, MapCanvas, setMapElements, resetMapElements, addPolygon } =
    useMap({
      mapOptions,
      mapClassName,
    });

  const {
    displayRegions = false,
    displayDepartments = false,
    geoZoneIds = [],
    handleGeoZoneClicked = (geoZoneId) => {},
  } = geoZone;

  let click = false;
  const user = useUserContext();
  const drawingManagerRef = useRef();

  useEffect(() => {
    hideDrawSystem();
  }, [drawingZone]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    (displayRegions || displayDepartments) && resetMapElements();
  }, [displayRegions, displayDepartments]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const markers = [];
    const polygons = [];
    const circles = [];

    if (!map) return;

    resetMapElements();

    if (displayRegions) {
      DrawAllRegions(map, geoZoneIds, handleGeoZoneClicked, addPolygon);
      map.setZoom(7);
    }
    if (displayDepartments) {
      DrawAllDepartements(map, geoZoneIds, handleGeoZoneClicked, addPolygon);
      map.setZoom(7);
    }

    let icon;
    let title;
    let contentString;

    switch (type) {
      case 'Machines': {
        const machineStatus = findMachineStatus(
          equipment.lastSyncTime,
          equipment.engineRunning,
          isInternalAppMode ? equipment.equipmentStatusKey : undefined,
          equipment.agencyOwnerId,
          equipment.agencyId,
          user.agencyId,
          user.mergedProfil
        );

        icon = findIconByMachineStatus(machineStatus);

        title = `${getEquipmentDescription(equipment, equipment.model)} (${
          equipment.rentalmanId
        })`;

        contentString = renderToString(
          <MachineMarkerPopup
            machine={el}
            machineStatus={machineStatus}
            title={title}
          />
        );

        break;
      }
      case 'Chantiers': {
        title = equipment.name;
        icon = iconJobSite;

        contentString = renderToString(
          <JobSiteMarkerPopup jobSite={equipment} title={title} />
        );

        break;
      }
      case 'Agences': {
        title = getEquipmentDescription(equipment, equipment.name);

        const openingTimes = {
          morning: {
            opening: '06h30',
            openingDate: new Date().setHours('06', '30'),
            closing: '12h00',
            closingDate: new Date().setHours('12', '00'),
          },
          afternoon: {
            opening: '13h30',
            openingDate: new Date().setHours('13', '30'),
            closing: '14h00',
            closingDate: new Date().setHours('18', '00'),
          },
          open: t`Ouvert jusqu'à`,
          close: t`Fermé jusqu'à`,
        };

        const actualTime = new Date();

        // Need to simplify this ^^'
        if (
          (actualTime > openingTimes.morning.openingDate &&
            actualTime < openingTimes.morning.closingDate) ||
          (actualTime > openingTimes.afternoon.openingDate &&
            actualTime < openingTimes.afternoon.closingDate)
        ) {
          icon = iconAgencyOpen;
        } else {
          icon = iconAgencyClosed;
        }

        contentString = renderToString(
          <AgencyMarkerPopup agency={equipment} title={title} />
        );

        break;
      }
      default: {
        console.error('Unknown equipment state.');
        icon = equipment.engineRunning ? iconMachineOn : iconMachineOff;
      }
    }

    const infowindow = new f4.map.InfoWindow({
      content: contentString,
    });

    const marker = createMarker(
      map,
      myLatlng,
      createIconProps(icon, new f4.map.Size(40, 50)),
      title,
      { draggable: isDraggable }
    );

    markers.push(marker);

    if (displayZone) {
      const isEquipmentZoneOrDrawedZone =
        (equipment.zones && equipment.zones.length > 0) ||
        zonePoints.length > 0;

      if (isEquipmentZoneOrDrawedZone) {
        const zoneToDraw = zonePoints.length > 0 ? zonePoints : equipment.zones;

        const zonePointsLatLng = zoneToDraw.map((z) => {
          return new f4.map.LatLng(z.lat, z.lon);
        });
        // Construct the polygon.
        const zonePointsArr = new f4.map.Polygon({
          paths: zonePointsLatLng,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
        });

        polygons.push(zonePointsArr);
        zonePointsArr.setMap(map);
      } else {
        const populationOptions = {
          strokeColor: '#5E19B0',
          fillColor: '#5E19B0',
          map,
          center: myLatlng,
          radius: 250,
        };

        // eslint-disable-next-line no-unused-vars
        const cityCircle = new f4.map.Circle(populationOptions);
        circles.push(cityCircle);
      }
    }

    if (drawingZone) {
      drawingManagerRef.current = generateDrawingManager();
      drawingManagerRef.current.setMap(map);

      try {
        f4.map.event.addListener(
          drawingManagerRef.current,
          'overlaycomplete',

          (event) => {
            polygons.push(event.overlay);
            const points = event.overlay.paths._array[0]._array.map((el) => {
              return {
                lat: el._lat,
                lon: el._lng,
              };
            });

            setZonePoints(points);
            hideDrawSystem();
          }
        );
      } catch (e) {
        console.error(e);
      }
    }

    if (isDraggable) {
      f4.map.event.addListener(marker, 'dragend', () => {
        const coords = marker.getPosition();

        handleMarkerDrag({ lat: coords._lat, lon: coords._lng });
        map.setCenter(coords);
      });
    }

    f4.map.event.addListener(marker, 'click', () => {
      let cnt = map.getZoom();
      const timer = setInterval(() => {
        if (cnt <= 18) {
          map.setZoom(cnt + 1);
          cnt += 1;
        }
        if (cnt === 19) window.clearInterval(timer);
      }, 80);
      map.setCenter(marker.getPosition());
      // eslint-disable-next-line react-hooks/exhaustive-deps
      click = true;
      infowindow.open(map, marker);
    });

    f4.map.event.addListener(map, 'zoom_changed', () => {
      const zoomLevel = map.getZoom();
      if (zoomLevel !== 18) {
        click = false;
      }
    });

    f4.map.event.addListener(marker, 'mouseover', () => {
      if (!click) {
        setTimeout(() => infowindow.open(map, marker), MARKER_POPUP_OPEN_DELAY);
      }
    });

    f4.map.event.addListener(marker, 'mouseout', () => {
      if (!click) {
        setTimeout(
          () => infowindow.close(map, marker),
          MARKER_POPUP_CLOSE_DELAY
        );
      }
    });

    setMapElements({ markers, polygons, circles });
  }, [
    map,
    lon,
    lat,
    equipment,
    type,
    displayZone,
    drawingZone,
    zonePoints,
    isDraggable,
  ]);

  function hideDrawSystem() {
    if (drawingManagerRef.current) {
      drawingManagerRef.current.setMap(null);
      drawingManagerRef.current.setOptions({
        drawingMode: false,
        drawingControl: false,
      });
    }
  }

  return MapCanvas;
};

export default SingleEquipmentMap;
