import { Trans } from '@lingui/macro';
import { MenuItem, Select } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface Option {
  value: string;
  label: string;
}

interface CustomSelectProps {
  value: string;
  handleChange: (value: string) => void;
  onMouseOver?: (value: any) => void;
  options: Option[];
  placeholder?: string;
  style?: any;
}

const CustomSelect = ({
  value,
  handleChange,
  onMouseOver,
  options,
  placeholder,
  style,
}: CustomSelectProps) => {
  return (
    <Select
      value={value}
      onChange={(e) => handleChange(e.target.value)}
      onMouseOver={onMouseOver}
      variant="standard"
      IconComponent={ArrowIcon}
      renderValue={(selected) => {
        // doesn't work with empty string
        if (selected === 'placeholder') {
          return <em>{placeholder}</em>;
        }

        return (
          <Trans id={options.find((item) => item.value === selected)?.label} />
        );
      }}
      fullWidth
      sx={{
        paddingLeft: '1rem',
        '& > svg': {
          color: 'primary.main',
        },
        '&:before': {
          borderColor: 'primary.main',
          borderWidth: '2px',
        },
        '&:after': {
          borderColor: 'primary.main',
          borderWidth: '2px',
        },
        '.MuiSelect-select:focus': {
          backgroundColor: 'transparent',
        },
        ...style,
      }}
    >
      {options.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          <Trans id={item.label} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default CustomSelect;

const ArrowIcon = (props: any) => {
  return <KeyboardArrowDownIcon {...props} />;
};
