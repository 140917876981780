import { t } from '@lingui/macro';
import MapIcon from '@mui/icons-material/Map';
import { Box, IconButton, Typography, Stack } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIcon from '../../../assets/images/copy.svg';
import { getMachineState } from '../../../pages/equipments/utils';
import { isDateBellow24h } from '../../../utils/date/calcul';
import { getFullDate } from '../../../utils/date/format';
import { useAppModeContext } from '../../../utils/hooks';
import SpecificationList from '../SpecificationList/SpecificationList';

import { getTrackerFromMachineId } from '../../../api/machines/Machines';
import { Loading } from '../../../../lib/apptheme';

import TelematicEngineRunningDateTime from './components/TelematicEngineRunningDateTime';
import TelematicMachineStatus from './components/TelematicMachineStatus';
import TelemacticLastContactRecieved from './components/TelematicLastContactRecieved';

const DeviceDetailTelematic = ({ id, machine, geoLocLastInfo }) => {
  const { isInternalAppMode } = useAppModeContext();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [machineTrackers, setMachineTrackers] = useState(null);

  const engineRunningDatetime = moment(machine.engineRunningDatetime);
  const engineRunning = getMachineState(machine);
  const lastSyncTime = moment(machine.lastSyncTime);

  const positionComponent = (title, lat, lon, syncTime) => {
    return (
      <Box className="device-detail-telematic__position">
        {lat && lon && syncTime && moment(syncTime).isValid() && (
          <>
            <Typography
              className="device-detail-telematic__position-title"
              variant="body1Bold"
            >
              {title} :
            </Typography>
            <>
              {lat || '-'}, {lon || '-'} {` ${`(${getFullDate(syncTime)})`}`}
              <CopyToClipboard
                text={`${lat},${lon}`}
                className="equipment-parc-loxam-map-card__copy-clip-board"
              >
                <img src={CopyIcon} alt="" />
              </CopyToClipboard>
              <a
                href={`https://www.google.com/maps?q=${lat},${lon}`}
                title="open google map with coord"
                aria-label="open google map with coord"
                target="_blank"
                rel="noreferrer"
              >
                <IconButton color="primary">
                  <MapIcon className="device-detail-telematic__position-map-icon" />
                </IconButton>
              </a>
            </>
          </>
        )}
      </Box>
    );
  };

  const memoizedPositionFn = useCallback(() => {
    const timeForOEMPosition = machine.gpsFixTime;

    const isGbPosition = !!(
      isInternalAppMode &&
      machine.gbLong &&
      machine.gbLat &&
      machine.gbLastSyncTime
    );

    const isOEMPosition = !!(machine.lat && machine.lon);

    const componentGb = () => ({
      component: positionComponent(
        t`Coordonnées Goobies`,
        machine.gbLat,
        machine.gbLong,
        machine.gbLastSyncTime
      ),
    });

    const componentOEM = () => ({
      component: positionComponent(
        t`Coordonnées`,
        machine.lat,
        machine.lon,
        timeForOEMPosition
      ),
    });

    return {
      componentGb:
        isGbPosition && moment(machine.gbLastSyncTime).isValid()
          ? componentGb()
          : {},
      componentOEM: isOEMPosition ? componentOEM() : {},
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInternalAppMode,
    machine.gbLastSyncTime,
    machine.gbLat,
    machine.gbLong,
    machine.gpsFixTime,
    machine.lat,
    machine.lon,
  ]);

  const machineDuration =
    machine.totalUsage && moment.duration(machine.totalUsage, 'seconds');

  const machineDurationFormatted =
    machineDuration &&
    `${Math.floor(
      machineDuration.asHours()
    )}h ${machineDuration.minutes()}min ${machineDuration.seconds()}sec`;

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const trackerList = await getTrackerFromMachineId(id);
    setMachineTrackers(trackerList);

    const imeis = trackerList?.map((element) => element.imei).join(', ') || '';

    const telamatics = {
      data: [
        // {
        //   name: t`Niveau de batterie`,
        //   value: machine.battery ? `${machine.battery} V` : null,
        // },
        {
          name: t`Compteur`,
          value: machineDurationFormatted || null,
        },
        {
          ...memoizedPositionFn().componentOEM,
        },
        {
          name: t`Adresse`,
          value: geoLocLastInfo?.OEM?.address,
        },
        {
          ...memoizedPositionFn().componentGb,
        },
        {
          name: t`Goobie IMEI`,
          value: imeis,
        },
        {
          name: t`Adresse`,
          value: geoLocLastInfo?.GOOBIE?.address,
        },
      ],
      title: null,
    };

    const isEngineRunningDatetimeBellow24h = isDateBellow24h(
      machine.engineRunningDatetime
    );
    setData([
      {
        data: [
          {
            child: (
              <Stack spacing={2} sx={{ flexDirection: 'row' }}>
                <TelemacticLastContactRecieved lastSyncTime={lastSyncTime} />
              </Stack>
            ),
          },
          {
            child: (
              <Stack spacing={2} sx={{ flexDirection: 'row' }}>
                <TelematicMachineStatus
                  engineRunning={engineRunning}
                  isEngineRunningDatetimeBellow24h={
                    isEngineRunningDatetimeBellow24h
                  }
                />
                <TelematicEngineRunningDateTime
                  engineRunningDatetime={engineRunningDatetime}
                />
              </Stack>
            ),
          },
        ],
        divider: true,
        title: null,
      },
      telamatics,
    ]);

    setIsLoading(false);
  }, [
    engineRunning,
    engineRunningDatetime,
    geoLocLastInfo?.GOOBIE?.address,
    geoLocLastInfo?.OEM?.address,
    id,
    lastSyncTime,
    machine.engineRunningDatetime,
    machineDurationFormatted,
    memoizedPositionFn,
  ]);

  useEffect(() => {
    if (!isLoading && !machineTrackers) {
      fetchData();
    }
  }, [fetchData, isLoading, machineTrackers]);

  return (
    <div>
      {isLoading ? (
        <Loading size={4} position={{ top: '60%', left: '55%' }} />
      ) : (
        <SpecificationList title={t`Télématiques`} specifications={data} />
      )}
    </div>
  );
};

export default DeviceDetailTelematic;

DeviceDetailTelematic.propTypes = {
  machine: PropTypes.shape({
    address: PropTypes.string,
    agencyId: PropTypes.string,
    agencyName: PropTypes.string,
    battery: PropTypes.string,
    brand: PropTypes.string,
    buildYear: PropTypes.number,
    catClass: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    currentContractId: PropTypes.string,
    description: PropTypes.string,
    descriptions: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    distance: PropTypes.string,
    engineRunning: PropTypes.bool,
    estimatedEnd: PropTypes.string,
    externalIds: PropTypes.object,
    height: PropTypes.number,
    id: PropTypes.string,
    jobSiteDesc: PropTypes.string,
    jobSiteId: PropTypes.string,
    lastSyncTime: PropTypes.string,
    lat: PropTypes.string,
    lon: PropTypes.string,
    gpsFixTime: PropTypes.string,
    length: PropTypes.number,
    machineGroupId: PropTypes.string,
    model: PropTypes.string,
    name: PropTypes.string,
    pim: PropTypes.shape({
      apoximate: PropTypes.bool,
      code: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          format: PropTypes.string,
          imageType: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    }),
    rentalStart: PropTypes.string,
    rentalmanId: PropTypes.string,
    serialNumber: PropTypes.string,
    statusCode: PropTypes.string,
    vin: PropTypes.string,
    weight: PropTypes.number,
    width: PropTypes.number,
    zipCode: PropTypes.string,
  }).isRequired,
  geoLocLastInfo: PropTypes.shape([]),
};
