import { t } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getReports } from '../../api/trackers/Trackers';
import { ReturnButton } from '../../components/molecules';
import { PaginatedTable } from '../../components/molecules/PaginatedTable/PaginatedTable';

/**
 * Page to a list of all reports
 * url = /reports/{reportUUID}
 * @param hideDetails show a simple list of tracker
 * @param search preset search tracker imei value
 * @returns {JSX.Element}
 * @constructor
 */
export const ReportGoobieList = ({ hideDetails, search }) => {
  const [reports, setReports] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageNumber, setPage] = useState(0);
  const [totalReports, setTotal] = useState(0);
  const [searchReq, setSearch] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [sortFilter, setSort] = useState(null);

  useEffect(() => {
    const fetchReports = async () => {
      if (search) {
        setSearch(search);
      }
      setLoading(true);
      const response = await getReports({
        page: pageNumber,
        size: rowsPerPage,
        search: search || searchReq,
        sort: sortFilter,
      });

      setReports(response.reports);
      setTotal(response.totalElements);
      setLoading(false);
    };

    fetchReports();
  }, [pageNumber, rowsPerPage, searchReq, sortFilter, search]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSortChange = (sortModel) => {
    // the multi sorting is not available for free plan, https://mui.com/x/react-data-grid/sorting/#single-and-multi-sorting
    setSort(sortModel.length === 0 ? null : sortModel[0]);
  };

  const columns = [
    {
      field: 'mode',
      headerName: t`Mode`,
    },
    {
      field: 'created_at',
      headerName: t`Date`,
      flex: 1,
    },
    {
      field: 'imei',
      headerName: t`IMEI tracker`,
      flex: 1,
      renderCell: (params) => (
        <Link to={`/trackers/${params.row.trackerId}`}>{params.value}</Link>
      ),
    },
    {
      field: 'operator',
      headerName: t`Operateur`,
      sortable: false,
    },
    {
      field: 'temperature',
      headerName: t`Température`,
      sortable: false,
    },
    {
      field: 'voltage',
      headerName: t`Voltage mv`,
      sortable: false,
    },
    {
      field: 'counterGps',
      headerName: t`"GPS" time counters`,
      sortable: false,
    },
    {
      field: 'counterAccelerometer',
      headerName: t`Compteur de temps de l'accéléromètre`,
      sortable: false,
    },
    {
      field: 'counterGsm',
      headerName: t`"GSM" time counters`,
    },
    {
      field: 'agencyName',
      headerName: t`Agence`,
      renderCell: (params) => (
        <Link to={`/equipments/agency/${params.row.agencyId}`}>
          {params.value}
        </Link>
      ),
      sortable: false,
      flex: 1,
    },
    {
      field: 'id',
      headerName: '',
      renderCell: (params) => (
        <Link
          to={`/tracker-reports/${params.row.id}`}
        >{t`Voir les details`}</Link>
      ),
      flex: 1,
    },
  ];
  return (
    <>
      {!hideDetails && <ReturnButton label={t`Retour`} sx={{ m: 2 }} />}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          width: '100%',
          height: '100%',
        }}
      >
        <Typography variant="h1"> {t`Rapports Goobies`} </Typography>

        <PaginatedTable
          searchLabel={t`Imei`}
          onSearchChange={handleSearch}
          onPageChange={handleChangePage}
          onRowChange={handleChangeRowsPerPage}
          onSortChange={handleSortChange}
          data={reports}
          columns={columns}
          page={pageNumber}
          rowsPerPage={rowsPerPage}
          totalElement={totalReports}
          searchEnable={!hideDetails}
          isLoading={isLoading}
        />
      </Box>
    </>
  );
};

export default ReportGoobieList;
