import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { CalendarMonth } from '../CalendarMonth/CalendarMonth';
import { CalendarMonthListProps } from './CalendarMonthList.interface';

import './CalendarMonthList.scss';

export const CalendarMonthList = ({
  selectedType,
  monthList,
  dateClicked,
  handleDayClick,
}: CalendarMonthListProps) => {
  const [dateHovered, setMouseOverDate] = useState<Date | null>(null);

  return (
    <Stack className="month-list" direction="row">
      {monthList.map((item: any) => (
        <CalendarMonth
          key={item.month}
          month={item.month}
          monthValue={item.monthValue}
          year={item.year}
          totalDays={item.totalDays}
          firstDayOfTheMonth={item.firstDayOfTheMonth}
          daysUseAndDuration={item.daysUseAndDuration}
          selectedType={selectedType}
          dateHovered={dateHovered}
          dateClicked={dateClicked}
          handleDateHover={setMouseOverDate}
          handleDateClick={handleDayClick}
        />
      ))}
    </Stack>
  );
};
