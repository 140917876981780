import { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import dataFetchReducer, {
  FETCH_FAILURE,
  FETCH_INIT,
  FETCH_SUCCESS,
  initialData,
} from './useAsyncFetch.reducer';
import { AsyncFunctionConfigProps } from './useAsyncFetch.interface';

export const useAsyncFunction = ({
  asyncFunction = () => Promise.reject(),
  onFetchSuccess = () => {
    // This is intentional
  },
  onFetchFailure = () => {
    // This is intentional
  },
  params = null,
  defaultValue = null,
  errorValue = null,
  shouldKeepLastResponse = true,
}: UseAsyncFunctionProps) => {
  const [state, dispatch] = useReducer(dataFetchReducer, {
    ...initialData,
    data: defaultValue,
  });

  const [needToReload, setNeedToReload] = useState(false);

  useEffect(() => {
    dispatch({ type: FETCH_INIT });
    const controller = new AbortController();
    const config = { signal: controller.signal };
    asyncFunction(params, config)
      .then((response) => {
        onFetchSuccess(response);
        dispatch({ type: FETCH_SUCCESS, payload: { data: response } });
      })
      .catch((error) => {
        const payload = {
          error,
          ...(!shouldKeepLastResponse && { data: errorValue }),
        };
        onFetchFailure(payload);
        dispatch({ type: FETCH_FAILURE, payload });
      });

    return () => {
      controller.abort();
    };
  }, [needToReload]); // eslint-disable-line react-hooks/exhaustive-deps

  function onRefresh() {
    setNeedToReload(!needToReload);
  }

  return { ...state, onRefresh };
};

useAsyncFunction.propTypes = {
  asyncFunction: PropTypes.func.isRequired,
  onFetchSuccess: PropTypes.func,
  onFetchFailure: PropTypes.func,
  params: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  shouldKeepLastResponse: PropTypes.bool,
};

interface UseAsyncFunctionProps {
  asyncFunction: (
    params: object | null,
    config: AsyncFunctionConfigProps
  ) => Promise<object>;
  onFetchSuccess?: (response?: object) => object | void;
  onFetchFailure?: (response?: object) => object | void;
  params?: object | null;
  defaultValue?: unknown;
  shouldKeepLastResponse?: boolean;
  errorValue?: unknown;
}

export default useAsyncFunction;
