import { getRequest, postRequest } from '../utils/axios.utils';

/**
 * get trackers, can be paginated
 * @returns {Promise<*>}
 */
export const getTrackers = (req: GetTrackersRequest) => {
  return postRequest('trackers', {
    size: req.size,
    page: req.page,
    search: req.search,
    mode: req.mode,
    sort: req.sort,
  });
};

export const getReports = (req: GetReportRequest) => {
  return postRequest('trackers/reports', {
    size: req.size,
    page: req.page,
    search: req.search,
    sort: req.sort,
  });
};

export const getOverActiveTrackers = (req: GetReportRequest) => {
  return postRequest('trackers/reports/overactive', {
    size: req.size,
    page: req.page,
    search: req.search,
    sort: req.sort,
  });
};

export const getInactiveTrackers = (req: GetReportRequest) => {
  return postRequest('trackers/reports/inactive', {
    size: req.size,
    page: req.page,
    search: req.search,
    sort: req.sort,
  });
};

export const getReport = (reportId: string) => {
  return getRequest(`trackers/reports/${reportId}`);
};

export const updateTracker = (req: any, id: any) => {
  return postRequest(`trackers/${id}`, req);
};

export const getTracker = (trackerId: string) => {
  return getRequest(`trackers/${trackerId}`);
};

export const getTrackerList = () => {
  return getRequest(`trackers/all`);
};

export const getTrackerDataByImei = (imei: number) => {
  return getRequest(`trackers/imei/${imei}`);
};

export const getTrackerDataByTag = (tag: string) => {
  return getRequest(`trackers/tag/${tag}`);
};

export interface GetTrackersRequest {
  /**
   * For pagination, page wanted and number of element
   */
  size: number;
  page: number;
  search: string;
  mode: string;
  sort: Record<string, string>;
}

export interface GetReportRequest {
  /**
   * For pagination, page wanted and number of element
   */
  size: number;
  page: number;
  search: string;
  sort: Record<string, string>;
}

/**
 * String enum, allow to loop with Object.values
 */
export enum TrackerMode {
  INIT = 'INIT',
  STORAGE = 'STORAGE',
  CALIBRATION = 'CALIBRATION',
  SERVICE = 'SERVICE',
  TRACKING = 'TRACKING',
  OTA = 'OTA',
}
