/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { Grid, Stack } from '@mui/material';

import './DeviceDetailUsage.scss';

const DeviceDetailUsage = ({ children }) => {
  return (
    <Stack spacing={2}>
      <Grid className="device-detail-usage__grid" container>
        {children}
      </Grid>
    </Stack>
  );
};

export default DeviceDetailUsage;

DeviceDetailUsage.propTypes = {
  data: PropTypes.shape({
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        backgroundColor: PropTypes.string,
        data: PropTypes.arrayOf(
          PropTypes.shape({
            x: PropTypes.string,
            y: PropTypes.number,
          })
        ),
        label: PropTypes.string,
      })
    ),
    labels: PropTypes.arrayOf(PropTypes.string),
  }),
  children: PropTypes.node.isRequired,
};
