import { t } from '@lingui/macro';
import colors from '../../../theme/_colors.scss';
import {
  CalendarTypeOptionsProps,
  MonthHoverTypeEnum,
} from './utils.interface';

export const calendarTypeOptions = (
  callback: (index: number) => void
): CalendarTypeOptionsProps[] => [
  {
    label: t`Jour`,
    value: MonthHoverTypeEnum.DAY,
    onClick: () => callback(0),
  },
  {
    label: t`Semaine`,
    value: MonthHoverTypeEnum.WEEK,
    onClick: () => callback(1),
  },
  {
    label: t`Mois`,
    value: MonthHoverTypeEnum.MONTH,
    onClick: () => callback(2),
  },
];

export function getMonthDifference(startDate: Date, endDate: Date) {
  return (
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear())
  );
}

export function getMaxColumnsToDisplay(width: number) {
  if (width >= 1400) {
    return 4;
  }
  if (width >= 1200 && width < 1400) {
    return 3;
  }
  if (width >= 1000 && width < 1200) {
    return 2;
  }
  if (width < 1000) {
    return 1;
  }

  return 3;
}

export function getDaysInMonth(year: number, month: number) {
  return new Date(year, month, 0).getDate();
}
export function getFirstDayOfMonth(year: number, month: number) {
  return new Date(year, month, 1).getDay();
}

export const getMondayFromDate = (date: Date) => {
  const newDate = new Date(date);
  const day = newDate.getDay();
  const diff = newDate.getDate() - day + (day === 0 ? -6 : 1);

  return new Date(newDate.setDate(diff));
};

export const getSundayFromDate = (date: Date) => {
  const newDate = new Date(date);
  const day = newDate.getDay();
  const diff = newDate.getDate() + (7 - (day === 0 ? 7 : day));

  return new Date(newDate.setDate(diff));
};

export const days: { [id: number]: string } = {
  0: t`Dimanche`,
  1: t`Lundi`,
  2: t`Mardi`,
  3: t`Mercredi`,
  4: t`Jeudi`,
  5: t`Vendredi`,
  6: t`Samedi`,
};

export const daysArr: string[] = [
  t`Lundi`,
  t`Mardi`,
  t`Mercredi`,
  t`Jeudi`,
  t`Vendredi`,
  t`Samedi`,
  t`Dimanche`,
];

export const months: { [id: number]: string } = {
  1: t`Janvier`,
  2: t`Février`,
  3: t`Mars`,
  4: t`Avril`,
  5: t`Mai`,
  6: t`Juin`,
  7: t`Juillet`,
  8: t`Août`,
  9: t`Septembre`,
  10: t`Octobre`,
  11: t`Novembre`,
  12: t`Décembre`,
};

interface CalendarCategories {
  description: string;
  value: number;
  timeInfo: string;
  color: string;
  func: any;
}

export const calendarCategories: CalendarCategories[] = [
  {
    description: '< 2h',
    value: 0,
    timeInfo: 'j',
    color: colors.darkCornflowerBlue,
    func: (usageOnContract: number, usageOutOfContract: number) => {
      return usageOutOfContract === 0 && usageOnContract < 120;
    },
  },
  {
    description: 'Entre 2h et 5h',
    value: 0,
    timeInfo: 'j',
    color: colors.cyanBlueAzure,
    func: (usageOnContract: number, usageOutOfContract: number) => {
      return (
        usageOutOfContract === 0 &&
        usageOnContract >= 120 &&
        usageOnContract <= 300
      );
    },
  },
  {
    description: '> 5h',
    value: 0,
    timeInfo: 'j',
    color: colors.moonstoneBlue,
    func: (usageOnContract: number, usageOutOfContract: number) => {
      return usageOutOfContract === 0 && usageOnContract > 300;
    },
  },
  {
    description: 'Utilisation hors contrat',
    value: 0,
    timeInfo: 'j',
    color: colors.jasper,
    func: (usageOnContract: number, usageOutOfContract: number) => {
      return usageOutOfContract > 0 && usageOnContract >= 0;
    },
  },
];

export function machineUsageDurationColor(
  duration: string,
  isUsageOutOfContract: boolean | null | undefined
): string {
  if (isUsageOutOfContract) {
    return calendarCategories[3].color;
  }
  switch (true) {
    // duration < 2h
    case parseInt(duration, 10) < 3600 * 2: {
      return calendarCategories[0].color;
    }
    // 2h <= duration <= 5h
    case parseInt(duration, 10) >= 3600 * 2 &&
      parseInt(duration, 2) <= 3600 * 5: {
      return calendarCategories[1].color;
    }
    // duration > 5h
    case parseInt(duration, 10) > 3600 * 5: {
      return calendarCategories[2].color;
    }
    default: {
      return 'transparent';
    }
  }
}
