/* eslint-disable no-undef */
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import './useMap.scss';

const useMap = ({ mapOptions = {}, mapClassName = '', forceReload }) => {
  const mapContainer = useRef(null);

  const markers = useRef([]);
  const polygons = useRef([]);
  const circles = useRef([]);
  const polylines = useRef([]);

  const [map, setMap] = useState(null);

  useEffect(() => {
    setMap(new f4.map.Map(mapContainer.current, mapOptions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceReload]);

  const setMapElements = (elements) => {
    markers.current.push(...(elements.markers ?? []));
    polygons.current = elements.polygons ?? [];
    circles.current.push(...(elements.circles ?? []));
    polylines.current.push(...(elements.polylines ?? []));
  };

  const addPolygon = (elements) => {
    polygons.current = [...polygons.current, elements];
  };

  const resetCircles = () => {
    // circles.current.forEach((m) => map.removeCircle(m));
    // eslint-disable-next-line no-underscore-dangle
    Object.keys(map._circlesById).forEach((c) => {
      // eslint-disable-next-line no-underscore-dangle
      map.removeCircle(map._circlesById[c]);
    });
  };

  const resetMapElements = () => {
    markers.current.forEach((m) => map.removeMarker(m));
    polygons.current.forEach((p) => map.removePolygon(p));
    circles.current.forEach((c) => map.removeCircle(c));
    polylines.current.forEach((c) => map.removePolyline(c));
    circles.current = [];
  };

  const MapCanvas = (
    <div
      data-testid="map-canvas"
      id="map-canvas"
      className={classNames('map', mapClassName)}
      ref={mapContainer}
    />
  );

  return {
    map,
    MapCanvas,
    setMapElements,
    resetMapElements,
    addPolygon,
    resetCircles,
  };
};

export default useMap;
