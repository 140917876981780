import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  InputAdornment,
  Paper,
  Stack,
  Typography,
  IconButton,
  TextField,
} from '@mui/material';
import Search from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import { Controller, useForm } from 'react-hook-form';
import Icon from '@mui/material/Icon';
import { Trans, t } from '@lingui/macro';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/_colors.scss';
import {
  getEquipmentDescription,
  RESULT_NUMBER_PER_CATEGORY,
} from '../../../utils/utils';
import ResultItem from './components/ResultItem/ResultItem';
import IsMobile from '../../../utils/IsMobile';
import {
  CATEGORY_MACHINE,
  CATEGORY_JOBSITE,
  CATEGORY_AGENCY,
  CATEGORY_CONTRACT,
} from './SearchInput.constants';

import './SearchInput.scss';

const useStyles = (props) =>
  makeStyles((theme) => ({
    root: {
      '& .MuiInputLabel-shrink': {
        color: 'primary.main',
      },
      '& .MuiFilledInput-root': {
        width: '30vw',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
        display: 'flex',
        alignItems: 'center',
        background: props.whiteBackground ? colors.white : colors.lightGray,
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        padding: '0px',
      },
    },
    icon: {
      fontSize: 32,
    },
  }));

const SearchInput = ({
  id,
  isLoading,
  performSearch,
  searchResult,
  filterValue,
  whiteBackground = false,
}) => {
  const defaultValues = { search: '' };
  const [resultData, setResultData] = useState(searchResult);
  const [key, setKey] = useState(defaultValues);

  const { handleSubmit, control, reset } = useForm({
    mode: 'all',
    defaultValues,
    criteriaMode: 'all',
  });
  const onFormSubmit = (data) => performSearch(data);

  const handleLinkClick = (eventKeyName, eventValueId) => {
    setResultData(null);
    reset(defaultValues);
    setKey({ search: '' });
  };
  const classes = useStyles({ whiteBackground })();

  const isMobile = IsMobile();

  useEffect(() => {
    if (searchResult) {
      setResultData({
        machinesResultsPage: 1,
        machinesResults:
          searchResult.machinesResults.length < RESULT_NUMBER_PER_CATEGORY
            ? searchResult.machinesResults
            : searchResult.machinesResults.slice(0, RESULT_NUMBER_PER_CATEGORY),
        jobSitesResultsPage: 1,
        jobSitesResults:
          searchResult.jobSitesResults.length < RESULT_NUMBER_PER_CATEGORY
            ? searchResult.jobSitesResults
            : searchResult.jobSitesResults.slice(0, RESULT_NUMBER_PER_CATEGORY),
        agenciesResultsPage: 1,
        agenciesResults:
          searchResult.agenciesResults.length < RESULT_NUMBER_PER_CATEGORY
            ? searchResult.agenciesResults
            : searchResult.agenciesResults.slice(0, RESULT_NUMBER_PER_CATEGORY),
        contractsResultsPage: 1,
        contractsResults:
          searchResult.contractsResults.length < RESULT_NUMBER_PER_CATEGORY
            ? searchResult.contractsResults
            : searchResult.contractsResults.slice(
                0,
                RESULT_NUMBER_PER_CATEGORY
              ),
      });
    } else {
      setResultData({});
    }
  }, [searchResult]);

  const showResultPanel = () => {
    return resultData && searchResult && key.search.length > 2 && !isLoading;
  };

  const showMore = (category) => {
    switch (category) {
      case CATEGORY_AGENCY:
        setResultData({
          ...resultData,
          agenciesResults: searchResult.agenciesResults.slice(
            0,
            (resultData.agenciesResultsPage + 1) * RESULT_NUMBER_PER_CATEGORY
          ),
          agenciesResultsPage: resultData.agenciesResultsPage + 1,
        });
        break;
      case CATEGORY_CONTRACT:
        setResultData({
          ...resultData,
          contractsResults: searchResult.contractsResults.slice(
            0,
            (resultData.contractsResultsPage + 1) * RESULT_NUMBER_PER_CATEGORY
          ),
          contractsResultsPage: resultData.contractsResultsPage + 1,
        });
        break;
      case CATEGORY_JOBSITE:
        setResultData({
          ...resultData,
          jobSitesResults: searchResult.jobSitesResults.slice(
            0,
            (resultData.jobSitesResultsPage + 1) * RESULT_NUMBER_PER_CATEGORY
          ),
          jobSitesResultsPage: resultData.jobSitesResultsPage + 1,
        });
        break;
      case CATEGORY_MACHINE:
        setResultData({
          ...resultData,
          machinesResults: searchResult.machinesResults.slice(
            0,
            (resultData.machinesResultsPage + 1) * RESULT_NUMBER_PER_CATEGORY
          ),
          machinesResultsPage: resultData.machinesResultsPage + 1,
        });
        break;
      default:
        break;
    }
  };

  const handleSearch = (value) => {
    performSearch(value);
  };

  return (
    <form
      className="search-input"
      onSubmit={handleSubmit(onFormSubmit)}
      hidden={window.location.href.includes('/scan')}
    >
      <Stack
        className="search-input__stack"
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        {isLoading && <CircularProgress disableShrink />}

        <Controller
          name="search"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              id={id}
              label={t`Recherche`}
              autoComplete="off"
              className={classes.root}
              variant="filled"
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment
                    position="start"
                    className="search-input__adornment"
                  >
                    {key.search.length === 0 ? (
                      <IconButton
                        variant="text"
                        type="submit"
                        aria-label={t`Recherche`}
                      >
                        <Search
                          className={classes.icon}
                          sx={{ color: 'primary.main' }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="Close"
                        variant="text"
                        onClick={() => {
                          setKey(defaultValues);
                          performSearch(defaultValues);
                          reset(defaultValues);
                        }}
                      >
                        <Icon
                          className={classes.icon}
                          sx={{ color: 'primary.main' }}
                        >
                          close
                        </Icon>
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              value={value}
              onChange={(e) => {
                setKey({ search: e.target.value });
                if (e.target.value) {
                  handleSearch({ search: e.target.value });
                }
                onChange(e);
              }}
            />
          )}
        />
      </Stack>

      {/* Adapter le code qui suit lors de l'implementation avec les champs correct */}
      {showResultPanel() && (
        <Box
          className={classnames(
            'search-input__result',
            isMobile
              ? 'search-input__result--mobile-width'
              : 'search-input__result--desktop-width'
          )}
        >
          <Paper className="search-input__result-paper">
            <Stack spacing={1}>
              {resultData.machinesResults?.length === 0 &&
              resultData.jobSitesResults?.length === 0 &&
              resultData.agenciesResults?.length === 0 &&
              resultData.contractsResults?.length === 0 ? (
                <Typography>
                  <Trans>Aucun élément correspondant</Trans>
                </Typography>
              ) : (
                <>
                  {['all', 'Machines'].includes(filterValue) && (
                    <Stack>
                      {resultData.machinesResults?.length > 0 ? (
                        <Typography variant="body1Bold">
                          <Trans>Machines</Trans>
                        </Typography>
                      ) : null}
                      <Stack>
                        {resultData.machinesResults?.map((result) => (
                          <ResultItem
                            key={`machine-${result.id}`}
                            mapLink={
                              result.lat && result.lon
                                ? `/equipments/map?zoomOn=${result.id}`
                                : null
                            }
                            location={
                              result.lat && result.lon
                                ? { lat: result.lat, lon: result.lon }
                                : null
                            }
                            detailLink={`/equipments/detail/${result.id}`}
                            machinesListLink="/equipments/list"
                            text={`${getEquipmentDescription(
                              result,
                              result.model
                            )} (${result.rentalmanId})`}
                            handleLinkClick={handleLinkClick}
                            eventValueId={result.id}
                            eventValue={`${getEquipmentDescription(
                              result,
                              result.model
                            )} (${result.rentalmanId})`}
                          />
                        ))}
                        {resultData.machinesResults?.length >=
                          RESULT_NUMBER_PER_CATEGORY &&
                          searchResult.machinesResults?.length !==
                            resultData.machinesResults?.length && (
                            <Typography
                              className="search-input__text-pointer"
                              sx={{ color: 'secondary.main' }}
                              onClick={() => {
                                showMore(CATEGORY_MACHINE);
                              }}
                            >
                              <MoreHorizIcon sx={{ color: 'secondary.main' }} />{' '}
                              {`+5 (${resultData.machinesResults.length}/${searchResult.machinesResults.length})`}
                            </Typography>
                          )}
                      </Stack>
                    </Stack>
                  )}
                  {['all', 'Chantiers'].includes(filterValue) && (
                    <Stack>
                      {resultData.jobSitesResults?.length > 0 ? (
                        <Typography variant="body1Bold">
                          <Trans>Chantiers</Trans>
                        </Typography>
                      ) : null}
                      <Stack>
                        {resultData.jobSitesResults?.map((result) => (
                          <ResultItem
                            key={`chantier-${result.id}`}
                            mapLink={
                              result.lat && result.lon
                                ? `/equipments/map?zoomOn=${result.id}`
                                : null
                            }
                            detailLink={`/equipments/jobsite/${result.id}`}
                            machinesListLink="/equipments/list"
                            text={result.name}
                            handleLinkClick={handleLinkClick}
                            eventValueId={result.id}
                            eventValue={result.name}
                          />
                        ))}
                        {resultData.jobSitesResults?.length >=
                          RESULT_NUMBER_PER_CATEGORY &&
                          searchResult.jobSitesResults?.length !==
                            resultData.jobSitesResults?.length && (
                            <Typography
                              className="search-input__text-pointer"
                              sx={{ color: 'secondary.main' }}
                              onClick={() => {
                                showMore(CATEGORY_JOBSITE);
                              }}
                            >
                              <MoreHorizIcon sx={{ color: 'secondary.main' }} />{' '}
                              {`+5 (${resultData.jobSitesResults.length}/${searchResult.jobSitesResults.length})`}
                            </Typography>
                          )}
                      </Stack>
                    </Stack>
                  )}
                  {['all', 'Agences'].includes(filterValue) && (
                    <Stack>
                      {resultData.agenciesResults?.length > 0 ? (
                        <Typography variant="body1Bold">
                          <Trans>Agences</Trans>
                        </Typography>
                      ) : null}
                      <Stack>
                        {resultData.agenciesResults?.map((result) => (
                          <ResultItem
                            key={`agency-${result.id}`}
                            mapLink={
                              result.lat && result.lon
                                ? `/equipments/map?zoomOn=${result.id}`
                                : null
                            }
                            detailLink={`/equipments/agency/${result.id}`}
                            machinesListLink="/equipments/list"
                            text={`${getEquipmentDescription(
                              result,
                              result.name
                            )} (${result.rentalmanId})`}
                            handleLinkClick={handleLinkClick}
                            eventValueId={result.id}
                            eventValue={`${getEquipmentDescription(
                              result,
                              result.name
                            )} (${result.rentalmanId})`}
                          />
                        ))}
                        {resultData.agenciesResults?.length >=
                          RESULT_NUMBER_PER_CATEGORY &&
                          searchResult.agenciesResults?.length !==
                            resultData.agenciesResults?.length && (
                            <Typography
                              className="search-input__text-pointer"
                              sx={{ color: 'secondary.main' }}
                              onClick={() => {
                                showMore(CATEGORY_AGENCY);
                              }}
                            >
                              <MoreHorizIcon sx={{ color: 'secondary.main' }} />{' '}
                              {`+5 (${resultData.agenciesResults.length}/${searchResult.agenciesResults.length})`}
                            </Typography>
                          )}
                      </Stack>
                    </Stack>
                  )}
                  {['all', 'Contrats'].includes(filterValue) && (
                    <Stack>
                      {resultData.contractsResults?.length > 0 ? (
                        <Typography variant="body1Bold">
                          <Trans>Contrats</Trans>
                        </Typography>
                      ) : null}
                      <Stack>
                        {resultData.contractsResults?.map((result) => (
                          <ResultItem
                            key={`contrat-${result.id}`}
                            machinesListLink="/equipments/list"
                            text={`${result.contractNumber} (${result.purchaseOrderNumber})`}
                            handleLinkClick={handleLinkClick}
                            eventValueId={result.id}
                            eventValue={result.purchaseOrderNumber}
                          />
                        ))}
                        {resultData.contractsResults?.length >=
                          RESULT_NUMBER_PER_CATEGORY &&
                          searchResult.contractsResults?.length !==
                            resultData.contractsResults?.length && (
                            // Add link to machine list with accurate parameter for table filter
                            <Typography
                              className="search-input__text-pointer"
                              sx={{ color: 'secondary.main' }}
                              onClick={() => {
                                showMore(CATEGORY_CONTRACT);
                              }}
                            >
                              <MoreHorizIcon sx={{ color: 'secondary.main' }} />{' '}
                              {`+5 (${resultData.contractsResults.length}/${searchResult.contractsResults.length})`}
                            </Typography>
                          )}
                      </Stack>
                    </Stack>
                  )}
                </>
              )}
            </Stack>
          </Paper>
        </Box>
      )}
    </form>
  );
};

export default SearchInput;

SearchInput.propTypes = {
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  performSearch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  searchResult: PropTypes.object,
  filterValue: PropTypes.string,
};

SearchInput.defaultProps = {
  isLoading: false,
  searchResult: null,
  filterValue: 'all',
};
