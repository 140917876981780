import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import {
  Alert,
  Button,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';

import {
  MobileDateTimePicker,
  pickersLayoutClasses,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import localeDa from 'dayjs/locale/da';
import localeDe from 'dayjs/locale/de';
import localeFr from 'dayjs/locale/fr';
import localeNl from 'dayjs/locale/nl';
import { useCallback, useEffect, useState } from 'react';

const LOCALES_LANGUAGES = {
  fr: localeFr,
  de: localeDe,
  da: localeDa,
  nl: localeNl,
};

const formatToDateTimeWithDaysJs = (date) => {
  return dayjs(moment(date).format('YYYY-MM-DD HH:mm'));
};

const ActionList = (props) => {
  const { onAccept, onCancel, isError, errrorMsg, className } = props;
  const actions = [
    {
      text: t`Annuler`,
      method: onCancel,
      textColor: '#007EB4',
      isError: false,
    },
    { text: t`Ok`, method: onAccept, textColor: '#007EB4', isError },
  ];
  return (
    <List
      className={className}
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '10px 10px',
        gap: '2px',
      }}
    >
      <Stack direction="column" sx={{ width: '100%', gap: '8px' }}>
        {isError && errrorMsg && <Alert severity="warning">{errrorMsg}</Alert>}
        <Stack direction="row" justifyContent="flex-end">
          {actions.map(({ text, method, textColor, isError }) => (
            <ListItem
              key={text}
              sx={{
                width: 'auto',
                padding: '0px',
              }}
            >
              <Button
                variant={isError && 'contained'}
                onClick={method}
                disabled={isError}
                sx={{
                  borderRadius: '4px',
                  padding: '4px 8px',
                }}
              >
                <Typography
                  variant="body1Bold"
                  sx={{
                    color: !isError && textColor,
                    fontSize: '15px !important',
                    textTransform: 'uppercase',
                  }}
                >
                  {text}
                </Typography>
              </Button>
            </ListItem>
          ))}
        </Stack>
      </Stack>
    </List>
  );
};

const CustomMobileDateTimePicker = ({
  label,
  value,
  minValue,
  maxValue,
  isError,
  handleChange,
}) => {
  return (
    <MobileDateTimePicker
      label={label}
      value={formatToDateTimeWithDaysJs(value)}
      ampm={false}
      ampmInClock={false}
      onChange={handleChange}
      minDateTime={minValue && formatToDateTimeWithDaysJs(minValue)}
      maxDateTime={formatToDateTimeWithDaysJs(maxValue)}
      format="DD/MM/YYYY HH:mm"
      renderInput={(params) => <TextField {...params} />}
      slots={{
        actionBar: ActionList,
      }}
      slotProps={{
        layout: {
          sx: {
            [`.${pickersLayoutClasses.toolbar} > span`]: {
              display: 'none',
            },
          },
        },
        actionBar: {
          isError,
          errrorMsg: `${t`La plage maximale est de 6 mois`}.`,
        },
      }}
    />
  );
};

const EquipmentHistoryDateSelection = ({ start, end, setStart, setEnd }) => {
  const [isRangeMax, setIsRangeMax] = useState(false);
  const handleStartDateChange = useCallback((newValue) => {
    const newStart = newValue.$d;
    setStart(newStart);
    // eslint-disable-next-line
  }, []);

  const handleEndDateChange = useCallback((newValue) => {
    const newEnd = newValue.$d;
    setEnd(newEnd);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const maxRange = moment(start).add(6, 'months');
    if (moment(end).isAfter(maxRange)) {
      setIsRangeMax(true);
    } else if (isRangeMax && !moment(end).isAfter(maxRange)) {
      setIsRangeMax(false);
    }
  }, [start, end, isRangeMax]);

  return (
    <Stack direction="row" gap="10px" justifyContent="space-between">
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={LOCALES_LANGUAGES[i18n.locale]}
      >
        <CustomMobileDateTimePicker
          label={t`Début`}
          value={start}
          maxValue={end}
          isError={isRangeMax}
          handleChange={handleStartDateChange}
        />
        <CustomMobileDateTimePicker
          label={t`Fin`}
          value={end}
          minValue={start}
          maxValue={moment()}
          isError={isRangeMax}
          handleChange={handleEndDateChange}
        />
      </LocalizationProvider>
    </Stack>
  );
};

export default EquipmentHistoryDateSelection;
