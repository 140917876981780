import { Period } from '../../../components/EquipmentSecurity/EquipmentSecurity.interface';
import { SecurityAlertProps } from '../../../components/SecurityAlert/SecurityAlert';
import { formatSecurityAlerts } from '../../utils/security/security.utils';

export const setSecurityData = (
  response: any,
  setAlerts: (v: SecurityAlertProps[]) => void,
  setCalendarData: (v: any) => void,
  setPeriod?: (v: Period) => void,
  handleAlertClick?: (alertType: string) => void
) => {
  const { startDate, endDate, jobSiteMainSummary, sessionsDay } = response;
  let alerts = formatSecurityAlerts(jobSiteMainSummary ?? []);

  alerts = alerts.map((alert) => ({
    ...alert,
    handleClick: handleAlertClick
      ? () => handleAlertClick(alert.title)
      : undefined,
  }));

  setAlerts(alerts);
  setCalendarData(sessionsDay?.sessionPeriodAggList);
  // eslint-disable-next-line no-unused-expressions
  setPeriod && setPeriod({ start: startDate, end: endDate });
};

export const setAlertsSecurityData = (
  response: any,
  setAlerts: (v: SecurityAlertProps[]) => void,
  handleAlertClick: (alertType: string) => void
) => {
  const { jobSiteMainSummary } = response;
  let alerts = formatSecurityAlerts(jobSiteMainSummary ?? []);

  alerts = alerts.map((alert) => ({
    ...alert,
    handleClick: () => handleAlertClick(alert.title),
  }));

  setAlerts(alerts);
};
