import moment from 'moment';

export const colorizeDate = (date) => {
  if (date) {
    const lastReport = moment(date);
    const okay = moment().subtract(0, 'days');
    const late = moment().subtract(2, 'days');

    if (lastReport >= okay) {
      return 'green';
    }

    if (lastReport >= late) {
      return 'orange';
    }

    if (lastReport < late) {
      return 'red';
    }
  }

  return 'inherit';
};

export const formatNextDate = (mode, date) => {
  if (mode && date) {
    return moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss');
  }
  return '-';
};

export const formatDate = (date) => {
  if (date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }
  return '-';
};

export const isValidNumber = (number) =>
  typeof number === 'number' && !Number.isNaN(number);

export const displayPercentageOrDefault = (number) =>
  isValidNumber(number) ? `${number.toFixed(2)}%` : '--';
