import { t } from '@lingui/macro';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Typography,
  tableRowClasses,
} from '@mui/material';
import { StyledTableCell } from '../../components/molecules/TableWithPagination/UsedComponentsAndImports';
import IsMobile from '../../utils/IsMobile';
import CockpitRow from './CockpitRow';

const StyledTableRowAlternateColor = styled(TableRow)(
  ({ theme, dark = false }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: '50px',
      backgroundColor: dark ? '#F0EDEE' : theme.palette.background.light,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  })
);

const CockpitTable = ({ tableHeaders, data }) => {
  const { origin } = data;
  const syncCount = data.sync_count;
  const syncPercentage = data.sync_percentage;
  const unsyncCount = data.unsync_count;
  const unsyncPercentage = data.unsync_percentage;
  const totalCount = data.total_machine;
  const unsyncMessage = t`${origin} en retard de synchronisation`;
  const isMobile = IsMobile();
  const totalCellSize = {
    height: '50px !important',
    maxHeight: '50px !important',
    minHeight: '50px !important',
    width: '50px !important',
    maxWidth: '50px !important',
  };

  const createSyncMessage = (manufacturer) => {
    if (manufacturer === 'GOOBIE') {
      return t`${manufacturer} à jour de synchronisation (fréquence 24 heures)`;
    }

    return t`${manufacturer} à jour de synchronisation (fréquence 15 minutes)`;
  };

  return (
    <TableContainer className="table-with-pagination__table">
      <Table aria-label="simple table" stickyHeader>
        {!isMobile && (
          <TableHead className="table-header">
            <TableRow>
              {tableHeaders.map((th, i) => (
                <StyledTableCell key={i}>
                  <Box className="table-header__cell">{th}</Box>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
        )}

        <TableBody className="table-with-pagination__table-body">
          <StyledTableRowAlternateColor>
            <CockpitRow
              origin={origin}
              message={unsyncMessage}
              count={unsyncCount}
              percentage={unsyncPercentage}
              isSync={false}
              error
            />
          </StyledTableRowAlternateColor>
          {!isMobile && (
            <StyledTableRowAlternateColor dark>
              <TableCell align="left" sx={totalCellSize}>
                <Typography variant="body1Bold">Total</Typography>
              </TableCell>
              <TableCell align="left" sx={totalCellSize}>
                <Typography variant="body1Bold">{totalCount}</Typography>
              </TableCell>
              <TableCell align="left" sx={totalCellSize} />
              <TableCell align="left" sx={totalCellSize} />
            </StyledTableRowAlternateColor>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CockpitTable;
