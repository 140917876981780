/* eslint-disable prettier/prettier */
import { Trans, t } from '@lingui/macro';
import { Button, Stack, Typography } from '@mui/material';
import IsMobile from '../../app/utils/IsMobile';
import { getFormatedFullDate } from '../../app/utils/date/format';
import { cellsWrap } from '../../app/utils/table/table';
import {
  colorizeDate,
  formatDate,
  formatNextDate,
} from '../../app/pages/cockpit/utils';
import { useUserContext } from '../../app/contexts/UserContext/UserContext';

const ScanEquipmenRow = ({ machine, openDialogFunction, selectMachine }) => {
  const isMobile = IsMobile();
  const user = useUserContext();
  const isDR = user.mergedProfil === 'DR';

  const handleButtonClick = () => {
    openDialogFunction(true);
    selectMachine(machine);
  };

  let cells;
  if (isMobile) {
    if (isDR) {
      cells = [
        <Stack className="machine-row-mobile">
          <Stack direction="row" spacing={1}>
            <Typography variant="body2Bold">
              {t`Numéro d'équipement`} :{machine?.equipmentNumber || '-'}
            </Typography>
          </Stack>
          {isDR && (
            <Stack direction="row" spacing={1}>
              <Typography variant="body2Bold">
                {t`Agence`} :{machine?.branchCode || '-'}
              </Typography>
            </Stack>
          )}
          <Stack direction="row" spacing={1}>
            <Typography variant="body2Bold">
              <Trans>IMEI</Trans> :{machine?.imei || '-'}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography variant="body2Bold">
              <Trans>Mode</Trans> :{machine?.mode || '-'}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography variant="fontBody">
              <Trans>Mode demandé</Trans> : {machine?.requestedMode || '-'}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography variant="fontBody">
              <Trans>Dernière synchronisation</Trans> :
              {formatDate(machine?.lastReport) || '-'}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography
              variant="fontBody"
              style={{ color: colorizeDate(machine?.nextReport) }}
            >
              <Trans>Prochaine synchronisation</Trans> :
              {formatDate(machine?.nextReport)}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Button variant="contained" onClick={handleButtonClick}>
              <Trans>Déconnecter la machine</Trans>
            </Button>
          </Stack>
        </Stack>,
      ];
    } else {
      cells = [
        <Stack className="machine-row-mobile">
          <Stack direction="row" spacing={1}>
            <Typography variant="body2Bold">
              {t`Numéro d'équipement`} :{machine?.equipmentNumber || '-'}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography variant="body2Bold">
              <Trans>IMEI</Trans> :{machine?.imei || '-'}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography variant="body2Bold">
              <Trans>Mode</Trans> :{machine?.mode || '-'}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography variant="fontBody">
              <Trans>Mode demandé</Trans> : {machine?.requestedMode || '-'}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography variant="fontBody">
              <Trans>Dernière synchronisation</Trans> :
              {getFormatedFullDate(machine?.lastReport) || '-'}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography
              variant="fontBody"
              style={{ color: colorizeDate(machine?.nextReport) }}
            >
              <Trans>Prochaine synchronisation</Trans> :
              {formatNextDate(machine?.mode, machine?.nextReport)}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Button variant="contained" onClick={handleButtonClick}>
              <Trans>Déconnecter la machine</Trans>
            </Button>
          </Stack>
        </Stack>,
      ];
    }
  } else {
    cells = [
      <Typography>{machine?.equipmentNumber || '-'}</Typography>,
      <Typography>{machine?.branchCode || '-'}</Typography>,
      <Typography>{machine?.imei || '-'}</Typography>,
      <Typography>{machine?.mode || '-'}</Typography>,
      <Typography>{machine?.requestedMode || '-'}</Typography>,
      <Typography>
        {getFormatedFullDate(machine?.lastReport) || '-'}
      </Typography>,
      <Typography style={{ color: colorizeDate(machine?.nextReport) }}>
        {formatNextDate(machine?.mode, machine?.nextReport)}
      </Typography>,
      <Button variant="contained" onClick={handleButtonClick}>
        <Trans>Déconnecter la machine</Trans>
      </Button>,
    ];
  }

  if (!isMobile && !isDR) {
    cells.splice(1, 1);
  }

  return cellsWrap(cells);
};

export default ScanEquipmenRow;
