import { t } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import SecurityAlert, {
  SecurityAlertProps,
} from '../../../SecurityAlert/SecurityAlert';

const SecurityAlertsList = ({ alerts }: { alerts: SecurityAlertProps[] }) => {
  if (!alerts.length) {
    return null;
  }

  return (
    <Stack>
      <Typography variant="inherit" fontSize={16} fontWeight={600}>
        {t`Répartition des alertes`}
      </Typography>

      <Typography className="mb-2" variant="subtitle1">
        {t`*Durée en minutes`}
      </Typography>

      <Stack
        direction="row"
        spacing={3}
        justifyContent="space-evenly"
        alignItems="center"
        sx={{
          overflowX: 'auto',
        }}
      >
        {alerts.map((alert) => (
          <SecurityAlert key={alert.title} {...alert} />
        ))}
      </Stack>
    </Stack>
  );
};

export default SecurityAlertsList;
