import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoxForm } from '../../../../components/molecules';
import { useUsersContext } from '../../../../contexts/UsersContext';

import './SettingMembersEditModal.scss';

const roles = [
  { value: 'ROLE_OWNER', label: t`Propriétaire` },
  { value: 'ROLE_ADMIN', label: t`Administrateur` },
  { value: 'ROLE_AGENT', label: t`Agent` },
];

const SettingMembersEditModal = ({ show, toggle, member }) => {
  const getMemberInfo = useCallback(() => {
    return [
      {
        id: 'email',
        value: member.email,
      },
      {
        id: 'firstName',
        value: member.firstName,
      },
      {
        id: 'lastName',
        value: member.lastName,
      },
      {
        id: 'phone',
        value: member.phone,
      },
      {
        id: 'role',
        value: member.role,
      },
    ];
  }, [member]);

  const [initialValues, setInitialValues] = useState(getMemberInfo);
  const { updateUserById } = useUsersContext();

  useEffect(() => {
    if (member) {
      setInitialValues(getMemberInfo);
    }
  }, [getMemberInfo, member]);

  const handleSubmit = (values) => {
    return updateUserById(member.id, values).then(() => {
      toggle();
    });
  };

  return (
    <Dialog
      className="setting.members-edit-modal"
      aria-labelledby="edit-members-modal"
      open={show}
    >
      <DialogTitle className="setting.members-edit-modal__title">
        <Typography variant="h3">
          <Trans>Modifications</Trans>
        </Typography>

        <IconButton
          className="setting.members-edit-modal__icon"
          aria-label="close"
          onClick={toggle}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <LoxForm
          form={{
            fields: [
              {
                disabled: true,
                id: 'email',
                label: t`Adresse Email`,
                type: 'email',
              },
              {
                disabled: true,
                id: 'firstName',
                label: t`Prénom`,
                type: 'text',
              },
              {
                disabled: true,
                id: 'lastName',
                label: t`Nom`,
                type: 'text',
              },
              {
                id: 'phone',
                isRequired: true,
                label: t`Téléphone`,
                type: 'phone',
              },
              {
                label: t`Rôle`,
                type: 'select',
                id: 'role',
                options: roles,
              },
            ],
            initialValues,
          }}
          onSubmit={handleSubmit}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SettingMembersEditModal;

SettingMembersEditModal.propTypes = {
  show: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  member: PropTypes.object.isRequired,
};

SettingMembersEditModal.defaultProps = {
  show: false,
};
