import { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { Paper, Stack, Typography } from '@mui/material';
import { Loading } from '../../../../../lib/apptheme';
import SettingMembersEditModal from '../SettingMembersEditModal/SettingMembersEditModal';
import { TextTable } from '../../../../components/molecules';
import { Pagination } from '../../../../components/atoms';
import { useUsersContext } from '../../../../contexts/UsersContext';

import './SettingsMembers.scss';

const PAGE_SIZE = 5;

const roles = {
  ROLE_OWNER: t`Propriétaire`,
  ROLE_ADMIN: t`Administrateur`,
  ROLE_AGENT: t`Agent`,
};

const SettingsMembers = () => {
  const [isEditMemberModalVisible, setIsEditMemberModalVisible] =
    useState(false);

  const [page, setPage] = useState(1);
  const [member, setMember] = useState(null);

  const { isLoading, usersNumber, getUsersMap, getUserByEmail } =
    useUsersContext();

  const [rows, setRows] = useState([[]]);

  const toggleEditMemberModal = () => {
    setIsEditMemberModalVisible(!isEditMemberModalVisible);
  };

  const handleItemEdit = (row) => {
    setMember(getUserByEmail(row[2]));
    toggleEditMemberModal();
  };

  useEffect(() => {
    setRows(
      getUsersMap(page - 1, PAGE_SIZE).map((item) => [
        item.lastName,
        item.firstName,
        item.email,
        item.phone,
        roles[item.role],
      ])
    );
  }, [getUsersMap, page]);

  return (
    <Paper className="settings-members">
      <Stack spacing={2}>
        <Typography variant="h3" color="primary.main">{t`Membres`}</Typography>
        {isLoading ? (
          <Loading />
        ) : (
          <TextTable
            headers={[
              t`Nom`,
              t`Prénom`,
              t`Adresse Email`,
              t`Téléphone`,
              t`Rôle`,
            ]}
            editItem
            rows={rows}
            emptyText={t`Aucune alertes`}
            onItemEdit={handleItemEdit}
          />
        )}
        <Stack direction="row" justifyContent="space-around">
          <Pagination
            totalItemsCount={usersNumber}
            page={page}
            itemsCountPerPage={PAGE_SIZE}
            onChange={(event, selectedPage) => setPage(selectedPage)}
          />
        </Stack>

        {member && (
          <SettingMembersEditModal
            show={isEditMemberModalVisible}
            toggle={toggleEditMemberModal}
            member={member}
          />
        )}
      </Stack>
    </Paper>
  );
};

export default SettingsMembers;
