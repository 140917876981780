import { Machine } from '../../../../../../../api/machines/Machine.interface';

interface OptionData {
  label: string;
  onClick: () => void;
}

interface Filter {
  data: OptionData[];
  index: number;
  value: string;
  isSelector: boolean;
}

const labelOptionFormat = (list: any[]): OptionData[] => {
  return list.map((label) => ({
    ...label,
    onClick: () => null,
  }));
};

export const formatOptionObject = (data: any) => {
  return {
    data: {
      machines: {
        data,
      },
    },
  };
};

export const OPTIONS_KEYS = {
  jobSites: 'jobSitesMach',
  contracts: 'contractsMach',
  agencies: 'agenciesMach',
  typeJobSite: 'Chantier',
  typeAgency: 'Agence',
  typeContract: 'Bon de commande',
};

export const getOtherOptions = (type: string) => {
  switch (type) {
    case OPTIONS_KEYS.jobSites: {
      return [OPTIONS.contract, OPTIONS.agency];
    }
    case OPTIONS_KEYS.contracts: {
      return [OPTIONS.jobSite, OPTIONS.agency];
    }
    case OPTIONS_KEYS.agencies: {
      return [OPTIONS.jobSite, OPTIONS.contract];
    }
    default: {
      return [OPTIONS.contract, OPTIONS.agency];
    }
  }
};

/*
 * Create the object used on the Autocomplete component
 * The result will be an object with the properties id and label
 */
export const optionFilterInit = (
  filters: any,
  state: any,
  type: string
): Filter => {
  let data: any[] = [];
  let index = 0;
  const optionFilter = filters.get[type];
  let structuredOptions = [];

  switch (type) {
    case 'Agence': {
      structuredOptions = state.data.machines.data
        .filter(
          (x: any) => x && (x.agencyId || x.id) && (x.agencyName || x.name)
        )
        .map((m: Machine) => ({
          id: m.agencyId || m.id,
          label: m.agencyName || m.name,
        }));
      break;
    }
    case 'Chantier': {
      structuredOptions = state.data.machines.data
        .filter(
          (x: any) => x && (x.jobSiteId || x.id) && (x.jobSiteDesc || x.name)
        )
        .map((m: Machine) => ({
          id: m.jobSiteId || m.id,
          label: `${m.jobSiteDesc || m.name} ${
            m.rentalmanId ? `(${m.rentalmanId})` : ``
          }`,
        }));
      break;
    }
    case 'Bon de commande': {
      structuredOptions = state.data.machines.data
        .filter(
          (x: any) => x && (x.purchaseOrderNumber || x.contractNumber) && x.id
        )
        .map((m: Machine) => {
          return {
            id: m.contractId || m.id,
            label: `${m.purchaseOrderNumber || m.contractNumber} ${
              m.rentalmanId ? `(${m.rentalmanId})` : ``
            }`,
          };
        });
      break;
    }
    default: {
      console.log('Error', type);
    }
  }

  const uniqueOptions = Array.from(
    new Map(structuredOptions.map((item: any) => [item.id, item])).values()
  );

  data = labelOptionFormat([
    {
      id: 'All',
      label: 'All',
    },
    ...uniqueOptions,
  ]);

  if (optionFilter) {
    const indexOf = data.map((i) => i.label).indexOf(optionFilter.values[0]);
    index = indexOf > 0 ? indexOf : 0;
  }

  const uniqueCleanData = Array.from(new Set(data)).filter((opt: any) =>
    opt.label.match(/[a-zA-Z0-9]/g)
  );

  return {
    data: uniqueCleanData,
    index,
    value: type,
    isSelector: true,
  };
};

export const OPTIONS = {
  jobSite: {
    name: OPTIONS_KEYS.jobSites,
    filterFunc: optionFilterInit,
    value: 'jobSites',
    type: 'Chantier',
  },
  contract: {
    name: OPTIONS_KEYS.contracts,
    filterFunc: optionFilterInit,
    value: 'contracts',
    type: 'Bon de commande',
  },
  agency: {
    name: OPTIONS_KEYS.agencies,
    filterFunc: optionFilterInit,
    value: 'agencies',
    type: 'Agence',
  },
};

export const machineStatesInit = (
  headersCheckbox: any,
  filtersObject: any,
  filters: any,
  setData: any
) => {
  const resp: any = {};
  const machStates = headersCheckbox
    .filter((f: any) => f.name === 'Etat')[0]
    .keys.sort();
  machStates.forEach((k: any) => {
    resp[k] = {
      text: k,
      value: [...filters.get.Etat.values].includes(k),
      isRunning: headersCheckbox.filter((f: any) => f.name === 'Etat')[0]
        .keysNamesValues[k],
    };
  });
  const selectors: any = {};
  Object.keys(filtersObject).forEach((el) => {
    if (filtersObject[el].isSelector && !filtersObject[el].hide) {
      Object.keys(filters.get).forEach((f) => {
        if (JSON.stringify(f) === JSON.stringify(filtersObject[el].value)) {
          const newIndex = filtersObject[el].data
            .map((d: any) => d.label)
            .indexOf(filters.get[f].values[0]);

          selectors[el] = {
            ...filtersObject[el],
            index: newIndex,
          };
        }
      });
    }
  });

  setData(resp);
};
