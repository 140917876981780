import { t } from '@lingui/macro';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import classNames from 'classnames';
import CustomizedSwitches from '../../../../../components/CustomizedSwitches/CustomizedSwitches';
import { ErrorMessage } from '../../../../../lib/apptheme/partials';
import IsMobile from '../../../../utils/IsMobile';
import FleetMapAll from '../../Map/FleetMapAll/FleetMapAll';
import SingleEquipmentMap from '../../Map/SingleEquipmentMap/SinglEquipmentMap';

import './JobSiteCarte.scss';

interface JobSiteCarteProps {
  jobsite: any;
  multipleLocation: any;

  jobsiteRow: any[];
  machinesRow: any[];
  agenciesRow: any[];
  zonePoints: any[];

  displayZone: boolean;
  drawingZone: boolean;

  setZonePoints: (values: any[]) => void;
  setDisplayZone: (value: boolean) => void;

  handleEquipmentFilter: (
    toggleValue: boolean,
    equipmentType: 'agency' | 'machine'
  ) => void;

  handleMarkerDrag: (coords: { lat: number; long: number }) => void;
}

const JobSiteCarte = ({
  jobsite,
  multipleLocation,
  jobsiteRow,
  machinesRow,
  agenciesRow,
  displayZone,
  drawingZone,
  zonePoints,
  setZonePoints,
  setDisplayZone,
  handleEquipmentFilter,
  handleMarkerDrag,
}: JobSiteCarteProps) => {
  const isMobile = IsMobile();
  return (
    <Box className="jobsite-carte">
      {!jobsite.lon && !jobsite.lat && !multipleLocation.oneChecked ? (
        <ErrorMessage message={t`Aucune Coordonnée`} />
      ) : (
        <>
          <Stack
            className={classNames('jobsite-carte__switches-header', {
              'jobsite-carte__switches-header--mobile-view': isMobile,
            })}
            direction={!isMobile ? 'row' : 'column'}
            spacing={!isMobile ? '10px' : '0px'}
          >
            {agenciesRow.length > 0 && (
              <CustomizedSwitches
                text={t`Afficher les agences`}
                onToggle={(value: boolean) =>
                  handleEquipmentFilter(value, 'agency')
                }
              />
            )}

            {machinesRow.length > 0 && (
              <CustomizedSwitches
                text={t`Afficher les machines`}
                onToggle={(value: boolean) =>
                  handleEquipmentFilter(value, 'machine')
                }
              />
            )}

            <CustomizedSwitches
              text={
                multipleLocation.oneChecked
                  ? t`Afficher les zones`
                  : t`Afficher la zone`
              }
              value={displayZone}
              onToggle={setDisplayZone}
            />
          </Stack>
          {multipleLocation.oneChecked ? (
            <FleetMapAll
              locations={jobsiteRow}
              displayZone={displayZone}
              hideZoneForIds={['Machines']}
              mapClassName="jobsite-carte__map"
              handleMarkerDrag={handleMarkerDrag}
            />
          ) : (
            <SingleEquipmentMap
              lon={jobsite.lon}
              lat={jobsite.lat}
              equipment={jobsite}
              displayZone={displayZone}
              type="Chantiers"
              drawingZone={drawingZone}
              zonePoints={zonePoints}
              setZonePoints={setZonePoints}
              isDraggable={!!jobsite.isDraggable}
              handleMarkerDrag={handleMarkerDrag}
              mapClassName="jobsite-carte__map"
            />
          )}
        </>
      )}
    </Box>
  );
};

export default JobSiteCarte;
