import { Field as FieldFormik } from 'formik';
import * as yup from 'yup';

// Return the validate function generated with
const handleValidate = (required, validationSchema) => {
  return (value) => {
    let schema =
      validationSchema ||
      (typeof value === 'string' ? yup.string() : yup.mixed());

    if (required) {
      schema = schema.required('Le champ est obligatoire');
    }

    return schema
      .validate(value)
      .then(() => {})
      .catch((error) => error.message);
  };
};

const Field = ({ component, required, validationSchema, ...props }) => (
  <FieldFormik
    component={component}
    {...props}
    validate={handleValidate(required, validationSchema)}
  />
);

export default Field;
