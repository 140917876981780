import { useState } from 'react';
import Paper from '@mui/material/Paper';
import { t } from '@lingui/macro';
import MachineGroupList from './list/MachineGroupList';
import MachineGroupCreate from './create/MachineGroupCreate';
import { updateSite, deleteSite } from '../../api/jobsites/JobSites';
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';

import './MachineGroup.scss';

const MachineGroup = () => {
  const [view, setView] = useState('list');
  const [machGrp, setMachGrp] = useState([]);
  const [machGrpId, setMachGrpId] = useState('');

  const [deleteError, setDeleteError] = useState('');

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  let component;

  const handleClick = (id, newView, machs) => {
    if (newView === 'delete') {
      setMachGrp(machs);
      setOpenDeleteDialog(true);
    }

    setMachGrpId(id);
    setView(newView);
  };

  const deleteMachGrp = async () => {
    // why we need update before delete ?
    if (machGrp.machines.length > 0) {
      const postData = {
        name: machGrp.name,
        description: machGrp.description,
        enabled: true,
        users: [],
        machines: [],
      };

      await updateSite(machGrpId, postData).catch(() => {
        setDeleteError(
          t`Erreur lors de la suppression d'un groupe de machines.`
        );
      });
    }

    deleteSite(machGrpId)
      .then(() => {
        setView('list');
        setOpenDeleteDialog(false);
      })
      .catch(() => {
        setDeleteError(
          t`Erreur lors de la suppression d'un groupe de machines.`
        );
      });
  };

  const renderComponent = () => {
    switch (view) {
      case 'list':
        return (
          <MachineGroupList
            onAdd={() => setView('create')}
            onItemClick={handleClick}
            updateList={view !== 'list'}
          />
        );
      case 'create':
        return (
          <MachineGroupCreate
            setView={setView}
            title={t`Création d'un groupement de machines`}
          />
        );

      case 'update':
        return (
          <MachineGroupCreate
            setView={setView}
            title={t`Modifier un groupement de machines`}
            machGrpId={machGrpId}
            isUpdating
          />
        );

      case 'read':
        return (
          <MachineGroupCreate
            setView={setView}
            title={t`Groupement de machines`}
            machGrpId={machGrpId}
            isReading
          />
        );

      default:
        return (
          <MachineGroupList
            onAdd={() => setView('create')}
            onItemClick={handleClick}
          />
        );
    }
  };

  return (
    <Paper className="machine-group">
      {renderComponent()}

      <ConfirmDialog
        title={`${t`Voulez-vous vraiment supprimer le groupe de la machine`} '${
          machGrp.name
        }' ?`}
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
          setDeleteError('');
        }}
        onConfirm={deleteMachGrp}
        errorMessage={deleteError}
      />
    </Paper>
  );
};

export default MachineGroup;
