import { t } from '@lingui/macro';
import {
  Autocomplete,
  Box,
  Button,
  Input,
  TextField,
  Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';

import { colorizeDate } from '../../utils';

const CockpitGoobieInformationMobile = ({
  goobie,
  updateImeiData,
  isPosting,
  setIsPosting,
  resultMessage,
  setResultMessage,
  setGoobieData,
  formatDate,
  formatNextDate,
}) => {
  const {
    id,
    tagNFC,
    imei,
    associationDate,
    machine,
    battery,
    agency,
    lastReport,
    nextReport,
    mode,
    modeRequested,
    latitude,
    longitude,
  } = goobie;

  const submitData = async (newValues) => {
    setIsPosting(true);
    setResultMessage('');
    const data = {
      id,
      imei,
      machine: newValues.machine || null,
      mode: newValues.modeRequested,
    };

    try {
      const result = await updateImeiData(data);
      if (result) {
        setResultMessage(
          t`L'étiquette a été mise à jour avec succès. Redirection vers la page d'administration...`
        );
        setTimeout(() => {
          window.location.replace('/cockpit/goobies/administration');
        }, 5000);
      } else {
        setResultMessage(
          t`Erreur de mise à jour de la balise, veuillez réessayer plus tard.`
        );
      }
    } catch (e) {
      if (e.response.data.message) {
        if (e.response.data.message === 'EQUIPMENT_NOT_EXIST_IN_RENTALMAN') {
          setResultMessage(t`EQUIPMENT_NOT_EXIST_IN_RENTALMAN`);
        } else {
          setResultMessage(e.response.data.message);
        }
      } else {
        setResultMessage(e.response.data);
      }
    }
    setIsPosting(false);
  };

  return (
    <Formik
      initialValues={{ machine, modeRequested }}
      onSubmit={(values) => {
        submitData(values);
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <Typography>
            <b className="cockpit-goobie__list-title">{t`Machine`}</b>
            <Field
              name="machine"
              component={Input}
              value={values.machine}
              variant="filled"
              hiddenLabel
              style={{ display: 'inline-block', width: '200px' }}
              onChange={(e) => setFieldValue('machine', e.target.value)}
            />
          </Typography>
          <Typography>
            <b className="cockpit-goobie__list-title">{t`Agence`}</b>
            {agency || '-'}
          </Typography>
          <Typography>
            <b className="cockpit-goobie__list-title">{t`IMEI`}</b>
            {imei || '-'}
          </Typography>
          <Typography>
            <b className="cockpit-goobie__list-title">{t`Tag NFC`}</b>
            {tagNFC || '-'}
          </Typography>
          <Typography>
            <b className="cockpit-goobie__list-title">{t`Date d'association`}</b>
            {formatDate(associationDate)}
          </Typography>
          <Typography>
            <b className="cockpit-goobie__list-title">{t`Mode actuel`}</b>
            {mode || '-'}
          </Typography>
          <Typography>
            <b className="cockpit-goobie__list-title">{t`Mode demandé`}</b>
            <Field
              name="modeRequested"
              value={values.modeRequested}
              as="select"
              variant="filled"
              hiddenLabel
              style={{ display: 'inline-block', width: '200px' }}
              data-testid="select-mode-requested"
            >
              <option value="">{t`Sélectionner un mode`}</option>
              <option value="1">{t`Stockage`}</option>
              <option value="3">{t`Service`}</option>
              <option value="4">{t`Traçabilité`}</option>
            </Field>
          </Typography>
          <Typography>
            <b className="cockpit-goobie__list-title">{t`Dernier rapport`}</b>
            {formatDate(lastReport)}
          </Typography>
          <Typography>
            <b className="cockpit-goobie__list-title">{t`Prochain rapport`}</b>
            <span style={{ color: colorizeDate(nextReport) }}>
              {formatNextDate(mode, nextReport)}
            </span>
          </Typography>
          <Typography>
            <b className="cockpit-goobie__list-title">{t`Latitude`}</b>
            {latitude || '-'}
          </Typography>
          <Typography>
            <b className="cockpit-goobie__list-title">{t`Longitude`}</b>
            {longitude || '-'}
          </Typography>
          <Typography>
            <b className="cockpit-goobie__list-title">{t`Autonomie de la batterie`}</b>
            {battery || '-'}
          </Typography>
          <Box className="cockpit-goobie__actions">
            <Button
              variant="contained"
              type="submit"
              disabled={isPosting || !values.modeRequested}
              data-testid="send-button"
            >
              {t`Valider les modifications`}
            </Button>
            <Typography>
              <b>{resultMessage}</b>
            </Typography>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default CockpitGoobieInformationMobile;
