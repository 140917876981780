import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/system/useTheme';

const IsMobile = () => {
  const theme = useTheme();

  if (!theme) {
    return false;
  }

  return useMediaQuery(theme.breakpoints.down('sm'));
};

export default IsMobile;
