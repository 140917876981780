import { t, Trans } from '@lingui/macro';
import Typography from '@mui/material/Typography';
import { calculFullHoursFromDuration } from '../../../../utils/date/calcul';

interface SecurityGraphTotalTitleProps {
  alerts: any[];
  graphDisplayInMinutes: boolean;
}

const SecurityGraphTotalTitle = ({
  alerts,
  graphDisplayInMinutes,
}: SecurityGraphTotalTitleProps) => {
  const alertsTotalDuration = alerts.reduce(
    (prevValue: number, value: number) => prevValue + value,
    0
  );

  const { hours, minutes } = calculFullHoursFromDuration(
    graphDisplayInMinutes
      ? alertsTotalDuration * 60
      : alertsTotalDuration * 3600
  );

  const alertsDurationFormatted = graphDisplayInMinutes
    ? Number(hours) * 60 + Number(minutes)
    : `${hours}:${minutes}`;

  const graphTotalText = graphDisplayInMinutes ? t`minutes` : t`heures`;

  return (
    <Typography variant="h4">
      {`${t`Total`}: ${alertsDurationFormatted} `}
      <Trans id={graphTotalText} />
    </Typography>
  );
};

export default SecurityGraphTotalTitle;
