import Box from '@mui/material/Box';
import { useOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Loading } from '../../../lib/apptheme';
import { AgenciesProvider } from '../../contexts/AgenciesContext';
import { ContractsProvider } from '../../contexts/ContractsContext';
import { JobSitesProvider } from '../../contexts/JobSitesContext';
import { MachinesProvider } from '../../contexts/MachinesContext';
import { useUserContext } from '../../contexts/UserContext/UserContext';
import { useAppModeContext } from '../../utils/hooks';
import { PlainAppNavBar } from '../molecules';
import {
  getInternalAgencies,
  getInternalContracts,
  getInternalJobSites,
  getInternalMachines,
} from './MainContainer.service';

import './MainContainer.scss';

/**
 * @param {object} children - React component
 * @param {Array} mobileLinks - Array for the mobile version
 * @param {Array} desktopLinks - Array for the desktop version
 */
const MainContainer = ({ children, mobileLinks, desktopLinks }) => {
  const { isInternalAppMode } = useAppModeContext();
  const user = useUserContext();
  const location = useLocation();
  const root = location.pathname && location.pathname.split('/')[1];
  const okta = useOktaAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [agencies, setAgencies] = useState();
  const [contracts, setContracts] = useState();
  const [jobSites, setJobSites] = useState();
  const [machines, setMachines] = useState();

  useEffect(() => {
    if (okta && isInternalAppMode) {
      getInternalAgencies(user.email).then((values) => setAgencies(values));
      getInternalContracts(user.email).then((values) => setContracts(values));
      getInternalJobSites(user.email).then((values) => setJobSites(values));
      getInternalMachines(user.email).then((values) => setMachines(values));
    } else {
      setIsLoading(false);
    }
  }, [isInternalAppMode, okta, user.email]);

  useEffect(() => {
    if (agencies && contracts && jobSites && machines) {
      setIsLoading(false);
    }
  }, [agencies, contracts, jobSites, machines]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <MachinesProvider machines={machines}>
      <JobSitesProvider jobsites={jobSites}>
        <AgenciesProvider agencies={agencies}>
          <ContractsProvider contracts={contracts}>
            <Box className="main-container">
              <Box>
                <PlainAppNavBar
                  pathName={root || location.pathname}
                  mobileLinks={mobileLinks}
                  desktopLinks={desktopLinks}
                />
              </Box>
              <Box className="main-container__content">{children}</Box>
            </Box>
          </ContractsProvider>
        </AgenciesProvider>
      </JobSitesProvider>
    </MachinesProvider>
  );
};

export default MainContainer;

MainContainer.propTypes = {
  children: PropTypes.node,
  mobileLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string,
      icon: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  desktopLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string,
      icon: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
};

MainContainer.defaultProps = {
  children: null,
};
