import { useEffect, useReducer } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';

import './SelectOptions.scss';

const reducer = (state, action) => {
  switch (action.type) {
    case 'ON_SELECT_VALUE':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const SelectOptions = ({
  options,
  defaultIndex = 0,
  updateParentIndex,
  className = '',
}) => {
  const [state, dispatch] = useReducer(reducer, {
    value: options[defaultIndex]?.label || options[0]?.label,
    index: defaultIndex,
  });

  const handleChange = (event) => {
    const index = options.map((e) => e.label).indexOf(event.target.value);

    dispatch({
      type: 'ON_SELECT_VALUE',
      payload: { value: event.target.value, index },
    });

    if (updateParentIndex) {
      updateParentIndex(
        options.map((e) => e.label).indexOf(event.target.value)
      );
    }
  };

  useEffect(() => {
    dispatch({
      type: 'ON_SELECT_VALUE',
      payload: {
        value: options[defaultIndex]?.label || options[0]?.label,
        index: defaultIndex,
      },
    });
  }, [defaultIndex, options]);

  return (
    <Box className={`select-options ${className}`}>
      <FormControl fullWidth variant="filled">
        {state && (
          <Select
            className="select-options__select"
            labelId="simple-select-label"
            id="simple-select"
            value={state.value}
            onChange={handleChange}
            sx={{
              backgroundColor: 'light.bgcolor',
              '&:before': {
                borderColor: 'primary.main',
              },
              '&:after': {
                borderColor: 'primary.main',
              },
              '& > svg': {
                color: 'primary.main',
              },
            }}
            inputProps={{
              classes: {
                icon: {
                  fill: 'primary.main',
                },
              },
            }}
          >
            {options.map((el) => {
              return (
                <MenuItem
                  key={el.label}
                  value={el.label}
                  onClick={el.label !== state.value && el.onClick}
                  disabled={el.disabled}
                >
                  {el.label}
                </MenuItem>
              );
            })}
          </Select>
        )}
      </FormControl>
    </Box>
  );
};

export default SelectOptions;

SelectOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  defaultIndex: PropTypes.number,
  updateParentIndex: PropTypes.func,
};

SelectOptions.defaultProps = {
  defaultIndex: 0,
  updateParentIndex: null,
};
