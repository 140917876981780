/* eslint-disable prettier/prettier */
import { t } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import GraphLegend from '../../app/components/organisms/MuiCustomCalendar/GraphLegend/GraphLegend';
import MuiCustomCalendar from '../../app/components/organisms/MuiCustomCalendar/MuiCustomCalendar';
import { calendarTypeOptions } from '../../app/components/organisms/MuiCustomCalendar/utils';
import SecurityGraphTotalTitle from '../../app/pages/Security/components/SecurityGraphTotalTitle/SecurityGraphTotalTitle';
import {
  asyncDataCallAndLogic,
  getChartArray,
  getGraphLabels,
} from '../../app/pages/equipments/EquipmentDetail/EquipmentDetail.utils';
import colors from '../../app/theme/_colors.scss';
import { checkSecurityGraphDisplayInMinutes } from '../../app/utils/security/security.utils';
import { Loading } from '../../lib/apptheme';
import { SecurityAlertProps } from '../SecurityAlert/SecurityAlert';
import {
  EquipmentSecurityProps,
  GraphUsageDataset,
  MachineUsageProps,
  Period,
} from './EquipmentSecurity.interface';
import {
  getAlertsGraphFilters,
  getAlertsGraphNumbers,
} from './EquipmentSecurity.utils';
import ChartBarAndAreaGraph from './components/ChartBarAndAreaGraph/ChartBarAndAreatGraph';
import SecurityAlertsList from './components/SecurityAlertsList/SecurityAlertsList';
import SecurityHeader from './components/SecurityHeader/SecurityHeader';
import SecurityTable from './components/SecurityTable/SecurityTable';
import { CellsInterface } from './components/SecurityTable/SecurityTable.interface';

const EquipmentSecurity = <T extends CellsInterface>({
  id,
  clientsOptions,
  jobSiteName,
  headersTable,
  metricsCallBack,
  metricsTableCallBack,
  GenerateTableCells,
}: EquipmentSecurityProps<T>) => {
  const [alerts, setAlerts] = useState<SecurityAlertProps[]>();
  const [calendarData, setCalendarData] = useState<any[]>();
  const [machineUsage, setMachineUsage] = useState([]);
  const [mchUsgGraphData, setMchUsgGraphData] = useState<MachineUsageProps>();

  const [dataTable, setDataTable] = useState<T[]>([]);
  const [periodType, setPeriodType] = useState(0);
  const [selectedDate, setSelectedDate] = useState<Date | null>();

  const [customerNumberSelected, setCustomerNumberSelected] = useState<
    number | null
  >(null);
  const [period, setPeriod] = useState<Period | null>(null);
  const [graphDisplayInMinutes, setGraphDisplayInMinutes] = useState(true);

  const selectedCalendarFilter = calendarTypeOptions(() => {})[periodType]
    .value;

  const maxYValue = graphDisplayInMinutes
    ? 60
    : selectedCalendarFilter === 'month'
    ? 40
    : 8;

  const hasGraphData =
    mchUsgGraphData?.alertsNumbers.find((i: number) => i !== 0) ||
    mchUsgGraphData?.alertsDuration.find((i: number) => i !== 0);

  useEffect(() => {
    metricsCallBack(customerNumberSelected).then(
      ({ alerts, calendarData, period }) => {
        setAlerts(alerts);
        setCalendarData(calendarData);
        setPeriod(period);
      }
    );

    metricsTableCallBack(customerNumberSelected).then((data) =>
      setDataTable(data)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //  }, [customerNumberSelected]); causing double render and for the moment we don't display the customer selector

  useEffect(() => {
    // machine utilisation usage request
    // eslint-disable-next-line no-unused-expressions
    id &&
      period &&
      asyncDataCallAndLogic({
        id,
        customerNumber: customerNumberSelected,
        startDate: period.start,
        endDate: period.end,
        setMachineUsage,
      });
  }, [id, period, customerNumberSelected]);

  useEffect(() => {
    if (selectedDate) {
      const begin = moment(selectedDate).startOf(selectedCalendarFilter);
      const end = moment(selectedDate).endOf(selectedCalendarFilter);

      let usage: GraphUsageDataset[] = [];

      const alertsFilters = getAlertsGraphFilters(calendarData, begin, end);
      const labelToUse = getGraphLabels(selectedCalendarFilter, end);
      const arrAlertsNumbers = getAlertsGraphNumbers(
        alertsFilters,
        labelToUse,
        selectedCalendarFilter
      );

      const machineUsageFilter = machineUsage?.filter(
        (f: any) =>
          moment(f.day) >= begin &&
          moment(f.day) <= end &&
          f?.sessionsByHourList &&
          f?.sessionsByHourList.length > 0
      );

      const isAlertsUnderOneHours = checkSecurityGraphDisplayInMinutes(
        alertsFilters,
        selectedCalendarFilter
      );

      setGraphDisplayInMinutes(isAlertsUnderOneHours);

      if (machineUsageFilter?.length > 0) {
        let arrUsageOutOfTime = [];
        let arrUsageInTime = [];

        arrUsageInTime = getChartArray(
          labelToUse,
          machineUsageFilter,
          selectedCalendarFilter,
          false,
          isAlertsUnderOneHours
        );
        arrUsageOutOfTime = getChartArray(
          labelToUse,
          machineUsageFilter,
          selectedCalendarFilter,
          true,
          isAlertsUnderOneHours
        );

        const usageOutOfContract = {
          label: t`Utilisation hors contrat`,
          data: arrUsageOutOfTime,
          backgroundColor: colors.imperialRed,
        };
        const usageInContract = {
          label: t`Utilisation horaires contrats`,
          data: arrUsageInTime,
          backgroundColor: colors.blueJeans,
        };

        usage = [usageInContract, usageOutOfContract];
      }

      const arrAlertsDuration = getChartArray(
        labelToUse,
        alertsFilters,
        selectedCalendarFilter,
        true,
        isAlertsUnderOneHours,
        true
      );

      setMchUsgGraphData({
        labels: labelToUse.map((m: any) => m.value),
        datasets: usage,
        alertsNumbers: arrAlertsNumbers,
        alertsDuration: arrAlertsDuration.map((x: any) =>
          x.length > 0 ? x[1] : 0
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineUsage, selectedCalendarFilter, selectedDate]);

  if (alerts === undefined || calendarData === undefined) {
    return <Loading />;
  }

  if (alerts?.length === 0 && calendarData?.length === 0) {
    return (
      <>
        <Typography sx={{ color: 'primary.main' }} variant="h3">
          {t`Sécurité`}
        </Typography>

        <Stack className="equipment-detail__no-data">
          <Typography variant="h5">
            {t`Aucune information disponible`}
          </Typography>
        </Stack>
      </>
    );
  } 

  return (
    <Stack spacing={3} width="100%">
      <SecurityHeader
        clientsOptions={clientsOptions}
        startDate={period ? new Date(period.start) : undefined}
        endDate={period ? new Date(period.end) : undefined}
        jobSiteName={jobSiteName}
        handleClientChange={setCustomerNumberSelected}
        periodType={periodType}
        handlePeriodType={setPeriodType}
      />

      <SecurityAlertsList alerts={alerts ?? []} />

      {period && (
        <>
          <MuiCustomCalendar
            startDate={new Date(period.start)}
            endDate={new Date(period.end)}
            data={calendarData ?? []}
            isSecurity
            setSelectedDate={setSelectedDate}
            periodType={periodType}
            handlePeriodType={setPeriodType}
          />

          <GraphLegend
            legends={[
              {
                color: colors.americanOrange,
                description: t`Présence d’alerte`,
              },
              {
                color: colors.brightGray,
                description: t`Durée de location`,
              },
            ]}
          />
        </>
      )}

      {hasGraphData && (
        <Stack sx={{ width: '80%', alignSelf: 'center' }}>
          <SecurityGraphTotalTitle
            alerts={mchUsgGraphData?.alertsDuration}
            graphDisplayInMinutes={graphDisplayInMinutes}
          />

          <ChartBarAndAreaGraph
            labels={mchUsgGraphData?.labels}
            maxYValue={maxYValue}
            numbersOfAlerts={mchUsgGraphData?.alertsNumbers}
            alertsDuration={mchUsgGraphData?.alertsDuration}
            usageOnContract={mchUsgGraphData?.datasets
              .filter(
                (x: any) => x.label === 'Utilisation horaires contrats'
              )[0]
              ?.data.map((x: any) => x.map((y: any) => y))}
            usageOutOfContract={mchUsgGraphData?.datasets
              .filter((x: any) => x.label === 'Utilisation hors contrat')[0]
              ?.data.map((x: any) => x.map((y: any) => y))}
          />

          <GraphLegend
            legends={[
              {
                color: colors.blueJeans,
                description: 'Utilisation horaires contrats',
              },
              {
                color: colors.jasper,
                description: 'Utilisation hors contrat',
              },
              {
                color: colors.americanOrange,
                description: 'Alerte',
              },
            ]}
          />
        </Stack>
      )}

      <SecurityTable
        data={dataTable}
        headers={headersTable}
        GenerateCells={GenerateTableCells}
      />
    </Stack>
  );
};

export default EquipmentSecurity;
