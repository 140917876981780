import { useMemo } from 'react';
import { t } from '@lingui/macro';
import { Stack, Divider, Typography } from '@mui/material';
import IsMobile from '../../../../../utils/IsMobile';
import { useEquipmentPageStateValue } from '../../Equipment.state';
import { CURRENT } from '../../../../../components/molecules/ButtonPositionsView/ButtonPositionsView.constants';
import { keysName } from '../../../constants';

const allTypesDesc = (data, isMobile, isCurrentView) => {
  const machinesTotal = isCurrentView
    ? `${t`Nombre de machines`} : ${data.nbMachines}`
    : `${t`Nombre de goobies`} : ${data.nbGoobies}`;
  const agenciesTotal = `${t`Nombre d'agences`} : ${data.nbAgencies}`;

  return isMobile ? (
    <>
      <Typography>{machinesTotal}</Typography>
      <Typography>{agenciesTotal}</Typography>
    </>
  ) : (
    <Typography>{`${machinesTotal} | ${agenciesTotal}`}</Typography>
  );
};

const totalMachineDesc = (data, isMobile, isCurrentView) => {
  const { totalMachines, totalElements } = data;
  const machinesTotal = isCurrentView
    ? `${t`Nombre de machines`} : ${totalMachines}`
    : `${t`Nombre de goobies`} : ${totalElements}`;

  return isMobile ? (
    <>
      <Typography>{machinesTotal}</Typography>
    </>
  ) : (
    <Typography>{machinesTotal}</Typography>
  );
};

const machinesDesc = (data, isMobile, isCurrentView) => {
  const machinesTotal = isCurrentView
    ? `${t`Nombre de machines`} : ${data.length}`
    : `${t`Nombre de goobies`} : ${data.length}`;

  return isMobile ? (
    <>
      <Typography>{machinesTotal}</Typography>
    </>
  ) : (
    <Typography>{machinesTotal}</Typography>
  );
};

const DataDescription = (state) => {
  const isMobile = IsMobile();
  const { currentView, stats } = useEquipmentPageStateValue();
  const isCurrentView = useMemo(() => currentView === CURRENT, [currentView]);

  if (!state && !state.value) {
    return null;
  }

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      spacing={0}
      divider={<Divider orientation="vertical" flexItem />}
    >
      {state?.value === keysName.SeeAll &&
        allTypesDesc(state.dataAll, isMobile, isCurrentView)}

      {state?.value === keysName.Machines &&
        totalMachineDesc(stats, isMobile, isCurrentView)}

      {state?.value === keysName.Agencies && (
        <Typography>
          {`${t`Nombre d'agences`} : ${state.data.agencies.length}`}
        </Typography>
      )}

      {state?.equipment?.value === keysName.Machines &&
        machinesDesc(state.visibleRows, isMobile, isCurrentView)}

      {state?.equipment?.value === keysName.JobSites && (
        <Typography>
          {`${t`Nombre de chantiers`} : ${
            state.equipment.data.jobSites.length
          }`}
        </Typography>
      )}

      {state?.equipment?.value === keysName.Agencies && (
        <Typography>
          {`${t`Nombre d'agences`} : ${state.equipment.data.agencies.length}`}
        </Typography>
      )}
    </Stack>
  );
};

export default DataDescription;
