import { Box, LinearProgress, TablePagination, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { debounce } from 'lodash';

/**
 * Paginated and filter table by server side request. See [Trackers.jsx] for an example
 *
 * @param searchLabel Label for the search bar
 * @param data rows data
 * @param totalElement max element, link to pagination
 * @param onPageChange handle page number change
 * @param onRowChange handle number of row per page change
 * @param page
 * @param rowsPerPage
 * @param columns headers definition
 * @param onSearchChange handle search bar, debounce by default
 * @param children other filters to add. The parent is responsible to handle custom filter
 * @param onSortChange handle sort
 * @param searchEnable hide search option
 * @param isLoading data is loading
 * @returns {JSX.Element}
 * @constructor
 */
export const PaginatedTable = ({
  searchLabel,
  data,
  totalElement,
  onPageChange,
  onRowChange,
  page,
  rowsPerPage,
  columns,
  onSearchChange,
  onSortChange,
  children,
  searchEnable,
  isLoading,
}) => {
  /**
   * Debounce search to limit request on server side
   * @type {DebouncedFunc<*>}
   */
  const debouncedSearchHandler = useMemo(
    () => debounce(onSearchChange, 600),
    [onSearchChange]
  );

  /**
   * UpperCase direction field as Spring use an ENUM for direction
   * @param sortModel
   */
  const normalizedFilter = (sortModel) => {
    const normalized = sortModel.map((sort) => {
      return {
        field: sort.field,
        sort: sort.sort.toUpperCase(),
      };
    });
    onSortChange(normalized);
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Box>
          {searchEnable && (
            <TextField
              sx={{ minWidth: 250 }}
              variant="filled"
              label={searchLabel}
              onChange={debouncedSearchHandler}
            />
          )}
          {children}
        </Box>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          count={totalElement}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowChange}
          sx={{
            '.MuiTablePagination-selectLabel': {
              marginBottom: 0,
            },
            '.MuiTablePagination-displayedRows': {
              marginBottom: 0,
            },
          }}
        />
      </Box>
      <div style={{ height: '100%', width: '100%' }}>
        <DataGrid
          components={{
            LoadingOverlay: LinearProgress,
          }}
          loading={isLoading}
          disableColumnFilter
          filterMode="server"
          sortingMode="server"
          rows={data}
          columns={columns}
          pageSize={rowsPerPage}
          onSortModelChange={normalizedFilter}
          hideFooter
        />
      </div>
    </div>
  );
};
