import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import AgencyRow from './components/AgencyRow/AgencyRow';
import JobSiteRow from './components/JobSiteRow/JobSiteRow';
import MachineRow from './components/MachineRow/MachineRow';
import IsMobile from '../../../utils/IsMobile';

interface EquipmentRowProps {
  equipmentType: string;
  equipmentPage: string;
  equipment: any;
}

const EquipmentRow = ({
  equipmentType,
  equipmentPage,
  equipment,
}: EquipmentRowProps) => {
  const history = useHistory();
  const isMobile = IsMobile();

  const handleHistoryPush = useCallback(() => {
    history.push(`/equipments/${equipmentPage}/${equipment.id}`);
  }, [equipment.id, equipmentPage, history]);

  const equipmentRow = useMemo(() => {
    switch (equipmentType) {
      case 'Machines':
        return MachineRow({
          machine: equipment,
          handleHistoryPush,
          isMobile,
        });

      case 'Chantiers':
        return JobSiteRow({
          jobSite: equipment,
          handleHistoryPush,
          isMobile,
        });

      case 'Agences':
        return AgencyRow({
          agency: equipment,
          handleHistoryPush,
          isMobile,
        });

      default:
        return [];
    }
  }, [equipment, equipmentType, handleHistoryPush, isMobile]);

  return equipmentRow;
};

export default EquipmentRow;
