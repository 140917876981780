import { Card, CardTitle, CardText, CardBody } from 'reactstrap';
import { Trans } from '@lingui/macro';

import '../../../../assets/scss/styles.scss';

const NoApp = () => (
  <Card>
    <CardBody>
      <CardTitle>
        <Trans>Erreur d&apos;authentification</Trans>
      </CardTitle>
      <CardText>
        <Trans>
          L&apos;iFrame ne peut pas être affichée car le token n&apos;a pas pu
          être validé.
        </Trans>
      </CardText>
    </CardBody>
  </Card>
);

export default NoApp;
