import React, { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Alert } from 'reactstrap';

import { Form, PasswordField } from '../components';
import { getUrlQueryParam } from '../../../utils/url';
import defaultLogo from '../assets/images/brand-logo.svg';

const ResetPassword = ({ handleResetPassword, logo }) => {
  const location = useLocation();
  const [submitSucceeded, setSubmitSucceeded] = useState(false);

  const token = useMemo(
    () => getUrlQueryParam(location.search, 'token'),
    [location.search]
  );

  const handleSubmit = async (values, actions) => {
    try {
      await handleResetPassword(values.token, values.password);

      setSubmitSucceeded(true);
    } catch (error) {
      actions.setStatus(
        error?.message ??
          'Erreur lors de la demande de réinitialisation de mot de passe'
      );
    } finally {
      actions.setSubmitting(false);
    }
  };

  // Check the password and confirmPassword are identic
  const validate = (values) => {
    const errors = {};

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword =
        "La confirmation du mot de passe n'est pas identidentique au mot de passe";
    }

    return errors;
  };

  return (
    <main className="auth">
      <header id="auth-header" className="auth-header">
        <h1>
          <img src={logo || defaultLogo} height="28" alt="" />
          <span className="sr-only">Sign In</span>
        </h1>
      </header>
      <div className="auth-form">
        {submitSucceeded ? (
          <Alert color="success">
            <p>Merci le mot de passe à bien été mise à jour.</p>
            <p>
              <Link to="/login">Se connecter</Link>
            </p>
          </Alert>
        ) : (
          <>
            <p className="text-center">
              Merci de renseigner un nouveau mot de passe.
            </p>
            <Form
              validate={validate}
              onSubmit={handleSubmit}
              initialValues={{
                password: '',
                confirmPassword: '',
                token,
              }}
              buttonClassName="btn btn-lg btn-primary btn-block"
            >
              <PasswordField
                name="password"
                placeholder="Nouveau mot de passe"
                required
              />
              <PasswordField
                name="confirmPassword"
                placeholder="Confirmation du nouveau mot de passe"
                required
              />
            </Form>
          </>
        )}
      </div>
    </main>
  );
};

export default ResetPassword;
