import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { cellsWrap } from '../../../../../utils/table/table';

import colors from '../../../../../theme/_colors.scss';
import { mets } from '../../../../../utils/utils';

interface SecurityTableRowProps {
  type: string;
  dateDescription: string;
  duration: number;
  outOfContract?: boolean;
}

const SecurityTableRow = ({
  type,
  dateDescription,
  duration,
  outOfContract,
}: SecurityTableRowProps) => {
  // eslint-disable-next-line prettier/prettier
  const str = type as string;

  const cells = [
    <Typography sx={{ color: outOfContract ? colors.imperialRed : '' }}>
      <Trans id={`${mets[str as keyof typeof mets] ?? type}`} />
    </Typography>,
    <Typography sx={{ color: outOfContract ? colors.imperialRed : '' }}>
      {dateDescription}
    </Typography>,
    <Typography sx={{ color: outOfContract ? colors.imperialRed : '' }}>
      {duration}
    </Typography>,
  ];

  return cellsWrap(cells);
};

export default SecurityTableRow;
