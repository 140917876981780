import { t } from '@lingui/macro';
import * as yup from 'yup';

import Field from './Field';
import ReactStrapComponent from './ReactStrapComponent';

const validationSchema = yup.string().email(t`Le courriel n'est pas valide.`);
const EmailField = (props) => (
  <Field
    type="email"
    component={ReactStrapComponent}
    validationSchema={validationSchema}
    {...props}
  />
);

export default EmailField;
