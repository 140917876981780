import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { LoadingButton } from '@mui/lab';
import { Trans } from '@lingui/macro';

import IsMobile from '../../../utils/IsMobile';
import SelectOptions from '../SelectOptions/SelectOptions';

import './TabButton.scss';

const LinkTab = ({ disabled, showspinner, ...rest }) => {
  return (
    <>
      {showspinner ? (
        <LoadingButton loading variant="contained">
          <Trans>Historique</Trans>
        </LoadingButton>
      ) : (
        <Tab
          disabled={disabled}
          className="tab-button__link-tab"
          {...rest}
          sx={{
            color: 'text.primary',
            '& ': {
              backgroundColor: disabled ? 'cardLink.bgcolor' : 'light.bgcolor',
              color: disabled && 'background.light',
            },
            '&:hover': {
              color: 'primary.main',
            },
          }}
        />
      )}
    </>
  );
};

const TabButton = ({ tab }) => {
  const [value, setValue] = useState('0');
  const isMobile = IsMobile();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="tab-button tab-button--not-default">
      <TabContext value={value}>
        {isMobile ? (
          <SelectOptions
            className="tab-button__select"
            options={tab.map((el, index) => {
              return {
                label: el.label,
                value: index.toString(),
                onClick: (e) => handleChange(e, index.toString()),
                disabled: el?.disabled,
              };
            })}
            defaultIndex={0}
          />
        ) : (
          <TabList
            className="tab-button__tab-list"
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              '& .MuiTab-root.Mui-selected': {
                backgroundColor: 'primary.main',
                color: 'background.light',
              },
            }}
          >
            {tab.map((el, index) => (
              <LinkTab
                {...el}
                value={index.toString()}
                key={index.toString()}
              />
            ))}
          </TabList>
        )}
        {tab.map((el, index) => (
          <TabPanel
            className="tab-button__tab-panel"
            key={index.toString()}
            value={index.toString()}
          >
            {el.content}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default TabButton;

TabButton.propTypes = {
  tab: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.oneOfType([PropTypes.object]).isRequired,
      disabled: PropTypes.bool,
      showspinner: PropTypes.bool,
    })
  ).isRequired,
};

LinkTab.propTypes = {
  disabled: PropTypes.bool,
  showspinner: PropTypes.bool,
};

LinkTab.defaultProps = {
  disabled: false,
  showspinner: false,
};
