import { t } from '@lingui/macro';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import PairTrackerButton from '../../../components/Scan/PairTracker';
import { getTrackers, TrackerMode } from '../../api/trackers/Trackers';
import { ReturnButton } from '../../components/molecules';
import { PaginatedTable } from '../../components/molecules/PaginatedTable/PaginatedTable';

/**
 * List of trackers
 * @param hideDetails show a simple list of tracker
 * @returns {JSX.Element}
 * @constructor
 */
export const Trackers = ({ hideDetails }) => {
  const [trackers, setTrackers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageNumber, setPage] = useState(0);
  const [totalTrackers, setTotal] = useState(0);
  const [searchReq, setSearch] = useState('');
  const [modeFilter, setMode] = useState('TOUT');
  const [sortFilter, setSort] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTrackers = async () => {
      setLoading(true);
      const response = await getTrackers({
        page: pageNumber,
        size: rowsPerPage,
        search: searchReq,
        mode: modeFilter === 'TOUT' ? null : modeFilter,
        sort: sortFilter,
      });

      setTrackers(response.trackers);
      setTotal(response.totalElements);
      setLoading(false);
    };

    fetchTrackers();
  }, [pageNumber, rowsPerPage, searchReq, modeFilter, sortFilter]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleModeChange = (event) => {
    setMode(event.target.value);
  };

  const handleSortChange = (sortModel) => {
    // the multi sorting is not available for free plan, https://mui.com/x/react-data-grid/sorting/#single-and-multi-sorting
    setSort(sortModel.length === 0 ? null : sortModel[0]);
  };

  const columns = [
    {
      field: 'mode',
      headerName: t`Mode`,
    },
    {
      field: 'imei',
      headerName: t`IMEI`,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Link to={`/trackers/${params.row.id}`}>{params.value}</Link>
      ),
    },
    {
      field: 'versionMCU',
      headerName: t`Version MCU`,
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'versionNET',
      headerName: t`Version Net`,
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    { field: 'data', headerName: t`Data` },
    {
      field: 'machineEquipementNumber',
      headerName: t`Machine`,
      renderCell: (params) => (
        <Link to={`/equipments/detail/${params.row.machineId}`}>
          {params.value}
        </Link>
      ),
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'agencyName',
      headerName: t`Agence`,
      renderCell: (params) => (
        <Link to={`/equipments/agency/${params.row.agencyId}`}>
          {params.value}
        </Link>
      ),
      flex: 1,
      minWidth: 150,
    },
  ];

  return (
    <>
      {!hideDetails && <ReturnButton label={t`Retour`} sx={{ m: 2 }} />}

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h1">{t`Trackers`}</Typography>
        <PairTrackerButton />
      </Box>
      <PaginatedTable
        searchLabel={`${t`Imei`} / ${t`Equipement`}`}
        onSearchChange={handleSearch}
        onPageChange={handleChangePage}
        onRowChange={handleChangeRowsPerPage}
        onSortChange={handleSortChange}
        data={trackers}
        columns={columns}
        page={pageNumber}
        rowsPerPage={rowsPerPage}
        totalElement={totalTrackers}
        searchEnable={!hideDetails}
        isLoading={isLoading}
      >
        <FormControl sx={{ minWidth: 100, ml: 1 }}>
          <InputLabel id="mode-select">{t`Mode`}</InputLabel>
          <Select
            value={modeFilter}
            label={t`Mode`}
            labelId="mode-select"
            onChange={handleModeChange}
          >
            <MenuItem value="TOUT">{t`TOUT`}</MenuItem>
            {Object.values(TrackerMode).map((key) => {
              return <MenuItem value={key}>{key}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </PaginatedTable>
    </>
  );
};
