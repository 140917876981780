import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import CockpitGoobiesTable from './components/tables/goobies/CockpitGoobiesTable';
import CockpitTrackUnitTable from './components/tables/track-unit/CockpitTrackUnitTable';

const CockpitEquipment = () => {
  const [selectedClass, setSelectedClass] = useState(0);

  return (
    <>
      <Typography variant="h3">
        Suivi des matéries équipés par catégorie-classe et par année
      </Typography>
      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id="equipment-class-label">Catégorie-classe</InputLabel>
        <Select
          labelId="equipment-class-label"
          id="equipment-class-select"
          value={selectedClass}
          label="Catégorie-classe"
          autoWidth
          onChange={setSelectedClass}
        >
          <MenuItem value={0}>Catégorie-classe</MenuItem>
        </Select>
      </FormControl>
      <CockpitTrackUnitTable />
      <CockpitGoobiesTable />
    </>
  );
};

export default CockpitEquipment;
