import { postRequest } from '../utils/axios.utils';

export const getNbrSessionsByMetric = (values) => {
  return postRequest(`session/count`, values);
};

export const getAggSessions = (values) => {
  return postRequest(`session/agg`, values);
};

export const getSessionsSummary = (values) => {
  return postRequest(`session/summary`, values);
};

export const getSessionsGroups = (values) => {
  return postRequest(`session/groups`, values);
};

export const getMachinesHavingSessions = (values) => {
  return postRequest(`session/machines`, values);
};

export const getTotalDurations = (values) => {
  return postRequest(`session/duration/total`, values);
};

export const getMetricSessionsDurationForMachine = (values) => {
  return postRequest(`session/machine/metric/duration`, values);
};

export const getSessionsMachinesDetails = (values) => {
  return postRequest(`session/machines/details`, values);
};

export const getSessionsMachineDetails = (values) => {
  return postRequest(`session/machine/details`, values);
};
