import { t } from '@lingui/macro';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  lazy,
  Suspense,
} from 'react';
import ButtonPositionsView from '../../components/molecules/ButtonPositionsView/ButtonPositionsView';
import { CURRENT } from '../../components/molecules/ButtonPositionsView/ButtonPositionsView.constants';
import { useAppModeContext, useBreadcrumbsContext } from '../../utils/hooks';
import { updateStatsWhenMachinesFilter } from './Equipment/Equipment.service';
import { useEquipmentPageState } from './Equipment/Equipment.state';
import EquipmentRow from './EquipmentRow/EquipmentRow';
import { keysName } from './constants';
import { equipmentObjectsKeyName } from './utils';
import { useUserContext } from '../../contexts/UserContext/UserContext';
import { Loading } from '../../../lib/apptheme';

const TableWithPagination = lazy(() =>
  import('../../components/molecules/TableWithPagination/TableWithPagination')
);

const EquipmentList = ({
  equipment,
  setEquipment,
  checkboxesStatesResp,
  parentFilters,
}) => {
  const [state, setState] = useState([]);
  const [, setBreadcrumbs] = useBreadcrumbsContext();
  const [dataFormated, setDataFormated] = useState([]);
  const [columnsObject, setColumnsObject] = useState([]);

  const { isInternalAppMode } = useAppModeContext();
  const [pageState, setPageState] = useEquipmentPageState();

  const user = useUserContext();
  const isDR = user.mergedProfil === 'DR';

  const isCurrentView = useMemo(
    () => pageState.currentView === CURRENT,
    [pageState.currentView]
  );

  const haveMachinesFilter = useMemo(
    () => equipment.value === keysName.Machines,
    [equipment.value]
  );

  const showSwitchMachinePosition = useMemo(
    () => isInternalAppMode && haveMachinesFilter,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [haveMachinesFilter]
  );

  const HEADER_EXTRACT = [
    { label: t`Machine`, key: 'rentalmanId' },
    { label: t`Statut`, key: 'equipmentStatusLib' },
    { label: t`Code agence`, key: 'branch_code' },
    { label: t`Agence`, key: 'agencyName' },
    { label: t`Imei`, key: 'imei' },
    { label: t`Tag NFC`, key: 'tag' },
    { label: t`Date d'association`, key: 'startDate' },
    { label: t`Mode`, key: 'mode' },
    { label: t`Requested Mode`, key: 'requestedMode' },
    { label: t`Dernière communication`, key: 'lastReport' },
    { label: t`Prochaine Communication`, key: 'nextReport' },
    {
      label: t`Date de la dernière position de la machine`,
      key: 'gbLastSyncTime',
    },
    { label: t`Latitude`, key: 'gbLat' },
    { label: t`Longitude`, key: 'gbLong' },
    { label: t`Comment`, key: 'comment' },
  ];

  useEffect(() => {
    setState(equipment);

    if (equipment.value === keysName.SeeAll) {
      setDataFormated(
        equipment.data.filter(
          (el) => el.state === (isCurrentView ? keysName.Machines : 'goobie')
        )
      );
    } else if (equipment.value === keysName.Machines) {
      const data = isCurrentView
        ? equipment.data.machines.data
        : equipment.data.goobies.data;

      setDataFormated(data);

      setPageState((prevState) => ({
        ...prevState,
        stats: updateStatsWhenMachinesFilter(data, true),
      }));
      if (equipment.columnsObject) {
        setColumnsObject(equipment.columnsObject);
      }
    } else {
      setDataFormated(equipment.data[equipmentObjectsKeyName[equipment.value]]);
      if (equipment.columnsObject) {
        setColumnsObject(equipment.columnsObject);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipment, parentFilters, isCurrentView]);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: t`Tableau de bord`,
        to: '/',
      },
      {
        label: equipment.breadcrumbs,
        to: '/equipments/list',
      },
      {
        label: t`Liste`,
        to: '/equipments/list',
      },
    ]);
    // TODO - fix eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const memoizedEquipmentRow = useCallback(
    (equip) => (
      <EquipmentRow
        key={equip.id}
        equipmentType={equipment.value}
        equipmentPage={equipment.page}
        equipment={equip}
      />
    ),
    [equipment.page, equipment.value]
  );

  const changeView = (value) => {
    const isCurrent = value === CURRENT;

    setPageState({
      ...pageState,
      currentView: value,
      showExtract: isDR && !isCurrent,
      titleExtract: isCurrent ? null : 'Goobies',
    });
  };

  return (
    dataFormated &&
    columnsObject && (
      <>
        {showSwitchMachinePosition && (
          <ButtonPositionsView
            isCurrentView={isCurrentView}
            onChange={changeView}
          />
        )}
        <Suspense fallback={<Loading />}>
          <TableWithPagination
            color="primary.main"
            headers={[...equipment.headers, '']}
            headerExtract={HEADER_EXTRACT}
            titleExtract={pageState.titleExtract}
            hideExract={!pageState.showExtract}
            checkboxHeader={equipment.headersCheckbox || null}
            checkboxesStatesResp={checkboxesStatesResp}
            initFilters={equipment.initFilters || null}
            rows={dataFormated}
            cells={memoizedEquipmentRow}
            rangeSelection
            columnsObject={columnsObject}
            enableFiltersOptions={equipment.enableFiltersOptions || false}
            parentFilters={parentFilters}
            hideColumns={equipment.hideColumns || null}
            setEquipment={setEquipment}
            equipment={equipment}
            dataDescription
          />
        </Suspense>
      </>
    )
  );
};

export default EquipmentList;
