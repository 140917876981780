import { createContext, useState, useContext } from 'react';
import { getAllUsers, getUserById, updateUser } from '../api/users/Users';
import { User } from '../api/users/Users.interface';
import { chunksArray, concatArraysWithoutDuplicates } from '../utils/array';

const UsersContext = createContext(null);

interface UserWithoutId {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  role: string;
}

const UsersProvider = (props: any) => {
  const [usersNumber, setUsersNumber] = useState<number>(0);
  const [isLoading, setLoading] = useState(false);

  const [users, setUsers] = useState<User[]>([]);

  const loadUsers = async (page: string, size: string) => {
    setLoading(true);

    const resp = await getAllUsers({ page, size });

    setUsers((prevUsers) =>
      concatArraysWithoutDuplicates(prevUsers, resp.users)
    );

    setUsersNumber(resp.totalElements);

    setLoading(false);
  };

  const loadUser = async (id: string) => {
    setLoading(true);

    const resp = await getUserById(id);

    setUsers((prevUsers) => [
      ...prevUsers.filter((u) => u.id !== resp.id),
      resp,
    ]);

    setLoading(false);
  };

  const getUsersMap = (page: string, size: string) => {
    const chunks = chunksArray(users, size);

    if (chunks.length < Number(page) + 1) {
      if (!isLoading) {
        loadUsers(page, size);
      }

      return [];
    }

    return chunks[Number(page)];
  };

  const getUserByMemberId = (id: string): User | null => {
    const usersFiltered: User[] = users.filter((u) => u.id === id);

    if (usersFiltered.length === 0) {
      loadUser(id);

      return null;
    }
    return usersFiltered[0];
  };

  const getUserByEmail = (email: string): User | undefined => {
    return users.find((u) => u.email === email);
  };

  const updateUserById = async (
    id: string,
    values: UserWithoutId
  ): Promise<User> => {
    const resp = await updateUser(id, values);

    setUsers((prevUsers) =>
      prevUsers.map((u) => {
        return u.id !== resp.id ? u : resp;
      })
    );

    return resp;
  };

  const usersData = {
    usersNumber,
    isLoading,
    getUsersMap,
    getUserByMemberId,
    getUserByEmail,
    updateUserById,
  };

  return <UsersContext.Provider value={usersData} {...props} />;
};

const useUsersContext = () => {
  return useContext(UsersContext);
};

export { UsersProvider, useUsersContext };
