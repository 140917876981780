import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Contract } from '../api/contracts/Contract.interface';
import {
  getContractByClientId,
  getContractByMachineIdRequest,
} from '../api/contracts/Contracts';
import { concatArraysWithoutDuplicates } from '../utils/array';
import { useAppModeContext } from '../utils/hooks';
import ContextLoadStatus from './utils';

const ContractsContext = createContext(null);

const ContractsProvider = ({ children, contracts: contractsInit }: any) => {
  const { isInternalAppMode } = useAppModeContext();

  const [loadStatus, setLoadStatus] = useState<ContextLoadStatus>(
    contractsInit ? ContextLoadStatus.LOADED : ContextLoadStatus.NOTLOADED
  );

  const [contracts, setContracts] = useState<Contract[]>(contractsInit || []);

  const [isLoadingContract, setIsLoadingContract] = useState<boolean>(false);

  useEffect(() => {
    setContracts(contractsInit || []);
    setLoadStatus(
      contractsInit ? ContextLoadStatus.LOADED : ContextLoadStatus.NOTLOADED
    );
  }, [contractsInit]);

  const loadContractsByClient = async (clientId: string) => {
    if (!isInternalAppMode) {
      setLoadStatus(ContextLoadStatus.LOADING);

      getContractByClientId(clientId).then(
        (result) => {
          setContracts((prevContracts) =>
            concatArraysWithoutDuplicates(prevContracts, result)
          );
          setLoadStatus(ContextLoadStatus.LOADED);
        },
        (error) => {
          setLoadStatus(ContextLoadStatus.ERROR);
        }
      );
    }
  };

  const loadContractByMachineId = useCallback(async (id: string) => {
    setIsLoadingContract(true);

    getContractByMachineIdRequest(id).then((result) => {
      // TODO - Delete this afeter machineId came on the response

      setIsLoadingContract(false);
      if (result === '') {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      result.machineId = id;

      setContracts((prevContracts) => [
        ...prevContracts.filter((c) => c.id !== result.id),
        result,
      ]);
    });
  }, []);

  const getContractsLoadStatus = (): ContextLoadStatus => {
    return loadStatus;
  };

  const getContractsByClient = (clientId: string): any[] => {
    if (loadStatus === ContextLoadStatus.NOTLOADED) {
      loadContractsByClient(clientId);

      return [];
    }

    return contracts.filter((c: Contract) => c.clientId === clientId);
  };

  const getContractByMachineId = useCallback(
    (id: string): Contract | undefined => {
      const contractsFiltered: Contract | undefined = contracts.find(
        (c) => c.machineId === id
      );

      if (!contractsFiltered && !isLoadingContract) {
        loadContractByMachineId(id);
        return undefined;
      }
      return contractsFiltered;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contracts]
  );

  const contractsData: any = {
    getContractsLoadStatus,
    loadContractsByClient,
    loadContractByMachineId,
    getContractsByClient,
    getContractByMachineId,
  };

  return (
    <ContractsContext.Provider value={contractsData}>
      {children}
    </ContractsContext.Provider>
  );
};

const useContractsContext = () => {
  return useContext(ContractsContext);
};

export { ContractsProvider, useContractsContext };
