/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import PlainApp from './Main/PlainApp/PlainApp';

import config from '../utils/OktaConfig';
import { useAppModeContext } from '../utils/hooks';
import Main from './Main/Main';
import AxiosInterceptor from '../../components/AxiosInterceptor/AxiosInterceptor';

const oktaAuthenticator = new OktaAuth(config.oidc);

function PlanAppAuthSource() {
  const { isInternalAppMode } = useAppModeContext();

  useEffect(() => {
    document.title = isInternalAppMode ? 'AgenceConnect' : 'LoxConnect';
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const triggerLogin = async () => {
    await oktaAuthenticator.signInWithRedirect();
  };

  const restoreOriginalUri = async () => {
    window.location.href = toRelativeUrl('/', window.location.origin);
  };

  const customAuthHandler = async () => {
    const previousAuthState =
      oktaAuthenticator.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await triggerLogin();
    }
  };

  const plainApp = (
    <AxiosInterceptor>
      <Main>
        <PlainApp />
      </Main>
    </AxiosInterceptor>
  );

  return isInternalAppMode ? (
    <Security
      oktaAuth={oktaAuthenticator}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      {plainApp}
    </Security>
  ) : (
    plainApp
  );
}

export default PlanAppAuthSource;
