import './MachineIcon.scss';

const getMachineUrlImage = (pim, key) => {
  if (pim?.images) {
    const icon = pim.images.find((p) => p.format === key);

    return icon?.url ?? '';
  }
  return '';
};

// uses <object> vs <img> because with img if the image is not loaded, renders a "broken icon"
// we cant use the onError method from img because isn't possible to use javascript on markers's popup
export const MachineIcon = ({ pim, pimKey, className = '', style = {} }) => {
  const machineUrlImage = getMachineUrlImage(pim, pimKey);

  return (
    <>
      {machineUrlImage ? (
        <img
          id="machine-icon"
          className={className}
          src={machineUrlImage}
          style={style}
          alt="machine icon"
        />
      ) : (
        <div id="machine-icon" className={className} style={style} />
      )}
    </>
  );
};
