import { t } from '@lingui/macro';
import { Button, Stack, Typography } from '@mui/material';
import IsMobile from '../../app/utils/IsMobile';

import './SaveCancelStack.scss';

interface SaveCancelStackProps {
  onSave: () => void;
  onCancel: () => void;
}

const SaveCancelStack = ({ onSave, onCancel }: SaveCancelStackProps) => {
  const isMobile = IsMobile();
  return (
    <Stack
      className="save-cancel-stack"
      direction={isMobile ? 'column' : 'row'}
      justifyContent="flex-start"
      spacing={1}
    >
      <Button
        className="save-cancel-stack__cancel-text"
        variant="contained"
        size="medium"
        onClick={onSave}
      >
        <Typography variant="h6">{t`Valider`}</Typography>
      </Button>

      <Button variant="outlined" size="medium" onClick={onCancel}>
        <Typography className="save-cancel-stack__save-text" variant="h6">
          {t`Annuler`}
        </Typography>
      </Button>
    </Stack>
  );
};

export default SaveCancelStack;
