import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { keysName } from '../constants';
import { CURRENT } from '../../../components/molecules/ButtonPositionsView/ButtonPositionsView.constants';

const defaultValue = {
  currentView: CURRENT,
  stats: {
    on: undefined,
    off: undefined,
    totalElements: undefined,
  },
  filters: {
    type: keysName.SeeAll,
  },
  showExtract: false,
  titleExtract: null,
};

const equipmentPageStateAtom = atom({
  key: 'equipmentPageState',
  default: defaultValue,
});

export const useEquipmentPageState = () =>
  useRecoilState(equipmentPageStateAtom);

export const useEquipmentPageStateValue = () =>
  useRecoilValue(equipmentPageStateAtom);
