import { useCallback, useEffect, useRef, useState } from 'react';
import { t } from '@lingui/macro';
import { RequestStatus } from '../../../../api/api.interface';
import { Notification } from '../../../../api/notifications/Notification.interface';
import { getUserNotifications } from '../../../../api/notifications/Notifications';
import { TableWithPagination } from '../../../../components/molecules';
import Loading from '../../../../../lib/apptheme/partials/Loading';
import NotificationsRow from '../NotificationsRow/NotificationsRow';

const defaultPage = 0;
const defaultPageSize = 5;

const NotificationsTable = () => {
  const [loadStatus, setLoadStatus] = useState<RequestStatus>(
    RequestStatus.LOADING
  );

  const [currentPage, setCurrentPage] = useState<number>(defaultPage);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);

  useEffect(() => {
    fetchNotifications(defaultPage, 5);
  }, []);

  const fetchNotifications = (page: number, size: number) => {
    getUserNotifications({ page, size })
      .then((resp: any) => {
        setNotifications(
          resp.alerts.map((n: any) => ({ id: n.alertID, ...n }))
        );

        setTotalElements(resp.totalElements);

        setLoadStatus(RequestStatus.SUCCESS);
      })
      .catch((error: any) => {
        setLoadStatus(RequestStatus.ERROR);
      });
  };

  const handlePageChanged = (page: number, size: number) => {
    if (currentPage !== page) {
      setCurrentPage(page);
    }

    if (pageSize !== size) {
      setPageSize(size);
    }

    fetchNotifications(page, size);
  };

  const memoizedNotificationsRow = useCallback(
    (notification: Notification, rowIdClicked: string) => (
      <NotificationsRow
        notification={notification}
        rowIdClicked={rowIdClicked}
      />
    ),
    []
  );

  if (loadStatus === RequestStatus.LOADING) {
    return <Loading />;
  }

  return (
    <>
      <TableWithPagination
        headers={[t`Source`, t`Chantier`, t`Type`, t`Date`, t`Mode`]}
        rows={notifications}
        cells={memoizedNotificationsRow}
        description={t`Nombre de notifications`}
        alternateBg
        checkboxesStatesResp={undefined}
        columnsObject={undefined}
        dataDescription={undefined}
        parentFilters={undefined}
        hideColumns={undefined}
        equipment={undefined}
        pagination={{
          currentPage,
          pageSize,
          totalElements,
          handlePageChange: handlePageChanged,
        }}
        noSearch
      />
    </>
  );
};

export default NotificationsTable;
