/* eslint-disable prettier/prettier */
import {
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import moment from 'moment';
import { getCockpitTrackers } from '../../api/machines/Machines';
import goobieLogo from '../../assets/images/logos/goobie.svg';
import haulotteLogo from '../../assets/images/logos/haulotte.svg';
import manitouLogo from '../../assets/images/logos/manitou.svg';
import trackunitLogo from '../../assets/images/logos/trackunit.svg';

import './cockpit.scss';
import ManufacturerStatus from './components/GoobiesInfrastructure/ManufacturerStatus';

const CockpitRecence = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [lastUpdate, setLastUpdate] = useState('');
  const [cockpitTrackers, setCockpitTrackers] = useState([]);
  const [goobieStatus, setGoobieStatus] = useState(null);
  const [manitouStatus, setManitouStatus] = useState(null);
  const [haulotteStatus, setHaulotteStatus] = useState(null);
  const [trackunitStatus, setTrackunitStatus] = useState(null);

  const MINUTES = 15;
  const TIME_IN_MILISECONDS = 1000 * 60 * MINUTES;

  const fetchData = async () => {
    setIsLoading(true);

    const result = await getCockpitTrackers();

    setGoobieStatus({
      ...result.find((tracker) => tracker?.origin?.toLowerCase() === "goobie"),
      logoSrc: goobieLogo
    });
    setManitouStatus({
      ...result.find((tracker) => tracker?.origin?.toLowerCase() === "manitou"),
      logoSrc: manitouLogo
    });
    setHaulotteStatus({
      ...result.find((tracker) => tracker?.origin?.toLowerCase() === "haulotte"),
      logoSrc: haulotteLogo
    });
    setTrackunitStatus({
      ...result.find((tracker) => tracker?.origin?.toLowerCase() === "trackunit"),
      logoSrc: trackunitLogo
    });
    setLastUpdate(moment().format('HH:mm'));
  };

  // Refetch the data given an amount of time
  useEffect(() => {
    const interval = setInterval(() => {
      setCockpitTrackers([]);
      fetchData();
    }, TIME_IN_MILISECONDS);

    fetchData();

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  // Manipulates the loading state
  useEffect(() => {
    if (goobieStatus &&
      manitouStatus &&
      haulotteStatus &&
      trackunitStatus) {
      setIsLoading(false);
    }
  }, [goobieStatus, manitouStatus, haulotteStatus, trackunitStatus]);

  console.log(goobieStatus);
  return (
    <>
      <Typography variant="h3" sx={{ paddingBottom: "15px" }}>
        <Trans>Synchronisation des données</Trans>
        <small className="cockpit-recence__last-update">
          <Trans>Dernière mise à jour</Trans> : {lastUpdate}
        </small>
      </Typography>
      {
        !isLoading &&
        goobieStatus &&
        manitouStatus &&
        haulotteStatus &&
        trackunitStatus ? (
          <>
            <ManufacturerStatus data={goobieStatus} />
            <ManufacturerStatus data={trackunitStatus} />
            <ManufacturerStatus data={haulotteStatus} />
            <ManufacturerStatus data={manitouStatus} />
          </>
        ) : <Typography><Trans>Chargement des données</Trans>...</Typography>
      }
    </>
  );
};

export default CockpitRecence;
