import { t } from '@lingui/macro';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Stack, TableCell, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import {
  createSite,
  getSiteById,
  updateSite,
} from '../../../api/jobsites/JobSites';
import {
  getMachineById,
  getUnassignedAllMachines,
} from '../../../api/machines/Machines';
import { getAllUsers, getUserById } from '../../../api/users/Users';
import { ReturnButton } from '../../../components/molecules';
import { getCells } from '../../../utils/utils';
import MachineGroupDialog from '../dialog/MachineGroupDialog';

import './MachineGroupCreate.scss';

const getCellsUsers = (machine) => {
  return [
    <Typography>{machine.lastName}</Typography>,
    <Typography>{machine.firstName}</Typography>,
    <Typography>{machine.email}</Typography>,
    <Typography>{machine.phone}</Typography>,
    <Typography>{machine.role}</Typography>,
  ];
};

const MachineGroupCreate = ({
  setView,
  title,
  machGrpId,
  isReading,
  isUpdating,
}) => {
  const [error, setError] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const returnPreviousPage = () => {
    setView('list');
  };

  const getObjects = async (arr, getter) => {
    const promises = [];
    arr.map((i) => {
      return promises.push(getter(i.id));
    });
    const results = await Promise.all(promises);
    return results;
  };

  const generateCellsMachines = (machine, onDelete) => {
    const cells = getCells(machine);
    const formatedCells = cells.map((cell) => (
      <TableCell align="left">{cell}</TableCell>
    ));

    if (!isReading) {
      formatedCells.push(
        <TableCell align="left">
          <DeleteIcon
            className="machine-group-create__pointer"
            sx={{ color: 'primary.main' }}
            onClick={() => onDelete(machine.id)}
          />
        </TableCell>
      );
    }
    return formatedCells;
  };

  const generateCellsUsers = (machine, onDelete) => {
    const cells = getCellsUsers(machine);
    const formatedCells = cells.map((cell) => (
      <TableCell align="left">{cell}</TableCell>
    ));

    if (!isReading) {
      formatedCells.push(
        <TableCell align="left">
          <DeleteIcon
            className="machine-group-create__pointer"
            sx={{ color: 'primary.main' }}
            onClick={() => onDelete(machine.id)}
          />
        </TableCell>
      );
    }
    return formatedCells;
  };

  const handleAddSite = async () => {
    const postData = {
      name,
      description,
      enabled: true,
      users: selectedMembers,
      machines: selectedMachines,
    };
    try {
      if (isUpdating) {
        await updateSite(machGrpId, postData);
      } else {
        await createSite(postData);
      }
      returnPreviousPage();
    } catch (err) {
      console.error(err);
      setError(true);
    }
  };

  const fetchData = async () => {
    try {
      const resp = await getSiteById(machGrpId);
      setName(resp.name);
      setDescription(resp.description);
      const users = await getObjects(resp.users, getUserById);
      const machines = await getObjects(resp.machines, getMachineById);

      setSelectedMachines(machines);
      setSelectedMembers(users);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (machGrpId) {
      fetchData();
    } else {
      setIsLoading(false);
    }
    // TODO - fix eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="machine-group-create">
      <ReturnButton
        label={t`Retour`}
        style={{ marginBottom: '.5rem' }}
        onClick={returnPreviousPage}
      />

      <Typography
        className="machine-group-create__title"
        variant="h3"
        color="primary.main"
      >
        {title}
      </Typography>

      <Typography className="machine-group-create__info" variant="body1">
        {t`Informations sur le groupement de machines`}
      </Typography>

      <Stack
        className="machine-group-create__stack"
        direction="column"
        spacing={2}
      >
        <TextField
          className="machine-group-create__text-field"
          id="filled"
          label={t`Nom du groupement de machines`}
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="filled"
          sx={{
            color: 'text.primary',
            '& label.Mui-focused': {
              color: 'text.primary',
            },
            '& .MuiFilledInput-underline:after': {
              borderBottomColor: 'text.primary',
            },
          }}
          disabled={isReading}
        />
        <TextField
          className="machine-group-create__text-field"
          id="filled"
          label={t`Description`}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          variant="filled"
          sx={{
            color: 'text.primary',
            '& label.Mui-focused': {
              color: 'text.primary',
            },
            '& .MuiFilledInput-underline:after': {
              borderBottomColor: 'text.primary',
            },
          }}
          disabled={isReading}
        />
      </Stack>

      <Stack
        className="machine-group-create__stack"
        direction="column"
        spacing={4}
      >
        {!isLoading && (
          <>
            <MachineGroupDialog
              title={t`machines`}
              headers={[t`Illustration`, t`Machine`, t`Location`, t`Etat`]}
              keyName="machines"
              getCells={getCells}
              generateCells={generateCellsMachines}
              asyncCall={getUnassignedAllMachines}
              selectedData={selectedMachines}
              setSelectedData={setSelectedMachines}
              disabled={isReading}
            />

            <MachineGroupDialog
              title={t`membres`}
              headers={[
                t`Nom`,
                t`Prénom`,
                t`Adresse email`,
                t`Téléphone`,
                t`Rôle`,
              ]}
              keyName="users"
              getCells={getCellsUsers}
              generateCells={generateCellsUsers}
              asyncCall={getAllUsers}
              selectedData={selectedMembers}
              setSelectedData={setSelectedMembers}
              disabled={isReading}
            />
          </>
        )}
      </Stack>

      <Stack
        className="machine-group-create__save-stack"
        direction="row"
        alignItems="center"
      >
        <Button
          className="machine-group-create__save-btn"
          variant="contained"
          disabled={
            (selectedMachines.length < 1 && selectedMembers < 1) ||
            !name ||
            !description ||
            isReading
          }
          onClick={handleAddSite}
        >
          {t`Enregistrer`}
        </Button>
        {error && (
          <Typography className="machine-group-create__error" color="error">
            {t`Erreur lors de l'enregistrement`} !{' '}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default MachineGroupCreate;
