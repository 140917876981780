export interface CalendarTypeOptionsProps {
  label: string;
  value: MonthHoverTypeEnum;
  onClick: () => void;
}

export enum MonthHoverTypeEnum {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}
