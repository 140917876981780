import {
  getAllDepartments,
  getAllRegions,
} from '../../../../api/geoZones/geoZones';
import colors from '../../../../theme/_colors.scss';
import { drawPolygon } from '../Map.utils';

const drawSection = (
  map: any,
  sectionCoords: any[],
  isClicked: boolean,
  onClickCallback: () => void,
  addPolygon: (polygon: any) => void
) => {
  sectionCoords.forEach((coords: any) => {
    if (Number.isFinite(coords[0][0])) {
      const normalStyle = {
        strokeColor: colors.dimGray,
        fillColor: colors.dimGray,
      };

      const section = drawPolygon(map, coords, normalStyle);
      addPolygon(section);
      section.on('click', onClickCallback);

      if (isClicked) {
        section.setOptions({
          fillColor: colors.imperialRed,
        });
      } else {
        section.on('mouseover', () => {
          section.setOptions({
            fillColor: colors.starCommandBlue,
          });
        });

        section.on('mouseout', () => {
          section.setOptions(normalStyle);
        });
      }
    } else {
      drawSection(map, coords, isClicked, onClickCallback, addPolygon);
    }
  });
};

export const DrawAllRegions = (
  map: any,
  geoZoneIds: string[],
  onClickCallback: (id: string) => void,
  addPolygon: (polygon: any) => void
) => {
  getAllRegions().then((response: any) =>
    response.data.features.forEach((regionCoords: any) => {
      const id = regionCoords.properties.code;

      drawSection(
        map,
        regionCoords.geometry.coordinates,
        geoZoneIds.includes(id),
        () => onClickCallback(id),
        addPolygon
      );
    })
  );
};

export const DrawAllDepartements = (
  map: any,
  geoZoneIds: string[],
  onClickCallback: (id: string) => void,
  addPolygon: (polygon: any) => void
) => {
  getAllDepartments().then((response: any) =>
    response.data.features.forEach((departmentCoords: any) => {
      const id = departmentCoords.properties.code;

      drawSection(
        map,
        departmentCoords.geometry.coordinates,
        geoZoneIds.includes(id),
        () => onClickCallback(id),
        addPolygon
      );
    })
  );
};
