import moment from 'moment';

export const isDateBellow24h = (date: string | Date): boolean => {
  return moment().diff(moment(date), 'hours') < 24;
};

export const getNumberOfWeeksInMonth = (date: moment.Moment) => {
  const startWeek = date.startOf('month').isoWeek();
  const endWeek = date.endOf('month').isoWeek();
  return endWeek - startWeek + 1;
};

// duration in seconds
export const calculFullHoursFromDuration = (duration: number) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);
  const seconds = duration - hours * 3600 - minutes * 60;

  let hoursFormated = `${hours}`;
  let minutesFormated = `${minutes}`;
  let secondsFormated = `${seconds}`;

  if (hours < 10) {
    hoursFormated = `0${hours}`;
  }
  if (minutes < 10) {
    minutesFormated = `0${minutes}`;
  }
  if (seconds < 10) {
    secondsFormated = `0${seconds}`;
  }

  return {
    hours: hoursFormated,
    minutes: minutesFormated,
    seconds: secondsFormated,
  };
};

export const formatDuration = (duration: number | string) => {
  const { hours, minutes, seconds } = calculFullHoursFromDuration(
    Number(duration)
  );
  return `${hours}:${minutes}:${seconds}`;
};
