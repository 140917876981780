import { useAppModeContext } from '../../utils/hooks';
import MainExt from './components/MainExt/MainExt';
import MainInt from './components/MainInt/MainInt';
import { useAsyncFunction } from '../../hooks/AsyncFetch/useAsyncFetch';
import { Loading } from '../../../lib/apptheme';
import { getUserAlreadyAuthen } from './Main.service';

const Main = ({ children }) => {
  const { isInternalAppMode } = useAppModeContext();

  const { data: userLogged, isLoading } = useAsyncFunction({
    asyncFunction: getUserAlreadyAuthen,
    params: { isInternalAppMode },
  });

  if (isLoading) {
    return <Loading />;
  }

  if (isInternalAppMode) {
    return <MainInt>{children}</MainInt>;
  }

  return (
    <MainExt user={userLogged ? { ...userLogged } : undefined}>
      {children}
    </MainExt>
  );
};

export default Main;
