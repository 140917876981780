import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Loading } from '../../../../../lib/apptheme';
import UserContext from '../../../../contexts/UserContext/UserContext';
import PrivateRoute from '../../../../utils/PrivateRoute';
import { getUserInfoByOkta } from '../../Main.service';

const MainInt = ({ user, children }) => {
  const okta = useOktaAuth();
  const [userInfo, setUserInfo] = useState(user);

  useEffect(() => {
    if (okta.authState && !okta.authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else if (!userInfo && okta.authState) {
      getUserInfoByOkta(okta).then((user) => {
        setUserInfo(user);
        if (
          okta.authState?.isAuthenticated &&
          'NDEFReader' in window &&
          user.mergedProfil === 'AG' &&
          !window.location.href.includes('/scan')
        ) {
          window.location.replace('/scan');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [okta.authState]); // Update if authState changes

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login/callback" component={LoginCallback} />
        <PrivateRoute path="/" isAuthenticated={!!userInfo}>
          <UserContext.Provider value={userInfo}>
            {!userInfo ? <Loading /> : children}
          </UserContext.Provider>
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  );
};

export default MainInt;
