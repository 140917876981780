/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
import { Tooltip } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';

const daysColor = {
  0: '#EEEEEE',
  50: '#2D3D81',
  100: '#398DC5',
  150: '#6AC4CA',
  200: '#E03C3C',
};

const monthKeysWidth = {
  7: 1,
  14: 2,
  21: 3,
  28: 4,
};

const formatNumberBellowTen = (number) => {
  return number < 10 ? `0${number}` : number;
};

const CustomCalendar = ({ start, end, data, onClick }) => {
  const diff = Math.floor((Date.parse(end) - Date.parse(start)) / 85400000);

  const date = new Date();
  date.setFullYear(moment(start).format('YYYY'));
  date.setMonth(moment(start).format('MM') - 1);

  const test = [];
  let mondayDate;
  for (let i = 0; i < diff + date.getDay() - 1; i += 1) {
    const x = new Date();

    x.setFullYear(moment(start).format('YYYY'));
    x.setMonth(moment(start).format('MM') - 1);

    if (x.getDay() === 1) {
      x.setDate(x.getDate() + i);
    } else {
      while (i === 0 && x.getDay() !== 1) {
        x.setDate(x.getDate() - 1);
        mondayDate = x;
      }

      if (i > 0) {
        x.setDate(mondayDate.getDate() + i);
      }
    }

    const year = x.getFullYear();
    const month = formatNumberBellowTen(x.getMonth() + 1);
    const day = formatNumberBellowTen(x.getDate());

    test.push({
      value: 0,
      day: `${year}-${month}-${day}`,
      isWeekEnd: x.getDay() === 0 || x.getDay() === 6,
    });
  }

  const sortedData = test.sort((a, b) => {
    return (
      moment(a.day, 'YYYY-MM-DD').toDate() -
      moment(b.day, 'YYYY-MM-DD').toDate()
    );
  });

  sortedData.forEach((el) => {
    data.forEach((d) => {
      if (el.day === d.day) {
        // eslint-disable-next-line no-param-reassign
        el.value = d.value;
      }
    });
  });

  const days = [
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche',
  ];

  const months = [
    'Janv',
    'Fev',
    'Mars',
    'Avr',
    'Mai',
    'Juin',
    'Juil',
    'Août',
    'Sept',
    'Oct',
    'Nov',
    'Déc',
  ];

  const monthInData = {
    month: [],
    list: [],
  };

  const month = [];

  const countOccurrences = (arr, val) =>
    arr.reduce((a, v) => (moment(v.day).format('MM') === val ? a + 1 : a), 0);

  sortedData.forEach((el) => {
    if (!monthInData.list.includes(moment(el.day).format('MM'))) {
      monthInData.list.push(moment(el.day).format('MM'));
      monthInData.month.push({
        month: moment(el.day).format('MM'),
        occurence: countOccurrences(sortedData, moment(el.day).format('MM')),
      });
    }
  });

  monthInData.month.forEach((el, index) => {
    month.push({
      value: months[el.month - 1],
      occurence: el.occurence,
      fullWidth:
        sortedData.length === 7 * 13 &&
        el.occurence === 1 &&
        index === monthInData.month.length - 1,
    });
  });

  const usageDetailByCategories = [
    {
      content: '< 2h',
      value: 50,
      color: daysColor[50],
      occurence: '',
    },
    {
      content: 'Entre 2h et 5h',
      value: 100,
      color: daysColor[100],
      occurence: '',
    },
    {
      content: '> 5h',
      value: 150,
      color: daysColor[150],
      occurence: '',
    },
    {
      content: 'Utilisation Hors Contrat',
      value: 200,
      color: daysColor[200],
      occurence: '',
    },
  ];

  usageDetailByCategories.forEach((el) => {
    const needle = el.value;
    let i = 0;

    sortedData.forEach((s) => {
      const closest = Object.keys(daysColor).reduce((a, b) => {
        return Math.abs(b - s.value) < Math.abs(a - s.value) ? b : a;
      });
      if (closest.toString() === needle.toString()) {
        i += 1;
      }
    });
    // eslint-disable-next-line no-param-reassign
    el.occurence = i;
  });

  return (
    <div
      style={{
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '0.5fr 2.5fr 1fr',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <h5 style={{ fontWeight: 'bold' }}>
          Utilisation détaillée de la machine
        </h5>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(15, 1fr) auto',
            gridTemplateRows: 'repeat(8, 1fr)',
            gridAutoFlow: 'column',
            width: '50%',
            height: '100%',
          }}
        >
          <div
            style={{
              gridRow: 'span 1',
              gridColumn: 'span 15',
              display: 'grid',
              gridTemplateColumns: 'repeat(15, 1fr) auto',
              gridTemplateRows: '1fr',
              gridAutoFlow: 'row',
            }}
          >
            <div
              style={{
                borderTop: '1px solid black',
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
                backgroundColor: '#fff',
                gridRow: 'span 1',
                gridColumn: 'span 1',
              }}
            />
            {month.map((el) => {
              const needle = el.occurence;

              const closest = Object.keys(monthKeysWidth).reduce((a, b) => {
                return Math.abs(b - needle) < Math.abs(a - needle) ? b : a;
              });

              return (
                <div
                  style={{
                    borderTop: '1px solid black',
                    borderRight: '1px solid black',
                    borderBottom: '1px solid black',
                    backgroundColor: '#fff',
                    gridRow: 'span 1',
                    visibility: el.fullWidth && 'hidden',
                    gridColumn: `span ${monthKeysWidth[closest]} `,
                  }}
                >
                  <span
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {el.value}
                  </span>
                </div>
              );
            })}
          </div>

          {days.map((el, index) => {
            return (
              <div
                style={{
                  backgroundColor: '#fff',
                  gridRow: 'span 1',
                  gridColumn: 'span 1',
                  display: 'grid',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderTop: '1px solid black',
                  borderLeft: '1px solid black',
                  borderRight: '1px solid black',
                  borderBottom: index === days.length - 1 && '1px solid black',
                }}
              >
                {el.substring(0, 3)}
              </div>
            );
          })}

          {sortedData.map((el) => {
            const needle = el.value;

            const closest = Object.keys(daysColor).reduce((a, b) => {
              return Math.abs(b - needle) < Math.abs(a - needle) ? b : a;
            });

            const bgColor =
              closest.toString() === '0' && el.isWeekEnd
                ? `#656565`
                : daysColor[closest];

            return (
              <Tooltip title={el.day.substring(5, 10)} followCursor>
                <div
                  key={el.day}
                  style={{
                    backgroundColor: bgColor,
                    gridRow: 'span 1',
                    gridColumn: 'span 1',
                    borderRight: '1px solid black',
                    borderBottom: '1px solid black',
                  }}
                  onClick={onClick}
                />
              </Tooltip>
            );
          })}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          paddingLeft: '10px',
          paddingRight: '10px',
        }}
      >
        {usageDetailByCategories.map((el) => {
          return (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '40px 1fr',
                gridTemplateRows: '2fr',
                marginTop: '20px',
                gap: '10px',
              }}
            >
              <div
                style={{
                  backgroundColor: el.color,
                }}
              />
              <div>{el.content}</div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gridColumn: '1 / -1',
                  fontSize: '24px',
                  fontWeight: 'bold',
                }}
              >
                {el.occurence} j
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

CustomCalendar.propTypes = {
  start: PropTypes.object.isRequired,
  end: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      value: PropTypes.object,
    })
  ).isRequired,
  onClick: PropTypes.func,
};

CustomCalendar.defaultProps = {
  onClick: null,
};

export default CustomCalendar;
