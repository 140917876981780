/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Typography } from '@mui/material';
import lunr from 'lunr';
import moment from 'moment';
import { useEffect, useState } from 'react';
import capitalize from 'lodash/capitalize';

import { MachineIcon } from '../components/MachineIcon/MachineIcon';
import { isDateBellow24h } from './date/calcul';
import { formatTime, getFormatedDate } from './date/format';
import { usePeriodContext } from './hooks';

export const NUMBER_OF_HOUR_PER_DAY = 15;
export const FETCH_ALL_PAGE_SIZE = 3000000;
export const MACHINE_WORKING_STATES = {
  working: t`En marche`,
  stopped: t`À l'arrêt`,
  undefined: t`Indéterminé`,
  stateUndefined: t`Connexion perdue`,
};
const dayRef = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
];

export const mets = {
  // eslint-disable-next-line prettier/prettier
  'FMI Error code from MCM': t`Code d erreur FMI de MCM`,
  'SPN Error code from ACM': t`Code d erreur SPN d ACM`,
  'FMI Error code from ACM': t`Code d erreur FMI de l ACM`,
  'Transmission Oil Pressure': t`Pression d huile de transmission`,
  'Total Vehicle Distance': t`Distance totale du véhicule`,
  'Engine Intake Air Temperature': t`Température de l air d admission du moteur`,
  'Auxiliary I/O #03': t`E/S auxiliaire #03`,
  'Auxiliary I/O #02': t`E/S auxiliaire #02`,
  'Ambient Air Temperature': t`Température ambiante`,
  'Engine Coolant Level 1': t`Niveau de liquide de refroidissement moteur 1`,
  'Engine Air Filter 1 Differential Pressure': t`Pression différentielle du filtre à air du moteur 1`,
  'Aftertreatment Diesel Particulate Filter Active Regeneration Status': t`Statut de régénération active du filtre à particules diesel post-traitement`,
  'Telescop In status': t`Télescope en état`,
  'Cab/ Platform /RC mode status': t`État du mode cabine/plate-forme/RC`,
  'Fork / Bucket / Suspended load mode status': t`État du mode fourche/godet/charge suspendue`,
  'DEF tank level below 10%': t`Niveau du réservoir DEF inférieur à 10%`,
  'Direction engaged': t`Sens engagé`,
  'Strain gauge (Max/min)': t`Jauge de contrainte (Max/min)`,
  'Strain gauge (Average)': t`Jauge de contrainte (moyenne)`,
  'Engine Oil Temperature 1': t`Température d huile moteur 1`,
  'Diesel Particulate Filter Active Regeneration Inhibited Due to Inhibit Switch': t`Régénération active du filtre à particules diesel inhibée en raison de l interrupteur d inhibition`,
  'Aftertreatment SCR Operator Inducement Severity': t`Sévérité de l induction de l opérateur SCR après traitement`,
  'Aftertreatment 1 Diesel Particulate Filter Soot Load Percent': t`Post-traitement 1 Filtre à particules diesel Pourcentage de charge de suie`,
  'Aftertreatment 1 Diesel Particulate Filter Ash Load Percent': t`Post-traitement 1 Pourcentage de charge de cendres du filtre à particules diesel`,
  'STOP Lamp': t`Lampe ARRÊT`,
  'WARNING Lamp': t`Lampe d avertissement`,
  'Servicing Lamp': t`Lampe d entretien`,
  'Active Error Code': t`Code d erreur actif`,
  'Active Diagnostic Trouble Codes': t`Codes de diagnostic actifs`,
  'Door opened while driving': t`Porte ouverte en roulant`,
  'Travelling with boom angle high': t`Déplacement avec un angle de flèche élevé`,
  'Driving without seatbelt': t`Conduire sans ceinture de sécurité`,
  'Engine Coolant Temperature': t`Température du liquide de refroidissement du moteur`,
  'Engine Speed': t`La vitesse du moteur`,
  'Engine Total Hours of Operation': t`Nombre total d heures de fonctionnement du moteur`,
  'Maximum load': t`Charge maximale`,
  'Engine Total Fuel Used': t`Consommation totale de carburant du moteur`,
  'Engine Oil Pressure': t`Pression d huile moteur`,
  'Engine Percent Load At Current Speed': t`Pourcentage de charge du moteur à la vitesse actuelle`,
  'Aftertreatment 1 Diesel Exhaust Fluid Concentration': t`Post-traitement 1 Concentration du fluide d échappement diesel`,
  'Aftertreatment Diesel Particulate Filter Status': t`État du filtre à particules diesel de post-traitement`,
  'Aftertreatment 1 Diesel Exhaust Fluid Tank Level': t`Post-traitement 1 Niveau du réservoir de liquide d échappement diesel`,
  'Air Filter Clogging lamp': t`Lampe de colmatage du filtre à air`,
  'Alternator Not Charging lamp': t`Témoin d alternateur non chargé`,
  'Wheel-Based Vehicle Speed': t`Vitesse du véhicule basée sur les roues`,
  'Coolant Temperature lamp': t`Témoin de température du liquide de refroidissement`,
  'Dpf lamp': t`Lampe Dpf`,
  'Engine Fuel Rate': t`Taux de carburant du moteur`,
  'Engine Oil Pressure lamp': t`Témoin de pression d huile moteur`,
  'Exhaust System High Temperature Lamp Command': t`Commande de lampe à haute température du système d échappement`,
  'Fault Braking lamp': t`Voyant de freinage défectueux`,
  'Fuel Level': t`Niveau de carburant`,
  'Hydraulic Filter Clogging lamp': t`Lampe de colmatage du filtre hydraulique`,
  'Low Brake Fluid Level lamp': t`Témoin de bas niveau de liquide de frein`,
  'Low Coolant Fluid Level lamp': t`Voyant de niveau de liquide de refroidissement bas`,
  'Outriggers on ground': t`Stabilisateurs au sol`,
  Override: t`Passer outre`,
  'Scr lamp': t`Lampe Scr`,
  Temperature1: t`Température1`,
  Temperature2: t`Température2`,
  'Transmission Oil Temperature 1': t`Température de l huile de transmission 1`,
  'attachment recognition': t`reconnaissance d attachement`,
  'Boom movement cut off': t`Mouvement de flèche coupé`,
  'Current engine status': t`État actuel du moteur`,
  'Current ignition status': t`État d allumage actuel`,
  'Attachment Confirmed': t`Pièce jointe confirmée`,
  'Fuel less than 10%': t`Carburant moins de 10%`,
  'Command position': t`Poste de commandement`,
  'Machine Type (PLUS 2150 = 1;Plus 2550 = 2; etc)': t`Type de machine (PLUS 2150 = 1; Plus 2550 = 2; etc.)`,
  'Engine type (Mercedes-Benz = 1; Perkins = 2; etc)': t`Type de moteur (Mercedes-Benz = 1; Perkins = 2; etc.)`,
  'transmission type (Sauer = 1; Rexroth = 2;etc)': t`type de transmission (Sauer = 1; Rexroth = 2; etc.)`,
  'Diesel Particulate Filter Status': t`État du filtre à particules diesel`,
  'SPN Error code from CPC4 (Mercedes-Benza Master ECU)': t`Code d erreur SPN de CPC4 (Mercedes-Benza Master ECU)`,
  'FMI Error code from CPC4 (Mercedes-Benza Master ECU)': t`Code d erreur FMI de CPC4 (Mercedes-Benza Master ECU)`,
  'Distributor errors': t`Erreurs de distributeur`,
  'Transmission errors': t`Erreurs de transmission`,
  'MANITOU Error codes': t`MANITOU Codes d erreur`,
  'MANITOU Warning message': t`Message d avertissement MANITOU`,
  'Actual load': t`Charge réelle`,
  Radius: t`Rayon`,
  Height: t`Hauteur`,
  'turret position': t`position de la tourelle`,
  Angle: t`Angle`,
  'Lmi percentage': t`Pourcentage Lmi`,
  'SPN Error code from MCM': t`Code d erreur SPN de MCM`,
  'machine status': t`état de l appareil`,
  'machine tilt': t`inclinaison de la machine`,
  'tracker mode': t`mode de suivi`,
  'tracker operator': t`opérateur de suivi`,
  'tracker GMS cell': t`tracker cellule GMS`,
  'tracker network signal quality': t`qualité du signal du réseau de suivi`,
  'tracker temperature': t`température de suivi`,
  'tracker tension Vbat': t`traqueur de tension Vbat`,
  'tracker counter accelerometer': t`tracker compteur accéléromètre`,
  'tracker counter GPS': t`tracker compteur GPS`,
  'tracker counter GSM 2G': t`tracker compteur GSM 2G`,
  'tracker counter IOT network': t`tracker compteur réseau IOT`,
  'tracker counter firmware installation': t`installation du micrologiciel du compteur de suivi`,
  'tracker number of GPS cells': t`tracker nombre de cellules GPS`,
  'tracker error stack': t`pile d erreurs de suivi`,
  'tracker cmd number': t`numéro de commande de suivi`,
  'tracker status': t`état du traqueur`,
  'Engine Status': t`État du moteur`,
  'Battery Potential': t`Potentiel de la batterie`,
  Seat: t`Siège`,
  'Steering Default lamp': t`Témoin de défaut de direction`,
  'Transmission Oil Pressure lamp': t`Témoin de pression d huile de transmission`,
  'Transmission Oil Temperature lamp': t`Témoin de température d huile de transmission`,
  'Water In Fuel Indicator 1': t`Indicateur d eau dans le carburant 1`,
  'cumulative-operation-hours': t`heures-de-fonctionnement-cumulées`,
  'cumulative-idle-hours': t`heures d inactivité cumulées`,
  'battery-voltage': t`Voltage de batterie`,
  odometer: t`odomètre`,
  Battery: t`La batterie`,
  Distance: t`Distance`,
  Key: t`Clé`,
  Speed: t`Vitesse`,
  'Total Alert': t`Total des alertes`,
  Summary: t`Résumé`,
};

export const getStartedAlerts = (events) => {
  const sortedData = events.sort((a, b) => {
    const aTimestamp = moment(a._source.timestamp);
    const bTimestamp = moment(b._source.timestamp);

    return aTimestamp.diff(bTimestamp);
  });

  return sortedData.reduce((acc, event, index) => {
    // Identify when the alert start
    if (index === 0) {
      if (event._source.data.boolean === 'true') {
        acc.push({ start: event, ...event });
      }
    } else {
      const prevEvent = sortedData[index - 1];

      // Create a new alert if previous event is false and current is true or when
      if (
        prevEvent._source.data.boolean === 'false' &&
        event._source.data.boolean === 'true'
      ) {
        acc.push({ start: event, ...event });
      }

      // Stop the alert if the previous event is true and current is false
      if (
        prevEvent._source.data.boolean === 'true' &&
        event._source.data.boolean === 'false'
      ) {
        acc[acc.length - 1].end = event;

        const start = moment(acc[acc.length - 1].start._source.timestamp);
        const end = moment(acc[acc.length - 1].end._source.timestamp);

        acc[acc.length - 1].duration = moment
          .duration(end.diff(start))
          .as('milliseconds');
      }
    }

    return acc;
  }, []);
};

export const getTimeAgg = (period) => {
  if (period.end.diff(period.start, 'hours') < 25) {
    return 'hour';
  }
  if (
    period.end.diff(period.start, 'week') < 2 ||
    period.end.diff(period.start, 'week') === 2
  ) {
    return 'day';
  }
  if (period.end.diff(period.start, 'month') < 1) {
    return 'week';
  }
  if (
    period.end.diff(period.start, 'month') > 1 ||
    period.end.diff(period.start, 'month') === 1
  ) {
    return 'month';
  }
  return 'day';
};

export const useFetchData = (fetchData, title) => {
  const [period] = usePeriodContext();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    document.title = title;
    setLoading(true);
    setData([]);

    fetchData(setData, setLoading, period, getTimeAgg(period));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  return [data, isLoading];
};

// eslint-disable-next-line no-unused-vars
export const getDurationByAgg = (duration, _time) => {
  return duration / (60 * 60);
};

export const dynamicSort = (property) => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    // eslint-disable-next-line no-param-reassign
    property = property.substr(1);
  }
  return (a, b) => {
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

export const getEquipmentDescription = (data, defaultValue = '') => {
  const lang = i18n._locale;

  if (lang && data.descriptions?.longDescription) {
    const linguaUpperCase = `description${lang.toUpperCase()}`;
    // longDescription properties don't have the same format
    const linguaCapitalize = `description${
      lang.charAt(0).toUpperCase() + lang.slice(1)
    }`;

    if (data.descriptions.longDescription[linguaUpperCase]) {
      return data.descriptions.longDescription[linguaUpperCase];
    }
    if (data.descriptions.longDescription[linguaCapitalize]) {
      return data.descriptions.longDescription[linguaCapitalize];
    }
  }

  if (data.description) {
    return data.description;
  }

  return defaultValue;
};

export const getMachineStatus = (
  engineRunning,
  isEngineRunningDatetimeBellow24h = false
) => {
  if (!isEngineRunningDatetimeBellow24h) {
    return t`Indéterminé`;
  }

  const machineStatus = {
    true: t`En marche`,
    false: t`À l'arrêt`,
  };

  return machineStatus[engineRunning]
    ? machineStatus[engineRunning]
    : t`Indéterminé`;
};

export const getCells = (machine) => {
  const isMachineUpdateBellow24H = isDateBellow24h(machine.lastSyncTime);

  return [
    <MachineIcon
      pim={machine.pim}
      pimKey="product"
      style={{ height: '60px', width: 'auto' }}
    />,
    <Typography>
      {`${getEquipmentDescription(machine, machine.model)} (${
        machine.rentalmanId
      })`}
    </Typography>,
    <Typography>
      {machine.rentalStart && machine.estimatedEnd
        ? `${t`Du`} ${getFormatedDate(
            machine.rentalStart
          )} ${t`au`} ${getFormatedDate(machine.estimatedEnd)}`
        : null}
    </Typography>,
    <>
      <Typography
        color={
          isMachineUpdateBellow24H && machine.engineRunning
            ? 'success.main'
            : 'secondary.main'
        }
      >
        {getMachineStatus(
          isMachineUpdateBellow24H ? machine.engineRunning : null
        )}
      </Typography>
      <Typography>
        {machine.lastSyncTime &&
          moment(machine.lastSyncTime).format('[Le] L [à] LTS')}
      </Typography>
    </>,
  ];
};

export const spreadToDayAndNorm = (arr) => {
  const sortedArr = arr.sort((a, b) => {
    return moment(a.x)
      .format('HH:mm')
      .localeCompare(moment(b.x).format('HH:mm'));
  });
  const formArr = sortedArr.map((item) => {
    return { ...item, x: moment(item.x).format(formatTime.hour) };
  });
  const liftedArr = dayRef.map((r) => {
    const e = formArr.filter((f) => f.x === r);
    if (e.length === 1) {
      return { x: r, y: e[0].y };
    }
    return { x: r, y: 0 };
  });
  liftedArr.forEach((item, index) => {
    if (item.y > 1) {
      const over = item.y - 1; // don't ask why
      liftedArr[index].y = 1;

      if (liftedArr[index + 1]) {
        liftedArr[index + 1].y = liftedArr[index + 1].y + over;
      }
    }
  });
  return liftedArr;
};

export const spreadToDay = (arr) => {
  const sortedArr = arr.sort((a, b) => {
    return moment(a.x)
      .format('HH:mm')
      .localeCompare(moment(b.x).format('HH:mm'));
  });
  const formArr = sortedArr.map((item) => {
    return { ...item, x: moment(item.x).format(formatTime.hour) };
  });
  return dayRef.map((r) => {
    const e = formArr.filter((f) => f.x === r);
    if (e.length === 1) {
      return { x: r, y: e[0].y };
    }
    return { x: r, y: 0 };
  });
};

export const getLabels = (arr, time) => {
  return [...arr.map((item) => item.x)]
    .sort((a, b) => {
      switch (time) {
        case 'hour':
          return moment(a)
            .format('HH:mm')
            .localeCompare(moment(b).format('HH:mm'));
        case 'day':
          return moment(a).format('YYYYMMDD') - moment(b).format('YYYYMMDD');
        case 'month':
          return moment(a).format('MM') - moment(b).format('MM');
        default:
          return a.valueOf() - b.valueOf();
      }
    })
    .map((item) => {
      if (time === 'week') {
        return item;
      }
      // very deep workaround
      if (item === '00:00') {
        return '00:00';
      }
      return moment(item).format(formatTime[time]);
    })
    .filter((item, index, array) => array.indexOf(item) === index);
};

// lunrIndex for top search bar
export const MACHINE_FIELDS = [
  { field: 'rentalmanId', attributes: {} },
  {
    field: 'descriptionEn',
    attributes: {
      extractor: (doc) =>
        doc.descriptions?.longDescription?.descriptionEn ?? doc.description,
    },
  },
  {
    field: 'descriptionFR',
    attributes: {
      extractor: (doc) => doc.descriptions.longDescription.descriptionFR,
    },
  },
];

export const AGENCY_FIELDS = [
  { field: 'city', attributes: {} },
  { field: 'name', attributes: {} },
  { field: 'rentalmanId', attributes: {} },
  { field: 'streetAddress', attributes: {} },
  { field: 'streetNumber', attributes: {} },
];

export const JOBSITE_FIELDS = [
  { field: 'city', attributes: {} },
  { field: 'description', attributes: {} },
  { field: 'name', attributes: {} },
  { field: 'postalCode', attributes: {} },
  { field: 'rentalmanId', attributes: {} },
  { field: 'streetAddress', attributes: {} },
  { field: 'streetNumber', attributes: {} },
  { field: 'customerNumber', attributes: {} },
];

export const CONTRACT_FIELDS = [
  { field: 'contractNumber', attributes: {} },
  { field: 'purchaseOrderNumber', attributes: {} },
];

// lunrIndex for equipment table search bar
export const MACHINE_FIELDS_TABLE = [
  {
    field: 'descriptionEn',
    attributes: {
      extractor: (doc) =>
        doc.descriptions?.longDescription?.descriptionEn ?? doc.description,
    },
  },
  {
    field: 'descriptionFR',
    attributes: {
      extractor: (doc) => doc.descriptions?.longDescription?.descriptionFR,
    },
  },
  { field: 'rentalmanId', attributes: {} },
  { field: 'purchaseOrderNumber', attributes: {} },
  { field: 'jobSiteDesc', attributes: {} },
  {
    field: 'jobSiteId',
    attributes: {},
  },
];

export const JOBSITE_FIELDS_TABLE = [
  { field: 'name', attributes: {} },
  { field: 'city', attributes: {} },
  { field: 'streetAddress', attributes: {} },
  { field: 'streetNumber', attributes: {} },
];

export const AGENCY_FIELDS_TABLE = [
  { field: 'name', attributes: {} },
  { field: 'city', attributes: {} },
  { field: 'streetAddress', attributes: {} },
  { field: 'streetNumber', attributes: {} },
  { field: 'email', attributes: {} },
  { field: 'rentalmanId', attributes: {} },
];

export const RESULT_NUMBER_PER_CATEGORY = 5;

export const SEARCH_HISTORIC_KEYS = {
  machine: 'Machine-search-historic',
  agency: 'Agency-search-historic',
  jobSite: 'JobSite-search-historic',
  contract: 'PurchaseOrder-search-historic',
};

// generate LunrIndex
export const generateLunrIndex = (fieldNames, documents) => {
  try {
    // eslint-disable-next-line func-names
    const resp = lunr(function () {
      fieldNames.forEach((name) => this.field(name.field, name.attributes));
      documents.forEach((doc) => {
        this.add(doc);
      }, this);
    });
    return resp;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const extractSearcMatchedData = (initialData, searchResult) => {
  if (!searchResult?.length) {
    return [];
  }

  const refArray = searchResult.map((res) => res.ref);
  return initialData.filter((data) => refArray.includes(data.id));
};

export const createSearchQuery = (terms) => {
  let goodTerms = terms.replace(/\s\s+/g, ' '); // replace multiple space by only one
  goodTerms = goodTerms.replace(' ', '');
  // goodTerms = goodTerms.trim();
  goodTerms = goodTerms.split(' '); // get every term in the query
  let searchTerms = '';
  goodTerms.forEach((term) => {
    searchTerms += ` *${term}* ${term}~0 `;
  });
  return searchTerms.trim();
};

export const getArrayMaxOrMinValue = (arr, key, type) => {
  return Math[type](
    ...arr.map((o) => {
      return o[key];
    })
  );
};

export const matriceSum = (a, b) => {
  return a.reduce(
    // eslint-disable-next-line no-return-assign, no-param-reassign, no-sequences
    (p, c, i) => ((p[i] = c.reduce((f, s, j) => ((f[j] += s), f), p[i])), p),
    b.slice()
  );
};

export const tryToParse = (element) => {
  let resp = {};

  try {
    resp = JSON.parse(element);
  } catch (e) {
    console.error(e);
  }

  return resp;
};

export const copyTextToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    console.error('Error', err);
  }
};

export const removeDuplicateByKeys = (data, keys = ['id']) => {
  if (data && Array.isArray(data)) {
    const filteredData = data.filter(
      (value, index, self) =>
        self.findIndex((v) => keys.every((k) => v[k] === value[k])) === index
    );
    return filteredData;
  }
  return data;
};

export const capitalizeOrDefault = (str) => {
  if (!str) {
    return '-';
  }

  return capitalize(str);
};
